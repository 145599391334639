import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Row, Col, Container } from "react-bootstrap";
import { AccountBalanceOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SvgIcon } from "@mui/material";
import { Home, Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import BadgeAvatar from "../../../BadgeAvatar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MembersFilter from "../../Filters/MembersFilter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import http from "../../../../http";
import PreLoader from "../../../PreLoader";
import { PLSIZE } from "../../../PavilionConstants";
import InfiniteScroll from "react-infinite-scroller";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Like from "@mui/icons-material/FavoriteBorder";
import Comment from "@mui/icons-material/MessageOutlined";
import Share from "@mui/icons-material/ReplyOutlined";
import Flag from "@mui/icons-material/OutlinedFlag";
import MenuIcon from "@mui/icons-material/Menu";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import TelegramIcon from "@mui/icons-material/Telegram";
import Collapse from "react-bootstrap/Collapse";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { CKEditor, ImageInsert } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CRUDDropdown from "../../../CRUDDropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../PavilionConstants";
import Swal from "sweetalert2";
import Comments from "../../../Comments";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import CompanyIcon from '@mui/icons-material/ApartmentOutlined';
import WorkIcon from '@mui/icons-material/WorkOutlineOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function PRFLWhosWho(props, { handleClick }) {
  const [showFilter, setShowFilter] = useState(false);
  const [users, setUsersData] = useState([]);
  const [userssearch, setUserssearch] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [searchhasMore, setSearchHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const [searchloadMsg, setSearchloadMsg] = useState(
    <PreLoader size={PLSIZE} />
  );
  const [post, setPost] = useState([]);
  const [domainName, setDomain] = useState("");
  const [writeData, setWriteData] = useState("Post");
  const [editorData, setEditorData] = useState("");
  const [byName, setByName] = useState("");
  const [byOrg, setByOrg] = useState("");
  const [name_contains, setNameContains] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [ecosystemFilter, setEcosystemFilter] = useState("");
  const [industriesFilter, setIndustriesFilter] = useState("");
  const [areaOfInterestFilter, setAreaOfInterestFilter] = useState("");
  const [bucketName, setBucketName] = useState([]);
  const [countrylist, setCountrylist] = useState([]);
  const [industriesSectors, setIndustriesSectors] = useState([]);
  const [areaOfInterests, setAreaOfInterests] = useState([]);
  const [ecosystemnameArray, setEcosystemnameArray] = useState([]);
  const [htmlContent, setHtmlContent] = useState();
  const [htmlSearchContent, setHtmlSearchContent] = useState(false);
  const [postsValues, setPostsValues] = useState([]);
  const [pavilionSlug, setpavilionSlug] = useState("");
  const [open, setOpen] = useState({});
  const [key, setKey] = useState("");

  const token = localStorage.getItem("token");
  const handleByNameChange = (e) => {
    setByName(e.target.value);
  };
  const handleByOrgChange = (e) => {
    setByOrg(e.target.value);
  };
  const handleNameContainsChange = (e) => {
    setNameContains(e.target.value);
  };
  const handleRoleFilterChange = (e) => {
    setRoleFilter(e.target.value);
  };
  const handleCountryFilterChange = (e) => {
    setCountryFilter(e.target.value);
  };
  const handleEcosystemFilterChange = (e) => {
    setEcosystemFilter(e.target.value);
  };
  const handleIndustriesFilterChange = (e) => {
    setIndustriesFilter(e.target.value);
  };
  const handleAreaOfInterestFilterChange = (e) => {
    setAreaOfInterestFilter(e.target.value);
  };
  function handleDropdown(event) {
    event.stopPropagation();
  }

  const handleEdit = (id, editor) => {
    setWriteData("Update");
    console.log("handleEdit");
    http()
      .get("/singlewallpost/" + props.idx + "/" + id + "/post")
      .then((data) => {
        setEditorData(
          data.data.data.post.post_content.replace(
            domainName + "posts_attachments",
            bucketName + "/posts_attachments"
          )
        );
      });
  };
  const handleFollowToggle = (id, status) => {
    http()
      .post("/followorunfollow", { to_id: id })
      .then((response) => {
        setUsersData((prevUsers) =>
          prevUsers.map((user) =>
            user.user_id === id ? { ...user, is_following: !status } : user
          )
        );
      })
      .catch((error) => {
        console.error("There was an error updating the follow status!", error);
      });
  };

  const fetchData = async () => {
    console.log("pageno", page);

    const response = await http().get(
      `/webWhoswhoMembers?page=${page}&name=${byName}&role_id=${roleFilter}&name_contains=${name_contains}&organization=${byOrg}&ecosystem=${ecosystemFilter}&country_id=${countryFilter}&describe_id=${industriesFilter}&intrests_area=${areaOfInterestFilter}`
    );
    const data = response.data.data.all_users.data;
    const last_page = response.data.data.all_users.last_page;

    setUsersData((prevUsers) => [...prevUsers, ...data]);

    setPermissions(response.data.data.permission);

    if (page <= last_page) {
      setPage(page + 1);
      setHasMore(true);
      setLoadMsg(<PreLoader size={PLSIZE} />);
    } else {
      setHasMore(false);
      setLoadMsg("No More Records...");
    }
  };
  useEffect(() => {
    fetchData();
    http()
      .get("/roles/10")
      .then((data) => {
        setPost(data.data.data.roles);
      });
    http()
      .get("/memberFilterNames/10")
      .then((data) => {
        setCountrylist(data.data.data.countries);
        setIndustriesSectors(data.data.data.industriesSectors);
        setAreaOfInterests(data.data.data.areaOfInterests);
        setEcosystemnameArray(data.data.data.ecosystemnameArray);
      });
    setHtmlContent(true);
  }, []);

  useEffect(() => {
    if (key === "members") {
      fetchData();
    }
    if (key === "wallposts") {
      fetchData1();
    }
  }, [key]);

  const searchwhoswhofilter = () => {
    setHtmlContent(false);
    setByName("");
    setByOrg("");
    setNameContains("");
    setRoleFilter("");
    setCountryFilter("");
    setEcosystemFilter("");
    setIndustriesFilter("");
    setAreaOfInterestFilter("");
    setShowFilter(true);
    //  setUsersData([])
    setHtmlContent(true);
    setLoadMsg("");
    //  setHasMore(false);
    setPage(1);
  };
  const handleMemberSearch = () => {
    setPage(1);
    setUsersData([]);
    setHasMore(false);
    setHtmlContent(true);
    setShowFilter(false);
    fetchData(1);
  };

  const loadMembers = () => {
    setPage(1);
    setByName("");
    setByOrg("");
    setNameContains("");
    setRoleFilter("");
    setCountryFilter("");
    setEcosystemFilter("");
    setIndustriesFilter("");
    setAreaOfInterestFilter("");
    setUsersData([]);
    setHasMore(false);
    setHtmlContent(true);
    setShowFilter(false);

    fetchData();
  };

  const loadWallPosts = () => {};

  const socialPopUp = (dataurl) => {
    const width = 550;
    const height = 420;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    window.open(
      dataurl,
      "twitter-share-dialog",
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete this Post?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: "auto",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Post Deleting!",
          html: "Deleting Post, Please wait...",
          timerProgressBar: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          iconColor: "#e30613",
          didOpen: () => {
            Swal.showLoading();
            http()
              .post("/delete_resource", {
                id: id,
                type: "post",
              })
              .then((data) => {
                Swal.fire({
                  title: "Post is deleted successfully",
                  icon: "success",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
                http()
                  .get("/wallfeed/" + props.idx)
                  .then((data) => {
                    setPostsValues(data.data.data.posts.data);
                    setDomain(data.data.data.domain);
                  });
                http()
                  .get("/about/" + props.idx)
                  .then((data) => {
                    setpavilionSlug(data.data.data.aboutPavilion.slug);
                  });
              });
          },
          willClose: () => {
            //clearInterval(timerInterval);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      }
    });
  };

  const handleCommentsClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };
  const handleCommentsLikeClick = (id) => {
    http()
      .post("/likes/store", {
        type: "post",
        post_id: id,
      })
      .then((data) => {
        console.log(data);
        http()
          .get("/singlewallpost/" + props.idx + "/" + id + "/post")
          .then((data) => {
            console.log(data.data.data.post.likes.length);
            document.getElementById("postlikecount" + id).innerHTML =
              data.data.data.post.likes.length;
          });
      });
  };

  const handleCopyUrl = (dataurl) => {
    navigator.clipboard
      .writeText(dataurl)
      .then(() => {
        Swal.fire({
          title: "Text copied to clipboard!",
          text: dataurl,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const fetchData1 = async (__page) => {
    //console.log(__page);
    if (token) {
      await http()
        .get(`/wallfeed/${props.idx}?page=${page2}`)
        .then((response) => {
          setHasMore(true);
          //console.log(response.data.data.posts.data)
          if (response.data.data.posts.data.length > 0) {
            setPostsValues([...postsValues, ...response.data.data.posts.data]);
            setDomain(response.data.data.domain);
          }
          const last_page = response.data.data.posts.last_page;
          if (page2 < last_page) {
            setPage2(page2 + 1);
            setHasMore(true);
            setLoadMsg(<PreLoader size={PLSIZE} />);
          } else {
            setHasMore(false);
            setLoadMsg("No More Records...");
          }
        });
    }
  };

  return (
    <ListGroup
      className="zoom overflow-hidden h-100 px-0"
      style={{ borderRadius: "15px" }}
    >
      <ListGroup.Item
        variant="light"
        className="d-flex justify-content-between align-items-center border-0 text-center py-0 "
      >
        <div>
          {showFilter && (
            <IconButton onClick={() => setShowFilter(false)}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="small" className="text-bw">
            {!showFilter && "Who's Who"}
            {showFilter && "Who's Who Filter"}
          </Typography>
        </div>
        <div>
          {props.section === "left" ? (
            <IconButton
              className="btn btn-outline-mode"
              onClick={(event) => props.handleClick("PRFL-MENU1")}
            >
              <Home />
            </IconButton>
          ) : (
            <IconButton
              className="btn btn-outline-mode"
              onClick={(event) => props.handleClick("PVLN-HOME", props.idx)}
            >
              <Home />
            </IconButton>
          )}
          <IconButton
            className="btn btn-outline-mode"
            onClick={() => searchwhoswhofilter()}
          >
            <FilterAltIcon />
          </IconButton>
        </div>
      </ListGroup.Item>
      {!showFilter && (
        <ListGroup.Item
          key="member-header"
          className="d-flex flex-column align-items-start justify-content-start h-100  border-0 bg-light bg-opacity-75 px-2"
        >
          <div className="w-100  p-0 ">
            <Tabs
              size="sm"
              className="w-100"
              defaultActiveKey="members"
              id="whoswhoTabs"
              className="mb-3 pb-0"
              fill
              onSelect={(eKey) => setKey(eKey)}
            >
              <Tab eventKey="members" title="Members" className="tabcss">
                <div
                  className="container  py-0  px-2 slim-scrollbar"
                  style={{ overflow: "auto", maxHeight: "60vh" }}
                >
                  {htmlContent && (
                    <>
                      <InfiniteScroll
                        pageStart={1}
                        loadMore={fetchData}
                        hasMore={hasMore}
                        initialLoad={false}
                        useWindow={false}
                      >
                        {users.map((innerArray, outerIndex) => (
                          <>
                            <Row className="my-0" key={innerArray.id}>
                              <Col xs={2} md={2}>
                                <a
                                  target="_blank"
                                  href={"/" + innerArray.my_link}
                                >
                                  <>
                                    {innerArray.image ? (
                                      <BadgeAvatar
                                        src={
                                          localStorage.getItem("bucketName") +
                                          "/images/profile_picture/" +
                                          innerArray.image
                                        }
                                        name="Stephen Metcalfe"
                                        size="40"
                                      />
                                    ) : (
                                      <BadgeAvatar
                                        name={innerArray.name}
                                        size="40"
                                      />
                                    )}
                                  </>
                                </a>
                              </Col>
                              <Col md={7} xs={7}>
                                <a
                                  target="_blank"
                                  href={"/" + innerArray.my_link}
                                >
                                  <>
                                    <h6 className="mb-0">{innerArray.name}</h6>{" "}
                                  </>
                                </a>
                                

                                {innerArray.designation !== "" &&
                                      innerArray.designation && (
                                <div >
                                  <small>
                                      {innerArray.designation}
                                  </small>
                                </div>
                        )}
                                {innerArray.organization !== "" &&
                                      innerArray.organization && (
                                <div >
                                  <small>
                                      {innerArray.organization}
                                  </small>
                                </div>
                        )}
                              </Col>
                              <Col md={3} xs={3} className="text-end">
                                {permissions["follow-member"] && (
                                  <Button className='py-0'
                                    variant="primary"
                                    size="sm"
                                    variant={
                                      innerArray.is_following
                                        ? "secondary"
                                        : "primary"
                                    }
                                    onClick={() =>
                                      handleFollowToggle(
                                        innerArray.user_id,
                                        innerArray.is_following
                                      )
                                    }
                                  >
                                    {innerArray.is_following
                                      ? "Unfollow"
                                      : "Follow"}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-2" key={outerIndex}>
                               <Col md={2} xs={2}>
                                &nbsp;
                              </Col> 
                              <Col md={10} xs={10}>
                                <small>
                                  {innerArray.description &&
                                    innerArray.description.substring(0, 100)}
                                </small>
                                <br />
                              </Col> 
                              
                            </Row>
                          </>
                        ))}
                      </InfiniteScroll>
                      <div className="loader my-2 text-center" key={0}>
                        {loadMsg}
                      </div>
                    </>
                  )}
                </div>
              </Tab>
              <Tab eventKey="wallposts" title="Wall Posts" className="tabcss">
                <div
                  className="container  py-0  px-2 slim-scrollbar"
                  style={{ overflow: "auto", maxHeight: "60vh" }}
                >
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={fetchData1}
                    hasMore={hasMore}
                    initialLoad={true}
                    // loader={
                    //     <div className="loader" key={0}>
                    //         {loadMsg}
                    //     </div>
                    // }
                    useWindow={false}
                  >
                    {postsValues.map((innerArray, outerIndex) => (
                      <div>
                        <ListGroup
                          className="overflow-hidden px-0 mb-2 "
                          style={{ borderRadius: "5px" }}
                        >
                          <ListGroup.Item
                            variant="light"
                            className="d-flex justify-content-between align-items-center  border-0 text-center py-1 px-1"
                          >
                            <div className="w-100  d-flex justify-content-start align-items-center ">
                              <BadgeAvatar
                                src={
                                  localStorage.getItem("bucketName") +
                                  "/images/profile_picture/" +
                                  innerArray.user.image
                                }
                                name={innerArray.user.name}
                                size="30"
                                isLoggedIn={false}
                              />
                              <div className="ms-2 text-start ">
                                <h6 className="mb-0 lh-1">
                                  {innerArray.user.name}
                                </h6>
                                <small>
                                  {formatDate(innerArray.created_at)}
                                </small>
                              </div>
                            </div>
                            <CRUDDropdown
                              title={<MoreVertIcon />}
                              handleDropdown={handleDropdown}
                              editTitle="Edit Post"
                              editClick={(event) => handleEdit(innerArray.id)}
                              deleteTitle="Delete Post"
                              deleteClick={(event) =>
                                handleClickDelete(innerArray.id)
                              }
                            />
                            {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
                          </ListGroup.Item>
                          <ListGroup.Item
                            className="d-flex align-items-center justify-content-center h-100  border-0"
                            style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
                          >
                            <div className="container post-content px-0 py-1">
                              <p
                                className="lead"
                                dangerouslySetInnerHTML={{
                                  __html: innerArray.post_content.replace(
                                    domainName + "posts_attachments",
                                    localStorage.getItem("bucketName") +
                                      "/posts_attachments"
                                  ),
                                }}
                              ></p>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item
                            className="w-100 d-flex align-items-center justify-content-between h-100 p-0 border-0"
                            style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
                          >
                            <ButtonGroup
                              size="sm"
                              className=" w-100 rounded-bottom"
                            >
                              <Button
                                size="sm"
                                variant="outline-secondary"
                                className="small"
                                onClick={() =>
                                  handleCommentsLikeClick(innerArray.id)
                                }
                              >
                                <Like fontSize="small" />
                                &nbsp; Like &nbsp;
                                <Badge
                                  bg="primary"
                                  id={"postlikecount" + innerArray.id}
                                >
                                  {innerArray.likes.length}
                                </Badge>
                              </Button>
                              <Button
                                size="sm"
                                onClick={() =>
                                  handleCommentsClick(innerArray.id)
                                }
                                aria-controls="collapseComments3"
                                aria-expanded={open}
                                variant="outline-secondary"
                                className="small"
                              >
                                <Comment fontSize="small" /> Comment{" "}
                                <Badge
                                  bg="primary"
                                  id={"postCommentcount" + innerArray.id}
                                >
                                  {innerArray.all_comments.length > 0 &&
                                    innerArray.all_comments.length}
                                </Badge>
                              </Button>
                              {/* <Button size="sm" variant="outline-secondary" className="small"><Share fontSize='small' className="flipx" /> Share</Button> */}
                              <Dropdown
                                as={Button}
                                drop="up"
                                variant="outline-secondary"
                                className="p-0 m-0"
                              >
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  variant=""
                                  className="w-100 small d-flex justify-content-center align-items-center"
                                >
                                  <Share className="flipx" fontSize="small" />{" "}
                                  &nbsp; Share &nbsp;<Badge bg="info">9</Badge>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    className="small"
                                    onClick={() =>
                                      socialPopUp(
                                        "https://telegram.me/share/url?url=" +
                                          process.env.REACT_APP_SITE_URL +
                                          "/walls/" +
                                          innerArray.id
                                      )
                                    }
                                  >
                                    <TelegramIcon fontSize="small" /> Telegram
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small"
                                    onClick={() =>
                                      socialPopUp(
                                        "https://www.facebook.com/sharer.php?u=" +
                                          process.env.REACT_APP_SITE_URL +
                                          "/walls/" +
                                          innerArray.id
                                      )
                                    }
                                  >
                                    <FacebookIcon fontSize="small" /> Facebook
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small"
                                    onClick={() =>
                                      socialPopUp(
                                        "https://twitter.com/share?url=" +
                                          process.env.REACT_APP_SITE_URL +
                                          "/walls/" +
                                          innerArray.id
                                      )
                                    }
                                  >
                                    <TwitterIcon fontSize="small" /> Twitter
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small"
                                    onClick={() =>
                                      socialPopUp(
                                        "https://www.linkedin.com/sharing/share-offsite/?url=" +
                                          process.env.REACT_APP_SITE_URL +
                                          "/walls/" +
                                          innerArray.id
                                      )
                                    }
                                  >
                                    <LinkedInIcon fontSize="small" /> LinkedIn
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small"
                                    onClick={() =>
                                      handleCopyUrl(
                                        process.env.REACT_APP_SITE_URL +
                                          "/walls/" +
                                          innerArray.id
                                      )
                                    }
                                  >
                                    <ContentPasteIcon fontSize="small" /> Copy
                                    to Clipboard
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </ButtonGroup>
                          </ListGroup.Item>
                          <Collapse
                            key={outerIndex}
                            in={open[innerArray.id]}
                            className="p-1"
                          >
                            <ListGroup.Item>
                              <div id="collapseComments3">
                                <Comments
                                  postId={innerArray.id}
                                  pavilionId={props.idx}
                                  type="post"
                                />
                              </div>
                            </ListGroup.Item>
                          </Collapse>
                        </ListGroup>
                      </div>
                    ))}
                  </InfiniteScroll>

                  <div className="loader my-2 text-center" key={0}>
                    {" "}
                    {loadMsg}{" "}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          {/* <div className='w-100 d-flex justify-content-between p-2 '>
                        <Button variant="primary" size="sm" sx={{ padding: 1 }} onClick={loadMembers}>Members</Button>
                        <Button variant="primary" size="sm" sx={{ padding: 1 }} onClick={loadWallPosts}>Wall Posts</Button>
                    </div> */}
        </ListGroup.Item>
      )}
      {showFilter && (
        <ListGroup.Item className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
          <Container>
            <Form className="mx-0 px-0 text-center">
              <Form.Control
                size="sm"
                name="byName"
                type="text"
                placeholder="By Name"
                className="my-1 py-1"
                value={byName}
                onChange={handleByNameChange}
              />
              <Form.Control
                size="sm"
                name="byOrg"
                type="text"
                placeholder="By Organisations"
                className="my-1 py-1"
                value={byOrg}
                onChange={handleByOrgChange}
              />
              <Form.Select
                required
                size="sm"
                id="name_contains"
                name="name_contains"
                className="my-1 py-1"
                onChange={handleNameContainsChange}
              >
                <option value="" selected>
                  Name Starts/Ends/Contains
                </option>
                <option value="start">Name Starts with</option>
                <option value="end">Name Ends with</option>
                <option value="contain">Name Contains</option>
              </Form.Select>
              <Form.Select
                name="All Roles"
                size="sm"
                className="my-1 py-1"
                onChange={handleRoleFilterChange}
              >
                <option value="" selected>
                  All Roles
                </option>
                {post.map((innerArray, outerIndex) => (
                  <option value={innerArray.id}>{innerArray.name}</option>
                ))}
              </Form.Select>
              <Form.Select
                size="sm"
                id="country_id"
                name="country_id"
                className="my-1 py-1"
                onChange={handleCountryFilterChange}
              >
                <option value="" selected>
                  Country
                </option>
                {countrylist.map((countrylistArray, countrylistIndex) => (
                  <option value={countrylistArray.id}>
                    {countrylistArray.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                name="ecosystem"
                id="ecosystem"
                size="sm"
                className="my-1 py-1"
                onChange={handleEcosystemFilterChange}
              >
                <option value="">Select Ecosystem</option>
                {ecosystemnameArray.map(
                  (ecosystemnameArray, ecosystemnameArrayIndex) => (
                    <option value={ecosystemnameArray.name}>
                      {ecosystemnameArray.value}
                    </option>
                  )
                )}
              </Form.Select>
              <Form.Select
                required
                id="describe_id"
                size="sm"
                name="describe_id"
                className="my-1 py-1"
                onChange={handleIndustriesFilterChange}
              >
                <option value="" selected>
                  All Industries
                </option>
                {industriesSectors.map(
                  (industriesSectors, industriesSectorsIndex) => (
                    <option value={industriesSectors.id}>
                      {industriesSectors.name}
                    </option>
                  )
                )}
              </Form.Select>
              <Form.Select
                size="sm"
                name="intrests_area"
                id="area-of-interest"
                className="my-1 py-1"
                onChange={handleAreaOfInterestFilterChange}
              >
                <option value="" selected>
                  Area of Interest
                </option>
                {areaOfInterests.map(
                  (areaOfInterests, areaOfInterestsIndex) => (
                    <option value={areaOfInterests}>{areaOfInterests}</option>
                  )
                )}
              </Form.Select>

              <Button size="sm" onClick={handleMemberSearch}>
                Search
              </Button>
            </Form>
          </Container>
        </ListGroup.Item>
      )}
    </ListGroup>
  );
}

export default PRFLWhosWho;
