// icon:bxl-flickr-square | Boxicons https://boxicons.com/ | Atisa
import React from 'react'

import { SvgIcon } from '@mui/material';

function FlickrIcon(props) {
  return (
<SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 3H4a1 1 0 00-1 1v16a1 1 0 001 1h16a1 1 0 001-1V4a1 1 0 00-1-1zM8.747 14.746A2.745 2.745 0 1111.494 12a2.744 2.744 0 01-2.747 2.746zm6.506 0a2.746 2.746 0 11-.001-5.493 2.746 2.746 0 01.001 5.493z"
      />
    </svg>
  </SvgIcon>
  );
}

export default FlickrIcon;
