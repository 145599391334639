import React from "react";
import adminhttp from '../../adminhttp';

// export const items = (userid): Item[] => {

 export const getPermissions = () => {
  return adminhttp()
     .get(`admin/admin_user_permissions_by_role/${localStorage.getItem('localstorage_role_id') ? localStorage.getItem('localstorage_role_id') : 'all'}`)
     .then((data) => (data.data.data) )
  }
