import React, { useState } from "react";
import Comment from "./Comment";
// import { debounce } from "./utils";
import BadgeAvatar from '../BadgeAvatar'
import {Card, Row, Col, Form, Button, Container } from 'react-bootstrap'
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { IconButton } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAlt';
import ReplyIcon from '@mui/icons-material/Reply';
import Badge from 'react-bootstrap/Badge';

export default function Comments2() {
  let [commentInput, setCommentInput] = useState("");
  let [comments, setComments] = useState([
    {
      id: 1,
      display: "c1",
      children: [
        {
          id: 2,
          display: "c11",
          children: []
        },
        {
          id: 3,
          display: "c12",
          children: []
        }
      ]
    }
  ]);

  function addReply(commentId, replyText) {
    let commentsWithNewReply = [...comments];
    insertComment(commentsWithNewReply, commentId, replyText);
    setComments(commentsWithNewReply);
  }

  function newComment(text) {
    return {
      id: new Date().getTime(),
      display: text,
      children: []
    };
  }

  function insertComment(comments, parentId, text) {
    for (let i = 0; i < comments.length; i++) {
      let comment = comments[i];
      if (comment.id === parentId) {
        comment.children.unshift(newComment(text));
      }
    }

    for (let i = 0; i < comments.length; i++) {
      let comment = comments[i];
      insertComment(comment.children, parentId, text);
    }
    console.log(comments)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setComments([newComment(commentInput), ...comments]);
      setCommentInput("");
    }
  }
  function handleEmoji(e) {
    setCommentInput(commentInput + e.target.value)
  }

  const onFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();

    reader.onload = () => {
      //    getUploadedFile(reader.result);
      //         setImage(reader.result)
      console.log(reader.result)
    };
    reader.readAsDataURL(files[0]);

  };

  return (
    <>
    
      {/* <div className="comment-input-box">
        <textarea
          rows="4"
          cols="50"
          value={commentInput}
          onChange={(e) => {
            // debounce(setCommentInput(e.target.value));
            setCommentInput(e.target.value);
          }}
        />
        <br />
        <button
          onClick={() => {
            setComments([newComment(commentInput), ...comments]);
            setCommentInput("");
          }}
        >
          Submit
        </button>
      </div> */}
      <ul className="px-0 ">
        {comments.map((comment) => (
          <Comment key={comment.id} comment={comment} addReply={addReply} />
        ))}
      </ul>
      <div className="write-comment  mx-1 my-1 d-flex justify-content-between align-items-center px-0 ">
      <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} name="Stephen Metcalfe" size='25' />
      <div className="input-holder w-100 ps-2 pe-0 d-flex justify-content-between align-items-center">
        <Form.Control value={commentInput}
        onKeyPress={handleKeyPress}
          onChange={(e) => {setCommentInput(e.target.value);}}  id="commentsField"  name="commendsField" size="small" className='py-0' type="text" placeholder="Write a comment..." />
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            className='d-none'
            onChange={onFileChange}
          />
          {/* <Button as="span" size="sm " variant="outlined-secondary" className="d-flex align-items-center p-1 " ><AddPhotoIcon fontSize="small" />&nbsp;Add Photo</Button> */}
          <IconButton as="span" size="small" style={{ cursor: 'pointer' }} variant="secondary" ><AddPhotoIcon /></IconButton>
        </label>
        <Dropdown >
          <Dropdown.Toggle as={IconButton} size="small" id="dropdown-split-basic" ><AddReactionIcon /></Dropdown.Toggle>
          <Dropdown.Menu >
            <div className='d-flex align-items-center flex-wrap justify-content-center'>
              <IconButton value="😁" onClick={handleEmoji} size="small">😀</IconButton>
              <IconButton value="😁" onClick={handleEmoji} size="small">😁</IconButton>
              <IconButton value="😂" onClick={handleEmoji} size="small">😂</IconButton>
              <IconButton value="😃" onClick={handleEmoji} size="small">😃</IconButton>
              <IconButton value="😄" onClick={handleEmoji} size="small">😄</IconButton>
              <IconButton value="😅" onClick={handleEmoji} size="small">😅</IconButton>
              <IconButton value="😆" onClick={handleEmoji} size="small">😆</IconButton>
              <IconButton value="😇" onClick={handleEmoji} size="small">😇</IconButton>
              <IconButton value="😈" onClick={handleEmoji} size="small">😈</IconButton>
              <IconButton value="😉" onClick={handleEmoji} size="small">😉</IconButton>
              <IconButton value="😋" onClick={handleEmoji} size="small">😋</IconButton>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
    </>
  );
}
