import React from 'react'
import { Button, Container } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ListGroup } from 'react-bootstrap';
import { Avatar } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import InfoIcon from '@mui/icons-material/Info';
import BadgeAvatar from '../../../components/BadgeAvatar';

function MyCommunityTab(props) {
  return (
    <Container className="mt-2 px-0">
      <Tabs
        defaultActiveKey="librarydocument"
        id="uncontrolled-tab-example"
        className="mb-0"
      >
        <Tab eventKey="librarydocument" title="Followers" >
          <ListGroup >
            {Array.from({ length: 8 }).map((_, idx) => (

              <ListGroup.Item key={idx} className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                <a href="/shareprofiles/stephen-metcalfe-88e929b053" className="py-2">
                  <div className="d-flex justify-content-start align-items-center">
                    <BadgeAvatar src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} name="Stephen Metcalfe" size='120'/>
                    <div className="mx-3 d-flex flex-column">
                      <h4 className="mb-1 text-primary">Stephen Metcalfe</h4>
                      <p className="text-muted mb-0 d-flex-mi">
                        <WorkIcon />&nbsp;House of Commons - UK Parliament
                      </p>
                      <p className="text-muted mb-0 d-flex-mi">
                        <InfoIcon />&nbsp;Stephen Metcalfe MP is Co-Chairman of the All-Party Parliamentary Group on Artificial Intelligence

                      </p>
                    </div>
                  </div>
                </a>
                <Button>Unfollow</Button>

              </ListGroup.Item>
            ))}
          </ListGroup>
        </Tab>
        <Tab eventKey=" profile" title="Followings">
          <ListGroup >
            {Array.from({ length: 8 }).map((_, idx) => (
              <ListGroup.Item key={idx} className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                <a href="/shareprofiles/stephen-metcalfe-88e929b053" className="py-2">
                  <div className="d-flex justify-content-start align-items-center">
                    <BadgeAvatar src="" name="Stephen Metcalfe" size='120'/>

                    <div className="mx-3 d-flex flex-column">
                      <h4 className="mb-1 text-primary">Stephen Metcalfe</h4>
                      <p className="text-muted mb-0 d-flex-mi">
                        <WorkIcon />&nbsp;House of Commons - UK Parliament
                      </p>
                      <p className="text-muted mb-0 d-flex-mi">
                        <InfoIcon />&nbsp;Stephen Metcalfe MP is Co-Chairman of the All-Party Parliamentary Group on Artificial Intelligence

                      </p>
                    </div>
                  </div>
                </a>
                <Button>Unfollow</Button>

              </ListGroup.Item>
            ))}
          </ListGroup>
        </Tab>

      </Tabs>
    </Container>
  )
}

export default MyCommunityTab
