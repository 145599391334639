import React from 'react';
import validator from 'validator';

function Validation(values){
    let error = {}
    const email_pattern=/^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const password_pattern=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{10,}$/
    
    if(values.email===''){
        error.email='Email should not be empty'
    } else if(!email_pattern.test(values.email)) {
        error.email='Email did not match'
    } 

    if(values.password===''){
        error.password='Password should not be empty'
    } else if(!password_pattern.test(values.password)) {
        error.password='Password did not match'
    } 

    return error;

}
export default Validation;