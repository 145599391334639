import React, { useState, useEffect } from 'react'
import PRFLMenu1 from './LeftSection/PRFLMenu1'
import PRFLMenu2 from './LeftSection/PRFLMenu2'
import PRFLMyPublicProfile from './LeftSection/PRFLMyPublicProfile'
import PRFLUpdateProfile from './LeftSection/PRFLUpdateProfile'
import PRFLPurchasedHistory from './LeftSection/PRFLPurchasedHistory'
import PRFLHelpSupport from './LeftSection/PRFLHelpSupport'
import PRFLInfo from './LeftSection/PRFLInfo'
import PRFLMyPavilions from './LeftSection/PRFLMyPavilions'
import PRFLWhosWho from './LeftSection/PRFLWhosWho'
import PRFLMyCommunity from './LeftSection/PRFLMyCommunity'
import PRFLMyEvents from './LeftSection/PRFLMyEvents'
import PRFLBusinessServices from './LeftSection/PRFLBusinessServices'
import { useSearchParams } from "react-router-dom";
import PRFLProgramDetails from '../Boxes/LeftSection/Public/PRFLInsights'
import PRFLFeaturedOfferings from '../Boxes/LeftSection/Public/PRFLFeaturedOfferings'
import PRFLSupporters from '../Boxes/LeftSection/Public/PRFLSupporters'
import PRFLUpcomingEvents from '../Boxes/LeftSection/Public/PRFLUpcomingEvents'
import PRFLMyResources from './LeftSection/PRFLMyResources'
import PRFLMyWall from './LeftSection/PRFLMyWall'
import PRFLMyWallGallery from './LeftSection/PRFLMyWallGallery'
import PRFLInsights from './LeftSection/Public/PRFLInsights'
import { useNavigate } from "react-router-dom"
import PRFLPublicPavilion from './LeftSection/Public/PRFLPublicPavilion'
import { useLocation } from 'react-router-dom';
import PRFLPublicProfile from './LeftSection/Public/PRFLPublicProfile'
import PRFLMenu3 from './LeftSection/PRFLMenu3'

function LeftBox(props) {
    const location = useLocation();
    const [showPRFL,setShowPRFL]=useState('PRFL-MENU1');
    // const [searchParams, setSearchParams] = useSearchParams();
    const  navigate = useNavigate();
    
    
    useEffect(() => { 
        const splitURL = location.pathname.toString().split("/");   
        const param = ['poll','walls','event','publication','newsinformation'];

        if(props.profile===0){setShowPRFL('PRFL-UPPRO')}

        if(param.includes(splitURL[1])){
            if(splitURL[2]){ 
                setShowPRFL('PRFL-PPVLN'); 
            }
        }
        if(splitURL[1]==='preview'){
            if(splitURL[2]){ setShowPRFL('PRFL-MENU3'); }
         } 
        if(splitURL[1]==='about_pavilion'){
             if(splitURL[2]){ setShowPRFL('PRFL-MENU2'); }
          }
          if(splitURL[1]==='shareprofiles'){
            if(splitURL[2]){ setShowPRFL('PRFL-PUBLP'); }
         }
        //  if(props.profile===0){
        //     setShowPRFL('PRFL-MYPRF');
        //  }
        
        //},[showPRFL]);   // for all pavilions left side
        },[props.profile]);   //for about_pavilion menu
    
        
        
        
    
    const handleClick = (count,idx) => {
        props.change(count, idx);
        if(count==='PVLN-MENU'){
            
        } else {
            setShowPRFL(count);
        }
        
        if(count==='PRFL-MENU1'){
            navigate('/homepavilion')
        }
    }

    return (
        <>
         {showPRFL==='PRFL-MENU1' && (<PRFLMenu1 handleClick={handleClick} />)}
         {showPRFL==='PRFL-MENU2' && (<PRFLMenu2 handleClick={handleClick} />)}
         {showPRFL==='PRFL-MENU3' && (<PRFLMenu3 handleClick={handleClick} />)}

         {showPRFL==='PRFL-MYPPR' && (<PRFLMyPublicProfile handleClick={handleClick} />)}
         {showPRFL==='PRFL-UPPRO' && (<PRFLUpdateProfile profile={props.profile} handleClick={handleClick} />)}
         {showPRFL==='PRFL-MYCOM' && (<PRFLMyCommunity handleClick={handleClick} />)}
         {showPRFL==='PRFL-MYPAV' && (<PRFLMyPavilions handleClick={handleClick} />)}
         {showPRFL==='PRFL-WHOSW' && (<PRFLWhosWho idx={10} section='left' handleClick={handleClick} />)}
         {showPRFL==='PRFL-MYEVE' && (<PRFLMyEvents handleClick={handleClick} />)}

         {showPRFL==='PRFL-MYRES' && (<PRFLMyResources handleClick={handleClick} />)}
         {showPRFL==='PRFL-MYWAL' && (<PRFLMyWall handleClick={handleClick} />)}
         {showPRFL==='PRFL-MYWAG' && (<PRFLMyWallGallery handleClick={handleClick} />)}

         {showPRFL==='PRFL-PHIST' && (<PRFLPurchasedHistory handleClick={handleClick} />)}
         {showPRFL==='PRFL-HSUPP' && (<PRFLHelpSupport handleClick={handleClick} />)}
         {showPRFL==='PRFL-INFOR' && (<PRFLInfo handleClick={handleClick} />)}
         {showPRFL==='PRFL-BSERV' && (<PRFLBusinessServices handleClick={handleClick} />)}

         {showPRFL==='PRFL-PRDET' && (<PRFLInsights handleClick={handleClick} />)}
         {showPRFL==='PRFL-OFFER' && (<PRFLFeaturedOfferings handleClick={handleClick} />)}
         {showPRFL==='PRFL-SUPTR' && (<PRFLSupporters handleClick={handleClick} />)}
         {showPRFL==='PRFL-EVNTS' && (<PRFLUpcomingEvents handleClick={handleClick} />)}

         {showPRFL==='PRFL-PPVLN' && (<PRFLPublicPavilion handleClick={handleClick} />)}

         {showPRFL==='PRFL-PUBLP' && (<PRFLPublicProfile handleClick={handleClick} />)}
         

         </>
    )
}

export default LeftBox