import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Validation from '../RegisterValidation'
import axios from 'axios'
import ApartmentIcon from '@mui/icons-material/Apartment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Group from '@mui/icons-material/Group'
import Layout from '../components/layouts/Layout'
import RegisterForm from '../components/RegisterForm2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Register() {
  const [showForm, setShowForm] = useState(true);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Layout>
      <div className="bg-light bg-image bg-register" >
        <header id="myHeader" className="mb-0 mt-5 mt-sm-5 p-0">
          <div className="container col-xxl-8 px-0 pt-5 ">
            <Row className="align-items-center g-5 p-3">
              <Col md={6} sm={12} className="p-0" data-aos="slide-right">
                <RegisterForm />
              </Col>
              <Col md={6} sm={12} className="px-5 " data-aos="slide-left">
                <div className="col-12 d-flex flex-column justify-content-center mx-5">
                  <h1 className="m-4 text-light "><ApartmentIcon fontSize='15px' /> Companies</h1>
                  <h1 className="m-4 text-light "><MenuBookIcon fontSize='15px' /><i className="bi-book"></i> Academia</h1>
                  <h1 className="m-4 text-light "><AssuredWorkloadIcon fontSize='15px' /> Government</h1>
                  <h1 className="m-4 text-light "><Group fontSize='15px' /> Citizens</h1>
                </div>
              </Col>
            </Row>
          </div>
        </header>

      </div>
    </Layout>
  )
}
export default Register
