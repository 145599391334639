
import PavilionHeader from "../pavilion/PavilionHeader"
import PavilionLeftSidebar from "../pavilion/PavilionLeftSidebar"
import PavilionRightSidebar from "../pavilion/PavilionRightSidebar"
import React,{useState} from 'react';
import ChatButton from "../ChatButton";

const PavilionLayout = ({ children, props }) => {
 
  const [showLS, setShowLS] = useState(true);

  function handleToggle1(evt) {
    if(showLS){setShowLS(false)}
    else{setShowLS(true)}
  }
  return (
    <>
     <PavilionHeader showLSB={showLS} isLoggedIn={false} handleToggle={handleToggle1}/>
    {showLS && ( <PavilionLeftSidebar showLSB={showLS} isLoggedIn={false} isPavilion={true} showSB={true} showAP={true} /> ) }


    <main id="main" style={{ marginLeft: showLS ? '240px' : '0' }} className="bg-header main px-0 pt-0" data-aos="fade-up">
      {/* <div style={{minHeight: '50px'}}>&nbsp;</div> */}
        {children}
      </main>
      <ChatButton/>
      <PavilionRightSidebar showCB={true}/>
      
    </>
  )
}

export default PavilionLayout
