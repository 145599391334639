import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import Share from '@mui/icons-material/ReplyOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import {useLocation} from 'react-router-dom'
import http from '../../../../../http'
import { formatDate } from '../../../../PavilionConstants';


function PUBLPressDetails({ handleClick }) {
  // const handleEventClick = (idx) => {
  //   alert(idx);
  // }

  const [newsTitle, setNewsTitle] = useState();
  const [newsDate, setNewsDate] = useState();
  const [newsCat, setNewsCat] = useState();
  const [newsDesn, setNewsDesn] = useState();
  const [newsPhoto, setNewsPhoto] = useState();
  const [newsUser, setNewsUser] = useState();
  const [newsUserAvatar, setNewsUserAvatar] = useState();

  const [newsId, setNewsId] = useState()
  const [pvlnId, setPvlnId] = useState()
  const [newsDetails, setNewsDetails] = useState([])

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
      if (splitURL[1] === 'newsinformation') {
          if (splitURL[2] ) {
            setNewsId(splitURL[2])
          
          // http().get(`/newsinformation/${splitURL[2]}`).then((data) => {
          //      setPvlnId(data.data.data.pavilion_id)
          // });
        } 
  
      }
      
  }, [location]);

  useEffect(() => {
    if(newsId){
  	  http().get(`/newsinformation/${newsId}`).then((data) => {
       //setNewsDetails([...newsDetails,...data.data.data.news.data]);

       setNewsTitle(data.data.data.news[0].name)
       setNewsDate(formatDate(data.data.data.news[0].start_date))
       setNewsCat(data.data.data.news[0].category.name)
       setNewsDesn(data.data.data.news[0].description)
       setNewsPhoto(data.data.data.news[0].image)
       setNewsUser(data.data.data.news[0].user.name)
       setNewsUserAvatar(data.data.data.news[0].user.details.image)
    });
  }
	}, [newsId]); 
  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
           <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} ><Share className='flipx' /></IconButton> 
          <Typography variant="small">
              News Shared
          </Typography>
        </div>
        {/* <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton> */}
			{/*<IconButton sx={{ padding: 1 }} ><PostAddIcon /></IconButton>*/}

      </ListGroup.Item>
    <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
      <div className="container slim-scrollbar mh-70  py-0">
        <ListGroup className="overflow-hidden h-100 px-0 " style={{ borderRadius: "10px" }}>
          <ListGroup.Item active className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Row className="w-100 g-2 d-flex justify-content-between align-items-center ">
              <Col md={2}>
                <BadgeAvatar src={`https://bic3-staging.s3.eu-west-2.amazonaws.com/images/profile_picture/${newsUserAvatar}`} name={newsUser} size='40' isLoggedIn={false} />
              </Col>
              <Col md={10} className=' text-start'>
                <h6 className='mb-0'>{newsUser}</h6>
                {/* <small>03 Tue Jan, 2023 at 03:07 PM</small> */}
              </Col>
            </Row>

            {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Image className='rounded-2' fluid src={`${localStorage.getItem('bucketName')}/images/news/${newsPhoto}`} />
          </ListGroup.Item>

          <ListGroup.Item className='  border-0 py-1 '>
            <h6 className="fw-bold">{newsTitle}</h6>
            <p className='lead mb-1'><span className='fw-bold'>Category: </span>{newsCat}</p>
            <p className='lead mb-1'><span className='fw-bold'>Publication Date:</span> {newsDate}</p>
            <p className='lead mb-1'><span className='fw-bold'>Description:</span> <p dangerouslySetInnerHTML={{ __html: newsDesn }} />
            
             </p>
            
            {/* <div className="d-flex justify-content-between align-items-center">
              <p className='w-50 lead mb-1'><strong>Document Type:</strong><br /> Evidence Meetings</p>
              <p className='w-50 lead mb-1'><strong>Tags:</strong><br /> #New Years</p>
            </div> */}
          </ListGroup.Item>
          </ListGroup>
      </div>
    </ListGroup.Item >
    </ListGroup>
  )
}
export default PUBLPressDetails
