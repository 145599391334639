import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import FlickrIcon from '../../../components/FlickrIcon'
import { ListGroup } from 'react-bootstrap'
import { Form,Row, Col, Card } from 'react-bootstrap'

import Modal from 'react-bootstrap/Modal';

function MCPhotosTab() {
  const [toggler, setToggler] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container fluid className="px-0 my-2">
        <ListGroup>
        <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <div className="fw-bold d-flex-mi"><FlickrIcon/>&nbsp;Flickr Albums</div>
            <Button size="sm" variant="outline-primary" onClick={handleShow}>Add New Flickr Album</Button>
        </ListGroup.Item>
        </ListGroup>
        <ListGroup className="my-2">
        <ListGroup.Item className="">
        <Row xs={1} md={3} className="gx-2 mt-2">
            {Array.from({ length: 6 }).map((_, idx) => (
              <Col key={idx}>
                <Card className="card-hover mb-2" onClick={() => setToggler(!toggler)}>
                  <Card.Img className="rounded" variant="top" src={`https://source.unsplash.com/random/300x300/?sig=${idx}`} />
                  <Card.ImgOverlay className="text-light d-flex justify-content-end flex-column bg-gradient-transparent" >
                    <Card.Title className="fw-bold" style={{fontSize: '1rem'}}>Weekly Snapshot {idx+1}</Card.Title>
                    <Card.Text className="small">
                      {idx*12} Photos . {idx*10} Views
                    </Card.Text>
                  </Card.ImgOverlay>
                </Card>
              
              </Col>
            ))}
          </Row>

        </ListGroup.Item>
        </ListGroup>
        
        <Modal show={show} backdrop="static" keyboard={false} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Flickr Album URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form.Control
                        required
                        type="text"
                        placeholder="https://www.flickr.com/photos/flickr/00000000000/"
                    />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleClose}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default MCPhotosTab
