import React, { useState } from 'react'
import { Button, ListGroup, Collapse, Container } from 'react-bootstrap'
import TuneIcon from '@mui/icons-material/Tune';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar } from '@mui/material';
import WorkIcon  from '@mui/icons-material/Work';
import InfoIcon  from '@mui/icons-material/Info';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CategoryIcon from '@mui/icons-material/Category';
import EventIcon from '@mui/icons-material/Event';
import BadgeAvatar from '../../../components/BadgeAvatar';
function PublicationsTab() {
  const [open, setOpen] = useState(false);

  return (
    <Container className="mt-2 px-0">
    <div className="border-0 d-flex justify-content-between bg-toggle rounded shadow-sm px-2 py-2 mb-2 mx-0">
      <h5 className="d-flex-mi mb-0"><GroupIcon />&nbsp;Publications</h5>
      <div>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-fade-text"
          aria-expanded={open} className='px-2 py-1 me-1'
        >
          <TuneIcon />
        </Button>
      </div>
    </div>
    <Collapse in={open}>
      <div id="example-fade-text" className="bg-toggle p-4 rounded shadow-sm" >
      <Form>
      <Form.Group className="mb-3 d-flex " controlId="formBasicEmail">
      <Form.Select>
        <option value="41">Enterprise Adoption</option>
        <option value="42">Citizen Participation</option>
        <option value="43">Education</option>
        <option value="44">Sustainability</option>
        <option value="45">Health and Wellbeing</option>
        <option value="46">generic</option>
        <option value="47">AI in the World</option>
        <option value="53">Policy and Government</option>
        </Form.Select>
        <Form.Select>
          <option value="">All Document Types</option>
          <option value="48">Evidence Meetings</option>
          <option value="49">Programme and Brochures</option>
          <option value="50">Think-pieces and Reports</option>
          <option value="51">Slides from meetings (mixed)</option>
        </Form.Select>
        <Form.Select>
        <option value="">Search By Year</option>
        <option value="2025">2025</option>
        <option value="2024">2024</option>
        <option value="2023">2023</option>
        <option value="All">All Time</option>
        </Form.Select>
      </Form.Group>


      <Form.Group className=" d-flex justify-content-center " controlId="formBasicPassword">
        <Form.Control type="text" className="w-50" placeholder="Search by Title, Author, Keywords, Tags" />
        <Button variant="primary" type="submit"><SearchIcon/></Button>
        <Button variant="primary" type="submit">Clear Filter</Button>
      </Form.Group>
      
    </Form>

      </div>
    </Collapse>
    <Row xs={1} md={3} className="gx-2 gy-0 mt-2">
            {Array.from({ length: 6 }).map((_, idx) => (
              <Col key={idx}>
                <Card className="">
                  <Card.Header className="border-bottom-0">
                    <div className="d-flex align-items-center avatar-50">
                      <BadgeAvatar size="40" alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} />
                      {/* <img src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} className="rounded-circle" /> */}
                      <a href="javascript:void(0);"><div className="ms-3 miw-100">
                        <h6 className="mb-0">APPG AI Secretariat</h6>

                      </div></a>                    </div>
                  </Card.Header>
                  <Card.Img className="rounded mx-0 py-2" variant="top" src={`https://source.unsplash.com/random/300x400/?sig=${idx}`} />
                  {/* <Card.ImgOverlay >
        <Card.Title>Card title</Card.Title>
        <Card.Text>
          This content is a little bit longer.
        </Card.Text>
        <Card.Text>Last updated 3 mins ago</Card.Text>
      </Card.ImgOverlay> */}
                  <Card.Body>

                    <ListGroup>
                      <ListGroup.Item className="d-flex justify-content-start border-0 px-0 align-items-center ">
                        <CategoryIcon />&nbsp;Citizen Participation</ListGroup.Item>
                      <ListGroup.Item className="d-flex justify-content-start border-0 px-0 align-items-center">
                        <div><EventIcon />&nbsp;<strong>27 September 2023</strong>
                        </div></ListGroup.Item>
                      <ListGroup.Item action href="https://bicpavilion.com/librarydetail/appg-artificial-intelligence/tipping-point-on-the-edge-of-superintelligence-123" className="d-flex justify-content-start border-0 px-0 align-items-center">
                        Tipping Point - On the Edge of Superintelligence
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer className="mx-0 px-2 d-flex justify-content-between border-top-0">
                    <Button variant="outline-primary" size="sm">View Detail</Button>
                    <Button variant="outline-primary" size="sm">Add to My Resources</Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>

    
    
  </Container>
  )
}

export default PublicationsTab
