import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import http from '../../../../../http'
import {useLocation, useNavigate} from 'react-router-dom'
import {formatDate} from '../../../../PavilionConstants'

function PRFLFeaturedOfferings({ handleClick }) {
    const [pavilionId, setPavilionId]=useState()
    const [pavilionSlug, setPavilionSlug]=useState()
    const [memberships, setMemberships] = useState([])

    const navigate = useNavigate();
    const location = useLocation();
    const splitURL = location.pathname.toString().split("/");
    useEffect(() => {
        if (splitURL[1] === 'about_pavilion') {
            if (splitURL[2] ) {
               setPavilionSlug(splitURL[2])
               http().get('/publicabout/'+splitURL[2]).then((res) => {
                //   setPavilionId(res.data.data.aboutPavilion.id)
                  http().get('/publicfeaturedofferings/'+res.data.data.aboutPavilion.id).then((res) => {
                    setMemberships(res.data.data.memberships)        
                });
               });
            } 
        }
    }, []);


    return (
        <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <div><IconButton onClick={event => handleClick('PRFL-MENU2')}><ArrowBackIcon /></IconButton>
                <Typography variant="small">Featured Offerings</Typography>
                </div>
                
                <IconButton onClick={event => handleClick('PRFL-MENU2')}><Home /></IconButton>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 p-0" >
                <div className="container slim-scrollbar mh-70  py-0 ">
                {memberships.map((innerArray,outerIndex) => ( 
                    <ListGroup className="mb-3 border-0 bg-transparent">
                        <ListGroup.Item key={outerIndex} className="pb-0">
                            <Row>
                                <Col md={6}><Image className='w-100' rounded src={`${localStorage.getItem('bucketName')}/images/pavilion/thumbnails/${innerArray.image}`} /></Col>
                                <Col md={6} className="d-flex flex-column justify-content-end">
                                    <h5 >{innerArray.name}</h5>
                                    <Button variant='primary' size="sm" onClick={()=> navigate(`/shop/${pavilionSlug}`)}>Go to Offer</Button>
                                </Col>
                            </Row>
                            <p className="mt-3" dangerouslySetInnerHTML={{ __html: innerArray.description }} />
                        </ListGroup.Item>
                    </ListGroup>
                    ))}
                </div>
            </ListGroup.Item>
        </ListGroup>
    )
}

export default PRFLFeaturedOfferings
