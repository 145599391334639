import React, { useState , useEffect} from 'react'
import Layout from '../components/layouts/Layout'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PavilionSetupForm from '../components/PavilionSetupForm';
import Pricing from '../components/Pricing';
import { Container } from 'react-bootstrap';
import http from '../http';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
function BusinessServices() {
    const [count, setCount] = useState(0);
    const [pName,setPName]=useState();
    const [planId,setPlanId]=useState();
    const [pType,setPType]=useState();
    const [pAmt,setPAmt]=useState();
    const [country,setCountry]=useState();

    const location = useLocation();
    const splitURL = location.pathname.toString().split("/");

    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');

    // const handleClick = num => {
    //     // 👇️ take the parameter passed from the Child component
    //     setCount(num);
    // };
    const handleClick = (planId, pName, pType, pAmt,country) => {
        setPName(pName);
        setPlanId(planId);
        setPType(pType);
        setPAmt(pAmt);
        setCountry(country)
    }
    useEffect(() => {
        if(sessionId){
            http().get('/setup/response?session_id='+ sessionId).then((data) => {
                console.log(sessionId);
            });
        }
    },[sessionId]);

    return (
        <Layout>
            <Container className="mb-5 text-center" style={{paddingTop:'150px'}}>
            {/* <h1 className="text-primary">Membership: {count}</h1> */}
            {pName || sessionId ? (
                // [ pName==='Standard' ? (<PavilionSetupForm planName={pName} planType={pType} planAmt={pAmt} country={country}/>) : (<PavilionSetupForm planName={pName} planType={pType} planAmt={pAmt} country={country}/>) ]
                <PavilionSetupForm planId={planId} planName={pName} planType={pType} planAmt={pAmt} country={country}/>
            ):( 
            <Pricing handleClick={handleClick} count={count}/>
            )}
            
            
                
            </Container>
        </Layout>
   
    )
}

export default BusinessServices
