import React from 'react'
    
function MyPublicProfileTab(props) {
  return (
    <div>
        <h1>My Public Profile</h1>
    </div>
  )
}

export default MyPublicProfileTab
