// import { PropaneSharp } from '@mui/icons-material'
import React ,{useState} from 'react'
import Group from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AllPavilionsBox from './Boxes/AllPavilionsBox';
import Logo from '../Logo';
import UserBox from './admin/UserBox';
import IamMemberBox from './Boxes/IamMemberBox';
import SponsorsBox from './Boxes/SponsorsBox';
import UserDetailsBox from './Boxes/UserDetailsBox';
import { ListGroup } from 'react-bootstrap';

function PavilionLeftSidebar(props) {
    const [show, setShow] = useState(props.isLoggedIn);
    const [isPavilion, setIsPavilion] = useState(props.isPavilion);
    const [showAP,setShowAP] = useState(props.showAP);
    const [showUB,setShowUB] = useState(props.showUB);
    const [showIM,setShowIM] = useState(props.showIM);
    const [showSB,setShowSB] = useState(props.showSB);
    const [showUD,setShowUD] = useState(props.showUD);

    return(
        <>


        <aside id="sidebar" className="sidebar lsbar aos-init aos-animate" data-aos="slide-right" data-aos-offset="slide-right">
         <Logo src={localStorage.getItem('bucketName')+'/images/pavilion/19361668670116.png'}/>            
        <div className="slim-scrollbar sidebar-left mh-70">
           <UserBox showMe={showUB}/>
           <IamMemberBox showMe={showIM}/>
           <UserDetailsBox showMe={showUD}/>
           <SponsorsBox showMe={showSB}/>
           <AllPavilionsBox showMe={showAP}/>
           
        </div>
        <div className="sidebar-footer d-block fixed-bottom p-2">
                <ListGroup className="d-flex flex-row">
                    
                    <ListGroup.Item className="pe-3"><a href="http://www.linkedin.com" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 32 32" style={{ fill: '#e30613' }}>
                            <path d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z">
                            </path>
                        </svg>
                    </a></ListGroup.Item>
                    <ListGroup.Item ><a href="https://www.youtube.com" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50" style={{ fill: '#e30613' }}>
                            <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z">
                            </path>
                        </svg>
                    </a>
                    </ListGroup.Item>
                </ListGroup>
                <small className="copyright">Copyright © 2022 Big Innovation Centre. All rights reserved</small>
            </div>
        </aside>
        </>
        
        
    )
}

export default PavilionLeftSidebar
