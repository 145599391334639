import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal from 'react-bootstrap/Modal';
import { Image } from 'react-bootstrap';
import http from '../../../../http';
import { useParams, useSearchParams } from 'react-router-dom';

function PVLNShop(props, { handleClick }) {

  const [memberships, setMemberships] = useState([]);
  const [userPurchased, setCurrentPurchased] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [pavilionId, setPavilionId]=useState(props.idx);

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
   
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    
   if(pavilionId){
    if (splitURL[1] === 'shop') {
      if (splitURL[2]) {
        http().get('/publicabout/'+splitURL[2]).then((res) => {
           setPavilionId(res.data.data.aboutPavilion.id) 
        });
      }
    }
    http().get('/shop/'+pavilionId).then((res) => {
      setMemberships(res.data.data.memberships);
      setCurrentPurchased(res.data.data.user_purchased);
    }).catch((error) => {
      // catch any unexpected errors
      console.log(error);
    });
  }
  if(sessionId){
    http().post('/decodestripesessionid',  {session_id: sessionId}).then((res) => {
      http().get('/shop/'+pavilionId).then((res) => {
        setMemberships(res.data.data.memberships);
        setCurrentPurchased(res.data.data.user_purchased);
      }).catch((error) => {
        // catch any unexpected errors
        console.log(error);
      });
    }).catch((error) => {
      // catch any unexpected errors
      console.log(error);
    });
  }
  }, []);

  const handleImageError = (e) => {
    e.target.src = localStorage.getItem('bucketName')+'/images/noimage.webp';
  };

  const handleShow = (membership) => {
    setSelectedMembership(membership);
    setShow(true);
  };

  const upgradeMembership = (membership_id) => {
    console.log(membership_id);
    http().post('/upgradeMembershipReact',{pavilion_id:props.idx,membership_id:membership_id}).then((res)=>{
      console.log(res.data.url);
      window.open(res.data.url, '_blank');
    });
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
          <div>
            <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PVLN-MENU',props.idx)}><ArrowBackIcon /></IconButton>
            <Typography variant="small">Shop</Typography>
          </div>
          <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PVLN-HOME',props.idx)}><Home /></IconButton>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
          <div className="container slim-scrollbar mh-70  py-0 ">
            <Row xs={1} md={1} className="g-0 " >
              {memberships.map((membership, index) => (
                <Col key={membership.id} className="p-4">
                  <Card className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }}>
                    <Card.Img variant="top" src={localStorage.getItem('bucketName') +'/'+ membership.image} onError={handleImageError}/>
                    <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-between " style={{ minHeight: "75px" }}>
                      <Card.Title className="pt-0 my-0" style={{ lineHeight: "0.7" }}>
                        <Typography key={membership.id} variant="large" className="fw-bold text-primary small" >
                          {membership.name}
                        </Typography>
                      </Card.Title>
                      <Card.Text > 
                        <Typography key={membership.id} variant="small" className="h1 text-muted">{membership.price}</Typography>
                      </Card.Text>
                      {userPurchased ? (
                        <Button variant="primary" size="sm" onClick={() => {
                          if (userPurchased.membership_id !== membership.id) {
                            handleShow(membership);
                          }
                        }}>
                        {userPurchased.membership_id === membership.id ? "Current" : "View Details"}
                      </Button>
                      ) : (
                        <Button variant="primary" size="sm" onClick={() => {
                            handleShow(membership);
                        }}>
                        {"View Details"}
                      </Button>
                      )}
                      
                    </Card.Body>
                    <Card.Footer className='text-center py-2'>
                      <label htmlFor="defaultChecked1" className='small'>
                        <input type="checkbox" className="form-check-input" id="defaultChecked1"></input>
                        &nbsp;Mark as Featured Offering</label>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </ListGroup.Item>
      </ListGroup>
      <Modal show={show} backdrop="static" keyboard={false} size="lg" centered onHide={handleClose}>
        {selectedMembership && (
          <>
            <Modal.Header className="py-1" closeButton>
              <Modal.Title>Membership Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={5}>
                  <Image src={localStorage.getItem('bucketName') + '/' + selectedMembership.image} width={250} height={250}/>
                </Col>
                <Col md={7}>
                  <h4>{selectedMembership.name}</h4>
                  <h5>Price: {selectedMembership.price}</h5>
                  <Button variant="primary" onClick={() => upgradeMembership(selectedMembership.id)} >Upgrade</Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12}>
                  <h5 className="border-bottom pb-2 ps-0">Features:</h5>
                  <p className='lead' dangerouslySetInnerHTML={{ __html: selectedMembership.description }} />
                </Col>
              </Row>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  )
}

export default PVLNShop
