import React,{useEffect} from "react";
import PageLogo from '../assets/img/bicpavilion.png';
import Logo from './Logo';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import react, { useState, useContext, useRef } from 'react'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
// import LightModeIcon from '@mui/icons-material/LightMode';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useHash } from '../components/useHash';
import { scrollToSection } from '../utils/utils';
function NaviBar() {
  const hash = useHash();
  useEffect(() => {
    const section = hash.replace("#", "");
    if (section) scrollToSection(section);
  }, [hash]);
  // const { theme, toggleTheme } = useContext(ThemeContext);
  const [isIconClicked, setIsIconClicked] = useState(true);
const navigate = useNavigate();

const pavilionsSection = useRef(null);
  const handleDarkMode = (event) => {
    setIsIconClicked(!isIconClicked);

    const thm = document.documentElement.getAttribute("data-bs-theme");
    const appId = document.getElementById('app-theme');
    const thm2 = appId.getAttribute('data-bs-theme');
    const thmToggler = event.currentTarget;
    const theme = localStorage.getItem("data-bs-theme");
    // thmToggler.innerHTML='<small>{theme}</small>';

    if (thm2 === 'light') {
      appId.setAttribute('data-bs-theme', 'dark');
      document.documentElement.setAttribute("data-bs-theme", "dark");
      localStorage.setItem("data-bs-theme", "dark");

      // thmToggler.innerHTML='<small>LIGHT MODE<small>';
    } else {
      appId.setAttribute('data-bs-theme', 'light');
      document.documentElement.setAttribute("data-bs-theme", "light");
      localStorage.setItem("data-bs-theme", "light");
      //  thmToggler.innerHTML='<small>DARK MODE</small>';
    }

  }
  
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-toggle shadow-sm fixed-top">
      <Container >
        <Navbar.Brand href="/">
          <Logo width="200vh" src={PageLogo} />
          {/* <img className="img-fluid" width="200vh" src={logo} /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto d-flex align-items-center">
            <NavLink activeclassname="active" className="nav-link" to="/">Home</NavLink>
            {/* <NavLink to='javascript:void(0)' style={{ textDecoration: 'none' }} className='nav-link' 
              onClick={() => scrollToSection('allpavilions')}
            >All Pavilions</NavLink> */}
            <HashLink to="/#allpavilions" onClick={() => scrollToSection('allpavilions')}>All Pavilions</HashLink>

            <NavLink activeclassname="active" style={{ textDecoration: 'none' }} className='nav-link ' to="/plans">Launch My Pavilion</NavLink>
            <Link to="https://www.youtube.com/channel/UCpvImeYUgu-UAHyjRKDaPTA" className='nav-link ' target="_blank">HowTo Videos</Link>
            <NavLink activeclassname="active" style={{ textDecoration: 'none' }} className='nav-link' to="/privacypolicy" >Privacy Policy</NavLink>
            <NavLink activeclassname="active" style={{ textDecoration: 'none' }} className='nav-link' to="/contact">Contact</NavLink>
            {/* <IconButton size="large" id="theme-toggler" onClick={handleDarkMode}>
                    {isIconClicked ? <DarkModeOutlinedIcon />  : <LightModeOutlinedIcon/>  }
                    </IconButton> */}
            <Nav.Link to="javascript:void(0);" onClick={handleDarkMode} className="btn btn-outline-mode btn-sm font-weight-bold d-flex align-items-center" id="theme-toggler" >
              {isIconClicked ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
            </Nav.Link>
            {/* <div className="header-toggle-buttons">
               <button onClick={() => toggleTheme()}>{theme}</button> 
               <button onClick={handleDarkMode}>DarkMode</button> 
            </div> */}
            {/* <Form.Check
  type="switch"            
  id=""
  label=""/> */}
            {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>

          {/* <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NaviBar;