import React, { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';


function PVLNPressDetails({ handleClick }) {
  // const handleEventClick = (idx) => {
  //   alert(idx);
  // }

  const [newsTitle, setNewsTitle] = useState([
    'New Year 2024',
    'Annual Day Celebrations',
    'TV Debate',
    'AI in Public Health News',
    'Data Governance Press Conference ',
    'The UK Economy',
  ]);
  const [newsDate, setNewsDate] = useState([
    'Jan 22 2023',
    'Feb 19 2023',
    'Mar 11 2023',
    'May 13 2023',
    'May 13 2023',
    'June 10 2023',
  ]);
  const [newsCat, setNewsCat] = useState([
    'Articles',
    'Press Releases',
    'Radio & Podcasts',
    'TV & Videos',
  ]);
  const [newsDesn, setPNewsDesn] = useState([
    "News Desctipn 1",
    "News Desctipn 2",
    "News Desctipn 3",
    "News Desctipn 4",
    "News Desctipn 5",
    "News Desctipn 6",
  ]);
  const [newsPhoto, setNewsPhoto] = useState([
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/67131668758278.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/45491667362602.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/34691667363783.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/97191667362774.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/32051667363893.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/63711667363561.png",
  ]);

  return (
    <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
      <div className="container slim-scrollbar mh-70  py-0">
        <ListGroup className="overflow-hidden h-100 px-0 " style={{ borderRadius: "10px" }}>
          <ListGroup.Item active className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Row className="w-100 g-2 d-flex justify-content-between align-items-center ">
              <Col md={2}>
                <BadgeAvatar src="https://bic3-staging.s3.eu-west-2.amazonaws.com/images/profile_picture/76041699890444.png" name="Charles Smith" size='40' isLoggedIn={false} />
              </Col>
              <Col md={10} className=' text-start'>
                <h6 className='mb-0'>Charles Smith</h6>
                {/* <small>03 Tue Jan, 2023 at 03:07 PM</small> */}
              </Col>
            </Row>

            {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Image className='rounded-2' fluid src="https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/45491667362602.png" />
          </ListGroup.Item>

          <ListGroup.Item className='  border-0 py-1 '>
            <h6 className="fw-bold">{newsTitle[0]}</h6>
            <p className='lead mb-1'><strong>Category: </strong>{newsCat[0]}</p>
            <p className='lead mb-1'><strong>Publication Date:</strong> {newsDate[0]}</p>
            <p className='lead mb-1'><strong>Description:</strong> {newsDesn[0]}</p>
            
            <div className="d-flex justify-content-between align-items-center">
              <p className='w-50 lead mb-1'><strong>Document Type:</strong><br /> Evidence Meetings</p>
              <p className='w-50 lead mb-1'><strong>Tags:</strong><br /> #New Years</p>
            </div>
          </ListGroup.Item>
          </ListGroup>
      </div>
    </ListGroup.Item >
  )
}
export default PVLNPressDetails
