import React from 'react'
import Table from 'react-bootstrap/Table';
import { ListGroup, Container } from 'react-bootstrap';
function MyPurchasedHistoryTab() {
  return (
      <ListGroup className="px-0 my-2 shadow-sm">
      <ListGroup.Item className="fw-bold">
        My Purchased History
      </ListGroup.Item>  
      <ListGroup.Item >
      <Table striped>
      <thead>
        <tr>
          <th>Pavilion</th>
          <th>Name</th>
          <th>Type</th>
          <th>Order</th>
          <th>Price</th>
          <th>Purchased</th>
          <th>Expiration</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>The Metaverse Pavilion and Festival</td>
          <td>Member Three Year</td>
          <td>membership</td>
          <td>#1122</td>
          <td>£0</td>
          <td>01-06-2022</td>
          <td>01-06-2025</td>
          <td>View</td>
        </tr>
        <tr>
        <td>The Metaverse Pavilion and Festival</td>
          <td>Member Three Year</td>
          <td>membership</td>
          <td>#1122</td>
          <td>£0</td>
          <td>01-06-2022</td>
          <td>01-06-2025</td>
          <td>View</td>
        </tr>
        <tr>
        <td>The Metaverse Pavilion and Festival</td>
          <td>Member Three Year</td>
          <td>membership</td>
          <td>#1122</td>
          <td>£0</td>
          <td>01-06-2022</td>
          <td>01-06-2025</td>
          <td>View</td>
        </tr>
        <tr>
        <td>The Metaverse Pavilion and Festival</td>
          <td>Member Three Year</td>
          <td>membership</td>
          <td>#1122</td>
          <td>£0</td>
          <td>01-06-2022</td>
          <td>01-06-2025</td>
          <td>View</td>
        </tr>
      </tbody>
    </Table>
    </ListGroup.Item>
    </ListGroup>
  )
}

export default MyPurchasedHistoryTab
