import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function UserCheck() {

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
    //alert(splitURL[4] + 'd')
    if (splitURL[1] && splitURL[2] && splitURL[3]) {
      const splitURLThird = decodeURI(splitURL[3]).replace(/"/g, '\\"');
      localStorage.setItem('localstorage_user_id', splitURL[2]);
      localStorage.setItem('token', splitURLThird);
      window.location.href = '/homepavilion';
    }

  }, []);

  return (
    <div>

    </div>
  )
}

export default UserCheck