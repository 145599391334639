import { AccountBalanceOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

function IamMemberBox(props) {

    const [show, setShow] = useState(props.showMe);

    return (
        <>
        { show && (<Container fluid className="px-1">
            <div className="w-100 mb-0 ">
                <div className="text-bg-primary text-center pt-2 pb-1 rounded-top ">
                    <h5 className="mb-0 d-flex-mi">
                        <AccountBalanceOutlined /> &nbsp;I am Member of
                    </h5>
                </div>
            </div>
            <div className="list-group w-100 pavilions shadow-sm" aria-hidden="true">
                <a href="/members/who%27s-who" className="list-group-item list-group-item-action d-flex align-items-center">
                    <img src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/95061639721979.png"} alt="Who's who" style={{ width: '40px', height: '40px' }} className="rounded" />
                    <div id="paviname" className="ms-3 d-sm-inline">
                        <h6 className="text-muted  mb-0">Who's who</h6>
                        {/* <small className="text-dark ">3475 Members</small> */}
                    </div>
                </a>

                <a href="/wall/appg-artificial-intelligence" className="list-group-item list-group-item-action d-flex align-items-center">
                    <img src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/62581603467852.png"} alt="APPG Artificial Intelligence" style={{ width: '40px', height: '40px' }} className="rounded" />
                    <div id="paviname" className="ms-3 d-sm-inline">
                        <h6 className="text-muted  mb-0">APPG Artificial Intelligence</h6>
                        {/* <small className="text-dark ">387 Members </small> */}
                    </div>
                </a>

                
            </div>
        </Container>
        )}
        </>
    )
}

export default IamMemberBox
