import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import http from '../http';

import TwitterIcon from '@mui/icons-material/X';
import GoogleIcon from '@mui/icons-material/Google';
import { Container, Row, Col } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';

import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import Swal from 'sweetalert2'


const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));


export default function Login() {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [redirectionUrl, setredirectionUrl] = useState('')

  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }
  const emailHandler = (event) => {
    setEmail(event.target.value)
  }

  function signinRequest() {
    http().post('/login', { email: email, password: password })
      .then((res) => {
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('localstorage_user_id', res.data.data.id);
        window.location.href = '/homepavilion';
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          title: "Login failed!",
          text: 'Please check your credentials and try again.',
          icon: "error",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });	
      });
  }

  const submitHandler = (event) => {
    event.preventDefault()
    signinRequest()
  }

  useEffect(() => {	  
  console.log(window.location.hostname);
	  if(window.location.hostname=='bicpavilion.com'){
		  setredirectionUrl('https://react-live.bicpavilion.com');
	  }else if(window.location.hostname=='bic3.bicpavilion.com'){
		  setredirectionUrl('https://staging-backend.bicpavilion.com');
	  }else{
		  setredirectionUrl('http://127.0.0.1:8000');
	  }
   
  }, []);
  return (<>
    <Container className='px-0 text-center'>
      <h4 className="fw-bold text-primary lh-1 mb-3">Welcome to Pavilion 3.0</h4>
      <p className="lead mb-2">Share your professional interests, contribute and be connected with your community.</p>
      <div className=" mb-2 text-center">
        <Divider>Login with Social Media</Divider>
        <ButtonGroup aria-label="outlined primary button group"  >
		<a href={redirectionUrl+'/social/google'}>
          <IconButton ><GoogleIcon className='p-1' style={{ borderRadius: '50%' }} sx={{ color: '#ffffff', backgroundColor: '#dd4b39' }} /></IconButton>
		  </a>
		  <a href={redirectionUrl+'/social/linkedin'}>
          <IconButton>
            <SvgIcon >
              <svg enableBackground="new 0 0 32 32" height="32px" id="Layer_1" version="1.0" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g><circle clipRule="evenodd" cx="16" cy="16" fill="#007BB5" fillRule="evenodd" r="16" /><g><rect fill="#FFFFFF" height="14" width="4" x="7" y="11" /><path d="M20.499,11c-2.791,0-3.271,1.018-3.499,2v-2h-4v14h4v-8c0-1.297,0.703-2,2-2c1.266,0,2,0.688,2,2v8h4v-7    C25,14,24.479,11,20.499,11z" fill="#FFFFFF" /><circle cx="9" cy="8" fill="#FFFFFF" r="2" /></g></g><g /><g /><g /><g /><g /><g /></svg>
            </SvgIcon>
          </IconButton>
		  </a>
		  <a href={redirectionUrl+'/social/twitter'}>
          <IconButton><TwitterIcon className='p-1' style={{ borderRadius: '50%' }} sx={{ color: '#ffffff', backgroundColor: '#000000' }} /></IconButton>
		  </a>
		  <a href={redirectionUrl+'/social/facebook'}>
          <IconButton>
            <SvgIcon >
              <svg enableBackground="new 0 0 96 96" height="96px" id="Layer_1" version="1.1" viewBox="0 0 96 96" width="96px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path d="M50,3.84C24.506,3.84,3.84,24.506,3.84,50S24.506,96.16,50,96.16S96.16,75.494,96.16,50S75.494,3.84,50,3.84  z M65.137,52.572c-1.854-0.031-3.549,0.023-5.704-0.07c-0.35-0.016-5.963,0.068-5.946,0.17c0.408,2.496-0.178,5.148-0.143,7.672  c0.021,1.471,0.453,14.779,0.254,19.688l-11.123,0.102c-0.046-4.18,0.158-8.479-0.023-13.992  c-0.037-1.133-0.074-13.061-0.206-13.689c0,0-4.916,0.035-7.214,0.049c0,0-0.031-4.402-0.031-5.891c0-1.463,0-2.924,0-4.387  c0,0,5.738-0.318,7.385-0.461c0,0,0.168-5.584,0.396-7.393c0.263-2.066,0.289-4.154,1.502-5.982  c2.223-3.346,4.417-6.295,8.504-7.184c1.546-0.338,11.741-2.342,12.119-0.693l-0.232,9.928c-2.772,0-6.094-0.281-8.923,1.82  c-1.575,1.172-2.01,2.148-2.169,4.076c-0.095,1.121-0.117,5.658-0.117,5.658c1.494,0.213,3.705,0.119,5.311,0  c1.814-0.135,6.471,0.346,6.471,0.346C65.246,45.342,65.126,52.275,65.137,52.572z" fill="#3A5897" /></svg>
            </SvgIcon>

            {/* <img alt="svgImg" style={{maxHeight:'0.9em'}}  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNTYuNjkzIDU2LjY5MyIKICAgaGVpZ2h0PSI1Ni42OTMwMDEiCiAgIGlkPSJMYXllcl8xIgogICB2ZXJzaW9uPSIxLjEiCiAgIHZpZXdCb3g9IjAgMCA1Ni42OTMgNTYuNjkzIgogICB3aWR0aD0iNTYuNjkzMDAxIgogICB4bWw6c3BhY2U9InByZXNlcnZlIgogICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzCiAgICAgaWQ9ImRlZnMxIiAvPjxwYXRoCiAgICAgZD0ibSA0MS45MDk1MDQsMTguNDUyOTA1IGggLTkuMjM4MTY1IHYgLTUuODI3ODMgYyAwLC0yLjE4ODYzMiAxLjUwODA3NSwtMi42OTg4ODY2IDIuNTcwMjUzLC0yLjY5ODg4NjYgMS4wNTk3NjEsMCA2LjUxOTI4MSwwIDYuNTE5MjgxLDAgViAwLjMwNDU3MzcxIGwgLTguOTc4MzYxLC0wLjAzMzcwNzEgYyAtOS45NjY4MjcsMCAtMTIuMjM0OTgsNy4xNzYxMTE2OSAtMTIuMjM0OTgsMTEuNzY4NDAzMzkgdiA2LjQxMzYzNSBoIC01Ljc2NDAzNSB2IDkuOTE0NTE3IGggNS43NjQwMzUgYyAwLDEyLjcyMzgyNCAwLDI4LjA1NDcxMiAwLDI4LjA1NDcxMiBoIDEyLjEyMzgwNyBjIDAsMCAwLC0xNS40ODE5ODkgMCwtMjguMDU0NzEyIGggOC4xODA4MjIgeiIKICAgICBpZD0icGF0aDEiCiAgICAgc3R5bGU9ImZpbGw6IzA4NjZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2Utd2lkdGg6MS4xODUxMiIgLz48L3N2Zz4K"></img> */}

          </IconButton>
		  </a>


        </ButtonGroup>
        <Divider>or</Divider>
      </div>
    </Container>
    <Form onSubmit={submitHandler}>
      <Form.Group controlId="validationCustom01" className='mb-2'>
        {/* <FloatingLabel  size="sm"
                    controlId="floatingInput"
                    label="Email address"
                    className="mb-3"
                > */}
        <Form.Control size="sm"
          required
          type="email"
          placeholder="Email"
          pattern="/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}
            [a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.com"
          onChange={emailHandler}
        />
        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
        <Form.Control.Feedback type="invalid">Please provide a valid Email.</Form.Control.Feedback>
        {/* </FloatingLabel> */}
      </Form.Group>
      <Form.Group controlId="validationCustom02" >
        {/* <FloatingLabel controlId="floatingPassword" label="Password"> */}
        <Form.Control size="sm"
          required
          type="password"
          placeholder="Password"
          onChange={passwordHandler}
        />
        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
        <Form.Control.Feedback type="invalid">Please provide a valid Password.</Form.Control.Feedback>
        {/* </FloatingLabel> */}

      </Form.Group>
      <div className="mb-0 mt-2">

        <div className="mb-2 d-flex align-items-center justify-content-between">
          <p className='lead mb-0'>Don't have an account? <Link style={{ textDecoration: 'none' }} to="/register">Sign Up</Link></p>
          <Button type="submit" className="btn-sm">LOGIN</Button>
        </div>
        <div className="my-0 d-flex align-items-center justify-content-between">
          {/* <Form.Check type="checkbox" name='rememberme' id='rememberme' label="Remember Me" className='ps-0' /> */}
          <Form.Check type='checkbox' id='chkRememberMe' className='ps-0 pt-2'>
            <Form.Check.Input type='checkbox' className='mt-0 me-2' />
            <Form.Check.Label>Remember Me</Form.Check.Label>
          </Form.Check>
          <p className='lead mb-0'><Link style={{ textDecoration: 'none' }} to="/resetpassword">Forgot Password</Link></p>

        </div>

      </div>
    </Form>
  </>
  )
}
