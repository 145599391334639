import React from 'react'
import { Button, DropdownButton, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import { Avatar } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Dropdown from 'react-bootstrap/Dropdown';

function MyResourcesTab() {
  return (
    <ListGroup className="my-2">
      <ListGroup.Item className="fw-bold d-flex justify-content-between align-items-center">
        <div>My Resources</div>
        <Button variant="outline-secondary" size="sm">Add New Resource</Button>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row xs={1} md={3} className="gx-2 gy-0 mt-2">
          {Array.from({ length: 6 }).map((_, idx) => (
            <Col key={idx}>
              <Card className="card-hover2">
                <Card.Header className="border-bottom-0 px-2 py-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title >
                    Developing AI Apps on Azure</Card.Title>
                    <Dropdown className="vdots">
                      <Dropdown.Toggle variant="outline-link" className="p-0" id="dropdown-basic">
                        <MoreVert size="sm" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Remove from My Resources</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </div>
                </Card.Header>
                <Card.Img className="rounded mx-0 py-2" variant="top" src={`https://source.unsplash.com/random/300x400/?sig=${idx}`} />
                <Card.ImgOverlay >
        <Card.Text className="bg-light text-dark rounded p-1 d-flex-mi" ><CalendarMonthOutlinedIcon />&nbsp; Tuesday 21, 2023 </Card.Text>
      </Card.ImgOverlay>
                {/* <Card.Body>
   <div className="d-flex-mi"><CalendarMonthOutlinedIcon />&nbsp;
                    Developing Healthcare Workers' Confidence in AI </div>
                </Card.Body> */}
              </Card>
            </Col>
          ))}
        </Row>

      </ListGroup.Item>
    </ListGroup>
  )
}

export default MyResourcesTab
