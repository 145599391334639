import React, { useEffect, useState, useRef } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import ListGroup from 'react-bootstrap/ListGroup';
import { Image, Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Modal } from 'react-bootstrap';
import Upload from "../../../Cropper/Upload";
import Popup from "../../../Cropper/Popup";
import Box from "@mui/material/Box";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import libraryThumbnail from '../../../../assets/img/default-library-thumbnail.jpg';
import { styled } from "@mui/material/styles";
import MyCropper from '../../../Cropper/MyCropper';
import axios from "axios";
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PreLoader from '../../../PreLoader';
import { PLSIZE, formatDate } from '../../../PavilionConstants';
import Swal from 'sweetalert2'
import http, { baseUrl } from '../../../../http';
import Spinner from 'react-bootstrap/Spinner';




const Input = styled("input")({
  display: "none"
});


function PRFLMyResources(props, { handleClick }) {
  const [progress, setProgress] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const scrollContainerRef = useRef(null);
  const token = localStorage.getItem('token');
  const [userdetails, setUserdetails] = useState([]);
  const [myResources, setMyResources] = useState([]);
  const [category, setCategory] = useState([]);
  const libraryImagePath = localStorage.getItem('bucketName') + '/images/';
  const libraryImagePathStatic = localStorage.getItem('bucketName') + '/images/noimage.webp';
  const [libraryname, setLibraryname] = useState('');
  const [attachFile, setAttachFile] = useState(null);


  // useEffect(() => {
  //   axios.get('${baseUrl}/library/3' , {
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json', 
  //     },
  //   }).then((data) => {
  //     console.log(data);
  //     setPost(data.data.data.libraryPavilionRecord.data);
  //     setCategory(data.data.data.libraryPavilionPublicationCategory);
  //   });
  // }, []);




  useEffect(() => {
    http().get('/userdetails', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // adjust the content type based on your API requirements
        // other headers if needed
      },
    }).then((data) => {
      console.log('userdetails1');
      console.log(data);
      console.log('userdetails2');
      setUserdetails(data.data.data.user);
      console.log('userdetails3');
      //console.log(data.data.data.user.data);

    });
    fetchData();
  }, []);


  const [state, setState] = useState([]);
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [showLibs, setShowLibs] = useState(0)

  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(libraryThumbnail);

  const handleClose = () => {
    setOpen(false);
  };
  const handlePublicationAttachFile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      setAttachFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const [fileUrl, setFileUrl] = useState('https://source.unsplash.com/random/250x335/?sig=0');

  // const [open, setOpen] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleParentClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  // const cropperRef = createRef<ReactCropperElement>(null);

  // const getCropData = () => {
  //   // if (typeof cropperRef.current?.cropper !== "undefined") {
  //   //   setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
  //   // }
  // };
  const handleLibraryNameChange = (e) => {
    setLibraryname(e.target.value);
  };

  const handleBoxClick = () => {

    setShowPopup(true);
  }
  const [dsType, setDSType] = useState(false);

  const handleDSTypeClick = (e) => {
    if (e.target.value === "0") {
      setDSType(true)
    } else {
      setDSType(false)
    }
  }


  const handleLibraryClick = (idx) => {

    setShowLibs(1)

  }

  //input file dialogue
  const onFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();

    reader.onload = () => {
      //    getUploadedFile(reader.result);
      setImage(reader.result)
      setOpen(true)
    };
    reader.readAsDataURL(files[0]);

  };

  //input file dialog
  const getCropData = (val) => {
    // do not forget to bind getData in constructor
    setImage(val);
  }

  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }
  function handleEdit(event) {
    console.log('handleEdit')
  }

  //Infinite Scroll
  const fetchData = async () => {

// if(!hasMore){
  
//   setLoadMsg('no more records')
//   return;
//   // setLoadMsg(<PreLoader size={PLSIZE} />)
// }
     setPage(page + 1);
   // console.log(__page);
    // if (page <= totalPages) {
    const res= await http().get(`/mylibrary?page=${page}`).then((response) => {
        //console.log(response.data.data.myResources.data)
        setMyResources([...myResources, ...response.data.data.myResources.data]);
        setTotalPages(response.data.data.myResources.last_page);
        setTotalRecords(response.data.data.myResources.total);
        if(page>totalPages){ setHasMore(false)}
      });
      
    // } else {
    //   setHasMore(false)
    //   setLoadMsg('No More Records...')
      
    // }

  };


  
  const [errors, setErrors] = useState({
    libraryname: '',
    attachFile: null,
    image: '',
  });
  const handleAdd= (e) =>{
   // alert('ssss');
  //  alert(image);
  setProgress(true);
   const newErrors = {};
   if (image === '/static/media/default-library-thumbnail.df2ecbb0b8afb8274607.jpg') {
     newErrors.image = 'Image is required.';
   }

   if (!libraryname) {
     newErrors.libraryname = 'Name is required.';
   }

   if (!attachFile) {
     newErrors.attachFile = 'File is required.';
   }

   setErrors(newErrors);

   if (Object.keys(newErrors).length === 0) {
    http().post('/library_upload', {
      library_state:"create",
      library_id: "",
      type_data: "",
      image: image,
      file: attachFile,
      name:libraryname,
      }).then((data) => {
        Swal.fire({
        title: "Successfully Uploaded file!",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
      setShow(false);
      setProgress(false);
    });
  }
    
    }
    
  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete this Library?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
		setMyResources([]);
		setHasMore(true);
		setLoadMsg('');
  

        http().post('/addtomylibrary', {
          id: id,
          type_id: id,          
          librarydata: "remove",
          actualid: id,
        }).then((data) => {
          http().get(`/mylibrary?page=1`).then((response) => {
            setPage(1);
            setMyResources(response.data.data.myResources.data);
            setTotalPages(response.data.data.myResources.last_page);
            setTotalRecords(response.data.data.myResources.total);
            if(page>totalPages){ setHasMore(false)}
          });
          Swal.fire({
            title: "Your Library has been deleted.",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });          
          //document.getElementById("myresource"+id).display.style="none";
          setShow(false);
        });
      }
    });

  }


  return (
    <>

      <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
          <div>
            {/* <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PRFL-MENU1', props.idx)}><ArrowBackIcon /></IconButton> */}
            <Typography variant="small">
              {showLibs === 0 && "My Resources"}
              {/* {showLibs === 1 && "Library Details"} */}
              {showLibs === 2 && "Search Resources"}
            </Typography>
          </div>
          <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PRFL-MENU1', props.idx)}><Home /></IconButton>
          <div>
            <IconButton onClick={handleShow} >
              <Tooltip id="button-report" title="Add New Resource">
                <PostAddIcon />
              </Tooltip></IconButton>
          </div>
        </ListGroup.Item>
        {showLibs === 0 && (
          <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
            <div className="container py-0 px-0" style={{ overflow: "auto" }} ref={scrollContainerRef}>
              {/* {myResources.length} */}
              {/* <InfiniteScroll
                pageStart={1}
                hasMore={page * 4 <= totalRecords ? true : false}
                loadMore={fetchData}
                getScrollParent={() => scrollContainerRef.current}
                initialLoad={true}
                useWindow={false}
              > */}
              <InfiniteScroll 
    dataLength={myResources.length} 
    // pullDownToRefreshThreshold={50} 
   next={fetchData} 
   scrollableTarget="scrollContainerRef" 
   hasMore={hasMore} 
   height={'70vh'}
   className='slim-scrollbar text-center'
   loader={<PreLoader size={PLSIZE} />}
   endMessage={<h6>No more records...</h6>}
> 


                <Row xs={1} md={2} className="g-0 " >
                  {myResources.map((innerArray, outerIndex) => (
                    <Col key={innerArray.id} id={"myresource"+innerArray.id} className="py-2">
                      <Card className="mb-0 px-2 pt-0 pb-2 shadow-sm bg-light" style={{ cursor: "pointer" }} >
                        <Card.Title className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="ms-0"><CalendarMonthOutlinedIcon fontSize="small" /> {formatDate(innerArray.created_at)}</h6>
                          <CRUDDropdown title={<MoreVertIcon fontSize='small' />} handleDropdown={handleDropdown}  deleteTitle="Delete" deleteClick={(event) => handleClickDelete(innerArray.id)}  />
                        </Card.Title>
                        <a target="_blank" href={ innerArray.name}><>
                        <Image src={localStorage.getItem('bucketName') + '/' + innerArray.image} sx={{ width: "100%", height: "100%" }} />
                        <Card.Body className="px-0 py-2 d-flex flex-column align-items-start justify-content-between " style={{ minHeight: "63px" }}>
                          <Card.Title className="pt-0 my-0" style={{ lineHeight: "0.7" }}>
                            <Typography key={innerArray.id} variant="large" className="d-flex align-items-center text-dark small" >
                              <div className=""><ArticleOutlinedIcon fontSize="small" /> <strong>{innerArray.title}</strong> <br></br>
                              </div>
                            </Typography>
                          </Card.Title>
                        </Card.Body>
                        </></a>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
              {/* <div className="loader my-2 text-center" key={0} >
                {loadMsg}
              </div> */}
              
            </div>
          </ListGroup.Item>
        )}
        {/* {showLibs === 1 && (<PVLNLibraryDetails />)} */}
        {showLibs === 2 && (
          <ListGroup.Item className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
            <Container>
              <Form className="mx-0 px-0">
                <Form.Select required size="sm" id="categories" name="categories" className="my-1 py-1">
                  <option value="0" selected disabled>Categories</option>
                  <option value="1" >Data and Ethics</option>
                  <option value="2" >Enterprise Adoption</option>
                  <option value="3" >Citizen Participation</option>
                  <option value="4" >Education</option>
                  <option value="5" >Sustainability</option>
                  <option value="6" >Health and Wellbeing</option>
                  <option value="7" >Generic</option>
                  <option value="8" >AI in the World</option>
                  <option value="9" >Policy and Government</option>
                </Form.Select>
                <Form.Select name="docType" size="sm" className="my-1 py-1">
                  <option value="0" selected disabled>All Documents Type</option>
                  <option value="1" >Evidence and Meetings</option>
                  <option value="2" >Programmes and Brochures</option>
                  <option value="3" >Think-pieces and Reports</option>
                  <option value="3" >Slides and Meetings(mixed)</option>
                </Form.Select>
                <Form.Select name="searchYear" size="sm" className="my-1 py-1">
                  <option value="0" selected disabled>Search by Year</option>
                  <option value="1" >2025</option>
                  <option value="2" >2024</option>
                  <option value="2" >2023</option>
                  <option value="2" >All</option>
                </Form.Select>

                <Form.Control size="sm" name="searchTitle" type="text" placeholder="Search by Title" className="my-1 py-1" />
                <Button size="sm" type="submit">Search</Button>
              </Form>
            </Container>
          </ListGroup.Item>
        )}
      </ListGroup>
      <Modal show={show} size="lg" backdrop="static" keyboard={false} onHide={handleParentClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title className="lead" >Add New Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row>
            <Col md={4} className="d-flex flex-column justify-content-center">
              <div >
                {/* <MyCropper sendCropData={getCropData}/> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Box my={2} >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={onFileChange}
                      />
                      <img className='rounded-2' style={{ cursor: "pointer" }} src={image} alt="cropped" height="100%" width="100%" />
                    </label>
                  </Box>

                  {/* <Upload image={image}
                    getUploadedFile={(image) => {
                      setOpen(true);
                      setImage(image);
                    }}
                  /> */}
                  <Popup
                    open={open}
                    handleClose={handleClose}
                    image={image}
                    getCroppedFile={(image) => {
                      setImage(image);
                      handleClose();
                    }}
                    aspectRatio={9 / 13}
                  />
                </Box>
              </div>
              {errors.image && <div style={{ color: 'red' }}>{errors.image}</div>}
              {/* <div>{output && <img src={output} />}</div> */}

              {/* <img id="libraryBanner" onClick={onImageClick} src={fileUrl} /> */}
              {/* </ReactCrop> */}
              {/* <img className="card-img-top rounded mx-0 py-2" src="https://source.unsplash.com/random/250x335/?sig=0"/> */}
            </Col>
            <Col md={8}>
              <Form.Control required type="text" size="sm" name="library-name" value={libraryname} id="library-name" placeholder="Library Name" className="my-2  py-1" onChange={handleLibraryNameChange} />
              {errors.libraryname && <div style={{ color: 'red' }}>{errors.libraryname}</div>}
              <Form.Group className="d-flex ">
                <Form.Control size="sm" type="file" id="attachFile" name="attachFile" accept="application/pdf" onChange={handlePublicationAttachFile} className="my-0  py-1 " />
              </Form.Group>
              {errors.attachFile && <div style={{ color: 'red' }}>{errors.attachFile}</div>}
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleParentClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleAdd}>
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Add Resource
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PRFLMyResources
