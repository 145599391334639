import React,{useState} from "react";
import { Chart } from "react-google-charts";

// export const data = [
//   ["Option", "Percentage",{ role: 'style' },{ role: 'annotation' }],
//   ["The AI systems creator or programmer", 30,'red', '30%'],
//   ["The organisation prompting the generative AI", 20 ,'silver','20%'],
//   ["The public domain", 10, 'gold','10%'],
//   ["A brand new entity specifically designed for AI-generated content", 40, 'green','40%'],
// ];


export function BarChart({data,hTitle,vTitle}) {
  //const [vTitle,setVTitle]=useState(hTitle);
  //const [hTitle,setHTitle]=useState(vTitle);
  
  const options = {
    title: "Q3. Who should hold intellectual property rights for content solely created by AI?",
    chartArea: { width: "80%" , height:"350px"},
    legend: { position: "none" },
    annotations: {alwaysOutside: true},
    hAxis: {
      title: hTitle,
      minValue: 0,
      gridlines:{interval:0}
    },
    vAxis: {
      title: vTitle,
      gridLines: { interval: '0'}
    },
  };

  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="350px"
      data={data}
      options={options}
    />
  );
}
