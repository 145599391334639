import React, { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';


function PRFLInfo({handleClick}) {


  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <Typography variant="small">Info</Typography>
                <IconButton className='btn btn-outline-mode' onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-center justify-content-center h-100  border-0 bg-light bg-opacity-75" >
                <div className="container slim-scrollbar mh-70  py-0 ">
                
          <h5>My Profile: Published and Private mode</h5>
          <p>Your profile encompasses professional information, including a photo, name, about section, areas of interest, job title, organisation, industry, ecosystem, and the Pavilions you are a member of. On your profile, you can view posts from the Pavilions you are a member of.</p>
		  <p>You have the option to update your professional information in private mode, with the ability to later publish it.</p>
		  <p>In published mode, your profile is assigned a personal URL reflecting your name and includes a Pavilion icon. This allows you to share your Pavilion professional account in your email signature or on other platforms.</p>
		  <p>Additionally, you can personalise your profile further by uploading your own header/banner that reflects your personal and professional profile.</p>

          <h5>All Pavilions:</h5>
          <p>This section lists all Pavilions in all global regions published on the Pavilion Platform.</p>

          <h5>My Pavilions:</h5>
          <p>My Pavilions comprise the Pavilions you are a member of or have a role in.</p>
        
          <h5>Who's Who:</h5>
          <p>The Who's Who Pavilion serves as the official directory of all Pavilion Platform account holders, encompassing members from Pavilions worldwide. It serves as a rich source of biographical data on professionals globally.</p>

          <h5>My Community:</h5>
          <p>Simplified Networking - Gain effortless access to established professional groups, simplifying your networking experience. On My Community, you can build a list of all those you follow and find all those who follow you.</p>

          <h5>My Events:</h5>
          <p>This section lists events you are 'Interested In.' When you come across events on the Pavilions you are a member of or have a role in, you can click 'Interested in,' and they will be listed here, allowing you to build your events diary.</p>
		  
		 <h5>My Resources:</h5>
          <p>In this section, you can find all the publications and library articles you are interested in. When you spot publications or library articles you like on the Pavilions you are a member of or have a role in, you can click 'Add to My Resources,' and they will be listed under My Resources, allowing you to build your own knowledge base.</p>
		  
		  <h5>My Wall:</h5>
          <p>Each account has its own Pavilion Wall, where you can view all Wall posts, you have created and published at any Pavilion you are a member of or have a role in. You can also view all the posts of those you follow. The Wall serves as an excellent platform for discussions and making announcements.</p>
		  
		  <h5>My Wall Gallery:</h5>
          <p>A compilation of all pictures or videos posted on My Wall.</p>
		  
		  <h5>My Purchase History:</h5>
          <p>Here, you can see the full history of all your purchases on the Pavilion. It serves as your collection of receipts.</p>
		  
		  <h5>Help and Support:</h5>
          <p>In this section, you can view ‘how to’ videos of the support you are most likely to request.</p>
		  
		  <h5>Chat:</h5>
          <p>The platform integrates chat and notifications to enhance communication and prompt engagement from your communities.</p>
          
                               
                </div>
            </ListGroup.Item>
        </ListGroup>
  )
}

export default PRFLInfo
