import React, {useState, useEffect} from "react";
import AdminLayout from "../layouts/AdminLayout";
import { styled } from '@mui/material/styles';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate } from 'react-router-dom'
import MemberCard from "../components/AdminCards/MemberCard";
import { ExportToExcel } from "../components/ExportToExcel";
import { formatDate } from "../../components/PavilionConstants";
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row, Col, Image
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from '../components/AdminBreadCrumbs';
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Badge from '@mui/material/Badge';
import adminhttp from '../../adminhttp';
import Alert from "@mui/material/Alert";
import Spinner from "react-bootstrap/Spinner";

import { Pagination, Typography } from "@mui/material";
import AddMember from "../components/AdminForms/AddMember";
import { getPermissions } from "../utils/utils";
import Swal from 'sweetalert2'


const columns = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "member_name",
    headerName: "Member Name",
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    editable: true,
  },
  {
    field: "created_at",
    headerName: "Created at",
    sortable: false,
  },
  {
    field: "status",
    headerName: "Active",
    sortable: false,
  },
  {
    field: "last_login_at",
    headerName: "Last Login at",
    editable: true,
  },
  {
    field: "last_seen",
    headerName: "Last Seen",
    editable: true,
  },
  {
    field: "online_status",
    headerName: "Online Status",
    editable: true,
  },
  {
    field: "action",
    headerName: "Action",
    width: 200,
    sortable: false,
    renderCell: ({ row }) => (
      <ButtonGroup>
        <Button
          variant="primary_outline"
          size="sm"
          onClick={() => handleAction(row)}
        >
          Active
        </Button>
        <Button
          variant="primary_outline"
          size="sm"
          onClick={() => handleAction(row)}
        >
          InActive
        </Button>
        <Button
          variant="primary_outline"
          size="sm"
          onClick={() => handleAction(row)}
        >
          Edit
        </Button>
      </ButtonGroup>
    ),
  },
];

const rows = [
  {
    id: 1,
    member_name: "Basic",
    email: "info@gmail.com	",
    created_at: "15-02-2024",
    status: "Active",
    last_login_at: "2023-03-02 15:02:46",
    last_seen: "1 year ago",
    online_status: "offline",
    action: "",
  },
  {
    id: 2,
    member_name: "Basic",
    email: "info@gmail.com	",
    created_at: "15-02-2024",
    status: "Active",
    last_login_at: "2023-03-02 15:02:46",
    last_seen: "1 year ago",
    online_status: "offline",
    action: "",
  },
  {
    id: 3,
    member_name: "Basic",
    email: "info@gmail.com	",
    created_at: "15-02-2024",
    status: "Active",
    last_login_at: "2023-03-02 15:02:46",
    last_seen: "1 year ago",
    online_status: "offline",
    action: "",
  },
  {
    id: 4,
    member_name: "Basic",
    email: "info@gmail.com	",
    created_at: "15-02-2024",
    status: "Active",
    last_login_at: "2023-03-02 15:02:46",
    last_seen: "1 year ago",
    online_status: "offline",
    action: "",
  },
  {
    id: 5,
    member_name: "Basic",
    email: "info@gmail.com	",
    created_at: "15-02-2024",
    status: "Active",
    last_login_at: "2023-03-02 15:02:46",
    last_seen: "1 year ago",
    online_status: "offline",
    action: "",
  },
];

function handleAction(row) {
  alert(row.id);
}
const Members = () => {
  const navigate=useNavigate()

  const [permissions, setPermissions] = useState({});
  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `Members List ${formatDate(today)}`; // here enter filename for your excel file
  
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [memberID, setMemberID] = useState();
  const [editMember, setEditMember] = useState(false);


  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [members, setMembers] = useState([]);

  const handleChange = (event, value) => {
        setPage(value);
   };

   const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard" >
      Dashboard
    </Link>,
    <Link underline="hover" key="1" color="inherit" to="/admin/members" >
    Members
  </Link>,
      <Typography key="3" sx={{ color: 'text.primary' }}>
      {page}
    </Typography>,
  ];
  
  const handleAddMember = (e) => {
    setEditMember(false)
    setShowModal(true)
  };
  const handleEdit = (id) => (e) => {
    setMemberID(id)
    setEditMember(true)
    setShowModal(true)
  };

  const showAlert = () => {
    if (Object.values(permissions).length > 0 && !permissions['view-members']) {
      Swal.fire({
        title: "Permission Denied",
        text: "You don't have permission to see this page, please contact Administrator",
        icon: "warning",
        confirmButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/admin/dashboard");
        }
      });
    }
  };
  
  const handleRequest = () => {
    adminhttp().get(`/admin/members?page=${page}`).then((data) => {
     if(data.data.data.members.data.length>0){
      setLoading(true)
      setMembers(data.data.data.members.data);
      setTotalPages(data.data.data.members.last_page);
      setLoading(false)
     }else{
      setNoData(true)
      setLoading(false)
     }
    });
  }

    useEffect(() => {
      handleRequest();      
    }, [page]);
  
// Export as
useEffect(() => {
  const fetchData = () => {
    adminhttp()
      .get(`/admin/members`)
      .then((data) => {
        // reshaping the array
        if (data.data.data.members.length > 0) {
          const customHeadings = data.data.data.members.map((member) => ({
            // "Membership Id": user.membership_id,
            "Name": member.name,
            "Email": member.email,
            "Created at": formatDate(member.created_at),
          }));
          setExportData(customHeadings);
        }
      });
  };
  fetchData();
}, []);

useEffect(() => {
    getPermissions().then((res)=> (setPermissions(res)))
}, []);


  return (
<>
<AdminLayout>
{loading && <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' height='100%'>
      <Spinner size='lg' className='me-2 ' animation='border' />
 </Box>}
      {(Object.values(permissions).length>0 && permissions['view-members']) ? <Container fluid className='bg-light pb-5'  >
      <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>
          <Box component="section" className='d-flex align-items-center justify-content-between header-menu' sx={{ px: 2, pt: 0, pb: 1 }} >
          <h4>Members</h4>
         <div className="d-flex justify-content-end align-items-center">
          <Form className='me-2'>
            <Form.Control type="search" size='small' placeholder="Search Members" />
          </Form>
           
          {permissions['create-members'] && <Button size='sm' className='me-2' onClick={handleAddMember}>Add Member</Button>}
            {permissions['export-members'] && <ExportToExcel apiData={exportData} fileName={fileName} />}
                   
           </div>
        </Box>
         
        {loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(
        <Container fluid>
            <Row xs={1} md={3} className="g-4">
            {members.map((member, index) => ( 
      // {Array.from({ length: 6 }).map((_, idx) => (
        <Col key={index}  >
        <MemberCard data={member} handleRequest={handleRequest} handleEdit={handleEdit} />
    {/* <Card className='rounded-3'   sx={{':hover': {boxShadow: 5},}}>
    <div style={{ position: "relative" }}>
      <CardMedia style={{ }}   component="img" image={"https://img.freepik.com/free-vector/colorful-watercolor-background_23-2148492944.jpg?size=626&ext=jpg&ga=GA1.1.942094041.1718883224&semt=ais_hybrid"} title="Pancakes" alt="Pancakes"/> 
      <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',position: "absolute", color: "white",top: 0,left: "50%",transform: "translateX(-50%)",}}> 
      <div className='h-100 d-flex flex-column align-items-center justify-content-center w-100'>
        <BadgeAvatar
                  src="https://jumbo.g-axon.work/assets/images/avatar/avatar3.jpg"
                  name="Jacob John"
                  size="100"
                />    
                  <Typography variant='h5' className='mt-1 text-light fw-bold'>John Jacob</Typography>
                  <Typography variant='h6' className='text-light'>johnjacob@gmail.com</Typography>
        <ProgressBar now={60} variant='warning' style={{height:'5px' , width:'100%'}} className='w-75 mt-3' />
        </div>
      </div>
  </div>
  <CardContent className='pb-1 pt-4 text-center'>
  <Grid container spacing={2}>
        <Grid item xs={4}>
        <Badge className='mb-3' color="success" max={250} badgeContent={250}></Badge>
        <h6>Followers</h6>
        </Grid>
        <Grid item xs={4}>
        <Badge className='mb-3' color="warning" max={250} badgeContent={225}></Badge>
        <h6>Followings</h6>
        </Grid>
        <Grid item xs={4}>
        <Badge className='mb-3' color="info" max={250} badgeContent={200}></Badge>
        <h6>Friends</h6>
        </Grid>
      </Grid>
      </CardContent>
</Card> */}
        </Col>
      ))}
    </Row>
    
            <Box sx={{ margin: "auto", width: "fit-content",alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
            </Box>
            </Container>
            )}
            {noData && ( 
                <Box
                  sx={{ flexGrow: 1 }}
                  className="d-flex justify-content-center"
                >
                  <Alert variant="outlined" severity="error">
                    Members not found
                  </Alert>
                </Box>
              )}
      </Container> : (showAlert())}
    </AdminLayout>
        <AddMember show={showModal} handleRequest={handleRequest}  close={() => setShowModal(false)} edit={editMember} memberID={memberID} />
</>
  );
};

export default Members;
