import React from 'react'
import NaviBar from './NaviBar'

function Header() {
  
  
  return (

      <NaviBar/>
  )
}

export default Header
