export const ECOSYSTEM_OPTIONS = [
    { value: 'entrepreneurial', label: 'Entrepreneurial Finance System (e.g. investor, bank, grant giver)'},
    { value: 'accelerators', label: 'Accelerators and incubators (e.g. start-up system)'},
    { value: 'industry', label: 'Industry system (e.g. company)'},
    { value: 'talent', label: 'Talent system (e.g. university, education, science parks and technology centres)'},
    { value: 'society', label: 'Think-tanks, Societies and interest groups'},
    { value: 'management', label: 'Management system (e.g. government departments, police, health care)'},
    { value: 'polictics', label: 'Politicians and legislators'},
    { value: 'culture', label: 'Livelihood system (e.g. sport, culture, amusement)'},
    { value: 'demonstration', label: 'Demonstration system (exhibition centres and events companies)'},
    { value: 'global', label: 'Global system (e.g. embassies, OECD, UN, Arab League)'},
    { value: 'urban', label: 'Urban and regional system (e.g. smart cities, mobility and transport)'},
    { value: 'press', label: 'Media and the Press'},
  ]

export const COUNTRIES = [
    { value: "", label:"Select Country"},
    { value:"1", label:"Afghanistan"},
    { value:"2", label:"Aland Islands"},
    { value:"3", label:"Albania"},
    { value:"4", label:"Algeria"},
    { value:"5", label:"American Samoa"},
    { value:"6", label:"Andorra"},
    { value:"7", label:"Angola"},
    { value:"8", label:"Anguilla"},
    { value:"9", label:"Antarctica"},
    { value:"10", label:"Antigua And Barbuda"},
    { value:"11", label:"Argentina"},
    { value:"12", label:"Armenia"},
    { value:"13", label:"Aruba"},
    { value:"14", label:"Australia"},
    { value:"15", label:"Austria"},
    { value:"16", label:"Azerbaijan"},
    { value:"17", label:"Bahamas The"},
    { value:"18", label:"Bahrain"},
    { value:"19", label:"Bangladesh"},
    { value:"20", label:"Barbados"},
    { value:"21", label:"Belarus"},
    { value:"22", label:"Belgium"},
    { value:"23", label:"Belize"},
    { value:"24", label:"Benin"},
    { value:"25", label:"Bermuda"},
    { value:"26", label:"Bhutan"},
    { value:"27", label:"Bolivia"},
    { value:"28", label:"Bosnia and Herzegovina"},
    { value:"29", label:"Botswana"},
    { value:"30", label:"Bouvet Island"},
    { value:"31", label:"Brazil"},
    { value:"32", label:"British Indian Ocean Territory"},
    { value:"33", label:"Brunei"},
    { value:"34", label:"Bulgaria"},
    { value:"35", label:"Burkina Faso"},
    { value:"36", label:"Burundi"},
    { value:"37", label:"Cambodia"},
    { value:"38", label:"Cameroon"},
    { value:"39", label:"Canada"},
    { value:"40", label:"Cape Verde"},
    { value:"41", label:"Cayman Islands"},
    { value:"42", label:"Central African Republic"},
    { value:"43", label:"Chad"},
    { value:"44", label:"Chile"},
    { value:"45", label:"China"},
    { value:"46", label:"Christmas Island"},
    { value:"47", label:"Cocos (Keeling) Islands"},
    { value:"48", label:"Colombia"},
    { value:"49", label:"Comoros"},
    { value:"50", label:"Congo"},
    { value:"51", label:"Congo The Democratic Republic Of The"},
    { value:"52", label:"Cook Islands"},
    { value:"53", label:"Costa Rica"},
    { value:"54", label:"Cote D'Ivoire (Ivory Coast)"},
    { value:"55", label:"Croatia (Hrvatska)"},
    { value:"56", label:"Cuba"},
    { value:"57", label:"Cyprus"},
    { value:"58", label:"Czech Republic"},
    { value:"59", label:"Denmark"},
    { value:"60", label:"Djibouti"},
    { value:"61", label:"Dominica"},
    { value:"62", label:"Dominican Republic"},
    { value:"63", label:"East Timor"},
    { value:"64", label:"Ecuador"},
    { value:"65", label:"Egypt"},
    { value:"66", label:"El Salvador"},
    { value:"67", label:"Equatorial Guinea"},
    { value:"68", label:"Eritrea"},
    { value:"69", label:"Estonia"},
    { value:"70", label:"Ethiopia"},
    { value:"71", label:"Falkland Islands"},
    { value:"72", label:"Faroe Islands"},
    { value:"73", label:"Fiji Islands"},
    { value:"74", label:"Finland"},
    { value:"75", label:"France"},
    { value:"76", label:"French Guiana"},
    { value:"77", label:"French Polynesia"},
    { value:"78", label:"French Southern Territories"},
    { value:"79", label:"Gabon"},
    { value:"80", label:"Gambia The"},
    { value:"81", label:"Georgia"},
    { value:"82", label:"Germany"},
    { value:"83", label:"Ghana"},
    { value:"84", label:"Gibraltar"},
    { value:"85", label:"Greece"},
    { value:"86", label:"Greenland"},
    { value:"87", label:"Grenada"},
    { value:"88", label:"Guadeloupe"},
    { value:"89", label:"Guam"},
    { value:"90", label:"Guatemala"},
    { value:"91", label:"Guernsey and Alderney"},
    { value:"92", label:"Guinea"},
    { value:"93", label:"Guinea-Bissau"},
    { value:"94", label:"Guyana"},
    { value:"95", label:"Haiti"},
    { value:"96", label:"Heard and McDonald Islands"},
    { value:"97", label:"Honduras"},
    { value:"98", label:"Hong Kong S.A.R."},
    { value:"99", label:"Hungary"},
    { value:"100", label:"Iceland"},
    { value:"101", label:"India"},
    { value:"102", label:"Indonesia"},
    { value:"103", label:"Iran"},
    { value:"104", label:"Iraq"},
    { value:"105", label:"Ireland"},
    { value:"106", label:"Israel"},
    { value:"107", label:"Italy"},
    { value:"108", label:"Jamaica"},
    { value:"109", label:"Japan"},
    { value:"110", label:"Jersey"},
    { value:"111", label:"Jordan"},
    { value:"112", label:"Kazakhstan"},
    { value:"113", label:"Kenya"},
    { value:"114", label:"Kiribati"},
    { value:"115", label:"Korea North"},
    { value:"116", label:"Korea South"},
    { value:"117", label:"Kuwait"},
    { value:"118", label:"Kyrgyzstan"},
    { value:"119", label:"Laos"},
    { value:"120", label:"Latvia"},
    { value:"121", label:"Lebanon"},
    { value:"122", label:"Lesotho"},
    { value:"123", label:"Liberia"},
    { value:"124", label:"Libya"},
    { value:"125", label:"Liechtenstein"},
    { value:"126", label:"Lithuania"},
    { value:"127", label:"Luxembourg"},
    { value:"128", label:"Macau S.A.R."},
    { value:"129", label:"Macedonia"},
    { value:"130", label:"Madagascar"},
    { value:"131", label:"Malawi"},
    { value:"132", label:"Malaysia"},
    { value:"133", label:"Maldives"},
    { value:"134", label:"Mali"},
    { value:"135", label:"Malta"},
    { value:"136", label:"Man (Isle of)"},
    { value:"137", label:"Marshall Islands"},
    { value:"138", label:"Martinique"},
    { value:"139", label:"Mauritania"},
    { value:"140", label:"Mauritius"},
    { value:"141", label:"Mayotte"},
    { value:"142", label:"Mexico"},
    { value:"143", label:"Micronesia"},
    { value:"144", label:"Moldova"},
    { value:"145", label:"Monaco"},
    { value:"146", label:"Mongolia"},
    { value:"147", label:"Montenegro"},
    { value:"148", label:"Montserrat"},
    { value:"149", label:"Morocco"},
    { value:"150", label:"Mozambique"},
    { value:"151", label:"Myanmar"},
    { value:"152", label:"Namibia"},
    { value:"153", label:"Nauru"},
    { value:"154", label:"Nepal"},
    { value:"155", label:"Netherlands Antilles"},
    { value:"156", label:"Netherlands The"},
    { value:"157", label:"New Caledonia"},
    { value:"158", label:"New Zealand"},
    { value:"159", label:"Nicaragua"},
    { value:"160", label:"Niger"},
    { value:"161", label:"Nigeria"},
    { value:"162", label:"Niue"},
    { value:"163", label:"Norfolk Island"},
    { value:"164", label:"Northern Mariana Islands"},
    { value:"165", label:"Norway"},
    { value:"166", label:"Oman"},
    { value:"167", label:"Pakistan"},
    { value:"168", label:"Palau"},
    { value:"169", label:"Palestinian Territory Occupied"},
    { value:"170", label:"Panama"},
    { value:"171", label:"Papua new Guinea"},
    { value:"172", label:"Paraguay"},
    { value:"173", label:"Peru"},
    { value:"174", label:"Philippines"},
    { value:"175", label:"Pitcairn Island"},
    { value:"176", label:"Poland"},
    { value:"177", label:"Portugal"},
    { value:"178", label:"Puerto Rico"},
    { value:"179", label:"Qatar"},
    { value:"180", label:"Reunion"},
    { value:"181", label:"Romania"},
    { value:"182", label:"Russia"},
    { value:"183", label:"Rwanda"},
    { value:"189", label:"Saint-Barthelemy"},
    { value:"184", label:"Saint Helena"},
    { value:"185", label:"Saint Kitts And Nevis"},
    { value:"186", label:"Saint Lucia"},
    { value:"190", label:"Saint-Martin (French part)"},
    { value:"187", label:"Saint Pierre and Miquelon"},
    { value:"188", label:"Saint Vincent And The Grenadines"},
    { value:"191", label:"Samoa"},
    { value:"192", label:"San Marino"},
    { value:"193", label:"Sao Tome and Principe"},
    { value:"194", label:"Saudi Arabia"},
    { value:"195", label:"Senegal"},
    { value:"196", label:"Serbia"},
    { value:"197", label:"Seychelles"},
    { value:"198", label:"Sierra Leone"},
    { value:"199", label:"Singapore"},
    { value:"200", label:"Slovakia"},
    { value:"201", label:"Slovenia"},
    { value:"202", label:"Solomon Islands"},
    { value:"203", label:"Somalia"},
    { value:"204", label:"South Africa"},
    { value:"205", label:"South Georgia"},
    { value:"206", label:"South Sudan"},
    { value:"207", label:"Spain"},
    { value:"208", label:"Sri Lanka"},
    { value:"209", label:"Sudan"},
    { value:"210", label:"Suriname"},
    { value:"211", label:"Svalbard And Jan Mayen Islands"},
    { value:"212", label:"Swaziland"},
    { value:"213", label:"Sweden"},
    { value:"214", label:"Switzerland"},
    { value:"215", label:"Syria"},
    { value:"216", label:"Taiwan"},
    { value:"217", label:"Tajikistan"},
    { value:"218", label:"Tanzania"},
    { value:"219", label:"Thailand"},
    { value:"220", label:"Togo"},
    { value:"221", label:"Tokelau"},
    { value:"222", label:"Tonga"},
    { value:"223", label:"Trinidad And Tobago"},
    { value:"224", label:"Tunisia"},
    { value:"225", label:"Turkey"},
    { value:"226", label:"Turkmenistan"},
    { value:"227", label:"Turks And Caicos Islands"},
    { value:"228", label:"Tuvalu"},
    { value:"229", label:"Uganda"},
    { value:"230", label:"Ukraine"},
    { value:"231", label:"United Arab Emirates"},
    { value:"232", label:"United Kingdom"},
    { value:"233", label:"United States"},
    { value:"234", label:"United States Minor Outlying Islands"},
    { value:"235", label:"Uruguay"},
    { value:"236", label:"Uzbekistan"},
    { value:"237", label:"Vanuatu"},
    { value:"238", label:"Vatican City State (Holy See)"},
    { value:"239", label:"Venezuela"},
    { value:"240", label:"Vietnam"},
    { value:"241", label:"Virgin Islands (British)"},
    { value:"242", label:"Virgin Islands (US)"},
    { value:"243", label:"Wallis And Futuna Islands"},
    { value:"244", label:"Western Sahara"},
    { value:"245", label:"Yemen"},
    { value:"246", label:"Zambia"},
    { value:"247", label:"Zimbabwe"},
];
export const CITIES = [
    { value: "", label:"Select City"},
    { value:"1", label:"Hyderabad"},
    { value:"2", label:"Khazipet"},
    { value:"3", label:"Warangal"}
];

export const ACTION_INVALID = "This action is invalid!";
export const CONSTANT_NUMBER_1 = 'hello I am a constant';
export const CONSTANT_NUMBER_2 = 'hello I am also a constant';
export const PLSIZE = 30;
export const CATEGORIES =[
                                        { value:"3", label: "Artificial Intelligence (AI)"},
                                        { value:"4", label: "Intelligent Automation"},
                                        { value:"5", label: "Cyber Security"},
                                        { value:"6", label: "Blockchain"},
                                        { value:"7", label: "Robotics"},
                                        { value:"8", label: "The Internet-of-Things (IoT)"},
                                        { value:"9", label: "5G"},
                                        { value:"10", label: "Virtual reality (VR)/Augmented reality (AR)"},
                                        { value:"11", label: "Drones"},
                                        { value:"12", label: "All Emerging Technologies"},
                                        { value:"13", label: "Metaverse"},
                                        { value:"14", label: "Web3"},
                                        { value:"15", label: "NFT (Non-fungible token)"},
                                        { value:"16", label: "Government"},
                                        { value:"17", label: "Technology"},
                                        { value:"18", label: "Financial Services"},
                                        { value:"19", label: "Real Estate"},
                                        { value:"20", label: "Agriculture"},
                                        { value:"21", label: "Chemical industries"},
                                        { value:"22", label: "Commerce"},
                                        { value:"23", label: "Construction"},
                                        { value:"24", label: "Education"},
                                        { value:"25", label: "Professional services"},
                                        { value:"26", label: "R&amp;D"},
                                        { value:"27", label: "Media"},
                                        { value:"28", label: "Retailers"},
                                        { value:"29", label: "Energy"},
                                        { value:"30", label: "Telecommunications services"},
                                        { value:"31", label: "Public Service"},
                                        { value:"32", label: "Manufacturing"},
                                        { value:"33", label: "Automotive"},
                                        { value:"34", label: "Retail"},
                                        { value:"35", label: "Wholesale and Distribution"},
                                        { value:"36", label: "Travel"},
                                        { value:"37", label: "Hospitality and Services"},
                                        { value:"38", label: "Security and Justice"},
                                        { value:"39", label: "Life Sciences &amp; Health Care"},
];

export function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return ( `${year}-${month}-${day}` );
}

