import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Hidden, SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import PreLoader from '../../../PreLoader';
import { PLSIZE } from '../../../PavilionConstants';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BadgeAvatar from '../../../BadgeAvatar';
import MembersFilter from '../../Filters/MembersFilter';
import http from '../../../../http';

const AntTabs = styled(Tabs)({
    width: '100%',
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-flexContainer': {
        border: '1px solid #ececec',
        width: '100%',
        padding: '2px 3px',
        borderRadius: 50,
        marginBottom:0,
    },
});

interface StyledTabProps {
    label: string;
}
const AntTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    minHeight: 20,
    width: '50%',
    borderRadius: 50,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 3,
    paddingBottom: 3,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: ['Barlow'].join(','),
    '&:hover': {
        color: '#e30613',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#e30613',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

function PRFLMyCommunity({ handleClick }) {

    const [followings, setFollowings] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
    useEffect(() => {
        // Call fetchData function when component mounts (empty dependency array means it runs once)
        fetchData();
    }, []); // Empty dependency array ensures this effect runs only once, like componentDidMount
        const fetchData = async () => {
            setLoadMsg(<PreLoader size={PLSIZE} />);
            try {
                // First API call to fetch user details
                const userDetailsResponse = await http().get('/userdetails');
                const myLink = userDetailsResponse.data.data.user.my_link;
                
                // Second API call to fetch user community using myLink from first response
                const userCommunityResponse = await http().post('/userCommunity/' + myLink);
                const communityData = userCommunityResponse.data.data;
                // Update state with fetched data
                setFollowings(communityData.followings);
                setFollowers(communityData.followers);
                setLoadMsg(null);

            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle errors if needed
            }
        };
    
    const handleFollowToggle = async (id, isFollowing, isFollowersTab) => {
    setLoadMsg(<PreLoader size={PLSIZE} />);
    try {
        const response = await http().post('/followorunfollow', { to_id: id });
        const updatedUserData = response.data.data;

        if (isFollowersTab) {
            // Update followers list based on isFollowing flag
            setFollowers(prevFollowers => {
                return prevFollowers.map(user => {
                    if (user.id === id) {
                        return { ...user, is_following: !isFollowing }; // Toggle is_following status
                    }
                    return user;
                });
            });
        } else {
            // Update followings list based on isFollowing flag
            setFollowings(prevFollowings => {
                return prevFollowings.map(user => {
                    if (user.id === id) {
                        return { ...user, is_following: !isFollowing }; // Toggle is_following status
                    }
                    return user;
                });
            });
        }
        fetchData();
        setLoadMsg(null); // Clear loading message
    } catch (error) {
        console.error('Error following/unfollowing user:', error);
        setLoadMsg(null); // Clear loading message in case of error
    }
};

    
    
    

    const [btnText, setBtnText] = useState(["Follow", "Unfollow", "Follow", "Unfollow"])


    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [showFilter, setShowFilter] = useState(false);

    return (
        <ListGroup className="zoom overflow-hidden h-100 px-0 " >
            <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <div>
                    {/* <IconButton sx={{padding:1}} onClick={event => handleClick(false, true,'PVLN2')}><ArrowBackIcon /></IconButton>  */}
                    {showFilter && (<IconButton onClick={() => setShowFilter(false)}><ArrowBackIcon /></IconButton>)}
                    <Typography variant="small">
                        {!showFilter && "My Community"}
                        {showFilter && "Community Filter"}
                    </Typography>
                </div>
                <div>
                    <IconButton onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
                    {/* <IconButton onClick={() => setShowFilter(true)}><FilterAltIcon /></IconButton> */}
                </div>
            </ListGroup.Item>
           {!showFilter && (  
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75 px-2">
                <div className="container py-2 px-0">

                    <TabContext value={value}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} className="rounded-4 p-0 small d-flex flex-column justify-content-between align-items-center">
                            <AntTabs
                                value={value}
                                onChange={handleChange}
                                scrollButtons="false"
                                aria-label="scrollable auto tabs example"
                            >
                                <AntTab label={<Typography variant="h6" className="text-capitalize py-0" >Following</Typography>} value="1" />
                                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Followers</Typography>} value="2" />
                            </AntTabs>
                            <TabPanel value="1" className='w-100 p-2 slim-scrollbar mh-70'>
                            <div className="loader my-2 text-center" key={0}>{loadMsg}</div>

                                <Row xs={1} md={12} className="g-3 d-flex justify-content-between align-items-center slim-scrollbar mt-0" style={{ maxHeight: "55vh" }} >
                                    {followings.map((following, index) => (
                                            <>
                                                <Col key={following.id} md={2} >
                                                    {/* <Image variant="top" className='rounded' style={{width:"40px",height:"40px"}} src={membershipImages[idx]} /> */}
                                                    {/* <BadgeAvatar src="" name={following.name} size='40' isLoggedIn={false} /> */}
                                                    <a target="_blank" href={ "/" + following.my_link}><>
                                                    {following.image? <BadgeAvatar  src={localStorage.getItem('bucketName')+'/images/profile_picture/'+following.image} name={following.name} size='40' />:<BadgeAvatar  name={following.name} size='40' />}
                                                    </></a>
                                                </Col>
                                                <Col md={7}>
                                                    <a target="_blank" href={ "/" + following.my_link}><>
                                                    <h6 className='mb-0 pb-1'>{following.name}</h6> </></a>
                                                    <div className='pb-1'><medium>{following.designation && following.designation.substring(0, 30)}</medium></div>
                                                    <div className=''><medium>{following.organization && following.organization.substring(0, 30)}</medium></div><br />
                                                </Col>
                                                <Col md={3}>
                                                    <Button variant="primary" size="sm" onClick={() => handleFollowToggle(following.id, true, false)}>{btnText[1]}</Button>
                                                </Col>
                                            </>
                                        ))}
                                </Row>
                            </TabPanel>
                            <TabPanel value="2" className='w-100 p-2 slim-scrollbar mh-70'>
                            <div className="loader my-2 text-center" key={0}>{loadMsg}</div>
                                <Row xs={1} md={12} className="g-3 d-flex justify-content-between align-items-center slim-scrollbar mt-0" style={{ maxHeight: "55vh" }} >
                                    {followers.map((follower, index) => (
                                        <>
                                            <Col key={follower.id} md={2} className="">
                                                {/* <Image variant="top" className='rounded' style={{width:"40px",height:"40px"}} src={membershipImages[idx]} /> */}
                                                {/* <BadgeAvatar src="" name={follower.name} size='40' isLoggedIn={false} /> */}
                                                {/* {followers.image? <BadgeAvatar  src={'https://bic3-staging.s3.eu-west-2.amazonaws.com/images/profile_picture/'+followers.image} name="Stephen Metcalfe" size='40' />:<BadgeAvatar  name={followers.name} size='40' />} */}
                                                <a target="_blank" href={ "/" + follower.my_link}><>
                                                {follower.image? <BadgeAvatar  src={localStorage.getItem('bucketName')+'/images/profile_picture/'+follower.image} name={follower.name} size='40' />:<BadgeAvatar  name={follower.name} size='40' />}
                                                </></a>
                                            </Col>
                                            <Col md={7}>
                                                <a target="_blank" href={ "/" + follower.my_link}><>
                                                <h6 className='mb-0 pb-1'>{follower.name}</h6></></a>
                                                <div className='pb-1'><medium>{follower.designation && follower.designation.substring(0, 30)}</medium></div>
                                                <div className=''><medium>{follower.organization && follower.organization.substring(0, 30)}</medium></div>
                                                {/* <small>{memberCo[idx].substring(0, 30)}</small> */}
                                            </Col>
                                            <Col md={3}>
                                            <Button variant="primary" size="sm" onClick={() => handleFollowToggle(follower.id, follower.is_following === 1, true)}> {follower.is_following === 1 ? 'Unfollow' : 'Follow'} </Button>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </div>
            </ListGroup.Item>
            )}
            {showFilter && (<MembersFilter/>)}

        </ListGroup>
    )
}

export default PRFLMyCommunity



