import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ResetIcon from '@mui/icons-material/Loop';
import ZoomInIcon from '@mui/icons-material/ControlPoint';
import ZoomOutIcon from '@mui/icons-material/RemoveCircleOutline';
// import { CircleFlag } from 'react-circle-flags'
import { markers } from "./markers";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import {useNavigate} from 'react-router-dom'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  useZoomPan,
  ZoomableGroup
} from "react-simple-maps";

const width = 800;
const height = 400;

const CustomZoomableGroup = ({ children, ...restProps }) => {
  const { mapRef, transformString, position } = useZoomPan(restProps);
  return (
    <g ref={mapRef}>
      <rect width={width} height={height} fill="transparent" />
      <g transform={transformString}>{children(position)}</g>
    </g>
  );
};


const MapChart = ({setTooltipContent}) => {

  const [position, setPosition] = useState({ coordinates: [10, 10], zoom: 1 });

  const [zoom, setZoom] = useState(1);
  const [center, setCenter] = useState([10, 10]);
  const [selected, setSelected] = useState(null);
  
  const navigate = useNavigate();

  function handleMarkerClick(num) {
    navigate(`/plans/${num}`)
  }
  function handleResetClick() {
     setCenter([10,10]);
     setZoom(1);
  //   console.log(zoom)
//if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: 1, coordinates: [10,10] }));
   }

   

  const handleGeographyClick = (geography, projection, path) => event => {
    const centroid = projection.invert(path.centroid(geography));
    setCenter(centroid);
    setZoom(4);
    
    //   setSelected(geography.properties.ISO_A3);
    //   if(selected=== geography.properties.ISO_A3){
    //   }else{
    //     setSelected(null);
    //     //alert('yes')
    //     setCenter([0,0]);
    // setZoom(1);
    //   }
    
  };

  // const [position, setPosition] = useState({ coordinates: [10, -10], zoom: 1 });

  //function handleZoomIn() {
    // if (position.zoom >= 4) return;
    // setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  //}

  //function handleZoomOut() {
  //  alert('tezt')
    // if (position.zoom <= 1) return;
     //setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
    // setCenter([0,0]);
 // }

  // function handleMoveEnd(position) {
  //   setPosition(position);
  // }

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }
  return (
    <>
    
        <ComposableMap height={height} width={width}  >
        {/* <rect
        width={width}
        height={height}
        fill="transparent"
        onClick={() => setSelected(null)}
      /> */}
        {/* <CustomZoomableGroup center={center} zoom={zoom}> */}
        <CustomZoomableGroup 
        zoom={position.zoom}
        center={position.coordinates}
        onMoveEnd={handleMoveEnd}
        >
        {position => (
          <>
        {/* <ZoomableGroup center={center} zoom={zoom}> */}
        <Geographies geography="/world.json">
        {({ geographies, projection, path }) =>
            geographies.map((geo) => (
              <>
              <Geography key={geo.rsmKey} geography={geo} stroke="#e30613" strokeWidth={1}
                 onClick={handleGeographyClick(geo, projection, path)}
            //   onMouseEnter={() => {
            //     // setTooltipContent(`${geo.properties.name}`);
            //   }}
            //   onMouseLeave={() => {
            //     // setTooltipContent("");
            //   }}
              style={{
                default: {
                  fill: "#ffffff90",
                  outline: "none",
                  strokeWidth: 0.5,
                },
                hover: {
                  fill: "#ffffff",
                  outline: "none",
                  strokeWidth: 0.5,
                },
                pressed: {
                  fill: "#666666",
                  outline: "none",
                  strokeWidth: 0.5,
                },
                
            }}
              
              />

             </>
            ))
          }
        </Geographies>
        
      
           
      {markers.map(({markerOffset, name, coordinates, icon }) => (
       <Tooltip id={name} arrow title={name}>
       <Marker onClick={(e)=>handleMarkerClick(name)} className='mapmarker' key={name} coordinates={coordinates} ><>
        <svg viewBox="0 0 500 500" width={(50/position.k)+10} height={(50/position.k)+10}>
    <defs>
    <circle id="circle" cx={50} cy={50} r={50} vectorEffect="non-scaling-stroke"/>
    <clipPath id="circle-clip">
      <use xlinkHref="#circle"/>
    </clipPath>
  </defs>
  <g clipPath="url(#circle-clip)">
  {/* <CircleFlag countryCode="es" height="35" /> */}
     <image xlinkHref={icon} width="20%" height="20%" preserveAspectRatio="xMidYMid slice"/> 
    <use xlinkHref="#circle" fill="none" stroke="#ffF" strokeWidth="2" opacity="1"/>
  </g>
</svg>
      
       {/* <circle r={6}  stroke="#fff" strokeWidth={1} fill="url('https://commons.wikimedia.org/wiki/File:Flag_of_India.png')" 
    > */}
           {/* <img src='https://github.com/HatScripts/circle-flags/blob/gh-pages/flags/ad.svg' width="100" height="140"/> */}
        {/* </circle>     */}
       {/* <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 1000 1000">
        <mask id="a">
          <circle  r={50} cx='50' cy='50' fill="#fff" strokeWidth={1}/>
          </mask>
          <g mask="url(#a)" >
            <path fill="#0052b4" d="M0 0h144.7l36 254.6-36 257.4H0z"/>
            <path fill="#d80027" d="M367.3 0H512v512H367.3l-29.7-257.3z"/>
            <path fill="#ffda44" d="M144.7 0h222.6v512H144.7z"/>
            <path fill="#d80027" d="M256 354.5V256h66.8v47.3zm-66.8-165.3H256V256h-66.8z"/>
            <path fill="#ff9811" d="M289.4 167a22.3 22.3 0 0 0-33.4-19.3 22.1 22.1 0 0 0-11.1-3c-12.3 0-22.3 10-22.3 22.3H167v111.3c0 41.4 32.9 65.4 58.7 77.8a22.1 22.1 0 0 0-3 11.2 22.3 22.3 0 0 0 33.3 19.3 22.1 22.1 0 0 0 11.1 3 22.3 22.3 0 0 0 19.2-33.5c25.8-12.4 58.7-36.4 58.7-77.8V167zm22.3 111.3c0 5.8 0 23.4-27.5 40.9a136.5 136.5 0 0 1-28.2 13.3c-7-2.4-17.8-6.7-28.2-13.3-27.5-17.5-27.5-35.1-27.5-41v-77.9h111.4z"/>
            </g>
            </svg> */}
            </>
        </Marker>
        
        </Tooltip>
      ))}
      
      {/* </ZoomableGroup> */}
      </>
        )}
      </CustomZoomableGroup>
      
      </ComposableMap>
      {/* <div className="controls">
        <button onClick={handleZoomIn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
        <button onClick={handleZoomOut}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
      </div> */}
      <div className="controls d-flex ">
      <IconButton onClick={handleZoomIn} ><ZoomInIcon sx={{ color: '#ffffff' }} fontSize='large'/></IconButton>
      <IconButton onClick={handleZoomOut} ><ZoomOutIcon sx={{ color: '#ffffff' }} fontSize='large'/></IconButton>
        <IconButton onClick={handleResetClick} ><ResetIcon sx={{ color: '#ffffff' }} fontSize='large'/></IconButton>
      </div>

      </>
  );
};

export default MapChart;
