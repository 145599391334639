import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import http from '../../../../../http'

function PRFLInsights({ handleClick }) {
    
    const [pavilionId, setPavilionId]=useState(3)
    const [pavilionSlug, setPavilionSlug]=useState()
    
    const [principalHost, setPrincipalHost]=useState([])
    const [secretariate, setSecretriate]=useState([])
    const [typeOfMembers, setTypeOfMembers]=useState([])
    const [contactUs, setContactUs]=useState([])

    const location = useLocation();
    const splitURL = location.pathname.toString().split("/");
    useEffect(() => {
        if (splitURL[1] === 'about_pavilion') {
            if (splitURL[2] ) {
              setPavilionSlug(splitURL[2])
               http().get('/publicabout/'+splitURL[2]).then((res) => {
                  setPavilionId(res.data.data.aboutPavilion.id)
               });
            } 
        }
        
    }, []);

    useEffect(() => {
        if(pavilionSlug){
        http().get('/publicabout/'+pavilionSlug).then((res) => {
            setPrincipalHost(res.data.data.principalArray.split('@##@'))        
            setSecretriate(res.data.data.secretriatArray.split('@##@'))
            setTypeOfMembers(res.data.data.typeOfMembersArray.split('@##@'))        
            setContactUs(res.data.data.contactusArray.split('@##@'))        
        });
    }
    },[pavilionSlug]);

    return (
        <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
            <div>
                <IconButton onClick={event => handleClick('PRFL-MENU2')}><ArrowBackIcon /></IconButton>
                <Typography variant="small">Insights</Typography>
            </div>
                <IconButton onClick={event => handleClick('PRFL-MENU2')}><Home /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-center justify-content-center h-100  border-0 bg-light bg-opacity-75" >
                <div className="container slim-scrollbar mh-70  py-0 ">

                    <ListGroup className="mb-3 shadow-sm">
                        <ListGroup.Item className="pb-0">
                            <h6 className="my-0 text-primary  ">Principal Host:</h6>
                            <div className="mb-0 mt-2">
                                <ul className="px-3 ">
                                  {principalHost.map((phost,idx) => ( 
                                    <li key={idx} className="h6">{phost}</li>
                                  ))}     
                                </ul>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className=" pb-0">
                            <h6 className="my-0 text-primary ">Secretariat:</h6>
                            <div className="mb-0 mt-2">
                                <ul className="px-3">
                                {secretariate.map((secret,idx) => ( 
                                    <li key={idx} className="h6">{secret}</li>
                                  ))}     
                                </ul>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="pb-0">
                            <h6 className="my-0 text-primary ">Type of Members:</h6>
                            <div className="mb-0 mt-2 ">
                                <ul className="px-3">
                                {typeOfMembers.map((member,idx) => ( 
                                    <li key={idx} className="h6">{member}</li>
                                  ))}
                                </ul>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className=" pb-0">
                            <h6 className="my-0 text-primary ">Contact Us:</h6>
                            <div className="mb-0 mt-2 ">
                                <ul className="px-3">
                                {contactUs.map((contact,idx) => ( 
                                    <li key={idx} className="h6">{contact}</li>
                                  ))}
                                </ul>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>

                </div>
            </ListGroup.Item>
        </ListGroup>
    )
}

export default PRFLInsights
