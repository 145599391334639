import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LockResetIcon from '@mui/icons-material/LockReset';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import LogoutIcon from '@mui/icons-material/Logout';
import BadgeAvatar from '../BadgeAvatar';
import { Link } from 'react-router-dom';

function PavilionHeader(props) {
    const [isIconClicked, setIsIconClicked] = useState(true);
    const [show, setShow] = useState(props.isLoggedIn);

    const handleDarkMode = (event) => {
      setIsIconClicked(!isIconClicked);
  
      const thm = document.documentElement.getAttribute('data-bs-theme');
      const appId = document.getElementById('app-theme');
      const thm2 =appId.getAttribute('data-bs-theme');
      const thmToggler = event.currentTarget;
       const theme= localStorage.getItem("data-bs-theme");
      // thmToggler.innerHTML='<small>{theme}</small>';

  
      if(thm2==='light'){
        appId.setAttribute('data-bs-theme','dark');
        document.documentElement.setAttribute("data-bs-theme","dark");
        localStorage.setItem("data-bs-theme", "dark");
       // thmToggler.innerHTML='<small>LIGHT MODE<small>';
      } else{
         appId.setAttribute('data-bs-theme','light');
         document.documentElement.setAttribute("data-bs-theme","light");
         localStorage.setItem("data-bs-theme", "light");
        // thmToggler.innerHTML='<small>DARK MODE</small>';
      }
    
    }

    return (
        <header id="header" className="header fixed-top d-flex align-items-center justify-content-between bg-header ps-0 aos-init aos-animate" style={{ marginLeft: props.showLSB ? '240px' : '0' }} data-aos="fade-down">
            <div className="d-flex align-items-center justify-content-between pe-2">
                {/* <i id="micon" className="material-symbols-outlined md-36 d-block d-sm-block toggle-sidebar-btn" style={{visibility: 'visible'}}>menu</i> */}
                <IconButton size="large" onClick={props.handleToggle}><MenuOutlinedIcon fontSize="inherit" /></IconButton>
            </div>
            <form name="searchKeyword" id="searchKeyword" className="search-form">
                <div className="search-form d-flex align-items-center">
                    <input type="search" name="searchName" id="searchName" placeholder="Everyone at Big Innovation Centre" className="form-control bg-light" />
                    <IconButton type="button" className="submit" size="large"><SearchIcon fontSize="inherit" /></IconButton>
                </div>
            </form>

            <nav className="header-nav">
                <ul className="d-flex align-items-center">
                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="material-symbols-outlined md-36 d-none d-sm-block" style={{ visibility: 'visible' }}>search</i>
                        </a>
                    </li>

                    <li className="nav-item dropdown">
                        {show && (
                            <Dropdown id="dd-notification">
                                <Dropdown.Toggle className='p-0' variant="" id="dropdown-basic">
                                    <IconButton size="large" className='p-0'><NotificationsNoneIcon fontSize="inherit" /></IconButton>
                                    <Badge bg="primary">0</Badge>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Header >You have 0 notifications
                                        <a href="/user/notifications"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                    </Dropdown.Header>
                                    <hr className="dropdown-divider" />
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    <hr className="dropdown-divider" />
                                    <Dropdown.Header>test</Dropdown.Header>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}


                    </li>

                    <li className="nav-item m-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Change Mode">
                    <IconButton size="large" id="theme-toggler" onClick={handleDarkMode}>
                    {isIconClicked ? <DarkModeOutlinedIcon />  : <LightModeOutlinedIcon/>  }
                    </IconButton>
                        {/* <a href="#" className="btn btn-outline-mode btn-sm font-weight-bold d-flex align-items-center" >
                            <i className="material-symbols-outlined flip md-24" style={{visibility: 'visible'}}>dark_mode</i>
                            
                            <small>DARK MODE</small></a> */}

                        {/* <!-- <label className="switch2">
                    <input id="theme-toggler" onClick="toggleTheme()" type="checkbox">
                    <span className="slider2"></span> 
                </label> --> */}
                    </li>
                    <li className="nav-item">
                        {show && (
                            <a className="nav-link nav-icon d-flex align-items-center" href="https://bicpavilion.com/user/changepassword">
                                {/* <i className="material-symbols-outlined md-36" style={{visibility: 'visible'}}>lock_reset</i> */}
                                <IconButton size="large"><LockResetIcon fontSize="inherit" /></IconButton>
                            </a>)}
                    </li>
                    <li className="nav-item dropdown pe-3">
                        {show && (
                            <a className="nav-link nav-profile d-flex align-items-center pe-0 " href="#" data-bs-toggle="dropdown">
                                <BadgeAvatar src={localStorage.getItem('bucketName')+"/images/profile_picture/23231686680942.png"} name="Raju Dayyala" size='40' isLoggedIn={false}/>
                                <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic">
      Charles Smith
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/profilepavilion/myprofile"><AccountCircleIcon/>&nbsp; My Profile</Dropdown.Item>
        <Dropdown.Item href="#/action-2"><PasswordIcon/>&nbsp;Change Password</Dropdown.Item>
        <Dropdown.Item href="#/action-3"><ContactSupportIcon/>&nbsp;Need Help?</Dropdown.Item>
        <Dropdown.Item href="#/action-3"><PersonRemoveIcon/>&nbsp;Delete/Deactivate Account?</Dropdown.Item>
        <Dropdown.Item href="#/action-3"><LogoutIcon/>&nbsp;Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                                {/* <span className="d-none d-md-block dropdown-toggle ps-2">Charles Smith</span> */}
                            </a>)}
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="https://bicpavilion.com/user/profile">
                                    <i className="material-symbols-outlined" style={{ visibility: 'visible' }}>manage_accounts</i>
                                    <span>My Profile</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="https://bicpavilion.com/user/changepassword">
                                    <i className="material-symbols-outlined" style={{ visibility: 'visible' }}>password</i>
                                    <span>Change Password</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <i className="material-symbols-outlined" style={{ visibility: 'visible' }}>question_mark</i>
                                    <span>Need Help?</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <a href="javascript:void(0)" id="logout" className="dropdown-item d-flex align-items-center">
                                    <i className="material-symbols-outlined" style={{ visibility: 'visible' }}>logout</i>
                                    <span>Log out</span>
                                </a>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>
        </header>
    )
}

export default PavilionHeader
