import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import http from '../../../../../http'
import {useLocation} from 'react-router-dom'
import {formatDate} from '../../../../PavilionConstants'

function PRFLUpcomingEvents({handleClick}) {
    
    const [pavilionId, setPavilionId]=useState()
    const [upcomingEvents, setUpcomingEvents] = useState([])

    const location = useLocation();
    const splitURL = location.pathname.toString().split("/");
    useEffect(() => {
        if (splitURL[1] === 'about_pavilion') {
            if (splitURL[2] ) {
            //   setPavilionSlug(splitURL[2])
               http().get('/publicabout/'+splitURL[2]).then((res) => {
                //   setPavilionId(res.data.data.aboutPavilion.id)
                  http().get('/upcomintevents/'+res.data.data.aboutPavilion.id).then((res) => {
                    
                    setUpcomingEvents(res.data.data.events.data)        
                    
                });
               });
               
            } 
        }
        
    }, []);


  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
            <div>
                {/* <IconButton  onClick={event => handleClick(false, true,'PVLN2')}><ArrowBackIcon /></IconButton> */}
                <Typography variant="small">Upcoming Events</Typography>
                </div>
                <IconButton  onClick={event => handleClick('PRFL-MENU2')}><Home /></IconButton>
               
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
                <div className="container slim-scrollbar mh-70  py-0 ">
                
                <Row xs={1} md={1} className="g-0 " >
      {/* {Array.from({ length: 7 }).map((_, idx) => ( */}
      {upcomingEvents.map((innerArray,outerIndex) => ( 
        <Col className="py-2">
          <Card key={outerIndex} className="mb-0 shadow-sm bg-light" style={{cursor:"pointer"}}>
            <Card.Img variant="top" src={`${localStorage.getItem('bucketName')}/images/events/${innerArray.image}`} />
            <div className="card-img-overlay">
            
                        <h4><span className="badge bg-primary">{formatDate(innerArray.start_date)}</span></h4>
            
            </div>
            <Card.Body className="p-2 d-flex flex-column align-items-start justify-content-between " style={{minHeight:"75px"}}>

              <Card.Title className="pt-0 my-0" style={{lineHeight:"0.7"}}>
             
                <Typography key={outerIndex} variant="large" className="d-flex align-items-center text-dark small" >
                <BadgeAvatar isLoggedIn={false} src={`${localStorage.getItem('bucketName')}/${innerArray.user_image}`} name={innerArray.user_name} size='50'  /> 
                <div className="ms-2"><strong>{innerArray.name}</strong> <br></br>
                <small className="text-muted">Category: {innerArray.category_name}</small>
                </div>               
                </Typography>
              </Card.Title>
              <Card.Text className='d-flex align-items-center my-2 text-dark'>
               <LocationOnOutlinedIcon/> {innerArray.location}
              </Card.Text>
              
              {/* <Button variant="primary" size="sm">{btnText[idx]}</Button> */}
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
          
                               
                </div>
            </ListGroup.Item>
        </ListGroup>
  )
}

export default PRFLUpcomingEvents
