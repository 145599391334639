import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import PeopleIcon from '@mui/icons-material/People';
import Group from '@mui/icons-material/Group';
import AllPavilionsBox from './Boxes/AllPavilionsBox';
import ProgramDetailsBox from './Boxes/ProgramDetailsBox';
import CommunityBox from './Boxes/CommunityBox';


function PavilionRightSidebar(props) {
    const [showAP,setShowAP] = useState(props.showAP);
    const [showPD,setShowPD] = useState(props.showPD);
    const [showCB,setShowCB] = useState(props.showCB);
    return (

<aside id="rsidebar" className="sidebar rsbar mt-5 pt-4 slim-scrollbar mh-100 aos-init aos-animate" data-aos="slide-left">
<AllPavilionsBox showMe={showAP}/>
<ProgramDetailsBox showMe={showPD}/>
<CommunityBox showMe={showCB}/>
</aside>
   )
}

export default PavilionRightSidebar
