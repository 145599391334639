import React, { useState, useRef } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import Form from 'react-bootstrap/Form';
import http from '../../../../http';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

function PRFLHelpSupport({ handleClick }) {

  const formRef = useRef();

  const [progress, setProgress] = useState(false);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const fnameHandler = (event) => {
    setFname(event.target.value)
  }
  const lnameHandler = (event) => {
    setLname(event.target.value)
  }
  const emailHandler = (event) => {
    setEmail(event.target.value)
  }
  const phoneHandler = (event) => {
    setPhone(event.target.value)
  }
  const messageHandler = (event) => {
    setMessage(event.target.value)
  }
  function contactSubmit() {
    setProgress(true);
    http().post('/contactuspublic', { fname: fname, lname: lname, email: email, phone: phone, message: message})
        .then((res) => {
          Swal.fire({
            title: 'Contact us request sent.',
            icon: 'success',
        });
        formRef.current.reset();
        setProgress(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            Swal.fire({
              title: 'An error occurred. Please try again.',
              icon: 'error',
          });
        });
  }
  const submitHandler = (event) => {
    event.preventDefault()
    contactSubmit()
  }
    return (
        <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <Typography variant="small">Help & Support</Typography>
                <IconButton className='btn btn-outline-mode' onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75" >
                <div className="container slim-scrollbar mh-70  py-0 ">

                    <Container fluid className="small mb-2 px-2">
                    <Form ref={formRef} onSubmit={submitHandler}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Text className="text-muted">
          <h4>Contact Us</h4>
        </Form.Text>
      <Form.Text className="h6">
          Fill up the form and our team will get back to you within 24 hours.
        </Form.Text>
        <Form.Control size="sm" type="text" placeholder="First Name" className="my-2 py-1" onChange={fnameHandler}/>
        <Form.Control size="sm" type="text" placeholder="Last Name" className="mb-2 py-1" onChange={lnameHandler}/>
        <Form.Control size="sm" type="email" placeholder="Email" className="mb-2 py-1" onChange={emailHandler}/>
        <Form.Control size="sm" type="tel" placeholder="Phone Number" className="mb-2 py-1" onChange={phoneHandler} />
        <Form.Control as="textarea" placeholder="Message" rows={3} className='py-1' onChange={messageHandler}/>
      </Form.Group>

      
      <Button variant="primary" size="sm" className="float-end" type="submit">
         {progress && ( <Spinner size='sm' className="me-2" animation="border" /> )} Submit
      </Button>
    </Form>
                    </Container>
                    
                  
                </div>
            </ListGroup.Item>
        </ListGroup>
    )
}

export default PRFLHelpSupport
