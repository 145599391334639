import { AccountBalanceOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import HandshakeIcon from '@mui/icons-material/Handshake';
import { Grid } from '@mui/material';
import ListGroup from 'react-bootstrap/ListGroup';
function SponsorsBox(props) {

    const [show, setShow] = useState(props.showMe);

    return (
        <>
        { show && (<Container fluid className="px-0">
        <ListGroup className="mb-2 px-0 mx-0 g-0 overflow-hidden">
          <ListGroup.Item className="active text-center bg-linear-gradient-primary ">
          <Grid container className=" mb-0" direction="row" alignItems="center" justifyContent="center">
                            <HandshakeIcon />&nbsp; <strong>Sponsors</strong>
                        </Grid>
          </ListGroup.Item>
          <ListGroup.Item className="p-2 g-2 mx-0 text-center bg-linear-gradient-primary ">
          <div className="grid pt-1">
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/52731688560806.png"} alt="Ami" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/79231595253477.png"} alt="16" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/45231647358448.png"} alt="Brunel" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/47751670234743.png"} alt="cms" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/13011641196339.png"} alt="5" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/22001651146861.png"} alt="EY" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/43531669990843.png"} alt="Innovate Uk" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/27131595253801.png"} alt="12" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/42561595253824.png"} alt="14" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/42191691914455.png"} alt="Duolingo" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/81891691914470.png"} alt="HP" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/49631695203916.png"} alt="Cognizant" /></div>
                        <div><img src={localStorage.getItem('bucketName')+"/images/sponsor/47011695203952.png"} alt="BT Group" /></div>
                    </div>
          </ListGroup.Item>
        </ListGroup>
        
        </Container>
        )}
        </>
    )
}

export default SponsorsBox
