import React, { useState } from 'react'
import { DropdownButton, Dropdown ,ButtonGroup, ListGroup, Image, Carousel, Badge, Button, Container, Collapse } from 'react-bootstrap'

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CommunityEngagementBox from '../../../components/pavilion/Boxes/CommunityEngagementBox';
import BadgeAvatar from '../../../components/BadgeAvatar';

function MyWallTab(props) {
  const [open, setOpen] = useState(false);
  return (
    <Container fluid className="px-0 ">

      <div className="container mt-2">
        <div className="row d-flex flex-column flex-sm-row">
          <div className="col-12 col-sm-8 ps-0 pe-0 pe-sm-3">


            <ListGroup className="mb-2 ">
              <ListGroup.Item className="py-2 d-flex align-items-center border-bottom-0" >
                <BadgeAvatar alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} size="40" />
                &nbsp;&nbsp;<strong>APPG AI Secretariat</strong>
              </ListGroup.Item>
              <ListGroup.Item >
                <Container>
                  <h5>AI & THE UNITED NATIONS SUSTAINABLE DEVELOPMENT GOALS!</h5>
                  <p>Join industry experts at the upcoming APPG AI Evidence Session on Monday 23rd October 2023 in the House of Lords. Register for this exciting event on the APPG AI events page: https://bit.ly/45oPh6U</p>
                  <figure ><img src={localStorage.getItem('bucketName')+"/posts_attachments/581/65381697207074.png"} alt="" /></figure>
                </Container>
                <ButtonGroup aria-label="Basic example" className="w-100">
                  <Button variant="outline-secondary" className="d-flex justify-content-between"><span><ThumbUpOutlinedIcon /> Like</span> <Badge bg="success">9</Badge></Button>
                  <Button variant="outline-secondary" className="d-flex justify-content-between" onClick={() => setOpen(!open)} aria-controls="collapse-comments"
                    aria-expanded={open}><span><ChatOutlinedIcon /> Comments</span> <Badge bg="danger">9</Badge></Button>
                  {/* <Button variant="outline-secondary" className="d-flex justify-content-between"></Button> */}
                  <Dropdown as={Button} variant="outline-secondary" className="p-0 m-0">
                    <Dropdown.Toggle id="dropdown-basic" variant="" className="w-100 d-flex justify-content-between align-items-center">
                      <span><ShareRoundedIcon /> Share</span> <Badge bg="info">9</Badge>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1"><FacebookIcon /> Facebook</Dropdown.Item>
                      <Dropdown.Item href="#/action-2"><TwitterIcon /> Twitter</Dropdown.Item>
                      <Dropdown.Item href="#/action-3"><LinkedInIcon /> LinkedIn</Dropdown.Item>
                      <Dropdown.Item href="#/action-3"><ContentPasteIcon /> Copy to Clipboard</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ButtonGroup>
                <Collapse in={open}>
                  <ListGroup><ListGroup.Item>
                    Comments Listed Here
                    </ListGroup.Item>
                  </ListGroup>
                </Collapse>
              </ListGroup.Item>
            </ListGroup>

            <button type="button" className="load-more-btn">Load More</button>
          </div>
          <div className="col-12 col-sm-4 px-0">
            <CommunityEngagementBox/>


          </div>
        </div>
      </div>

    </Container>
  )
}

export default MyWallTab
