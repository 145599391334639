import React, {useState} from 'react'
import { Container, Image } from 'react-bootstrap'
import logo from '../assets/icons/biclogoicon.svg';


export default function PreLoader(props) {

  const [size,setSize]=useState(props.size);
   
      return (
        <div className={props.position ? 'position-relative top-50 start-50' : 'position-relative top-0 start-0'}>
      <Image className='rotate bg-light rounded-5' src={logo} style={{width:size, height:size}}/>
      </div>
      
        );
     }