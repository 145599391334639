import React, { useState } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import PeopleIcon from '@mui/icons-material/People'
import Group from '@mui/icons-material/Group'
import BadgeAvatar from '../../BadgeAvatar'

function CommunityBox(props) {
    const [show, setShow] = useState(props.showMe);

    return (
        <>
            {show && (
                <Container fluid className="px-1">
                    {/* <div className="text-center text-bg-primary pt-2 pb-1">
                <h5 className="mb-0">
                    <PeopleIcon className="pe-1" /> Community</h5>
            </div> */}
                    <Accordion as={ListGroup} defaultActiveKey="0">
                        <Accordion.Item as={ListGroup.Item} active className="px-2 py-1 fw-bold text-center">
                            <PeopleIcon className="pe-1" />Community
                        </Accordion.Item>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="py-0">Co Chairs</Accordion.Header>
                            <Accordion.Body className="p-0" >
                                <ListGroup className="list-group-light">
                                    <ListGroup.Item
                                        className="list-group-item-action d-flex justify-content-between align-items-center ps-2 rounded-0">
                                        <a href="#" className="d-flex align-items-center avatar-50 mt-1">
                                            {/* <img src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} className="rounded-circle" alt="" /> */}
                                            <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} name="Stephen Metcalfe" size='50' />

                                            <div className="ms-3 miw-100">
                                                <h6 className="mb-0">Kate Hunington</h6>
                                                <small className="text-muted mb-0">4 months</small>
                                            </div>
                                        </a>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        className="list-group-item-action d-flex justify-content-between align-items-center ps-2 rounded-0">
                                        <a href="#" className="d-flex align-items-center mt-1">
                                            <BadgeAvatar name="Kate Hunington" size='50' isLoggedIn={false} />
                                            <div className="ms-3 miw-100">
                                                <h6 className="mb-0">Kate Hunington</h6>
                                                <small className="text-muted mb-0">4 months</small>
                                            </div>
                                        </a>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        className="list-group-item-action d-flex justify-content-between align-items-center ps-2 rounded-0">
                                        <a href="#" className="d-flex align-items-center avatar-50 mt-1">
                                            {/* <img src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} className="rounded-circle" alt="" /> */}
                                            <BadgeAvatar src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} name="Kate Hunington" size='50' />

                                            <div className="ms-3 miw-100">
                                                <h6 className="mb-0">Kate Hunington</h6>
                                                <small className="text-muted mb-0">4 months</small>
                                            </div>
                                        </a>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Vice Chairs</Accordion.Header>
                            <Accordion.Body className="p-0">
                                <ListGroup className="list-group-light">
                                    <ListGroup.Item
                                        className="list-group-item-action d-flex justify-content-between align-items-center ps-2 rounded-0">
                                        <a href="#" className="d-flex align-items-center avatar-50 mt-1">
                                            {/* <img src={localStorage.getItem('bucketName')+"/images/profile_picture/74381698049059.png"} className="rounded-circle" alt="" /> */}
                                            <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName')+"/images/profile_picture/74381698049059.png"} name="Stephen Metcalfe" size='50' />

                                            <div className="ms-3 miw-100">
                                                <h6 className="mb-0">Kate Hunington</h6>
                                                <small className="text-muted mb-0">4 months</small>
                                            </div>
                                        </a>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup className="list-group-light">
                                    <ListGroup.Item
                                        className="list-group-item-action d-flex justify-content-between align-items-center ps-2 rounded-0">
                                        <a href="#" className="d-flex align-items-center avatar-50 mt-1">
                                            {/* <img src={localStorage.getItem('bucketName')+"/images/profile_picture/74381698049059.png"} className="rounded-circle" alt="" /> */}
                                            <BadgeAvatar src={localStorage.getItem('bucketName')+"/images/profile_picture/74381698049059.png"} name="Stephen Metcalfe" size='50' />

                                            <div className="ms-3 miw-100">
                                                <h6 className="mb-0">Kate Hunington</h6>
                                                <small className="text-muted mb-0">4 months</small>
                                            </div>
                                        </a>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Advisory Board</Accordion.Header>
                            <Accordion.Body className="p-0">
                                <ListGroup className="list-group-light">
                                    <ListGroup.Item
                                        className="list-group-item-action d-flex justify-content-between align-items-center ps-2 rounded-0">
                                        <a href="#" className="d-flex align-items-center avatar-50 mt-1">
                                            {/* <img src={localStorage.getItem('bucketName')+"/images/profile_picture/74381698049059.png"} className="rounded-circle" alt="" /> */}
                                            <BadgeAvatar src={localStorage.getItem('bucketName')+"/images/profile_picture/74381698049059.png"} name="Stephen Metcalfe" size='50' />

                                            <div className="ms-3 miw-100">
                                                <h6 className="mb-0">Kate Hunington</h6>
                                                <small className="text-muted mb-0">4 months</small>
                                            </div>
                                        </a>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>

            )}
        </>
    )
}

export default CommunityBox
