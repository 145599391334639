import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import RoleCard from "../components/AdminCards/RoleCard";
import MembershipCard from "../components/AdminCards/MembershipCard";
import AddMembership from "../components/AdminForms/AddMembership";
import Swal from "sweetalert2";
import TicketCard from "../components/AdminCards/TicketCard";
import AddTicket from "../components/AdminForms/AddTicket";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import MenuCard from "../components/AdminCards/MenuCard";
import AdminUserRoleCard from "../components/AdminCards/AdminUserRoleCard";
import AddAdminRole from "../components/AdminForms/AddAdminRole";
import { ExportToExcel } from "../components/ExportToExcel";
import Spinner from "react-bootstrap/Spinner";
import { getPermissions } from "../utils/utils";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

// function handleAction(row) {
//   alert(row.id);
// }

const AdminUserRoles = (props) => {
  const [permissions, setPermissions] = useState({});

  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `Admin Users Roles List ${formatDate(today)}`; // here enter filename for your excel file
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");

  const [roles, setRoles] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("0");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editAdminRole, setEditAdminRole] = useState(false);
  const [adminRoleID, setAdminRoleID] = useState();
  const [filteredRoles, setFilteredRoles] = useState([]); // Filtered roles data
  const [searchTerm, setSearchTerm] = useState(""); // Define searchTerm state

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Admin User Roles
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }


  // const handleSearch = (e) => {
  //   setValue(e.target.value);
  //   setLoading(true)
  //   adminhttp()
  //     .get(
  //       `/admin/search_membership?search=${e.target.value}&pavilion_id=${splitURL[3]}`
  //     )
  //     .then((data) => {
  //       // alert(data.data.data.length)
  //       if (data.data.data.length > 0) {
  //         setRoles(data.data.data);
  //         setLoading(false)
  //       }else{
  //         setNoData(true)
  //         setLoading(false)
  //       }
  //     });
  // };


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const form = e.currentTarget;
  //   const name = form.name.value;
  //   const price = form.price.value;
  //   const productType = form.producttype.value;
  //   const description = form.description.value;
  //   const status = form.status.value;
  //   const membershipplan = form.membershipplan.value;
  //   const image = form.imagefilen.value;
  //   if (form.checkValidity()) {
  //     adminhttp()
  //       .post("/admin/add_membership", {
  //         name: name,
  //         pavilion_id: splitURL[3],
  //         price: price,
  //         product_type: productType,
  //         description: description,
  //         status: status,
  //         membership_plan: membershipplan,
  //         image: image,
  //       })
  //       .then((res) => {
  //         Swal.fire({
  //           title: "Membership added successfully!",
  //           icon: "success",
  //           allowOutsideClick: false,
  //           confirmButtonColor: "#e30613",
  //         }).then((result) => {
  //           if (result["isConfirmed"]) {
  //             handleClose();
  //             handleRequest();
  //           }
  //         });
  //       });
  //   }
  // };

  function handleAddAdminRole() {
    setShowModal(true)
    setEditAdminRole(false)
  }

  const handleEdit = (id) => (e) => {
    setAdminRoleID(id)
    setEditAdminRole(true)
    setShowModal(true)
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue); // Update searchTerm state

    if (searchValue === "") {
      setFilteredRoles(roles); // Reset to all roles if search is empty
    } else {
      const filtered = roles.filter((role) =>
        role.name.toLowerCase().includes(searchValue)
      );
      setFilteredRoles(filtered);
    }
  };

  const handleRequest = () => {
    adminhttp()
      .get(`/admin/admin_user_roles`)
      .then((data) => {
        if (data.data.data.length > 0) {
          setLoading(true)
          setRoles(data.data.data);
          setFilteredRoles(data.data.data);
          setLoading(false)
        } else {
          setNoData(true)
          setFilteredRoles([]);
          setLoading(false)
        }
      });
  };

  const showAlert = () => {
    if (Object.values(permissions).length > 0 && !permissions['index-role']) {
      Swal.fire({
        title: "Permission Denied",
        text: "You don't have permission to see this page, please contact Administrator",
        icon: "warning",
        confirmButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/admin/dashboard');
        }
      })
    }
  }

  useEffect(() => {
    handleRequest();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      adminhttp().get(`/admin/admin_user_roles`).then((data) => {
        // reshaping the array
        const customHeadings = data.data.data.map(role => ({
          "Role Id": role.id,
          "Role Name": role.name,
          "status": role.status,
        }))
        setExportData(customHeadings)
      })
    }
    fetchData()
  }, [])

  useEffect(() => {
    getPermissions().then((res) => (setPermissions(res)))
  }, []);
  useEffect(() => {
    const filtered = roles.filter((role) =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRoles(filtered);
  }, [searchTerm, roles]);


  return (
    <>
      <AdminLayout>
        {loading && <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' height='100%'>
          <Spinner size='lg' className='me-2 ' animation='border' />
        </Box>}
        {(Object.values(permissions).length > 0 && permissions['index-role']) ? <Container fluid className="bg-light">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box
            component="section"
            className="d-flex align-items-center justify-content-between header-menu"
            sx={{ px: 2, pt: 0, pb: 1 }}
          >
            <h4>Admin User Roles</h4>
            <div className="d-flex justify-content-end align-items-center">
              <Form className="me-2">
                <Form.Control
                  type="search"
                  size="sm"
                  value={searchTerm} // Bind to searchTerm
                  onChange={handleSearch} // Update searchTerm
                  placeholder="Search Roles"
                />
              </Form>

              {permissions['create-role'] && (
                <Button variant="primary" type="button" size='sm' className='w-auto me-2' onClick={handleAddAdminRole}>
                  Add Role
                </Button>
              )}
              {permissions['export-role'] && (
                <ExportToExcel apiData={exportData} fileName={fileName} />
              )}
              {/* <Box sx={{ alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
           </Box> */}
            </div>
          </Box>

          <ListGroup variant="flush">
            <ListGroup.Item className="bg-transparent">
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 0,
                    p: 2,
                    width: '100%',
                    height: 'auto',
                  },
                }}
              >

                <Paper elevation={3} className='bg-secondary py-3 mb-3 rounded-4'>

                  <Grid container spacing={2} >
                    <Grid item xs={6} md={5} >
                      <Typography variant="body" component="p" className='mt-1 text-light' >
                        Role Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} >
                      <Typography variant="body" component="p" className='mt-1 text-light' >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={2} className='d-flex align-items-center'>
                      <Typography variant="body" component="p" className='text-light'>
                        Position
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={2} className='d-flex align-items-center justify-content-end' >
                      <Typography variant="body" component="p" className='text-light'>
                        Actions
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>

              {loading ? (
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Spinner size='lg' className='me-2 ' animation='border' />
                </Box>
              ) : (


                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 1, md: 12 }}
                  >
                    {filteredRoles.length > 0 && (
                      filteredRoles.map((role, index) => (
                        <Grid item xs={12} md={12} key={role.id}>
                          <AdminUserRoleCard
                            data={role}
                            handleRequest={handleRequest}
                            handleEdit={handleEdit}
                          />
                        </Grid>
                      )))}

                  </Grid>
                </Box>
              )}
              {noData && (
                <Box
                  sx={{ flexGrow: 1 }}
                  className="d-flex justify-content-center"
                >
                  <Alert variant="outlined" severity="error">
                    Admin User Roles not found
                  </Alert>
                </Box>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Container> : (showAlert())}
      </AdminLayout>
      <AddAdminRole handleRequest={handleRequest} show={showModal} close={() => setShowModal(false)} edit={editAdminRole} adminRoleID={adminRoleID} />
      {/* <Modal centered backdrop="static" show={show} onHide={handleClose} >
    <Modal.Header className='py-1 my-1' closeButton>
      <Modal.Title >Add Membership</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <AddMembership handleSubmit={handleSubmit} />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary"  onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" type="submit" form="membershipForm">
        Save Membership
      </Button>
    </Modal.Footer>
    </Modal> */}
    </>
  );
};

export default AdminUserRoles;
