import React, { useState } from 'react'
import { Button, Container, Collapse } from 'react-bootstrap'
import { Image, Carousel, Badge } from 'react-bootstrap'
import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ListGroup } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { Avatar } from '@mui/material';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CommunityEngagementBox from '../../../components/pavilion/Boxes/CommunityEngagementBox';
import BadgeAvatar from '../../../components/BadgeAvatar';
import CommentsBox from '../../../components/CommentsBox';

function WallPostTab(props) {
  const [open, setOpen] = useState(false);
  return (

    <Container fluid className="px-0 ">

      <div className="container mt-2">
        <div className="row d-flex flex-column flex-sm-row">
          <div className="col-12 col-sm-8 ps-0 pe-0 pe-sm-3">


            <ListGroup className=" ">
              <ListGroup.Item active className="py-2 fw-bold" >
                Write Post
              </ListGroup.Item>
              <ListGroup.Item className="text-end">
                <CKEditor
                  editor={ClassicEditor}
                  
                  data="<p>Hello from CKEditor&nbsp;5!</p>"
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
                <Button className="btn btn-primary mt-2">Post</Button>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="my-2 ">
              <ListGroup.Item className="py-2 d-flex align-items-center border-bottom-0" >
                <BadgeAvatar size="40" alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} />
                <a className="btn btn-bw fw-bold" href="">APPG AI Secretariat</a>
              </ListGroup.Item>
              <ListGroup.Item >
               
                <Container>
                  <h5>AI & THE UNITED NATIONS SUSTAINABLE DEVELOPMENT GOALS!</h5>
                  <p>Join industry experts at the upcoming APPG AI Evidence Session on Monday 23rd October 2023 in the House of Lords. Register for this exciting event on the APPG AI events page: https://bit.ly/45oPh6U</p>
                  <figure ><img src={localStorage.getItem('bucketName')+"/posts_attachments/581/65381697207074.png"} alt="" /></figure>
                </Container>
                <ButtonGroup aria-label="Basic example" className="w-100">
                  <Button variant="outline-secondary" className="d-flex justify-content-between"><span><ThumbUpOutlinedIcon fontSize="sm"/> Like</span> <Badge bg="success">9</Badge></Button>
                  <Button variant="outline-secondary" className="d-flex justify-content-between" onClick={() => setOpen(!open)} aria-controls="collapse-comments"
                    aria-expanded={open}><span><ChatOutlinedIcon fontSize="sm"/> Comments</span> <Badge bg="danger">9</Badge></Button>
                  {/* <Button variant="outline-secondary" className="d-flex justify-content-between"></Button> */}
                  <Dropdown as={Button} variant="outline-secondary" className="p-0 m-0">
                    <Dropdown.Toggle id="dropdown-basic" variant="" className="w-100 d-flex justify-content-between align-items-center">
                      <span><ShareRoundedIcon fontSize="sm"/> Share</span> <Badge bg="info">9</Badge>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1"><FacebookIcon /> Facebook</Dropdown.Item>
                      <Dropdown.Item href="#/action-2"><TwitterIcon /> Twitter</Dropdown.Item>
                      <Dropdown.Item href="#/action-3"><LinkedInIcon /> LinkedIn</Dropdown.Item>
                      <Dropdown.Item href="#/action-3"><ContentPasteIcon /> Copy to Clipboard</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ButtonGroup>
                <Collapse in={open}>
                  <ListGroup><ListGroup.Item>
                    <CommentsBox/>
                    </ListGroup.Item>
                  </ListGroup>
                </Collapse>
              </ListGroup.Item>
            </ListGroup>

            <button type="button" className="load-more-btn">Load More</button>
          </div>
          <div className="col-12 col-sm-4 px-0">
            <CommunityEngagementBox/>
          </div>
        </div>
      </div>

    </Container>


  )
}

export default WallPostTab
