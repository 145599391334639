import React, { useState } from 'react'
import PavilionLayout from '../../components/layouts/PavilionLayout'
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import Banner from '../../assets/img/74861673933396.png'
import Group from '@mui/icons-material/Group';
import PavilionNavBar from '../../components/pavilion/PavilionNavBar';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Col, Container, Stack } from 'react-bootstrap'
import PavilionHeader from '../../components/pavilion/PavilionHeader';
import PavilionLeftSidebar from '../../components/pavilion/PavilionLeftSidebar';
import PavilionRightSidebar from '../../components/pavilion/PavilionRightSidebar';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import InfoIcon from '@mui/icons-material/Info';
import { Grid } from '@mui/material';
import { ListGroup } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import EmojiNatureOutlinedIcon from '@mui/icons-material/EmojiNatureOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CommunityEngagementBox from '../../components/pavilion/Boxes/CommunityEngagementBox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar } from '@mui/material';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import BadgeAvatar from '../../components/BadgeAvatar';
import ChatButton from '../../components/ChatButton';

function SharePavilion(props) {
    const [showLS, setShowLS] = useState(true);

    function handleToggle1(evt) {
        if (showLS) { setShowLS(false) }
        else { setShowLS(true) }
    }

    return (
        <>
            <PavilionHeader showLSB={showLS} isLoggedIn={false} handleToggle={handleToggle1} />
            {showLS && (<PavilionLeftSidebar showLSB={showLS} isLoggedIn={false} isPavilion={true} showUB={true} showIM={true} />)}
            <main id="main" style={{ marginLeft: showLS ? '240px' : '0' }} className="bg-header main px-0 pt-0" data-aos="fade-up">
                {/* <div style={{minHeight: '50px'}}>&nbsp;</div> */}
                <Container fluid>
                    <div className="bg-header sticky-top" >
                        <div className="w-100 bg-header" style={{ minHeight: '65px' }}>&nbsp;</div>
                        <Card className="bg-dark text-white mb-2">
                            <Card.Img src={Banner} alt="Card image" />
                            <Card.ImgOverlay className="d-flex flex-column justify-content-end px-3 py-0">
                                <Card.Title className="h4 mb-0 mt-5 mt-sm-0 fs-2vw text-light">Charles Smith</Card.Title>
                                <div className="d-flex justify-content-between text-light">
                                    <Card.Text>
                                        <Group />&nbsp;at UK Parliament
                                    </Card.Text>
                                    <Card.Text>
                                        <Stack gap={1} direction="horizontal" >
                                            <Button variant="primary" className="p-1">Update Profile</Button>
                                        </Stack>
                                    </Card.Text>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                    <ListGroup >
                        <ListGroup.Item className="shadow-sm">
                            <Stack gap={1} direction="horizontal" className="d-flex justify-content-between" >
                                <Button variant="btn-link" className=""><PrecisionManufacturingOutlinedIcon />&nbsp;Area of Interest</Button>
                                <Button variant="btn-link" className=""><BusinessCenterOutlinedIcon />&nbsp;Industries & Sectors</Button>
                                <Button variant="btn-link" className=""><EmojiNatureOutlinedIcon />&nbsp;Ecosystem</Button>
                                <Button variant="btn-link" className=""><LocationOnOutlinedIcon />&nbsp;Location</Button>
                            </Stack>
                        </ListGroup.Item>
                        <ListGroup.Item className="my-2 shadow-sm">
                            <h5><InfoOutlinedIcon />&nbsp;About</h5>
                            <hr className="my-2" />
                            Software Engineer
                        </ListGroup.Item>
                        <ListGroup.Item >
                            Recent Posts/Activity from My Pavilions
                        </ListGroup.Item>
                        <ListGroup.Item >
                            <div className="d-flex align-items-center my-2">
                                <BadgeAvatar size="40" alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} />
                                <a href="javascript:void(0);"><div className="ms-3 miw-100">
                                    <h6 className="mb-0">APPG AI Secretariat</h6>
                                </div></a>
                            </div>          
                        
                        <h5 className="fw-bold">GENERATIVE AI AS A CATALYST FOR CHANGE IN EDUCATION</h5>
                        <p>Join industry experts at the forthcoming APPG AI Evidence Session on Monday 11th September 2023 in the House of Lords. Register for this exciting event on the APPG AI events page: https://bit.ly/3LpRdFl</p>
                        <p>- How is AI being used in schools and other educational settings? Have generative AI tools changed the way education and training takes place?  Consider these questions and many more with our expert panellists  (see picture)</p>
                        <Image className="w-100" src={localStorage.getItem('bucketName')+"/posts_attachments/579/82301693473871.png"}/>
                        <div className="d-flex align-items-center my-2">
                            <QuestionAnswerOutlinedIcon/>&nbsp;0&nbsp;<ThumbUpAltOutlinedIcon/>&nbsp;2
                        </div>
                        </ListGroup.Item>

                        
                    </ListGroup>
                </Container>
            </main>
            <ChatButton/>
            <PavilionRightSidebar showAP={true} />
        </>
    )
}

export default SharePavilion
