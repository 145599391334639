import React, { useState } from 'react'
import { InputGroup, Form, Button, Container, Collapse } from 'react-bootstrap'
import { Image, Carousel, Badge } from 'react-bootstrap'
import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ListGroup } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { Avatar } from '@mui/material';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CommunityEngagementBox from '../../../components/pavilion/Boxes/CommunityEngagementBox';
import BadgeAvatar from '../../../components/BadgeAvatar';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function PollsTab() {
  const [open, setOpen] = useState(false);

  return (
    <Container fluid className="px-0 ">

      <div className="container mt-2">
        <div className="row d-flex flex-column flex-sm-row">
          <div className="col-12 col-sm-8 ps-0 pe-0 pe-sm-3">
            <ListGroup className="shadow-sm">
              <ListGroup.Item className=" d-flex justify-content-between" >
                <InputGroup className="w-50">
                  <Form.Select >
                    <option value="">All Surveys</option>
                    <option value="48">Current Surveys</option>
                    <option value="49">Upcoming Surveys</option>
                    <option value="50">Past Surveys</option>
                  </Form.Select>
                  <Button ><SearchIcon size="sm" /></Button>
                </InputGroup>
                <Button 
                      variant="outline-primary" 
                      size="sm"  
                      onClick={() => setOpen(!open)} 
                      aria-controls="example-fade-text"
                      aria-expanded={open}
                      >Create Survey</Button>
              </ListGroup.Item>
              <Collapse in={open}>
                <ListGroup>
                  <ListGroup.Item id="example-fade-text" className="d-flex justify-content-between align-items-center">
                  <Form.Control type="text" id="Name"></Form.Control>
            
               
                  <Button size="sm"><SearchIcon/></Button>
                </ListGroup.Item>
                <ListGroup.Item>
                <Form.Select>
                    <option value="keyword">Search By Year</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="keyword">All Time</option>
                  </Form.Select>
                </ListGroup.Item>
                </ListGroup>
              </Collapse>
            </ListGroup>
            <ListGroup className="my-2 ">
              <ListGroup.Item className="py-2 d-flex justify-content-between align-items-center border-bottom-0" >
                <div className="d-flex align-items-center">
                  <BadgeAvatar size="40" alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} />
                  &nbsp;<strong>APPG AI Secretariat</strong>
                </div>
                <Dropdown className="vdots">
                  <Dropdown.Toggle className="p-0" variant="outline-link" id="dropdown-basic">
                    <MoreVertIcon size="sm" />      </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </ListGroup.Item>

              <ListGroup.Item >
                Date: 14-10-2023
              </ListGroup.Item>
              <ListGroup.Item >
                <h5 className="mb-3"> Q1. which color pen do you like? </h5>
                <Form.Check type="radio" name="Q1" aria-label="Blue" label="Pink" />
                <Form.Check type="radio" name="Q1" aria-label="Blue" label="Yellow" />
                <Form.Check type="radio" name="Q1" aria-label="Blue" label="Green" />
              </ListGroup.Item>
              <ListGroup.Item>
                <h5 className="mb-3"> Q2. Order your language preference?</h5>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="w-25" id="basic-addon2">English</InputGroup.Text>
                  <Form.Select className="w-75">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="w-25" id="basic-addon2">Telugu</InputGroup.Text>
                  <Form.Select className="w-75">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="w-25" id="basic-addon2">Arabic</InputGroup.Text>
                  <Form.Select className="w-75">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="w-25" id="basic-addon2">Urdu</InputGroup.Text>
                  <Form.Select className="w-75">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </InputGroup>
              </ListGroup.Item>
              <ListGroup.Item >
                <h5 className="mb-3"> Q3. which color pen do you like? </h5>
                <div className="d-flex justify-content-between">
                  <span>Green</span>
                  <span>Orange</span>
                  <span>Blue</span>
                </div>
                <Form.Range min='1' max="3"  />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <span>Survey ended in 24 days ago</span>
                <Button size="sm" >Survey Results</Button>
              </ListGroup.Item>

            </ListGroup>
            <Dropdown as={Button} variant="outline-secondary" className="p-0 m-0">
              <Dropdown.Toggle id="dropdown-basic" variant="" className="w-100 d-flex justify-content-between align-items-center">
                <span><ShareRoundedIcon fontSize="sm" /> Share</span> &nbsp;<Badge bg="info">9</Badge>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1"><TelegramIcon /> Telegram</Dropdown.Item>
                <Dropdown.Item href="#/action-1"><FacebookIcon /> Facebook</Dropdown.Item>
                <Dropdown.Item href="#/action-2"><TwitterIcon /> Twitter</Dropdown.Item>
                <Dropdown.Item href="#/action-3"><LinkedInIcon /> LinkedIn</Dropdown.Item>
                <Dropdown.Item href="#/action-3"><ContentPasteIcon /> Copy to Clipboard</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-12 col-sm-4 px-0">
            <CommunityEngagementBox />
          </div>
        </div>
      </div>

    </Container>

  );
}

export default PollsTab;