import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Image } from "react-bootstrap";
import Box from "@mui/material/Box";
import { useScaleImageOptions } from "react-advanced-cropper";
const Input = styled("input")({
  display: "none"
});

export default function Upload({ getUploadedFile }, props) {
  const [img, setImg]=useState(props.image);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    
    reader.onload = () => {
      getUploadedFile(reader.result);
      setImg(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  return (
    <label htmlFor="contained-button-file">
      <Input
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={onChange}
      />
       
       <Button variant="contained" size="sm" component="span">
        <img src={img}/>
      </Button> 
    </label>
  );
}
