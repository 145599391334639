
import Header from '../Header102'
import Footer from '../Footer2'

const Layout2 = ({ children }) => {
  return (
    <div className='bg-light'>
      <Header/>
      <main >{children}</main>
      <Footer/>
    </div>
  )
}

export default Layout2
