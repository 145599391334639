import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AccountBalanceOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Share from '@mui/icons-material/ReplyOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import http from '../../../../../http';

function PUBLPostDetails(props, { handleClick }) {
  const [eventDetail, setEventDetail] = useState([]);
  const [members, setMembers] = useState([]);
  const [interestedMembers, setInterestedMembers] = useState([]);
  const [countDays, setCountDays] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [createdAt, setCreatedAtDate] = useState(0);
  
  const [eventId, setEventId] = useState(props.rowIndex);

  const [publicPost, setPublicPost] = useState([]);

  const handleImageError = (e) => {
    e.target.src = localStorage.getItem('bucketName')+'/images/noimage.webp';
  };

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");

  useEffect(() => {
      if (splitURL[1] === 'walls') {
          if (splitURL[2] ) {
            http().get(`/walls/${splitURL[2]}`).then((data) => {
              setPublicPost([...publicPost, data.data.data.posts])
            });
          } 
      }
      
  }, []);


  
  
  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
           <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} ><Share className='flipx' /></IconButton> 
          <Typography variant="small">
            Wall Post Shared
          </Typography>
        </div>
        {/* <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton> */}
			{/*<IconButton sx={{ padding: 1 }} ><PostAddIcon /></IconButton>*/}

      </ListGroup.Item>
      
        <ListGroup.Item className="d-flex align-items-top justify-content-center px-2 h-100  border-0 bg-light bg-opacity-75">
            <div className='w-100'>
          <ListGroup className="overflow-hidden px-0 mb-2 " style={{ borderRadius: "5px" }}>
          {publicPost.map((innerArray, outerIndex) => (<>
            <ListGroup.Item key={outerIndex} variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-1 px-1'>
              <div className="w-100  d-flex justify-content-start align-items-center ">
                 <BadgeAvatar src={`${localStorage.getItem('bucketName')}/images/profile_picture/${innerArray.user_image}` } name={innerArray.user_name} size='20' isLoggedIn={false} />
                <div className='ms-2 text-start '>
                  <h6 className='mb-0'>{innerArray.user_name}</h6>
                  <small>2023-11-12</small>
                </div>
              </div>
              {/* <CRUDDropdown title={<MoreVertIcon />} handleDropdown={handleDropdown} editTitle="Edit Post" editClick={(event) => handleEdit(innerArray.id)} deleteTitle="Delete Post"  deleteClick={(event) => handleClickDelete(innerArray.id)} /> */}
              {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-center justify-content-center h-100  border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
              <div className="container px-0   py-1">
			             <p dangerouslySetInnerHTML={{ __html: innerArray.Data.replace(localStorage.getItem('bucketName')+'posts_attachments', localStorage.getItem('bucketName')+'/posts_attachments') }}></p>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="w-100 d-flex align-items-center justify-content-center p-0 border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                            <Button size="sm" variant="outline-secondary" className="small p-1 me-2"><Share className="flipx" /> 5</Button>
                            <Button size="sm" variant="outline-secondary" className="small p-1"><ThumbUpIcon  /> 15</Button>
            </ListGroup.Item>
            
            </>
            ))}
          </ListGroup>
</div>
        </ListGroup.Item>
      
    </ListGroup>
  )
}

export default PUBLPostDetails;
