import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Validation from '../RegisterValidation'
import axios from 'axios'
import ApartmentIcon from '@mui/icons-material/Apartment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Layout from '../components/layouts/Layout'
import ContactForm from '../components/ContactForm'


function Contact() {
  return (
    <Layout>
      
      <header id="myHeader" className="mb-0 mt-5 mt-sm-5 bg-light bg-home" >
        <div className="container col-xxl-8 px-0 pt-5 ">
          <div className="row flex-lg-row-reverse align-items-center g-5 pt-5">
            <div className="col-10 col-sm-8 col-lg-6">
              {/* <img src="bootstrap-themes.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>  */}
            </div>
            <div className="col-lg-6 p-0" data-aos="slide-right">
              <h3 className="fw-bold text-primary lh-1 mb-3">Contact Us</h3>
              <p className="lead">Fill up the form and our Team will get back to you within 24 hours</p>
              {/* <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
              <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>
            </div>  */}
              <div className="col-12 mx-0 col-sm-8 col-lg-12">
                <ContactForm/>
              </div>
            </div>
          </div>
        </div>
    </header >
   </Layout>
    )
}
export default Contact
