import React, { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import PieChart from '../../../Charts/PieChart';
import Modal from 'react-bootstrap/Modal';
import { BarChart } from '../../../Charts/BarChart';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MembersFilter from '../../Filters/MembersFilter';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AddchartIcon from '@mui/icons-material/Addchart';
import InputGroup from 'react-bootstrap/InputGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import http from '../../../../http';
import { Chart } from "react-google-charts";
import Swal from 'sweetalert2'


function PVLNPolls(props, { handleClick }) {
	const [show, setShow] = useState(false);
	const [showAdd, setShowAdd] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [surveryListData, setSurveryListData] = useState([]);
	const [startDateData, setStartDate] = useState('');
	const [endDateData, setEndDate] = useState('');
	const [surveyName, setSurveyName] = useState('');
	const [qType, setQType] = useState('multiple')
	const [label, setLabel] = useState('')
	const [multipleinput, setMultipleinput] = useState('')
	const [itemvalue, setItemvalue] = useState([])
	const [surveyQuestionName, setSurveyQuestionName] = useState('')
	const [addPoll, setAddPoll] = useState('0')
	const [pollId, setPollId] = useState('')
	const [searchName, setSearchName] = useState('')
	const [surveryEditData, setSurveryEditData] = useState([])
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleCloseAdd = () => setShowAdd(false);
	const [itemvalues, setItemvalues] = useState([]);
	const [pollResult, setPollResult] = useState([]);
	const [pollSubmitRecords, setPollSubmitRecords] = useState([]);
	const [pollTotalParticipants, setPollTotalParticipants] = useState(0);
	const [multipleSubmit, setMultipleSubmit] = useState('');
	const [domContent, setDomContent] = useState([]);
	const [domMatrixContent, setDomMatrixContent] = useState([]);

	const [permissions, setPermissions] = useState({});
	const [pieChatData, setpieChatData] = useState([
		["Opinion", "No of Persons"],
		["Strongly Disagree", 2],
		["Disagree", 2],
		["Agree", 2],
		["Strongly Agree", 2],
	]);
	const [pieChatoptions, setpieChatoptions] = useState({
		title: "Q1. Generative AI technologies pose a significant challenge to whether creativity should be protected by IPR"
	});


	const addSurveyPopUp = () => {
		setShowAdd(true);
		setPollId('');
		setAddPoll('0');
		setQType('multiple');
		setSurveyName('');
		setStartDate('');
		setEndDate('');
		setSurveyQuestionName('');
		if (qType == "multiple") {
			setLabel('Answer Title');
		}
	}
	const searchPoll = () => {
		http().get('/getSurveyNameQuestionNameOptions/' + props.idx + '?search=' + searchName).then((data) => {
			console.log(data);
			setShowPolls(0)
			setSurveryListData(data.data.data.survey);
			setPermissions(data.data.data.permission);

		});
	}

	let handleSaveSurvey = () => {
		//alert('ssss');
		http().get('/saveallquestions/' + pollId).then((data) => {
			http().get('/getSurveyNameQuestionNameOptions/' + props.idx).then((data) => {
				setShowAdd(false);
				setSurveryListData(data.data.data.survey);
				setPermissions(data.data.data.permission);
			});
		});
	};


	const handleClickEdit = (id) => {
		setShowEdit(true);
		http().get('/polledit/' + props.idx + '/' + id).then((data) => {
			setSurveryEditData(data.data.data.singlesurvey);
		});

		//alert(id);
	}


	const handleClickDelete = (id) => {
		Swal.fire({
			title: "Are you sure to delete this Survey?",
			text: "You won't be able to revert this!",
			icon: "warning",
			width: 'auto',
			showCancelButton: true,
			confirmButtonColor: "#e30613",
			cancelButtonColor: "#6c757d",
			confirmButtonText: "Yes, delete it!"
		}).then((result) => {
			if (result.isConfirmed) {
				http().get('/deletepoll/' + id).then((data) => {
					document.getElementById('survey_' + id).style.display = "none";
				}).then((data) => {
					Swal.fire({
						title: "Survey has been deleted succesfully",
						icon: "success",
						allowOutsideClick: false,
						confirmButtonColor: "#e30613",
					});
				});
			}
		});
		// http().get('/deletepoll/'+id).then((data) => {
		// 	alert('it is deleted sucssfully')
		// document.getElementById('survey_'+id).style.display="none";

		// });	   
	}


	let handleAddSurvey = async () => {
		try {
			//setShowAdd(false);
			// First AJAX call to get a value
			// const response1 = await fetchData(formValues);
			console.log('yes');
			setQType('multiple');
			setSurveyQuestionName('');
			if (qType == "multiple") {
				setLabel('Answer Title');
			}
			setFormValues([{ answerRow: "" }]);
			setFormValues2([{ answerCol: "" }]);
			http().post('/createpollweb/' + props.idx, {
				"create_poll_pavilion_id": props.idx,
				"survey_name": surveyName,
				"start_date": startDateData,
				"end_date": endDateData,
				"content": surveyQuestionName,
				"poll_option": qType,
				"poll_options": formValues,
				"txtMatColLabel": formValues2,
				"created_poll_id": pollId,
			}).then((data) => {
				setAddPoll('1');
				//console.log(data);
				//console.log(data.data.created_poll_id);
				document.getElementById('answerRow').value = '';
				document.getElementById('surveyQuestion').value = '';
				setQType('multiple');
				document.getElementById('questionType').value = 'multiple';
				setPollId(data.data.data.created_poll_id);
			});
		} catch (error) {
			// Handle errors
			console.error('Error:', error);
			//setResult('An error occurred');
		}
	};
	const handlePollSearch = (e) => {
		setSearchName(e.target.value);
	}
	const handlePollStartDate = (e) => {
		setStartDate(e.target.value);
	};
	const handlePollEndDate = (e) => {
		setEndDate(e.target.value);
	};
	const handlePollSurveyName = (e) => {
		setSurveyName(e.target.value);
	};
	const handlePollQuestion = (e) => {
		setSurveyQuestionName(e.target.value);
	};

	const submitPoll = (pollId) => {
		//alert(pollId);

		http().get('/polledit/' + props.idx + '/' + pollId).then((data) => {
			//setPollSubmitRecords(data.data.data.singlesurvey);
			for (let i = 0; i < data.data.data.singlesurvey.length; i++) {
				console.log('ssss');
				if (data.data.data.singlesurvey[i].poll_option_type == "multiple") {
					if (multipleSubmit) {
						//alert(multipleSubmit);
					}			//document.getElementById('row_multiple_'+data.data.data.singlesurvey[i].poll_options_id).value;					//console.log(document.getElementById('datrow_multiple_'+a.data.data.singlesurvey[i].poll_options_id).value);
					if (data.data.data.singlesurvey.length == i + 1) {
						//alert('Yes');
						http().post('/webaddpollsvote', {
							"set_poll_id": data.data.data.singlesurvey[i].poll_id,
							"pavilion_id": props.idx,
							"multiple_question": data.data.data.singlesurvey[i].poll_options_id,
							"multiple_row_response_answer": data.data.data.singlesurvey[i].poll_options_id + "@@@" + multipleSubmit,
						}).then((data) => {
							Swal.fire({
								title: "Poll submitted successfully!",
								icon: "success",
								allowOutsideClick: false,
								confirmButtonColor: "#e30613",
							});
						});
					}
				}
				//rank
				if (data.data.data.singlesurvey[i].poll_option_type == "ranking") {
					console.log(data.data.data.singlesurvey[i].id);
					console.log(data.data.data.singlesurvey[i].poll_options_id);
					if (multipleSubmit) {
						//alert(multipleSubmit);
					}			//document.getElementById('row_multiple_'+data.data.data.singlesurvey[i].poll_options_id).value;					//console.log(document.getElementById('datrow_multiple_'+a.data.data.singlesurvey[i].poll_options_id).value);
					//console.log('vvvv');
					//alert(data.data.data.singlesurvey[i].poll_options_multiple_idval);
					//alert(document.getElementById('1139').value);
					//alert(document.getElementById(data.data.data.singlesurvey[i].poll_options_multiple_idval).value);
					//alert(data.data.data.singlesurvey.length+'vvvv'+i);
					domContent.push(data.data.data.singlesurvey[i].poll_options_id + "___" + data.data.data.singlesurvey[i].poll_options_multiple_idval + "@@@" + document.getElementById(data.data.data.singlesurvey[i].poll_options_multiple_idval).value);
					if (data.data.data.singlesurvey.length == i + 1) {
						domContent.reverse();
						//document.getElementById(data.data.data.singlesurvey[i].poll_options_multiple_idval).value
						//alert('Yes');
						http().post('/webaddpollsvote', {
							"set_poll_id": data.data.data.singlesurvey[i].poll_id,
							"pavilion_id": props.idx,
							"ranking_question": data.data.data.singlesurvey[i].poll_options_id,
							"ranking_row_response_answer": domContent,
						}).then((data) => {
							Swal.fire({
								title: "Poll submitted successfully!",
								icon: "success",
								allowOutsideClick: false,
								confirmButtonColor: "#e30613",
							});
						});
					}
				}
				//matrix
				if (data.data.data.singlesurvey[i].poll_option_type == "matrix") {
					console.log(data.data.data.singlesurvey[i].id);
					console.log(data.data.data.singlesurvey[i].poll_options_id);
					if (multipleSubmit) {
						//alert(multipleSubmit);
					}			//document.getElementById('row_multiple_'+data.data.data.singlesurvey[i].poll_options_id).value;					//console.log(document.getElementById('datrow_multiple_'+a.data.data.singlesurvey[i].poll_options_id).value);
					console.log('vvvv');
					//alert(data.data.data.singlesurvey[i].poll_options_multiple_idval);
					//alert(document.getElementById('1139').value);
					//alert(document.getElementById(data.data.data.singlesurvey[i].poll_options_multiple_idval).value);
					//alert(data.data.data.singlesurvey.length+'vvvv'+i);
					domMatrixContent.push(data.data.data.singlesurvey[i].poll_options_id + "___" + data.data.data.singlesurvey[i].poll_options_multiple_idval + "@@@" + data.data.data.singlesurvey[i].poll_options_multiple_columns_idval);
					if (data.data.data.singlesurvey.length == i + 1) {
						domMatrixContent.reverse();
						//document.getElementById(data.data.data.singlesurvey[i].poll_options_multiple_idval).value
						//alert('Yes');
						http().post('/webaddpollsvote', {
							"set_poll_id": data.data.data.singlesurvey[i].poll_id,
							"pavilion_id": props.idx,
							"matrix_question": data.data.data.singlesurvey[i].poll_options_id,
							"matrix_row_response_answer": domMatrixContent,
						}).then((data) => {
							Swal.fire({
								title: "Poll submitted successfully!",
								icon: "success",
								allowOutsideClick: false,
								confirmButtonColor: "#e30613",
							});
						});
					}
				}


			}
		});
	}

	const handleSubmitEdit = () => {

		//alert(document.getElementById('row_multiple_1096').value);
		//alert(document.getElementById('row_multiple_1097').value);
		//alert(document.getElementById('row_multiple_1098').value);	
	}

	const handleShowAdd = () => setShowAdd(true);
	const handleCloseEdit = () => setShowEdit(false);
	const handleShowEdit = () => setShowEdit(true);
	const [showPolls, setShowPolls] = useState(0);
	const [showFilter, setShowFilter] = useState(false);
	const location = useLocation();
	useEffect(() => {
		http().get('/getSurveyNameQuestionNameOptions/' + props.idx).then((data) => {
			setSurveryListData(data.data.data.survey);
			setPermissions(data.data.data.permission);
		});
		const splitURL = location.pathname.toString().split("/");
		if (splitURL[1] === 'poll') {
			setShowPolls(1);
		}
		console.log(`The current route is ${splitURL[2]}`);
	}, [location]);

	useEffect(() => {
		if (qType == "multiple") { setLabel('Answer Title') }
		if (qType == "ranking") { setLabel('Rank Title') }
		if (qType == "scaling") { setLabel('Range Title') }
		if (qType == "matrix") { setLabel('Row Title') }
	}, [qType]);

	const [formValues, setFormValues] = useState([{ answerRow: "" }])
	const [formValues2, setFormValues2] = useState([{ answerCol: "" }])

	let handleChangeRow = (i, e) => {
		let newFormValues = [...formValues];
		newFormValues[i] = e.target.value;
		setFormValues(newFormValues);
		console.log(formValues)
	}
	let handleChangeCol = (i, e) => {
		let newFormValues2 = [...formValues2];
		newFormValues2[i] = e.target.value;
		setFormValues2(newFormValues2);
		console.log(formValues2)
	}

	let handleAddAnswerRow = () => {
		setFormValues([...formValues, { answerRow: "" }])
	}
	let handleAddAnswerCol = () => {
		setFormValues2([...formValues2, { answerCol: "" }])
	}

	let handleRemoveAnswerRow = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues)
	}
	let handleRemoveAnswerCol = (i) => {
		let newFormValues2 = [...formValues2];
		newFormValues2.splice(i, 1);
		setFormValues2(newFormValues2)
	}

	let handleEditSurvey = () => {

	}
	function handleDropdown(event) {
		event.stopPropagation();
		// console.log('handleChildClick');
	}




	const handlePollResultClick = (i) => {
		//alert(i);
		http().get('/webpollresult/' + props.idx + '/' + i).then((data) => {
			setShow(true);
			console.log('vvvv');
			console.log(data);
			console.log(data.data);
			console.log(data.data.data);
			console.log(data.data.data.result);
			setPollResult(data.data.data.result);
			setPollTotalParticipants(data.data.data.total_participants);



		});
	}

	let handleQuestionTypeChange = (e) => {
		setQType(e.target.value)
	}
	const [formData, setFormData] = useState({});

	const handleMultipleSubmitChange = (event) => {
		//console.log('ssss');
		//alert(event.target.value);
		//console.log(event.target.name);
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
		//setMultipleSubmit(event.target.value);
	}
	const handleSubmit = (event) => {
		event.preventDefault();
		// Send formData to your server or API
		console.log(event);
		console.log(event.target.name);
		//const { name, value } = event.target;
		//setFormData({ ...formData, ['survey_id']: name });
		// setFormData('survey_id', event.target.name);
		console.log("Form Data:", formData);
		http().post('/webaddpollsvote', {
			"set_poll_id": event.target.name,
			"pavilion_id": props.idx,
			formData,
		}).then((data) => {
			//alert(data);
			console.log(data)
			console.log(data.data)
			console.log(data.data.data)
			console.log(data.data.data.error)
			console.log(data.data.data.polls)
			if (data.data.data.error == 1) {
				alert(data.data.data.polls);
			} else {
				alert(data.data.data.polls);
				document.getElementById('submitSurvey_' + data.data.data.polls_id).style.display = "none";
			}
		});

	};

	return (
		<>
			<ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
				<ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
					<div>
						{showPolls === 0 && (<IconButton onClick={event => props.handleClick('PVLN-MENU', props.idx)} className='p-1 m-1 btn btn-outline-mode'><ArrowBackIcon /></IconButton>)}
						{showPolls === 1 && (<IconButton onClick={() => setShowPolls(0)} className='p-1 m-1 btn btn-outline-mode'><ArrowBackIcon fontSize="small" /></IconButton>)}
						{showPolls === 2 && (<IconButton onClick={() => setShowPolls(0)} className='p-1 m-1 btn btn-outline-mode'><ArrowBackIcon fontSize="small" /></IconButton>)}
						{/* <IconButton onClick={event => props.handleClick('PVLN-MENU',props.idx)}><ArrowBackIcon /></IconButton> */}
						<Typography variant="small">Polls & Survey</Typography>
					</div>
					<IconButton onClick={event => props.handleClick('PVLN-HOME', props.idx)} className='p-1 m-1 btn btn-outline-mode'><Home fontSize="small" /></IconButton>
					<div>
						<IconButton className='p-1 m-1 btn btn-outline-mode' onClick={() => setShowPolls(2)}><FilterAltIcon fontSize="small" /></IconButton>
						{permissions['create-polls'] && <IconButton className='p-1 m-1 btn btn-outline-mode' onClick={addSurveyPopUp}><AddchartIcon fontSize="small" /></IconButton>}
					</div>


				</ListGroup.Item>
				{/* <div> */}
					{showPolls === 0 && (
						<ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75 px-2">
							<div className="container slim-scrollbar mh-70  py-0">
								{surveryListData.map((innerArray, outerIndex) => (
									<form onSubmit={handleSubmit} name={"survey_" + innerArray.survey_id} id={"survey_" + innerArray.survey_id} >
										<ListGroup className="overflow-hidden px-0  mb-2 " style={{ borderRadius: "5px" }}>
											<input type="text" style={{ display: 'none' }} name={innerArray.survey_id} value={innerArray.survey_id} onChange={handleMultipleSubmitChange} />
											<ListGroup.Item variant='light' className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
												<Row className="w-100  d-flex justify-content-between align-items-center ">
													<Col md={2}>
														<BadgeAvatar src={localStorage.getItem('bucketName') + '/images/profile_picture/' + innerArray.user_image} name={innerArray.user_name} size='40' isLoggedIn={false} />
													</Col>
													<Col md={8} className=' text-start'>
														<h6 className='mb-0'>{innerArray.user_name}</h6>
													</Col>
													<Col md={2}>

														{/*<CRUDDropdown title={<MoreVertIcon fontSize="small" />} handleDropdown={handleDropdown} editTitle="Edit" 
										  editClick={(event) => handleClickEdit(innerArray.survey_id)} 
									   />*/}
														<CRUDDropdown title={<MoreVertIcon fontSize="small" />} handleDropdown={handleDropdown} deleteTitle="Delete" deleteClick={(event) => handleClickDelete(innerArray.survey_id)}
														/>
													</Col>
												</Row>
											</ListGroup.Item>
											<ListGroup.Item className="d-flex flex-column align-items-center justify-content-center h-100 border-0 p-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
												<ListGroup className="rounded-0 w-100">
													<ListGroup.Item className='d-flex align-items-center justify-content-between'>
														<h6>{innerArray.survey_name}</h6>
														{/* <CRUDDropdown title={<MoreVertIcon fontSize="small"/>} editTitle="Edit" editClick={()=> setShowEdit(true)}/> */}
													</ListGroup.Item>
													{innerArray.question.map((innerArrayQuestion, outerIndexQuestion) => (<ListGroup.Item className="">
														<h6>  Q{outerIndexQuestion + 1}. {innerArrayQuestion.question_name}  </h6>
														{innerArrayQuestion.type == "multiple" && (
															<div>
																<input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} onChange={handleMultipleSubmitChange} />

																{innerArrayQuestion.option.map((innerArrayQuestionOption, outerIndexQuestionOption) => (
																	<Form.Check className="px-0 mb-2">
																		<Form.Check.Label for="archived" >
																			<Form.Check.Input id="archived" className="me-2" name={"row_multiple_" + innerArrayQuestion.question_id + "[]"} type="radio" value={innerArrayQuestionOption.option_id} id="" onChange={handleMultipleSubmitChange} />
																			{innerArrayQuestionOption.multiple_value}
																		</Form.Check.Label>
																	</Form.Check>
																))}
															</div>
														)}
														{innerArrayQuestion.type == "scaling" && (
															<div>
																<input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} />
																<div className="d-flex justify-content-between small">
																	{innerArrayQuestion.option.map((innerArrayQuestionOption, outerIndexQuestionOption) => (
																		<span>{innerArrayQuestionOption.scaling_value}</span>
																	))}
																</div>
																<Form.Range min='0' name={"scaling_" + innerArrayQuestion.question_id} max={innerArrayQuestion.option.length - 1} variant="primary" onChange={handleMultipleSubmitChange} />
															</div>
														)}
														{innerArrayQuestion.type == "ranking" && (
															<div>
																<input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} onChange={handleMultipleSubmitChange} />
																{innerArrayQuestion.option.map((innerArrayQuestionOption, outerIndexQuestionOption) => (
																	<InputGroup className="mb-2 d-flex align-items-center" size="sm">
																		<Form.Select className='p-1' size="sm" required name={"row_ranking_" + innerArrayQuestionOption.option_id} id={innerArrayQuestionOption.option_id} onChange={handleMultipleSubmitChange}>
																			<option selected value='0'>0</option>
																			{innerArrayQuestion.option.map((innerArrayQuestionOptionDropdown, outerIndexQuestionOptionDropdown) => (
																				<option value={outerIndexQuestionOptionDropdown + 1}  >{outerIndexQuestionOptionDropdown + 1}</option>
																			))}
																		</Form.Select>
																		<InputGroup.Text className='w-75'>{innerArrayQuestionOption.ranking_value}</InputGroup.Text>
																	</InputGroup>
																))}
															</div>
														)}
														{innerArrayQuestion.type == "matrix" && (
															<Container>
																<Row>
																	<input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} />
																	<Col></Col>
																	{innerArrayQuestion.option.map((innerArrayQuestionRow, outerIndexQuestionRow) => (
																		<Col>
																			{innerArrayQuestionRow.columns.map((innerArrayQuestioncolumns, outerIndexQuestioncolumns) => (
																				<span>
																					{innerArrayQuestionRow.matrix_serial_no == innerArrayQuestioncolumns.matrix_columns_level ? (innerArrayQuestioncolumns.matrix_columns_title) : ('')}
																				</span>
																			))}
																		</Col>
																	))}
																</Row>
																{innerArrayQuestion.option.map((innerArrayQuestionColumnData, outerIndexQuestionColumn) => (
																	<Row><Col>{innerArrayQuestionColumnData.matrix_rows_title}</Col>
																		{innerArrayQuestionColumnData.columns.map((innerArrayQuestionColumn, outerIndexQuestionColumn) => (
																			<Col><Form.Check type="radio" name="row-group1" name={"row_matrix_" + innerArrayQuestion.question_id + '@' + innerArrayQuestionColumnData.option_id} value={innerArrayQuestionColumn.option_column_id} onChange={handleMultipleSubmitChange} /></Col>
																		))}
																	</Row>
																))}

															</Container>
														)}
													</ListGroup.Item>
													))}



												</ListGroup>
												<ListGroup className="w-100 rounded-0 ">
												{!innerArray.pollresultsumitted && (
												<ListGroup.Item className="d-flex justify-content-center align-items-center px-1">
												<small>{innerArray.title}</small>
												</ListGroup.Item>
												)}
													<ListGroup.Item className="d-flex justify-content-between align-items-center px-2">
														{/*<Button type='button' className='px-1 py-0' variant="primary" size="sm" 
										onClick={() => submitPoll(innerArray.survey_id)}>Submit Survey</Button>*/}
														{(innerArray.pollresultsumitted == false || innerArray.pollresultsumitted == '') ? <Button type="submit" size='sm' className='px-1 py-0' variant="primary" id={'submitSurvey_' + innerArray.survey_id} size="sm">Submit Survey</Button> : (<small>{innerArray.title}</small>)}
														<Button variant="primary" className='px-1 py-0' type='button' size="sm" onClick={() => handlePollResultClick(innerArray.survey_id)}>Survey Results</Button>
													</ListGroup.Item>
												</ListGroup>
											</ListGroup.Item>
										</ListGroup>
									</form>
								))}
							</div>
						</ListGroup.Item>)}

				{/* </div> */}
				{showPolls === 1 && (
					<ListGroup.Item className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
						PVLNPollDetails
					</ListGroup.Item>
				)}
				{showPolls === 2 && (
					<ListGroup.Item className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
						<Container>
							<Form className="mx-0 px-0">
								<Form.Select required size="sm" onChange={handlePollSearch} id="when" name="when" className="my-1 py-1">
									<option value="all" selected>All</option>
									<option value="current" >Current Surveys</option>
									<option value="future" >Upcoming Surveys</option>
									<option value="past" >Previous Surveys</option>
								</Form.Select>
								<Button size="sm" type="button" onClick={searchPoll}>Search</Button>
							</Form>
						</Container>
					</ListGroup.Item>
				)}

			</ListGroup>
			<Modal centered show={show} backdrop="static" keyboard={false} onHide={handleClose}>
				<Modal.Header className='my-1 py-1' closeButton>
					<Modal.Title className='lead'>Survey Results</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-0 m-0 d-flex align-items-start' style={{ overflowY: "scroll" }}>
					<Container >
						{/*<PieChart className='mb-5' />*/}
						{pollTotalParticipants == 0 && (<>No Records Found </>)}
						{pollTotalParticipants >= 1 && (<>

							{pollResult.map((innerArray, outerIndex) => (
								<div>{innerArray.question.questionname}
									{innerArray.quetiontype == 'scaling' && (<>
										<Chart
											chartType="BarChart"
											data={innerArray.question.typescalarData}
											options={{
												"title": `${innerArray.question.questionname}`,
												colors: ['#e30613'],
												backgroundColor: 'white'
											}}
											width="100%"
											height="350px"
										/>
									</>)}
									{innerArray.quetiontype == 'ranking' && (<>
										{innerArray.question.typemultiple.map((innerArrayValue, outerIndexValue) => (
											<Chart
												chartType="PieChart"
												data={innerArrayValue.rank}
												options={{ "title": `Rank ${outerIndexValue + 1}`, 'colors': ['#50B432', '#e30613', '#DDDF00', '#24CBE5', '#64E572', '#FF9655'] }}
												width="100%"
												height="350px"
											/>

										))}
									</>)}
									{innerArray.quetiontype == 'multiple' && (<>
										<Chart
											chartType="BarChart"
											width="100%"
											height="350px"
											data={innerArray.question.typemultipleData}
											options={{
												"title": `${innerArray.question.questionname}`,
												colors: ['#e30613'],
												backgroundColor: 'white',
												chartArea: { width: "75%", height: "350px" },
												legend: { position: "none" },
												annotations: { alwaysOutside: true }
											}}
										/>
									</>)}

									{innerArray.quetiontype == 'matrix' && (<>
										{innerArray.question.typemultiplematrix.map((innerArrayValue, outerIndexValue) => (
											<Chart
												chartType="PieChart"
												data={innerArrayValue.matrix}
												options={{ "title": `${innerArrayValue.name}` }}
												width="100%"
												height="350px"
											/>

										))}
									</>)}



								</div>





							))}
						</>
						)}
						{/*<BarChart />*/}








					</Container>
				</Modal.Body>
				<Modal.Footer className='my-1 py-1 d-flex justify-content-between align-items-center '>
					{pollTotalParticipants != 0 && (
						<medium>User participants count so for {pollTotalParticipants}</medium>
					)}


					<Button size="sm" variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal centered show={showAdd} backdrop="static" keyboard={false} onHide={handleCloseAdd}>
				<Modal.Header className='my-1 py-1' closeButton>
					<Modal.Title className='lead'>Add New Survey</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-0 m-0 d-flex align-items-start' style={{ overflowY: "scroll" }}>
					<Container>
						<Form className="mx-0 px-0">
							<Form.Control size="sm" name="surveyName" type="text" placeholder="Survey Name" className="my-2 py-1" onChange={handlePollSurveyName} required />
							<InputGroup className="mb-2" size="sm">
								<Form.Control onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start-date" placeholder="Start Date" onChange={handlePollStartDate} required />
								<Form.Control onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start-date" placeholder="End Date" onChange={handlePollEndDate} required />
							</InputGroup>
							<InputGroup className="mb-2" size="sm">
								<InputGroup.Text>Q1</InputGroup.Text>
								<Form.Control type="text" placeholder="Survey Question" id="surveyQuestion" className='w-50' onChange={handlePollQuestion} required />
								<Form.Select required id="questionType" name="questionType" onChange={(e) => handleQuestionTypeChange(e)} >
									<option value="multiple" selected defaultValue={0}>Multiple Choice</option>
									<option value="ranking" >Ranking</option>
									<option value="scaling" >Scaling</option>
									<option value="matrix" >Matric/Rating</option>
								</Form.Select>
							</InputGroup>
							{formValues.map((element, index) => (
								<InputGroup className="mb-2" size="sm" key={index}>
									<InputGroup.Text >R</InputGroup.Text>
									<Form.Control type="text" id="answerRow" name="answerRow" placeholder={label} className='w-50' onChange={(e) => handleChangeRow(index, e)} required />
									{index > 0 && (<Button type="button" variant="outline-secondary" onClick={() => handleRemoveAnswerRow(index)}><RemoveIcon fontSize='small' /></Button>)}
									{index === 0 && (<Button type="button" variant="outline-secondary" onClick={() => handleAddAnswerRow()}><AddIcon fontSize='small' /></Button>)}
								</InputGroup>
							))}
							{formValues2.map((element, index) => (<>
								{qType == "matrix" && (
									<InputGroup className="mb-2" size="sm" key={index}>
										<InputGroup.Text >C</InputGroup.Text>
										<Form.Control type="text" id='answerCol' name='answerCol' placeholder='Column Title' className='w-50' onChange={(e) => handleChangeCol(index, e)} required />
										{index > 0 && (<Button type="button" variant="outline-secondary" onClick={() => handleRemoveAnswerCol(index)}><RemoveIcon fontSize='small' /></Button>)}
										{index === 0 && (<Button type="button" variant="outline-secondary" onClick={() => handleAddAnswerCol()}><AddIcon fontSize='small' /></Button>)}
									</InputGroup>
								)}
							</>))}
						</Form>

					</Container>
				</Modal.Body>
				<Modal.Footer className='my-1 py-1'>
					<Button size="sm" variant="secondary" onClick={handleCloseAdd}>
						Cancel
					</Button>
					{addPoll == "0" ? (<span><Button size="sm" variant="primary" onClick={handleAddSurvey}>
						Save Question
					</Button></span>) : (<span><Button size="sm" variant="primary" onClick={handleAddSurvey}>
						Next
					</Button>
						<Button size="sm" variant="primary" onClick={handleSaveSurvey}>
							Save Survey
						</Button></span>)}
				</Modal.Footer>
			</Modal>

			<Modal size="lg" centered show={showEdit} backdrop="static" keyboard={false} onHide={handleCloseEdit}>
				<Modal.Header className='my-1 py-1' closeButton>
					<Modal.Title className='lead'>Edit Survey</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-0 m-0 d-flex align-items-start' style={{ maxHeight: '70vh', overflowY: "scroll" }}>
					<Container>
						<Form className="mx-0 px-0">
							{surveryEditData.map((innerArray, outerIndex) => (
								<div>
									<Form.Control type="text" className='w-50' required value={innerArray.poll_content} name="row_multiple_{innerArray.id}" id={`row_multiple_${innerArray.id}`} />
								</div>
							)
							)}

							<InputGroup className="mb-2" size="sm">
								<InputGroup.Text>Survey Name </InputGroup.Text>
								<Form.Control size="sm" name="surveyName" type="text" placeholder='Survey Name text here' className="p-1" />
							</InputGroup>

							<InputGroup className="mb-2" size="sm">
								<InputGroup.Text className="text-primary">Q1. </InputGroup.Text>
								<Form.Control size="sm" name="q1" type="text" placeholder='Question 1 text here' className=" text-primary p-1" />
							</InputGroup>
							{Array.from({ length: 4 }).map((_, idx) => (
								<InputGroup className="mb-2" size="sm" key={idx}>
									<InputGroup.Text>A{idx + 1}. </InputGroup.Text>
									<Form.Control size="sm" name={`a${idx + 1}`} type="text" placeholder={`Answer ${idx + 1} text here`} className="p-1" />
								</InputGroup>
							))}

							<InputGroup className="mb-2" size="sm">
								<InputGroup.Text className="text-primary">Q2. </InputGroup.Text>
								<Form.Control size="sm" name="q2" type="text" placeholder='Question 2 text here' className="text-primary p-1" />
							</InputGroup>
							{Array.from({ length: 3 }).map((_, idx) => (
								<InputGroup className="mb-2" size="sm" key={idx}>
									<InputGroup.Text>A{idx + 1}. </InputGroup.Text>
									<Form.Control size="sm" name={`a${idx + 1}`} type="text" placeholder={`Answer ${idx + 1} text here`} className="p-1" />
								</InputGroup>
							))}
							<InputGroup className="mb-2" size="sm">
								<InputGroup.Text className="text-primary">Q3. </InputGroup.Text>
								<Form.Control size="sm" name="q3" type="text" placeholder='Question 3 text here' className="text-primary p-1" />
							</InputGroup>
							{Array.from({ length: 5 }).map((_, idx) => (
								<InputGroup className="mb-2" size="sm" key={idx}>
									<InputGroup.Text>A{idx + 1}. </InputGroup.Text>
									<Form.Control size="sm" name={`a${idx + 1}`} type="text" placeholder={`Answer ${idx + 1} text here`} className="p-1" />
								</InputGroup>
							))}

						</Form>

					</Container>
				</Modal.Body>
				<Modal.Footer className='my-1 py-1'>
					<Button size="sm" variant="secondary" onClick={handleCloseEdit}>
						Cancel
					</Button>
					<Button size="sm" type="submit" variant="primary" onClick={handleSubmitEdit}>
						Update Survey
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	)
}

export default PVLNPolls