import React, { useState, useEffect } from 'react'
import Layout from '../components/layouts/Layout2'
import Modal from 'react-bootstrap/Modal';
import http from '../http';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'

import { Form, Card, Row, Col, Button, Container, Image } from 'react-bootstrap'
function SponsorshipAPPG(props) {
  
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [show, setShow] = useState(false);
  const [memberships, setMembership] = useState([]);
  const [countrylist, setCountries] = useState([]);

  const handleClose = () => setShow(false);

  const handleShow = (membership) => {
    setMemId(membership.id);
    setSelectedMembership(membership);
    setShow(true);
  };

  const upgradeMembership = (membership_id) => {
    console.log(membership_id);
    http().post('/upgradeMembershipReact', { pavilion_id: props.idx, membership_id: membership_id }).then((res) => {
      console.log(res.data.url);
      window.open(res.data.url, '_blank');
    });
  }
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [company, setCompany] = useState('');
  const [mem_id, setMemId] = useState('');
  const [price, setPrice] = useState('');
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [errors, setErrors] = useState({
    fname: 'Enter First Name',
    lname: 'Enter Last Name',
    email: 'Enter Email Address',
    phone: 'Enter Phone Number',
    country: 'Select your country',
    co: 'Enter Company Name',
  })

  
const memidHandler = (event) => {
  setMemId(event.target.value)
  alert(event.target.value)
}

  const fnameHandler = (event) => {
    setFname(event.target.value)
  }
  const lnameHandler = (event) => {
    setLname(event.target.value)
  }
  const emailHandler = (event) => {
    setEmail(event.target.value)
  }
  const phoneHandler = (event) => {
    setPhone(event.target.value)
  }
  const countryHandler = (event) => {
    setCountry(event.target.value)
  }
  const companyHandler = (event) => {
    setCompany(event.target.value)
  }
  const formSubmit = (event) => {
    event.preventDefault();
    // setErrors(Validation(values));
    // if (errors.name === '' && errors.email === '') {
      // http().post('/register',values)
      // .then(res => {
      //     setShow(false)
      // })
      // .catch(err => console.log(err));
      
      http().post('/upgradeappg', { fname: fname, lname: lname, email: email, phone: phone, country: country, company: company, mem_id: mem_id })
        .then((res) => {
          console.log(res);
          setShow(false);
          window.open(res.data.url, '_self');
        })
        .catch((error) => {
          console.error('Error:', error);
        });
   // }

  }
  
  useEffect(() => {
    if(sessionId){
      http().get('/sponsorshipAPPG?session_id='+sessionId).then((data) => {
        console.log(data);
        setCountries(data.data.countries);
        setMembership(data.data.memberships);

        Swal.fire({
          title: "Congratulations for purchasing.",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });

      });
    }else{
      http().get('/sponsorshipAPPG').then((data) => {
        console.log(data);
        setCountries(data.data.countries);
        setMembership(data.data.memberships);
      });
    }
  }, [sessionId]);

  return (
    <Layout>
      <Container fluid className='px-0 mx-0 bg-primary d-flex justify-content-center align-content-center' >
        <Image src="https://bic3-live.s3.eu-west-2.amazonaws.com/images/publicshop/public-shop-banner.png" className="mw-100" alt="..." />
      </Container>
      <Container className="pt-0 px-5" data-aos="">

        <div className="container shadow-sm p-5">
          {/* <section className="d-flex justify-content-center"> */}
          <Row xs={1} md={2} className="gx-5 d-flex justify-content-center">
            {memberships.map((mem, index) => (<>
              <Col md={5} >
                <Card className=''>
                  <Card.Img variant="top" src={mem.image} />
                  <Card.Body className="text-center">
                    <Card.Title>
                      <h5 className='fs-6'>{mem.name}</h5>
                      <h4 className='fs-5'>{mem.category}</h4>
                    </Card.Title>
                    <Card.Text>
                      <h1 className='fw-bold'> <small className="text-muted">£</small> {Number(mem.price).toLocaleString()} </h1>
                      <h5>Total</h5>
                    </Card.Text>
                    <Button variant="primary w-100" onClick={() => { handleShow(mem) }}>KNOW MORE</Button>
                  </Card.Body>
                  {/* <Card.Footer className="text-center">
                                        <input type="checkbox" className="custom-control-input" id="defaultChecked1"></input>
                                        <label htmlFor="defaultChecked1">&nbsp;Mark as Favourite</label>
                                    </Card.Footer> */}
                </Card>
              </Col>
            </>))}

          </Row>
          {/* </section> */}
        </div>

      </Container>
      <Modal show={show} backdrop="static" keyboard={false} size="lg" centered onHide={() => setShow(false)}>
        {selectedMembership && (
          <>
            <Modal.Header className="py-1" closeButton>
              <Modal.Title>Membership Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={5}>
                  <Image src={selectedMembership.image} width={250} height={250} />
                  {/* <Image src='https://bic3-live.s3.eu-west-2.amazonaws.com/images/pavilion/Entrepreneurship%20banner.png' width={250} /> */}
                </Col>
                <Col md={7}>
                  <h4>{selectedMembership.name}</h4>
                  <h5>Price: £{Number(selectedMembership.price).toLocaleString()}</h5>
                  {/* <Button variant="primary" onClick={() => upgradeMembership(selectedMembership.id)} >Buy Now</Button> */}
                  <h5 className="border-bottom py-2 ps-0">Features:</h5>
                  {/* <p className='lead' dangerouslySetInnerHTML={{ __html: selectedMembership.description }} /> */}
                  <p className='lead'>{selectedMembership.description} </p>
                </Col>
              </Row>
              <Row className="mt-2">
                {/* <Col md={12}>
                  <h5 className="border-bottom pb-2 ps-0">Features:</h5>
                  <p className='lead' dangerouslySetInnerHTML={{ __html: selectedMembership.description }} />
                  <p className='lead'>{selectedMembership.description} </p>
                </Col> */}
                <Col md={12}>
                  <form className="py-1 p-md-0 rounded-3 bg-transparent" onSubmit={formSubmit}>
                    <Row>
                      <Col md={6} sm={12}>
                        <div className="form-floating mb-1">
                        {/* <input type="hidden" name="mem_id" value={selectedMembership.id} className="mem_id9 input-sm" />  */}
                          <input type="text" name="fname"required className="form-control" id="floatingInput" placeholder="First Name"  
                          onChange={fnameHandler}
                          onInvalid={F => F.target.setCustomValidity('Enter First Name Here')} 
                          onInput={F => F.target.setCustomValidity('')} 
                          />
                          <label htmlFor="floatingInput">First Name</label>
                          {/* {errors.fname && <span className='text-danger'>{errors.fname}</span>} */}
                        </div>
                      </Col>
                      <Col md={6} sm={12}>
                        <div className="form-floating mb-1">
                          <input type="text" name='lname' required className="form-control" id="floatingInput" placeholder="Last Name"  
                          onChange={lnameHandler}
                          onInvalid={F => F.target.setCustomValidity('Enter Last Name Here')} 
                          onInput={F => F.target.setCustomValidity('')} 
                          />
                          <label htmlFor="floatingInput">Last Name</label>
                          {/* {errors.lname && <span className='text-danger'>{errors.lname}</span>} */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <div className="form-floating mb-1">
                          <input type="email" name="email" required className="form-control" id="floatingInput" placeholder="name@example.com"  
                          onChange={emailHandler}
                          onInvalid={F => F.target.setCustomValidity('Enter Valid Email Here')} 
                          onInput={F => F.target.setCustomValidity('')} 
                          />
                          <label htmlFor="floatingInput">Email Address</label>
                          {/* {errors.email && <span className='text-danger'>{errors.email}</span>} */}
                        </div>
                      </Col>
                      <Col md={6} sm={12}>
                        <div className="form-floating mb-1">
                          <input type="text" required name='phone' className="form-control" id="floatingInput" placeholder="Phone" 
                          onChange={phoneHandler}
                          onInvalid={F => F.target.setCustomValidity('Enter Phone Number Here')} 
                          onInput={F => F.target.setCustomValidity('')} 
                          />
                          <label htmlFor="floatingInput">Phone</label>
                          {/* {errors.phone && <span className='text-danger'>{errors.phone}</span>} */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <div className="form-floating mb-1">
                          <select className="form-control " required style={{ appearance: 'auto!important' }} name="country" id="country9" onChange={countryHandler}
                          onChange={countryHandler}
                          onInvalid={F => F.target.setCustomValidity('Select your Country ')} 
                          onInput={F => F.target.setCustomValidity('')} 
                          >
                            <option value="">Select</option>
                            {countrylist.map((countrylistArray, countrylistIndex) => (
                              <option value={countrylistArray.id}>{countrylistArray.name}</option>
                            ))}
                          </select>
                          <label for="floatingInput">Country</label>
                          {/* {errors.country && <span className='text-danger'>{errors.country}</span>} */}
                        </div>
                      </Col>
                      <Col md={6} sm={12}>
                        <div className="form-floating mb-2">
                          <input type="text" required name='company' className="form-control" id="floatingInput" placeholder="Company" 
                          onChange={companyHandler}
                          onInvalid={F => F.target.setCustomValidity('Enter Company Name Here')} 
                          onInput={F => F.target.setCustomValidity('')} 
                          />
                          <label htmlFor="floatingInput">Company</label>
                          {/* {errors.co && <span className='text-danger'>{errors.co}</span>} */}
                        </div>
                      </Col>
                    </Row>
                    <div className=" ">
                      <button className="btn btn-lg btn-primary w-100" type="submit">BUY NOW</button>
                    </div>
                  </form>
                  {/* <Form>
                  <div className="container mmbr-form9">
                    <div>
                      <label className="mb-4">Please enter your contact details before proceeding for payment:</label>
                      <div className="form-floating mb-3">
                        <input type="hidden" name="mem_id" value={setMemId(selectedMembership.id)} className="mem_id9 input-sm" />
                        <input type="hidden" name="price" value={setPrice(selectedMembership.price)} className="price9 input-sm" />
                        <input type="text" className="form-control input-sm" name="fname" required="" id="fname9" placeholder="First Name" onChange={fnameHandler} />
                        <label for="floatingInput">First Name</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input type="text" className="form-control input-sm" name="lname" required="" id="lname9" placeholder="Last Name" onChange={lnameHandler} />
                        <label for="floatingInput">Last Name</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input type="email" className="form-control input-sm" name="email" required="" id="email9" placeholder="Email Address" onChange={emailHandler} />
                        <label for="floatingInput">Email Address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <select className="form-control input-sm" name="country" id="country9" onChange={countryHandler}>
                          <option value="">Select</option>
                          {countrylist.map((countrylistArray, countrylistIndex) => (
                            <option value={countrylistArray.id}>{countrylistArray.name}</option>
                          ))}
                        </select>
                        <label for="floatingInput">Country</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input type="tel" className="form-control input-sm" name="phone" required="" id="phone9" placeholder="Phone" onChange={phoneHandler} />
                        <label for="floatingInput">Phone</label>
                      </div>

                      <div className="form-floating mb-3">
                        <input type="text" name="company" className="form-control input-sm" required="" id="company9" placeholder="Company" onChange={companyHandler} />
                        <label for="floatingPassword">Company</label>
                      </div>
                      <div className="mb-3 d-flex justify-content-between align-items-center">
                        <button className="btn btn-lg btn-primary btn-sm" type="submit" onSubmit={formSubmit} >Submit</button>
                      </div>
                    </div>
                  </div>
                  </Form> */}
                </Col>
              </Row>
            </Modal.Body>
          </>
        )}
      </Modal>
    </Layout>


  )
}
export default SponsorshipAPPG
