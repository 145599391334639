import React from 'react'
import { Button, Card, Dropdown, Row, Col } from 'react-bootstrap'
import StarIcon from '@mui/icons-material/Star'
import { Avatar } from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import BadgeAvatar from '../../../components/BadgeAvatar'
function MyEventsTab() {
  return (
    <Row xs={1} md={3} className="gx-2 gy-0 mt-2">
    {Array.from({ length: 2 }).map((_, idx) => (
      <Col key={idx}>
        <Card className="">
          <Card.Img className="mx-0 px-0" variant="top" src={localStorage.getItem('bucketName')+"/images/events/66321640890329.png"} style={{
            borderBottom: '0.75px solid lightgray'
          }} />

          <Card.Header className="border-bottom-0">
            <Dropdown className="vdots float-end">
              <Dropdown.Toggle className="p-0" variant="outline-link" id="dropdown-basic">
                <MoreVertIcon size="sm"/>      </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Card.Title className="mt-3 mb-0 fw-bold">Welcoem to Event</Card.Title>
            <small >Category: Citizen Participation</small>

            <div className="d-flex align-items-center mt-2">
              <BadgeAvatar size="40" alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} />
              <a href="javascript:void(0);"><div className="ms-3 miw-100">
                <h6 className="mb-0">APPG AI Secretariat</h6>
              </div></a>
              </div>
          </Card.Header>

          <Card.Body className="pt-0">
            <div className="rounded-1 bg-primary d-inline-flex text-white align-items-center justify-content-center flex-column profile p-1">
              <span className="month">Dec</span>
              <span className="counter">04</span>
            </div>
            <div>    <CalendarMonthOutlinedIcon />&nbsp;Oct 23 2023 | 05:30 PM</div>
            <div><MapOutlinedIcon />&nbsp;UK Parliment</div>


          </Card.Body>
          <Card.Footer className="mx-0 px-2 d-flex justify-content-between align-items-center border-top-0">
            <small>You and 25 more going</small>
            <Button variant="secondary" size="sm" className="px-1 py-0"><StarIcon className="mb-1" sx={{ width: '15px', height: '15px' }} />Interested</Button>
          </Card.Footer>
        </Card>
      </Col>
    ))}
  </Row>
  )
}

export default MyEventsTab
