import React from 'react'

function Footer101() {
  return (
    <footer className="container-fluid bg-light footer--pin d-flex justify-content-between align-items-center px-5 py-2 mx-0">
  <div className='w-50'>
    <small> © Copyright 2023 – Pavilion Platform Ltd. All rights reserved. |  <a href="https://coreui.io">Privacy Policy</a></small>
  </div>
  <div className="text-end">
    <small>Pavilion Platform is part of Big Innovation Centre in all global regions. <br/>Pavilion Platform is registered in England and Wales No. 11556858</small>
  </div>
</footer>
  )
}

export default Footer101
