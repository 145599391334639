import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';

function CRUDDropdown(props,{handleDropdown}) {
    
  return (
    <Dropdown onClick={props.handleDropdown}>
      
      <Dropdown.Toggle as={IconButton} size='small' className='p-0 btn btn-outline-mode' variant="outline-secondary" id="dropdown-basic">
        {props.title}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {props.addTitle && (<Dropdown.Item  size="sm" onClick={props.addClick}>{props.addTitle}</Dropdown.Item>)}
        {props.editTitle && (<Dropdown.Item size="sm" onClick={props.editClick}>{props.editTitle}</Dropdown.Item>)}
        {props.deleteTitle && (<Dropdown.Item size="sm" onClick={props.deleteClick}>{props.deleteTitle}</Dropdown.Item>)}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CRUDDropdown;