import React, { useState, useRef } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import CRUDDropdown from '../CRUDDropdown';
import BadgeAvatar from '../BadgeAvatar'
import { Card, Row, Col, Form, Button, Container } from 'react-bootstrap'
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { IconButton } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAlt';
import ReplyIcon from '@mui/icons-material/Reply';
import Badge from 'react-bootstrap/Badge';
import CommentsInput from "../CommentsInput";

export default function Comment({ comment, addReply, showReply }) {
    const [replyText, setReplyText] = useState("");
    const [showReplyBox, setShowReplyBox] = useState(false);
    const inputEl = useRef(null);

    const [commentReply, setCommentReply] = useState()
    const [replyCount, setReplyCount] = useState(0)
    const [thumbCount, setThumbCount] = useState(0)


    function handleDeleteComment(e) {

    }

    function handleThumbClick(e) {
        setThumbCount(thumbCount + 1)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            addReply(comment.id, replyText);
            setShowReplyBox(false);
            setReplyText("");
        }
    }

    function handleEmoji(e) {
        setCommentReply(commentReply + e.target.value)
    }

    const onFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();

        reader.onload = () => {
            //    getUploadedFile(reader.result);
            //         setImage(reader.result)
            console.log(reader.result)
        };
        reader.readAsDataURL(files[0]);

    };


    return (
        <ListGroup key={comment.id} className="ms-1 mt-1 px-0 ">
            <ListGroup.Item className={`${showReply !== 0 ? 'px-0' : 'px-4'} border-0 py-0`}>
                {/* {!showReplyBox && ( */}
                    <div className='d-flex align-items-center justify-content-between mb-1'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} name="Stephen Metcalfe" size='20' />
                            <h6 className='ps-1 my-0'>Charles Smith</h6>
                            {showReply !== 0 && (<>
                                <small className='text-muted'> - 4 months ago</small>
                                <small className='ps-1'>
                                    <IconButton className='p-1' onClick={handleThumbClick} size="small"><ThumbUpIcon fontSize="small" /></IconButton>
                                    <Badge bg="primary">{thumbCount}</Badge>
                                    <IconButton className='p-1' onClick={() => {
                                        setShowReplyBox(true);
                                        setTimeout(() => inputEl.current.focus());
                                    }} size="small"><ReplyIcon fontSize="small" /></IconButton>
                                    <Badge bg="secondary">{replyCount}</Badge>
                                </small></>
                            )}
                        </div>
                        {showReply !== 0 && (<CRUDDropdown title={<MoreVertIcon fontSize="small" />} deleteTitle="Delete" deleteClick={handleDeleteComment} />)}
                    </div>
                    {/* // <button 
                    //   type="button"
                    //   className="ml-3 mr-3"
                    //   onClick={() => {
                    //     setShowReplyBox(true);

                    //     // This is to make the ref available
                    //     setTimeout(() => inputEl.current.focus());
                    //   }}
                    // >
                    //   r
                    // </button>
                // )}*/}
                <div className="ms-4 mb-0">{comment.display}</div>
                {showReplyBox && (<>
                    <div className="write-comment bg-light  my-2 d-flex justify-content-between align-items-center px-0">
                        <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} name="Stephen Metcalfe" size='25' />
                        <div className="input-holder w-100 ps-2 pe-0 d-flex justify-content-between align-items-center">
                            <Form.Control value={commentReply} ref={inputEl} size="small"
                                onKeyPress={handleKeyPress}
                                onChange={(e) => {
                                    setReplyText(e.target.value);
                                }} id="commentsField" name="commendsField" className='py-0' type="text" placeholder="Write a comment..." />
                            <label htmlFor="contained-button-file">
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    type="file"
                                    className='d-none'
                                    onChange={onFileChange}
                                />
                                <IconButton as="span" size="small" style={{ cursor: 'pointer' }} variant="secondary" ><AddPhotoIcon /></IconButton>
                            </label>
                            <Dropdown >
                                <Dropdown.Toggle as={IconButton} size="small" id="dropdown-split-basic" ><AddReactionIcon /></Dropdown.Toggle>
                                <Dropdown.Menu >
                                    <div className='d-flex align-items-center flex-wrap justify-content-center'>
                                        <IconButton value="😁" onClick={handleEmoji} size="small">😀</IconButton>
                                        <IconButton value="😁" onClick={handleEmoji} size="small">😁</IconButton>
                                        <IconButton value="😂" onClick={handleEmoji} size="small">😂</IconButton>
                                        <IconButton value="😃" onClick={handleEmoji} size="small">😃</IconButton>
                                        <IconButton value="😄" onClick={handleEmoji} size="small">😄</IconButton>
                                        <IconButton value="😅" onClick={handleEmoji} size="small">😅</IconButton>
                                        <IconButton value="😆" onClick={handleEmoji} size="small">😆</IconButton>
                                        <IconButton value="😇" onClick={handleEmoji} size="small">😇</IconButton>
                                        <IconButton value="😈" onClick={handleEmoji} size="small">😈</IconButton>
                                        <IconButton value="😉" onClick={handleEmoji} size="small">😉</IconButton>
                                        <IconButton value="😋" onClick={handleEmoji} size="small">😋</IconButton>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {/* <textarea
            ref={inputEl}
            onChange={(e) => {
              setReplyText(e.target.value);
            }}
            type="text"
          />
          <br />
          <button
            type="button"
            onClick={() => {
              addReply(comment.id, replyText);
              setShowReplyBox(false);
              setReplyText("");
            }}
          >
            save
          </button>
          <button
            type="button"
            onClick={() => {
              setShowReplyBox(false);
              setReplyText("");
            }}
          >
            cancel
          </button> */}
                </>
                )}
                {comment.children.length > 0 && (
                    <ul className="px-0">
                        {comment.children.map((childComment) => (
                            <Comment
                                key={childComment.id}
                                comment={childComment}
                                addReply={addReply} showReply={0}
                            />
                        ))}
                    </ul>
                )}
            </ListGroup.Item>
        </ListGroup>
    );
}
