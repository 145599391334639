import React, { useState } from 'react'
import { Button, Container, Collapse } from 'react-bootstrap'
import EventIcon from '@mui/icons-material/Event';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Avatar } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import StarIcon from '@mui/icons-material/Star';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BadgeAvatar from '../../../components/BadgeAvatar';

function EventsTab() {
  const [open, setOpen] = useState(false);
  return (
    <Container className="mt-2 px-0">
      <div className="border-0 d-flex justify-content-between bg-toggle rounded shadow-sm px-2 py-2 mb-2 mx-0">
        <h5 className="d-flex-mi mb-0"><EventIcon />&nbsp;Events <NavigateNextIcon /> View All</h5>
        <div>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-fade-text"
            aria-expanded={open} className='px-2 py-1 me-1'
          >
            <TuneIcon />
          </Button>
        </div>
      </div>
      <Collapse in={open}>
        <div id="example-fade-text" className="bg-toggle p-4 rounded shadow-sm mb-2" >
          <form method="get" className="search-form publications-search-filters members-bg-gray" name="form_search_events" id="form_search_events">
            <input type="hidden" name="event_pavilion_id" id="event_pavilion_id" value="4" />
            <div className="row d-flex justify-content-center flex-wrap align-items-center g-3">
              <div className="col-4">
                <select name="find_event" className="form-select" id="find_event">
                  <option value="keyword">When</option>
                  <option value="previous">Previous</option>
                  <option value="week">This Week</option>
                  <option value="month">This Month</option>
                  <option value="upcoming">Upcoming</option>
                </select>
              </div>
              <div className="col-4">
                <select name="event_location" className="form-select" id="event_location">
                  <option value="">Where</option>
                  <option value="0">Onsite</option>
                  <option value="1">Online</option>
                  <option value="2">Both</option>
                </select>
              </div>
              <div className="col-4">
                <select name="event_search_type" className="form-select" id="event_search_type">
                  <option value="">Format</option>
                  <option value="0">Meeting</option>
                  <option value="1">Webinar</option>
                </select>
              </div>

              <div className="col-4">

                <select name="event-type" className="form-select" id="event-type">
                  <option value="">Type</option>
                  <option value="1">Evidence Meetings</option>
                  <option value="2">Advisory Board Meetings</option>
                  <option value="3">Dinners</option>
                  <option value="4">Annual Reception</option>
                  <option value="5">Fringe Events</option>
                  <option value="">View All</option>
                </select>
              </div>
              <div className="col-4">
                <input type="search" className="form-control" name="text_search_events" id="text_search_events" placeholder="Search Event" />
              </div>

              <div className="col-auto text-center ">
                <button className="btn btn-primary d-flex-mi " type="submit" name="btn_search_events" id="btn_search_events">
                  <SearchIcon />
                </button>
              </div>
            </div>
          </form>
        </div>
      </Collapse>
      <Row xs={1} md={3} className="gx-2 gy-0">
        {Array.from({ length: 6 }).map((_, idx) => (
          <Col key={idx}>
            <Card className="">
              <Card.Img className="mx-0 px-0" variant="top" src={localStorage.getItem('bucketName')+"/images/events/32991672758177.png"} style={{
                borderBottom: '0.75px solid lightgray'
              }} />

              <Card.Header className="border-bottom-0">
                <Dropdown className="vdots float-end">
                  <Dropdown.Toggle className="p-0" variant="outline-link" id="dropdown-basic">
                    <MoreVertIcon size="sm"/>      </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Card.Title className="mt-3 mb-0 fw-bold">Welcoem to Event</Card.Title>
                <small >Category: Citizen Participation</small>

                <div className="d-flex align-items-center mt-2">
                  <BadgeAvatar size="40" alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"}/>
                  <a href="javascript:void(0);"><div className="ms-3 miw-100">
                    <h6 className="mb-0">APPG AI Secretariat</h6>
                  </div></a>
                  </div>
              </Card.Header>

              <Card.Body className="pt-0">
                <div className="rounded-1 bg-primary d-inline-flex text-white align-items-center justify-content-center flex-column profile p-1">
                  <span className="month">Dec</span>
                  <span className="counter">04</span>
                </div>
                <div>    <CalendarMonthOutlinedIcon />&nbsp;Oct 23 2023 | 05:30 PM</div>
                <div><MapOutlinedIcon />&nbsp;UK Parliment</div>


              </Card.Body>
              <Card.Footer className="mx-0 px-2 d-flex justify-content-between align-items-center border-top-0">
                <small>You and 25 more going</small>
                <Button variant="secondary" size="sm" className="px-1 py-0"><StarIcon className="mb-1" sx={{ width: '15px', height: '15px' }} />Interested</Button>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default EventsTab
