import React, { useState, useEffect } from "react";
import http from '../../../../http';
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';

import InfiniteScroll from "react-infinite-scroller";
import { Image, Card } from 'react-bootstrap'
import axios from "axios";

import PreLoader from "../../../PreLoader";
import { PLSIZE, formatDate } from "../../../PavilionConstants";

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};


function PRFLPurchasedHistory({ handleClick }) {
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE}/>);
    const token = localStorage.getItem('token');

    const fetchData = async (__page) => {
        console.log(__page);
        const response = http().get(`/userpurchasedhistory?page=${page}`)
        .then((response) => {
            setItems([...items, ...response.data.data.data]);
            const last_page = response.data.data.last_page;
            if (last_page < page) {
                setPage(page + 1);
                setHasMore(true);
                setLoadMsg(<PreLoader size={PLSIZE}/>)
            } else { setHasMore(false); setLoadMsg('No More Records...') }

        });
    };

    return (<>
        <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <Typography variant="small">Purchased History</Typography>
                <IconButton className='btn btn-outline-mode' onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light" >
                <div className="container slim-scrollbar mh-70  py-0 " id='scrollableDiv' style={{ overflow: "auto" }}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={fetchData}
                        hasMore={hasMore}
                        initialLoad={true}
                        // isReverse={true}
                        // loader={
                        //     <div className="loader" key={0}>
                        //         {loadMsg}
                        //     </div>
                        // }
                        useWindow={false}
                    >
  
                            {items.map((item, index) => (<>
   <Container fluid className="small  border-bottom border p-2 rounded mb-2">
                        <Row className='my-1'>
                            <Col md={4}>Pavilion Name:</Col>
                            <Col md={8} className="fw-bold">{item.pavilion_name}</Col>
                        </Row>
                        <Row className='my-1'> 
                            <Col md={4}>Name:</Col>
                            <Col md={8}>{item.title}</Col>
                        </Row>
                        <Row className='my-1'>
                            <Col md={4}>Type:</Col>
                            <Col md={8}>{item.type}</Col>
                        </Row>
                        <Row className='my-1'>
                            <Col md={4}>OrderNo.:</Col>
                            <Col md={8}>{item.order_number}</Col>
                        </Row >
                        <Row className='my-1'>
                            <Col md={4}>Price:</Col>
                            <Col md={8}>{item.total_price} {item.currency}</Col>
                        </Row>
                        <Row className='my-1'>
                            <Col md={4}>Purchase Date:</Col>
                            <Col md={8}>{formatDate(item.purchased_at)}</Col>
                        </Row>
                        <Row className='my-1'>
                            <Col md={4}>Expiry Date:</Col>
                            <Col md={8}>{formatDate(item.expiration_at)}</Col>
                        </Row>
                    </Container>
</>))}
                        
                    </InfiniteScroll>
                         <div className="loader text-center" key={0} >
                                 {loadMsg}
                         </div>

                    {/* <Container fluid className="small  border-bottom border p-2 rounded mb-2">
                        <Row>
                            <Col md={4}>Pavilion Name:</Col>
                            <Col md={8} className="fw-bold">The Metaverse Pavilion and Festival</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Name:</Col>
                            <Col md={8}>Member Three Year</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Type:</Col>
                            <Col md={8}>Member</Col>
                        </Row>
                        <Row>
                            <Col md={4}>OrderNo.:</Col>
                            <Col md={8}>#1122</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Price:</Col>
                            <Col md={8}>£50</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Purchase Date:</Col>
                            <Col md={8}>01-06-2023</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Expiry Date:</Col>
                            <Col md={8}>01-06-2025</Col>
                        </Row>
                    </Container>
                    <Container fluid className="small  border-bottom border p-2 rounded mb-2">
                        <Row>
                            <Col md={4}>Pavilion Name:</Col>
                            <Col md={8} className="fw-bold">APPG Blockchain</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Name:</Col>
                            <Col md={8}>Member One Year</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Type:</Col>
                            <Col md={8}>Membership</Col>
                        </Row>
                        <Row>
                            <Col md={4}>OrderNo.:</Col>
                            <Col md={8}>#1108</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Price:</Col>
                            <Col md={8}>£250</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Purchase Date:</Col>
                            <Col md={8}>25-04-2023</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Expiry Date:</Col>
                            <Col md={8}>25-06-2025</Col>
                        </Row>
                    </Container>
                    <Container fluid className="small  border-bottom border p-2 rounded mb-2">
                        <Row>
                            <Col md={4}>Pavilion Name:</Col>
                            <Col md={8} className="fw-bold">BIC Premium Member</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Name:</Col>
                            <Col md={8}>Member Two Year</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Type:</Col>
                            <Col md={8}>Membership</Col>
                        </Row>
                        <Row>
                            <Col md={4}>OrderNo.:</Col>
                            <Col md={8}>#1118</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Price:</Col>
                            <Col md={8}>£500</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Purchase Date:</Col>
                            <Col md={8}>01-09-2023</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Expiry Date:</Col>
                            <Col md={8}>01-01-2025</Col>
                        </Row>
                    </Container> */}
                </div>
            </ListGroup.Item>
        </ListGroup></>
    )
}

export default PRFLPurchasedHistory
