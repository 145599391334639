import * as React from 'react';
import { Chart } from "react-google-charts";
export const data = [
  ["Opinion", "No of Persons"],
  ["Strongly Disagree", 11],
  ["Disagree", 2],
  ["Agree", 2],
  ["Strongly Agree", 2],
];
export const options = {
  title: "Q1. Generative AI technologies pose a significant challenge to whether creativity should be protected by IPR",
  pieHole: 0,
  is3D: false,
  'chartArea': {'width': '95%', 'height': '350px'},
};

export default function PieChart() {
  return (
    <Chart 
      chartType="PieChart"
      data={data}
      options={options}
      width="100%"
      height="350px"
    />
  );
}