import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TuneIcon from '@mui/icons-material/Tune';
import SortIcon from '@mui/icons-material/Sort';
import { SouthAmericaOutlined } from '@mui/icons-material';
import Fade from 'react-bootstrap/Fade';
import CollectionsIcon from '@mui/icons-material/Collections';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Badge from 'react-bootstrap/Badge';

function MyWallGalleryTab() {
  return (
    <Container className="mt-2 px-0">
    <Tabs
      defaultActiveKey="librarydocument"
      id="uncontrolled-tab-example"
      className="mb-0"
    >
      <Tab eventKey="librarydocument" title="Your Photos" >
        <div className="border-0 d-flex justify-content-between bg-toggle rounded shadow-sm px-3 py-2 mb-2">
          <h5 className="d-flex-mi mb-0"><CollectionsIcon />
            &nbsp;Photos Gallery</h5>
          <div>
            {/* <button type="button" className="btn btn-primary d-flex-mi p-1 me-1" data-bs-toggle="collapse" data-bs-target="#collapseSearch" aria-expanded="true" aria-controls="collapseSearch"><TuneIcon /></button> */}
            <Button className='px-2 py-1 me-1'>
              <CameraEnhanceIcon />&nbsp; Add Photos
            </Button>
          </div>
        </div>

        <Row xs={1} md={3} className="gx-4 gy-0">
          {Array.from({ length: 6 }).map((_, idx) => (
            <Col key={idx}>
              <Card className="card-hover2">
              <Card.Img className="rounded" variant="top" src={`https://source.unsplash.com/random/300x300/?sig=${idx}`} />
                <Card.ImgOverlay className="text-light align-middle text-center bg-black bg-opacity-75" >
                  <Card.Text className="mb-1" >17th October 2023</Card.Text>
                  <Card.Text className="">
                    <ThumbUpOffAltIcon size="sm"/> <Badge bg="success">0</Badge>  <MessageOutlinedIcon size="sm"/> <Badge bg="success">0</Badge>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
          ))}
        </Row>

      </Tab>
      <Tab eventKey=" profile" title="Your Videos">
        <div className="border-0 d-flex justify-content-between bg-toggle rounded-bottom shadow-sm px-3 py-2 mb-2">
          <h5 className="d-flex-mi mb-0">
            <VideoCameraBackIcon />&nbsp;Videos Gallery
          </h5>
          <div className="d-flex ">
            <Button className='px-2 py-1 me-1'>
              <VideoCallIcon />&nbsp; Add Videos
            </Button>
            {/* <button className="btn btn-link p-0  text-dark me-1" type="button">
                  <VideoCallIcon />
                </button> */}
          </div>
        </div>
      </Tab>

    </Tabs>

  </Container>
  )
}

export default MyWallGalleryTab
