import React, { useState, useRef, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { AccountBalanceOutlined, ContentCopy, Facebook } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import BadgeAvatar from '../../../BadgeAvatar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Like from '@mui/icons-material/FavoriteBorder';
import Comment from '@mui/icons-material/MessageOutlined';
import Share from '@mui/icons-material/ReplyOutlined';
import Flag from '@mui/icons-material/OutlinedFlag';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TelegramIcon from '@mui/icons-material/Telegram';
import Collapse from 'react-bootstrap/Collapse';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Comments from '../../../Comments';
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import http from '../../../../http'
import PreLoader from '../../../PreLoader';
import { PLSIZE, formatDate } from '../../../PavilionConstants';
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from "react-infinite-scroll-component";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';

const AntTabs = styled(Tabs)({
  width: '100%',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    border: '1px solid rgba(255,255,255, 0.5)',
    backgroundColor: 'rgba(255,255,255, 0.5)',
    width: '100%',
    padding: '2px 3px',
    borderRadius: 50,

  },
});

interface StyledTabProps {
  label: string;
}
const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minHeight: 20,
  width: '50%',
  borderRadius: 50,
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 3,
  paddingBottom: 3,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  color: 'rgb(0, 0, 0)',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  fontFamily: ['Barlow'].join(','),
  '&:hover': {
    color: '#e30613',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#ffffff',
    backgroundColor: '#e30613',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#ffffff',
  },
}));

function PVLNMyWall(props, { handleClick }) {

  const [bucketName, setBucketName] = useState('');
  const [domainName, setDomainName] = useState('');

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [myPosts, setMyPosts] = useState([]);
  const [theirPosts, setTheirPosts] = useState([]);

  const [hasMore, setHasMore] = useState(true);

  const [page1, setPage1] = useState(1);
  const [totalPages1, setTotalPages1] = useState(1);
  const [page2, setPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);

  const [perPage1, setPerPage1] = useState();
  const [perPage2, setPerPage2] = useState();

  const [loadMsg1, setLoadMsg1] = useState(<PreLoader size={PLSIZE} />);
  const [loadMsg2, setLoadMsg2] = useState(<PreLoader size={PLSIZE} />);

  const scrollContainerRef = useRef(null);

  const [value, setValue] = useState('one');


  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }
  function handleEdit(event) {
    console.log('handleEdit')
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 'one') {
      fetchData1();
    }
    if (value === 'two') {
      fetchData2();
    }

  }, [value]);
  // Function to fetch events from an API
  const fetchData1 = async () => {
    const res = await http().get(`/mywallposts?page=${page1}`).then((response) => {
      setTheirPosts([...theirPosts, ...response.data.data.posts.data]);
      setTotalPages1(response.data.data.posts.last_page);
      setPerPage1(response.data.data.posts.per_page);
      setDomainName(response.data.data.domain);
      if (page1 > totalPages1) { setHasMore(false); return; }
    });
    setPage1(page1 + 1);
  };

  const fetchData2 = async () => {
    const res = await http().get(`/myrecentposts?page=${page2}`).then((response) => {
      setMyPosts([...myPosts, ...response.data.data.posts.data]);
      setTotalPages2(response.data.data.posts.last_page);
      setPerPage2(response.data.data.posts.per_page);
      setDomainName(response.data.data.domain);
      if (page2 > totalPages2) { setHasMore(false); return; }
    });
    setPage2(page2 + 1);
  };


  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
          {/* <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} onClick={event => props.handleClick('PRFL-MENU1', props.idx)}><ArrowBackIcon /></IconButton> */}
          <Typography variant="small">My Wall</Typography>
        </div>
        <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} onClick={event => props.handleClick('PRFL-MENU1', props.idx)}><Home /></IconButton>
        {/* <IconButton sx={{ padding: 1 }} ><PostAddIcon /></IconButton> */}

      </ListGroup.Item>
      <ListGroup.Item className="d-flex align-items-top justify-content-center px-2 h-100  border-0 bg-light bg-opacity-75">
        <div className="container py-0 px-0 " >
          {/* <div>
            <InfiniteScroll
              pageStart={1}
              hasMore={hasMore}
              loadMore={fetchData}
              getScrollParent={() => scrollContainerRef.current}
              initialLoad={true}
              useWindow={false}
            >
              {myPosts.map((post, index) => (
                <ListGroup className="overflow-hidden px-0 mb-2 " style={{ borderRadius: "5px" }}>
                  <ListGroup.Item key={post.id} className='d-flex justify-content-between align-items-center border-0 text-center py-1 px-1'>
                    <div className="w-100 d-flex justify-content-start align-items-center ">
                      <BadgeAvatar src={localStorage.getItem('bucketName') + "/images/profile_picture/" + post.user.image} name={post.user.name} size='30' isLoggedIn={false} />
                      <div className='ms-2 text-start'>
                        <h6 className='mb-0 lh-1'>{post.user.name}</h6>
                        <small>{formatDate(post.created_at)}</small>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item key={index} className="d-flex align-items-center justify-content-center h-100  border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                    <div className="container px-0   py-1">
                      <p dangerouslySetInnerHTML={{ __html: post.post_content.replace(domainName + 'posts_attachments', localStorage.getItem('bucketName') + '/posts_attachments') }}></p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </InfiniteScroll>
            <div className="loader my-2 text-center" key={0} >
              {loadMsg}
            </div>
          </div> */}
          <TabContext value={value}>
            <Box sx={{ width: '100%' }} className="rounded-4 p-0 small d-flex flex-column justify-content-between align-items-center">
              <AntTabs style={{ position: "absolute", width: '95%', zIndex: '9999', borderRadius: '15px', minHeight: 0 }}
                value={value}
                onChange={handleChange}
                scrollButtons={false}
                aria-label="scrollable auto tabs example"
              >
                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">My Community Posts</Typography>} value='one' />
                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">My Wall Posts</Typography>} value='two' />
              </AntTabs>
              <TabPanel value="one" className='w-100 p-2 mh-70 mb-2 overflow-auto' style={{ marginTop: '30px' }} ref={scrollContainerRef}>
                {/* <div className=" py-0 px-0" > */}
                {/* <InfiniteScroll
                    pageStart={1}
                    loadMore={fetchData1}
                    hasMore={hasMore}
                    getScrollParent={() => scrollContainerRef.current}
                    initialLoad={true}
                    useWindow={false}
                  > */}
                <InfiniteScroll
                  dataLength={page1*2}
                  next={fetchData1}
                  scrollableTarget="scrollContainerRef"
                  hasMore={hasMore}
                  height={'300'}
                  className='slim-scrollbar mh-62'
                  loader={<PreLoader size={PLSIZE} />}
                  endMessage={<h6>No more records...</h6>}
                >

                  <Row xs={1} md={1} className="g-0" >
                    {theirPosts.map((post, index) => (
                      <ListGroup className="px-0 mb-2 " style={{ borderRadius: "5px" }}>
                        <ListGroup.Item key={post.id} className='d-flex justify-content-between align-items-center border-0 text-center py-1 px-1'>
                          <div className="w-100 d-flex justify-content-start align-items-center ">
                            <BadgeAvatar src={localStorage.getItem('bucketName') + "/images/profile_picture/" + post.user.image} name={post.user.name} size='30' isLoggedIn={false} />
                            <div className='ms-2 text-start'>
                              <h6 className='mb-0 lh-1'>{post.user.name}</h6>
                              <small>{formatDate(post.created_at)}</small>
                            </div>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item key={index} className="d-flex align-items-center justify-content-center h-100  border-0 bg-light bg-opacity-75">
                          <div className="container post-content px-0 py-1">
                            <p className='text-start' dangerouslySetInnerHTML={{ __html: post.post_content.replace(domainName + 'posts_attachments', localStorage.getItem('bucketName') + '/posts_attachments') }}></p>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                  </Row>
                </InfiniteScroll>
                {/* </div> */}
                {/* <div className="loader my-2 text-center" key={0} >
                    {loadMsg1}
                  </div> */}

              </TabPanel>
              <TabPanel value="two" className='w-100 p-2 mh-70 mb-2 overflow-auto' style={{ marginTop: '30px' }} ref={scrollContainerRef}>

                {/* <InfiniteScroll
                    pageStart={1}
                    loadMore={fetchData2}
                    hasMore={hasMore}
                    initialLoad={true}
                    getScrollParent={() => scrollContainerRef.current}
                    useWindow={false}
                  > */}
                <InfiniteScroll
                  dataLength={page2*5}
                  next={fetchData2}
                  scrollableTarget="scrollContainerRef"
                  hasMore={hasMore}
                  height={'300'}
                  className='slim-scrollbar mh-62'
                  loader={<PreLoader size={PLSIZE} />}
                  endMessage={<h6>No more records...</h6>}
                >
                  <Row xs={1} md={1} className="g-0 " >
                    {myPosts.map((post, index) => (
                      <ListGroup className="overflow-hidden px-0 mb-2 " style={{ borderRadius: "5px" }}>
                        <ListGroup.Item key={post.id} className='d-flex justify-content-between align-items-center border-0 text-center py-1 px-1'>
                          <div className="w-100 d-flex justify-content-start align-items-center ">
                            <BadgeAvatar src={localStorage.getItem('bucketName') + "/images/profile_picture/" + post.user.image} name={post.user.name} size='30' isLoggedIn={false} />
                            <div className='ms-2 text-start'>
                              <h6 className='mb-0 lh-1'>{post.user.name}</h6>
                              <small>{formatDate(post.created_at)}</small>
                            </div>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item key={index} className="d-flex align-items-center justify-content-center h-100  border-0 bg-light bg-opacity-75">
                          <div className="container post-content px-0 text-start  py-1">
                            <p dangerouslySetInnerHTML={{ __html: post.post_content.replace(domainName + 'posts_attachments', localStorage.getItem('bucketName') + '/posts_attachments') }}></p>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                  </Row>
                </InfiniteScroll>
                {/* <div className="loader my-2 text-center" key={1} >
                    {loadMsg2}
                  </div> */}

              </TabPanel>
            </Box>
          </TabContext>
        </div>
      </ListGroup.Item>
    </ListGroup>
  )
}

export default PVLNMyWall
