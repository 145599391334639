import React from 'react'
import { ListGroup, Carousel, Image } from 'react-bootstrap'

function CommunityEngagementBox() {
  return (
    <ListGroup>
      <ListGroup.Item active className="py-2">
        <strong>Community Engagement</strong>
      </ListGroup.Item>
      <ListGroup.Item>
        <Carousel indicators={false} >
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/35301637222156.png"} rounded className="w-100" />

          </Carousel.Item>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/36841637249646.png"} rounded className="w-100" />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/63081637222080.png"} rounded className="w-100" />

          </Carousel.Item>
        </Carousel>
      </ListGroup.Item>
      <ListGroup.Item>
        <Carousel indicators={false}>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/24621637222413.png"} rounded className="w-100" />

          </Carousel.Item>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/63091637222392.png"} rounded className="w-100" />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/21231637222374.png"} rounded className="w-100" />

          </Carousel.Item>
        </Carousel >
      </ListGroup.Item>
      <ListGroup.Item>
        <Carousel indicators={false}>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/36841637249646.png"} rounded className="w-100" />

          </Carousel.Item>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/36841637249646.png"} rounded className="w-100" />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={localStorage.getItem('bucketName')+"/images/community_engagement/thumbnails/36841637249646.png"} rounded className="w-100" />

          </Carousel.Item>
        </Carousel>
      </ListGroup.Item>
    </ListGroup>

  )
}

export default CommunityEngagementBox
