import React, { useState } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import Group from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import { Grid } from '@mui/material';
function UserDetailsBox(props) {
  const [show, setShow] = useState(props.showMe);
  return (
    <>
      {show && (<Container fluid className="px-0">
        <ListGroup className="my-2 px-0 mx-0 g-0 shadow-sm">
          <ListGroup.Item className="active text-center bg-linear-gradient-primary ">
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Group />&nbsp; <strong>Details</strong>
            </Grid>
            {/* <h5 className="mb-0 "><Group />&nbsp;Details</h5> */}
          </ListGroup.Item>
          <ListGroup.Item >
            <h6 className="mb-0"><span className="text-primary">Founding Year:</span> 2020</h6>
          </ListGroup.Item>
          <ListGroup.Item>
            <h6 className="mb-0"><span className="text-primary">Location:</span> Global</h6>
          </ListGroup.Item>
        </ListGroup>
        <div className="text-center">

          <a href="/" type="button" className="btn btn-success d-flex-mi p-2 m-2">
            {/* <i className="material-symbols-outlined" style="visibility: visible;">login</i> */}
            <LoginIcon /> Login</a>

        </div>
      </Container>
      )}
    </>
  )
}

export default UserDetailsBox
