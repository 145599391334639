import React, { useState, createRef,useRef } from "react";
import { Button } from 'react-bootstrap'
import { Container, ListGroup } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TuneIcon from '@mui/icons-material/Tune';
import SortIcon from '@mui/icons-material/Sort';
import { SouthAmericaOutlined } from '@mui/icons-material';
import Collapse from 'react-bootstrap/Collapse';
import CategoryIcon from '@mui/icons-material/Category';
import EventIcon from '@mui/icons-material/Event';
import Avatar from '@mui/material/Avatar';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import BadgeAvatar from '../../../components/BadgeAvatar';
import { Form,Row, Col, Card } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
// import Cropper, { ReactCropperElement } from "react-cropper";
// import "cropperjs/dist/cropper.css"; 
import Box from "@mui/material/Box";
import Upload from "../../../components/Cropper/Upload";
import Popup from "../../../components/Cropper/Popup";
function LibraryTab() {

  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(
    "https://source.unsplash.com/random/250x335/?sig=0"
  );

  const handleClose = () => {
    setOpen(false);
  };
  
  const [fileUrl, setFileUrl] = useState('https://source.unsplash.com/random/250x335/?sig=0');

  // const [open, setOpen] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleParentClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  // const cropperRef = createRef<ReactCropperElement>(null);
  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result );
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    // if (typeof cropperRef.current?.cropper !== "undefined") {
    //   setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    // }
  };

  const handleBoxClick = () => {
    
    setShowPopup(true);
  }

  return (

    <Container className="mt-2 px-0">
      <Tabs
        defaultActiveKey="librarydocument"
        id="uncontrolled-tab-example"
        className="mb-0"
      >
        <Tab eventKey="librarydocument" title="Library Documents" >
          <div className="border-0 d-flex justify-content-between bg-toggle rounded shadow-sm px-3 py-2 mb-2">
            <h5 className="d-flex-mi mb-0"><FindInPageIcon />
              Search Library Documents</h5>
            <div>
              <button type="button" className="btn btn-primary d-flex-mi px-2 py-1 me-1" data-mdb-ripple-color="dark" data-bs-toggle="modal" data-bs-target="#modalAddNewLibrary" id="AddNewLibraryBtn" onClick={handleShow}>Add New Library</button>
              {/* <button type="button" className="btn btn-primary d-flex-mi p-1 me-1" data-bs-toggle="collapse" data-bs-target="#collapseSearch" aria-expanded="true" aria-controls="collapseSearch"><TuneIcon /></button> */}
              <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-fade-text"
                aria-expanded={open} className='px-2 py-1 me-1'
              >
                <TuneIcon />
              </Button>
            </div>
          </div>
          <Collapse in={open}>


            <div id="example-fade-text" className="bg-toggle p-4 rounded shadow-sm mb-2" >
              <form  id="library-search-filters" className="search-form publications-search-filters members-bg-gray">
                <input type="hidden" name="_token" value="fbywViR5gKo8BDdAvpo63qoa1YynhOLSSRS2S9Hf" />
                <input type="hidden" name="pavilion_id" value="4" />
                <div className="row d-flex justify-content-center flex-wrap align-items-center g-3">
                  <div className="col-4">
                    <select name="category_id" className="form-select">
                      <option value="">All Categories</option>
                      <option value="1">Data and Ethics</option>
                      <option value="2">Enterprise Adoption</option>
                      <option value="3">Citizen Participation</option>
                      <option value="4">Education</option>
                      <option value="5">Sustainability</option>
                      <option value="6">Health and Wellbeing</option>
                      <option value="7">AI in the World</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <select name="document_type" className="form-select document_type">
                      <option value="">All Document Types</option>
                      <option value="11">Policy and Government</option>
                      <option value="12">Significant from Members</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <select name="year" className="form-select">
                      <option value="">Search By Year</option>
                      <option value="2025">2025</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <input type="search" className="form-control" name="filter" placeholder="Search by Title, Author, Keywords, Tags" />
                  </div>
                  <div className="col-auto text-center">
                    <button className="btn btn-primary d-flex-mi me-2 " type="submit">
                      <i className="material-symbols-outlined fill" >search</i>
                    </button>
                    <button className="btn btn-primary d-flex-mi " type="reset">
                      Clear Filter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Collapse>
          <Row xs={1} md={3} className="gx-2 gy-0">
            {Array.from({ length: 6 }).map((_, idx) => (
              <Col key={idx}>
                <Card className="">
                  <Card.Header className="border-bottom-0">
                    <div className="d-flex align-items-center avatar-50">
                      <BadgeAvatar size="40" alt="User Avatar" src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} />
                      {/* <img src={localStorage.getItem('bucketName')+"/images/profile_picture/54951603485876.png"} className="rounded-circle" /> */}
                      <a href="javascript:void(0);"><div className="ms-3 miw-100">
                        <h6 className="mb-0">APPG AI Secretariat</h6>

                      </div></a>                    </div>
                  </Card.Header>
                  <Card.Img className="rounded mx-0 py-2" variant="top" src={`https://source.unsplash.com/random/250x335/?sig=${idx}`} />
                  {/* <Card.ImgOverlay >
        <Card.Title>Card title</Card.Title>
        <Card.Text>
          This content is a little bit longer.
        </Card.Text>
        <Card.Text>Last updated 3 mins ago</Card.Text>
      </Card.ImgOverlay> */}
                  <Card.Body>

                    <ListGroup>
                      <ListGroup.Item className="d-flex justify-content-start border-0 px-0 align-items-center ">
                        <CategoryIcon />&nbsp;Citizen Participation</ListGroup.Item>
                      <ListGroup.Item className="d-flex justify-content-start border-0 px-0 align-items-center">
                        <div><EventIcon />&nbsp;<strong>27 September 2023</strong>
                        </div></ListGroup.Item>
                      <ListGroup.Item action href="https://bicpavilion.com/librarydetail/appg-artificial-intelligence/tipping-point-on-the-edge-of-superintelligence-123" className="d-flex justify-content-start border-0 px-0 align-items-center">
                        Tipping Point - On the Edge of Superintelligence
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer className="mx-0 px-2 d-flex justify-content-between border-top-0">
                    <Button variant="outline-primary" size="sm">View Detail</Button>
                    <Button variant="outline-primary" size="sm">Add to My Resources</Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>



        </Tab>
        <Tab eventKey="profile" title="Admin Files">
          <div className="border-0 d-flex justify-content-between bg-toggle rounded-bottom shadow-sm px-3 py-2 mb-2">
            <h5 className="d-flex-mi mb-0"><NewspaperIcon />
              Pavilion Level
            </h5>
            <div className="d-flex ">
              <button className="btn btn-link p-0  text-dark me-1" type="button">
                <SortIcon />
              </button>
            </div>
          </div>
        </Tab>

      </Tabs>
      <Modal show={show} backdrop="static" keyboard={false} size="lg" onHide={handleParentClose}  centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title >Add New Library</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
       <Row>
        <Col md={4}> 
        <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {/* <Box my={2} >
          <img src={image}  alt="cropped" height={200} />
        </Box> */}

       <Upload image={image}
          getUploadedFile={(image) => {
            setOpen(true);
            setImage(image);
          }}
        />
        <Popup
          open={open}
          handleClose={handleClose}
          image={image}
          getCroppedFile={(image) => {
            setImage(image);
            handleClose();
          }}
        />
      </Box>
    </div>
        <div>
        <div className="box" style={{ width: "50%", float: "right" }}>
          {/* <h1>Preview</h1> */}
          <div
            className="img-preview"
            style={{ width: "100%", float: "left", height: "300px" }}
          />
        </div>
        </div>
        {/* <div>{output && <img src={output} />}</div> */}

      {/* <img id="libraryBanner" onClick={onImageClick} src={fileUrl} /> */}
    {/* </ReactCrop> */}
        {/* <img className="card-img-top rounded mx-0 py-2" src="https://source.unsplash.com/random/250x335/?sig=0"/> */}
        </Col>
        <Col md={8}> Form</Col>
        </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleParentClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleParentClose}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default LibraryTab
