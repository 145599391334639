import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts/Layout'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from '@mui/material';
import { Container, Card } from 'react-bootstrap';
import http from '../http';

function Pricing({handleClick}) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [monthly, setMonthlyPlan] = useState([]);
    const [annually, setAnnuallyPlan] = useState([]);

    const [duration, setDuration] = useState('month');

    // const sw = document.getElementById('toggleSwitch');

    const [checked, setChecked] = useState(false);

    const handleChange = (e) => {
        setChecked(!checked);


        if (checked) {
            setDuration('month');
        } else {
            setDuration('year');
        }

    };
    useEffect(() => {
        http().get('/plans').then((data) => {
        //   console.log(data.data.data.plans);
          setMonthlyPlan(data.data.data.plans.monthly);
          setAnnuallyPlan(data.data.data.plans.annually.reverse());
        });
      }, []);

    return (

                    <Container className="mb-0 bg-light text-center pt-0" >
                <div className="d-flex flex-row justify-content-center">Monthly
                    <Form.Check // prettier-ignore
                        type='switch'
                        id='toggleSwitch'
                        className="text-center mx-5"
                        style={{ transform: 'scale(1.8)' }}
                        checked={checked}
                        onChange={handleChange}
                    /> Annually
                </div>
                <div className="container mt-2 col-xxl-8 col-12 px-0 pt-5 gap-4 d-flex flex-column flex-md-row aling-items-center justify-content-center">
             {duration==='month' && (<>
                {monthly.map((innerArray, outerIndex) => (
                    <Card className="mb-4 shadow-sm col-12 col-md-6">
                        <Card.Header className="text-center bg-toggle">
                            <h3 className="my-0 font-weight-normal text-primary">
                                {innerArray.name}
                            </h3>
                        </Card.Header>
                        <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                            <div className='text-center'>
                                <Card.Title className="fw-bold pricing-card-title text-primary fs-1" id="price1">
                                <small className="text-muted">£</small> {Number(innerArray.price).toLocaleString()} <small className="text-muted">/ {innerArray.type==='monthly' && 'month'}</small>
                                </Card.Title>
                                <div className="text-start d-flex flex-column align-items-between justify-content-between" dangerouslySetInnerHTML={{ __html: innerArray.description }}>
                                    {/* <ul className=" mt-3 mb-4">
                                        <li>Set up a social-media, open-project website on Pavilion 3.0</li>
                                        <li>Community made up by people with different types of roles.</li>
                                        <li>Branding and Image design for your visual look.</li>
                                        <li>Hosted on AWS server</li>
                                        <li>Self-paced knowledge video tutorials for onboarding your own pavilion.</li>
                                        <li>Book for standard assistance in setting up your Pavilion for 30 minutes slot.</li>
                                        <li>Access to the ticketing system to manage inquiries/helpline</li>
                                    </ul> */}
                                </div>
                            </div>
                            <button onClick={event => handleClick(innerArray.id, innerArray.name,innerArray.type,innerArray.price)}
                                className="btn btn-lg btn-block btn-primary"
                                type="button"
                            >
                                BUY NOW
                            </button>
                        </Card.Body>
                    </Card>
                    ))}
                    </>  )}
             {duration==='year' && (<>
                {annually.slice().reverse().map((innerArray, outerIndex) => (
                    <Card className="mb-4 shadow-sm col-12 col-md-6">
                        <Card.Header className="text-center bg-toggle">
                            <h3 className="my-0 font-weight-normal text-primary">
                                {innerArray.name}
                            </h3>
                        </Card.Header>
                        <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                            <div className='text-center'>
                                <Card.Title className="fw-bold pricing-card-title text-primary fs-1" id="price1">
                                <small className="text-muted">£</small> {Number(innerArray.price).toLocaleString()} <small className="text-muted">/ {innerArray.type ==='annually' && 'year'}</small>
                                </Card.Title>
                                <div className="text-start d-flex flex-column align-items-between justify-content-between" dangerouslySetInnerHTML={{ __html: innerArray.description }}>
                                    {/* <ul className=" mt-3 mb-4">
                                        <li>Set up a social-media, open-project website on Pavilion 3.0</li>
                                        <li>Community made up by people with different types of roles.</li>
                                        <li>Branding and Image design for your visual look.</li>
                                        <li>Hosted on AWS server</li>
                                        <li>Self-paced knowledge video tutorials for onboarding your own pavilion.</li>
                                        <li>Book for standard assistance in setting up your Pavilion for 30 minutes slot.</li>
                                        <li>Access to the ticketing system to manage inquiries/helpline</li>
                                    </ul> */}
                                </div>
                            </div>
                            <button onClick={event => handleClick(innerArray.id, innerArray.name,innerArray.type,innerArray.price)}
                                className="btn btn-lg btn-block btn-primary"
                                type="button"
                            >
                                BUY NOW
                            </button>
                        </Card.Body>
                    </Card>
                    ))}
               </> )}
                    {/* <Card className="mb-4 shadow-sm box-shadow col-12 col-md-6">
                        <Card.Header className="text-center bg-toggle">
                            <h3 className="my-0 font-weight-normal text-primary">
                                PREMIUM
                            </h3>
                        </Card.Header>
                        <Card.Body className="text-center ">
                            <div className="text-start d-flex flex-column justify-content-between align-items-center">
                                <div className='text-center'>
                                    <Card.Title className="fw-bold pricing-card-title text-primary fs-1" id="price2">
                                        {price2} <small className="text-muted">/ {duration}</small>
                                    </Card.Title>
                                    <ul className="text-start mt-3 mb-4">

                                        <li>Set up a social-media, open-project website on Pavilion 3.0</li>
                                        <li>Community made up by people with different types of roles.</li>
                                        <li>Branding and Image design for your visual look.</li>
                                        <li>Hosted on AWS server</li>
                                        <li>Self-paced knowledge video tutorials for onboarding your own pavilion.</li>
                                        <li>Book for premium assistance in setting up your Pavilion for 60 minutes slot.</li>
                                        <li>Access to the ticketing system to manage inquiries/helpline</li>
                                        <li>Add on knowledge Transfer session to show you how to manage the website yourself.</li>
                                        <li>A dedicated relationship person who can support your inquiries regarding your own Pavilion</li>
                                    </ul>
                                </div>
                                <button onClick={event => handleClick('Premium',{duration},{price2})}
                                    className="btn btn-lg btn-block btn-primary "
                                    type="button"
                                >
                                    BUY NOW
                                </button>
                            </div>

                        </Card.Body>
                    </Card> */}
                </div>

            </Container>
   
    )
}

export default Pricing
