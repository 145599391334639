import React from 'react'
import PavilionLayout from '../../../components/layouts/PavilionLayout'
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import Group from '@mui/icons-material/Group';
import PavilionNavBar from '../../../components/pavilion/PavilionNavBar';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Container, Stack, ListGroup, Image, Row, Col } from 'react-bootstrap'
import CommunityEngagementBox from '../../../components/pavilion/Boxes/CommunityEngagementBox';
import InfoIcon from '@mui/icons-material/Info';
import Banner from '../../../assets/img/banner-1.png'
import { Grid } from '@mui/material';

function AboutTab() {

  return (

    <Container  className="mt-2">
    
    <Container className="mx-0 px-0">
        
        <Row>
          <Col className="px-0">
          <ListGroup className="mb-2 px-0 mx-0 g-0 shadow-sm overflow-hidden">
            <ListGroup.Item className="bg-linear-gradient-primary ">
              <Grid container className=" mb-0" direction="row" alignItems="center">
                <InfoIcon />&nbsp; <strong>About APPG Pavilion</strong>
              </Grid>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col >
                  <h5 className="fw-bold">Purpose:</h5>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} >
                  <p>The All-Party Parliamentary Group on Artificial Intelligence (APPG AI) was set up in January 2017, with the aim to explore the impact and implications of Artificial Intelligence.</p>

                  <p>All-Party Parliamentary Groups (APPGs) are informal all-party groups in UK Parliament. They are run by and for Members of the Commons and Lords. The APPG AI functions as the permanent authoritative voice within UK Parliament (House of Commons and House of Lords) on all AI-related matters.</p>
                </Col>
                <Col sm={12} md={6} className="text-center">
                  <Image className="rounded" src={localStorage.getItem('bucketName')+"/images/paviion_purpose/thumbnails/59881669685236.png"} />
                </Col>
              </Row>
              <Row >
                <Col >
                  <h5 className="fw-bold">Further Details:</h5>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col className="text-center" xs={12} md={6}>
                  <Image className="rounded" src={localStorage.getItem('bucketName')+"/images/paviion_purpose/thumbnails/59881669685236.png"} />
                </Col>
                <Col sm={12} md={6}>
                  <p>The All-Party Parliamentary Group on Artificial Intelligence (APPG AI) was set up in January 2017, with the aim to explore the impact and implications of Artificial Intelligence.</p>

                  <p>All-Party Parliamentary Groups (APPGs) are informal all-party groups in UK Parliament. They are run by and for Members of the Commons and Lords. The APPG AI functions as the permanent authoritative voice within UK Parliament (House of Commons and House of Lords) on all AI-related matters.</p>
                </Col>

              </Row>
              <Row>
                <Col>
                <h5 className="fw-bold">Keywords:</h5>
                  <hr />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  
                  #Artificial Intelligence
                </Col>
              </Row>

            </ListGroup.Item>
          </ListGroup>
          </Col>
        </Row>
        <Row className="" gap={2}>
          <Col sm={12} md={8} className="px-0 pe-md-2">
            <ListGroup>
              <ListGroup.Item className="bg-linear-gradient-primary">
                <strong>Featured Offerings</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col md={4} className="d-flex align-items-center justify-content-center">
                    <Image className="w-100" src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/85291662484318.png"}></Image>
                  </Col>
                  <Col md={8}>
                  <h5 className="text-primary">Member One Year</h5>
                  <p>Individual APPG AI Membership is £250 per year.</p>
                  <p>Benefits:</p>
                  <ul>
                    <li>Priority booking to audience attendance for evidence sessions and selected events.</li>
                    <li>Be on the guest list to receive an invite for the private annual reception.</li>
                    <li>Access resources: publications, video recordings, publications, photo gallery, Discussion Wall.</li>
                  </ul>
                  <Button className="btn btn-primary">Go to Offer</Button>
                  </Col>
                </Row>
                </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col md={4} className="d-flex align-items-center justify-content-center">
                    <Image className="w-100" src={localStorage.getItem('bucketName')+"/images/publication/thumbnails/57791692002009.png"}></Image>
                  </Col>
                  <Col md={8}>
                  <h5 className="text-primary">Industrial Policy & Artificial Intelligence: What Should our AI Industrial Strategy be?
</h5>
                  <p>Parliamentary Brief from evidence session on 15th May 2023.</p>
                  <Button className="btn btn-primary">Go to Offer</Button>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col md={4} className="d-flex align-items-center justify-content-center">
                    <Image className="w-100" src={localStorage.getItem('bucketName')+"/images/publication/thumbnails/65851669671928.png"}></Image>
                  </Col>
                  <Col md={8}>
                  <h5 className="text-primary">Evidence to the Science and Technology Committee 2022</h5>
                  <p>Enquiry into the Governance of Artificial Intelligence</p>
                  <Button className="btn btn-primary">Go to Offer</Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col sm={12} md={4} className="px-0">
            <CommunityEngagementBox />
            <ListGroup className="mt-2">
              <ListGroup.Item className="bg-linear-gradient-primary">
                <strong>Pavilion Trending</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                Pavilion Trending
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>

    </Container>

  </Container>

  )
}

export default AboutTab
