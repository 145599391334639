import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { formatDate } from "../../../components/PavilionConstants";
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import Card from "@mui/material/Card";
import { CardMedia } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Badge from "react-bootstrap/Badge";
import { DateDiff } from "../../../components/PavilionConstants";
import { Pagination, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CRUDDropdown from "../../../components/CRUDDropdown";
import Swal from "sweetalert2";
import adminhttp from "../../../adminhttp";
import { getPermissions } from "../../utils/utils";

const cardStyle = {
  color: "white",
  backgroundColor: "#e30613!important",
};
const RoleCard = (props) => {
  const location = useLocation();
  const data = location.state;
  const data2 = {
    name: data.name,
    rolename: props.data.name,
  }

  const navigate = useNavigate();
  const splitURL = location.pathname.toString().split("/");
  const [permissions, setPermissions] = useState({});
  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = () => {
    //setShow(true)
  };
  const handleDropdown = () => {
    //setShow(true)
  };

  const handleStatus = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: `Are you sure to ${
        props.data.status === 1 ? "Deactive" : "Activate"
      } this Role?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      width: "auto",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: `Yes, ${
        props.data.status === 1 ? "Deactive" : "Activate"
      } this role!`,
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp()
          .post("/admin/update_role_status", {
            role_id: props.data.id,
            pavilion_id: splitURL[3],
            status: props.data.status === 1 ? 0 : 1,
          })
          .then((data) => {
            Swal.fire({
              title: `Role has been ${
                props.data.status === 1 ? "Deactivated" : "Activated"
              }.`,
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            }).then((result) => {
              if (result["isConfirmed"]) {
                props.handleRequest();
              }
            });
          });
      }
    });
  };

  useEffect(() => {
    getPermissions().then((res)=> (setPermissions(res)))
  },[permissions]);

  return (
    <Card className={`rounded-3 bg-opacity-10 ${props.data.status===1 ? ' bg-primary ' : ' bg-secondary'}`}  sx={{":hover": { boxShadow: 5 } }}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "120px", position: "relative" }}
      >
        <CardContent className="p-2 d-flex justify-content-center align-items-center">
          <Typography variant="h5" component="div">
            {props.data.name}
          </Typography>
        </CardContent>
        <div
          className="text-end p-2 rounded-5"
          style={{
            backgroundColor: "",
            position: "absolute",
            color: "white",
            top: 0,
            right: "0",
            transform: "translateX(0)",
          }}
        >
          <CRUDDropdown
            title={<MoreVertIcon fontSize="small" />}
            handleDropdown={handleDropdown}
            addTitle="Edit Role"
            addClick={props.handleEdit(props.data.id)}
            editTitle={props.data.status === 1 ? "Deactivate" : "Activate"}
            editClick={handleStatus}
            deleteTitle={permissions['view-pavilion-acl'] && 'ACL'}
            deleteClick={() => navigate(`${props.data.id}`, { state: data2 })}
          />
        </div>
      </div>
    </Card>
  );
};

export default RoleCard;
