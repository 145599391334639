import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ReplyIcon from '@mui/icons-material/Reply';
import { IconButton } from "@mui/material";
import InputEmoji from 'react-input-emoji'

// import { Picker } from "emoji-mart";
// import "emoji-mart/css/emoji-mart.css";

const getNewComment = (commentValue, isRootNode = false, parentNodeId) => {
  return {
    id: uuidv4(),
    commentText: commentValue,
    childCommments: [],
    isRootNode,
    parentNodeId,
  };
};

const initialState = {};

function CommentsBox() {
  const [comments, setComments] = useState(initialState);
  const [rootComment, setRootComment] = useState("");
  
    const addComment = (parentId, newCommentText) => {
    let newComment = null;
    if (parentId) {
      newComment = getNewComment(newCommentText, false, parentId);
      setComments((comments) => ({
        ...comments,
        [parentId]: {
          ...comments[parentId],
          childCommments: [...comments[parentId].childCommments, newComment.id],
        },
      }));
    } else {
      newComment = getNewComment(newCommentText, true, null);
    }
    setComments((comments) => ({ ...comments, [newComment.id]: newComment }));
  };
  const commentMapper = (comment) => {
    return {
      ...comment,
      childCommments: comment.childCommments
        .map((id) => comments[id])
        .map((comment) => commentMapper(comment)),
    };
  };
  const enhancedComments = Object.values(comments)
    .filter((comment) => {
      return !comment.parentNodeId;
    })
    .map(commentMapper);
  const onAdd = () => {
    addComment(null, rootComment);
    setRootComment("");
  };
  return (
    <div className="">
      {/* <header style={{marginBottom : '2rem', fontSize : '2rem'}}>Nested Comment Example</header> */}
      <div className="comments-container w-100 ">
        {/* <input 
        className="form-control btn-group-item"
          type="text"
          value={rootComment}
          onChange={(e) => setRootComment(e.target.value)}
          placeholder="add comment"
          style={{ width: "80%", marginRight: "1rem" }}
        />{" "}
        <button className="btn btn-primary btn-sm btn-group-item" onClick={onAdd}>Add</button> */}
        <InputGroup size="sm" className="mb-3">
        <Form.Control
            value={rootComment}
            onChange={(e) => setRootComment(e.target.value)}
          placeholder="add comments"
        />
        <Button variant="outline-secondary" id="button-addon2" onClick={onAdd}>Add</Button>
      </InputGroup>

      </div>
      <div
        style={{
          border: "1px solid gray",
          width: "100%",
          margin: "auto",
          overflowX: "auto",
          padding: "1rem",
        }}
      >
        {enhancedComments.map((comment, key) => {
          return (
            <Comment key={key} comment={comment} addComment={addComment} />
          );
        })}
      </div>
    </div>
  );
}

const Comment = ({ comment, addComment }) => {
  const { commentText, childCommments, id } = comment;
  const [childComment, setChildComment] = useState("");
  const [show, setShow] = useState(true);
  const [showAddComponet, setShowAddComponet] = useState(false);
  const [ text, setText ] = useState('')
  
  function handleOnEnter (text) {
    console.log('enter: ', text)
  }

  const onAdd = () => {
    addComment(id, childComment);
    setChildComment("");
    setShowAddComponet(false);
  };
  return (
    <div className="Comment">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ textAlign: "left" }}>{commentText}</div>
        &nbsp;
        {childCommments.length > 0 && ( 
        <IconButton variant="primary" className="p-1" size="small" onClick={() => setShow((show) => !show)}>
            {show ? <VisibilityIcon size="sm"/> : <VisibilityOffIcon size="sm"/>}
        </IconButton>
        // <button className="btn btn-primary btn-sm" onClick={() => setShow((show) => !show)}>
        //     {show ? <VisibilityIcon/> : <VisibilityOffIcon/>}
        //   </button>
        )}
      </div>
      <div>
        <div>
          {showAddComponet ? (
            <>
            <InputGroup size="sm" className="mb-3">
            <InputEmoji className="form-control"
          value={text}
          onChange={setText}
          cleanOnEnter
          onEnter={handleOnEnter}
          placeholder="Type a message"
        />
        {/* <Form.Control
         type="text"
          onChange={(e) => setInput(e.target.value)}
            value={input} 
          placeholder="add comments"
        /> */}
        <Button variant="outline-secondary" id="button-addon2" onClick={onAdd}>Submit</Button>
        {/* <Button variant="outline-secondary" id="button-addon2" onClick={(e) => setShowEmojis(true)}>S</Button> */}
        {/* {showEmojis && ( <Picker onSelect={addEmoji} /> ) } */}
      </InputGroup>
      
              {/* <input
                type="text"
                className="form-control"
                value={childComment}
                onChange={(e) => setChildComment(e.target.value)}
                placeholder="add comment"
              />{" "}
              <button className="btn btn-primary btn-sm" onClick={onAdd}>Submit</button> */}
            </>
          ) : (
            <a className="btn btn-link btn-sm"
              style={{ cursor: "pointer", fontSize: "0.7rem", color: "black" }}
              onClick={() => setShowAddComponet(true)}
            >
              <ReplyIcon size="sm"/>
            </a>
          )}
        </div>
      </div>
      {show &&
        childCommments.map((childCommentEl, key) => {
          return (
            <Comment
              key={key}
              comment={childCommentEl}
              addComment={addComment}
            />
          );
        })}
    </div>
  );
};

export default CommentsBox;
