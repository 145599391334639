import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useHash } from '../components/useHash';
import { scrollToSection } from '../utils/utils';
function Footer() {
    return (
        <footer className="text-center text-bg-secondary aos-init" data-aos="">
            <div className="container">
                <section className="mt-0">
                    <div className="row text-center d-flex justify-content-center pt-3">
                        <div className="col-md-2">
                            <h6 className="text-uppercase font-weight-bold">
                                <Link to="/" className="text-white text-decoration-none">Home</Link>
                            </h6>
                        </div>
                        <div className="col-md-2">
                            <h6 className="text-uppercase font-weight-bold">
                                {/* <Link to="https://bicpavilion.com/#allpavilions" className="text-white text-decoration-none">All Pavilions</Link> */}
                                <HashLink to="/#allpavilions" onClick={() => scrollToSection('allpavilions')}>All Pavilions</HashLink>
                            </h6>
                        </div>
                        <div className="col-md-2">
                            <h6 className="text-uppercase font-weight-bold">
                                <Link to="/plans" className="text-white text-decoration-none">Launch My Pavilion</Link>
                            </h6>
                        </div>
                        <div className="col-md-2">
                            <h6 className="text-uppercase font-weight-bold">
                                <Link to="https://www.youtube.com/channel/UCpvImeYUgu-UAHyjRKDaPTA" target='_blank' className="text-white text-decoration-none">'How To' videos</Link>
                            </h6>
                        </div>
                        <div className="col-md-2">
                            <h6 className="text-uppercase font-weight-bold">
                                <Link to="/contact" className="text-white text-decoration-none">Contact</Link>
                            </h6>
                        </div>
                    </div>
                </section>
                <hr className="my-3"/>
                <section className="mb-2">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12">
                                <p>Pavilion Platform is part of Big Innovation Centre in all global regions. <br className="d-none"/>Pavilion Platform is registered in England and Wales No. 11556856</p>
                            </div>
                        </div>
                    </section>
                    <section className="text-center mb-3">
                        <ul className="nav justify-content-center">
                            <li className="nav-item">
                                <a href="https://www.linkedin.com/company/biginnovcentre/" target="_blank" className="nav-link px-2 text-red">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 32 32" styles=" fill:#fff;"><path d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z"></path></svg>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="https://www.youtube.com/channel/UCpvImeYUgu-UAHyjRKDaPTA" target="_blank" className="nav-link px-2 text-muted">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50" styles=" fill:#fff;"><path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path></svg>
                                </a>
                            </li>
                        </ul>
                    </section>
            </div>
            <div className="text-center p-3 bg-dark" >
                © Copyright  - <a className="text-white text-decoration-none" href="https://bicpavilion.com/">Pavilion Platform Ltd.</a> - All rights reserved. - <a href="#" className="text-white text-decoration-none">Privacy Policy</a>
            </div>
        </footer>
        
    )
}

export default Footer
