import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom'


function PavilionNavBar() {
  return (

<Navbar expand="lg" className="bg-light rounded-bottom">
      <Container className="d-flex flex-column justify-content-center">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="about">About</Nav.Link>
            <Nav.Link as={NavLink} to="wallpost">Wall Post</Nav.Link>
            <Nav.Link as={NavLink} to="members">Members</Nav.Link>
            <NavDropdown  title="Events" id="basic-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="events">View All</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.2">Evidence Meetings</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Advisory Board Meetings</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">Dinners</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.5">Annual Reception</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.6">Fringe Events</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="publications">Publications</Nav.Link>
            <Nav.Link as={NavLink} to="polls">Polls</Nav.Link>
            <NavDropdown title="Media Centre" id="basic-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="mcpress">Press</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="mcvideos">Videos</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="mcphotos">Photos</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="library">Library</Nav.Link>
            <Nav.Link as={NavLink} to="wallgallery">Wall Gallery</Nav.Link>
            <Nav.Link as={NavLink} to="shop">Shop</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    // <nav className="navbar navbar-expand-lg navbar-default  p-0  mt-0 mb-2 rounded-bottom shadow-sm ">
    //         <div className="container-fluid py-2 text-center">
    //           <a className="navbar-brand d-block d-lg-none" href="#">Menu</a>
    //           <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
    //             data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    //             aria-expanded="false" aria-label="Toggle navigation">
    //             <span className="navbar-toggler-icon"></span>
    //           </button>
    //           <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
    //             <ul className="navbar-nav me-0 mb-2 mb-lg-0">
    //               <li className="nav-item">
    //                 <a className="nav-link  active" aria-current="page" href="http://localhost/pavilion5/about.php">About</a>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link" href="http://localhost/pavilion5/pavilion-wall.php">Pavilion Wall</a>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link" href="http://localhost/pavilion5/members.php">Members</a>
    //               </li>
    //               <li className="nav-item dropdown">
    //                 <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
    //                   aria-expanded="false">
    //                   Events
    //                 </a>
    //                 <ul className="dropdown-menu">
    //                   <li><a className="dropdown-item" href="http://localhost/pavilion5/events.php">View All</a></li>
    //                   <li>
    //                     <hr className="dropdown-divider"/>
    //                   </li>
    //                   <li><a className="dropdown-item" href="#">Conference</a></li>
    //                   <li><a className="dropdown-item" href="#">Meet-Ups</a></li>
    //                   <li><a className="dropdown-item" href="#">Celebrations</a></li>
    //                 </ul>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link" href="http://localhost/pavilion5/publications.php">Publications</a>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link ">Polls & Surveys</a>
    //               </li>
    //               <li className="nav-item dropdown">
    //                 <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
    //                   aria-expanded="false">
    //                   Media Center
    //                 </a>
    //                 <ul className="dropdown-menu">
    //                   <li><a className="dropdown-item" href="#">Press</a></li>
    //                   <li><a className="dropdown-item" href="#">Videos</a></li>
    //                   <li><a className="dropdown-item" href="#">Photos</a></li>
    //                 </ul>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link" href="http://localhost/pavilion5/library.php">Library</a>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link ">Gallery</a>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link ">Shop</a>
    //               </li>
    //             </ul>
    //             {/* <!-- <ul className="navbar-nav me-0 mb-2 mb-lg-0">
    //               <li className="nav-item dropdown">
    //                 <a className="nav-link px-0 dropdown-toggle d-flex-mi" href="#" role="button"
    //                   data-bs-toggle="dropdown" aria-expanded="false">
    //                   <i className="material-symbols-outlined md-24">search</i>
    //                 </a>
    //                 <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-end">
    //                   <li>
    //                     <a className="dropdown-item  px-2 py-0" href="#">
    //                       <form className="d-flex" role="search">
    //                         <input className="form-control " type="search" placeholder="Search"
    //                           aria-label="Search">
    //                       </form>
    //                     </a>

    //                   </li>
    //                 </ul>
    //               </li>

    //             </ul> --> */}

    //           </div>
    //         </div>
    //       </nav>
  )
}

export default PavilionNavBar
