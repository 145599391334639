// import * as React from "react";
import React, { useState, useEffect , useRef} from 'react'

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CropperDemo from "./Cropper";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Popup({ open, image, handleClose, getCroppedFile, aspectRatio }) {
  
  return (
    <div>
      
      <Dialog 
        open={open}
        // onClose={handleClose}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='d-flex justify-content-between align-items-center'>
          <h4>Crop Image </h4>
          <IconButton onClick={handleClose}>
            <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CropperDemo 
              handleClose={handleClose}
              src={image}
              getCroppedFile={getCroppedFile}
              aspectRatio={aspectRatio}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
