import { AccountBalanceOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import Group from '@mui/icons-material/Group';

function ProgramDetailsBox(props) {

    const [show, setShow] = useState(props.showMe);

    return (
        <>
        { show && (<Container fluid className="px-1">
        <ListGroup className="mb-3 shadow-sm">
        <ListGroup.Item className="active text-center bg-linear-gradient-primary  pt-2 pb-1">
            <h5 className="accordion-header active mb-0 d-flex-mi">
                {/* <i className="material-symbols-outlined" style="visibility: visible;">group</i> */}
        <Group/>&nbsp;Insights</h5>
        </ListGroup.Item>
        <ListGroup.Item className="pb-0">           
            <h6 className="my-0 text-primary  ">Principal Host:</h6>
            <div className="mb-0 mt-2">
                <ul className="px-3 ">
                                                <li className="h6">Pavilion Platform Limited</li>
                                            </ul>
            </div>
        </ListGroup.Item>        
        <ListGroup.Item className="pb-0">
            <h6 className="my-0 text-primary ">Secretariat:</h6>
            <div className="mb-0 mt-2">
                <ul className="px-3">
                                                <li className="h6">Pavilion Platform Limited</li>
                                            </ul>
            </div>
        </ListGroup.Item>
        <ListGroup.Item className="pb-0">                
            <h6 className="my-0 text-primary ">Type of Members:</h6>
            <div className="mb-0 mt-2 ">
                <ul className="px-3">
                                                <li className="h6">All Pavilion Participants</li>
                                            </ul>
            </div>
        </ListGroup.Item>   
        <ListGroup.Item className="pb-0">     
            <h6 className="my-0 text-primary ">Contact Us:</h6>
            <div className="mb-0 mt-2 ">
                <ul className="px-3">
                                                <li className="text-break h6 text-wrap">members@biginnovationcentre.com</li>
                                            </ul>
            </div>            
        </ListGroup.Item>
        </ListGroup>

        </Container>
        )}
        </>
    )
}

export default ProgramDetailsBox
