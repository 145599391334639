import React, { useState } from 'react'
import { Button, ListGroup, Collapse, Container } from 'react-bootstrap'
import TuneIcon from '@mui/icons-material/Tune';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar } from '@mui/material';
import WorkIcon  from '@mui/icons-material/Work';
import InfoIcon  from '@mui/icons-material/Info';
import Pagination from 'react-bootstrap/Pagination';
import BadgeAvatar from '../../../components/BadgeAvatar';

function MembersTab() {
  const [open, setOpen] = useState(false);
  return (
    <Container className="mt-2 px-0">
      <div className="border-0 d-flex justify-content-between bg-toggle rounded shadow-sm px-2 py-2 mb-2 mx-0">
        <h5 className="d-flex-mi mb-0"><GroupIcon />&nbsp;Community</h5>
        <div>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-fade-text"
            aria-expanded={open} className='px-2 py-1 me-1'
          >
            <TuneIcon />
          </Button>
        </div>
      </div>
      <Collapse in={open}>
        <div id="example-fade-text" className="bg-toggle p-4 rounded shadow-sm mb-2" >
          <form method="get" className="search-form publications-search-filters members-bg-gray">

            <div className="row d-flex justify-content-center flex-wrap align-items-center g-3">
              <div className="col-4">
                <input type="text" className="form-control" placeholder="Name" name="name" id="name" onkeyup="updateSelectContain();" value="" />
              </div>
              <div className="col-4">
                <input type="text" className="form-control" placeholder="Organization" name="organization" value="" />
              </div>
              <div className="col-4">
                <select name="name_contains" id="name_contains" className="form-select">
                  <option value="">Name starts/ends/contains</option>
                  <option value="start">Name Starts with </option>
                  <option value="end">Name Ends with </option>
                  <option value="contain">Name contains </option>
                </select>
              </div>
              <div className="col-4">
                <select name="role_id" id="role_id" className="form-select">
                  <option value="">All Roles</option>
                  <option value="37">Co-Chairs</option>
                  <option value="38">Vice Chairs</option>
                  <option value="39">Advisory Board</option>
                  <option value="41">Parliament Members</option>
                  <option value="44">Secretariat</option>
                  <option value="106">Member</option>
                </select>
              </div>
              <div className="col-4">
                <select name="country_id" className="form-select">
                  <option value="">All Countries</option>
                  <option value="1">Afghanistan</option>
                  <option value="2">Aland Islands</option>
                  <option value="3">Albania</option>
                  <option value="4">Algeria</option>
                  <option value="5">American Samoa</option>
                  <option value="6">Andorra</option>
                  <option value="7">Angola</option>
                  <option value="8">Anguilla</option>
                  <option value="9">Antarctica</option>
                  <option value="10">Antigua And Barbuda</option>
                  <option value="11">Argentina</option>
                  <option value="12">Armenia</option>
                  <option value="13">Aruba</option>
                  <option value="14">Australia</option>
                  <option value="15">Austria</option>
                  <option value="16">Azerbaijan</option>
                  <option value="17">Bahamas The</option>
                  <option value="18">Bahrain</option>
                  <option value="19">Bangladesh</option>
                  <option value="20">Barbados</option>
                  <option value="21">Belarus</option>
                  <option value="22">Belgium</option>
                  <option value="23">Belize</option>
                  <option value="24">Benin</option>
                  <option value="25">Bermuda</option>
                  <option value="26">Bhutan</option>
                  <option value="27">Bolivia</option>
                  <option value="28">Bosnia and Herzegovina</option>
                  <option value="29">Botswana</option>
                  <option value="30">Bouvet Island</option>
                  <option value="31">Brazil</option>
                  <option value="32">British Indian Ocean Territory</option>
                  <option value="33">Brunei</option>
                  <option value="34">Bulgaria</option>
                  <option value="35">Burkina Faso</option>
                  <option value="36">Burundi</option>
                  <option value="37">Cambodia</option>
                  <option value="38">Cameroon</option>
                  <option value="39">Canada</option>
                  <option value="40">Cape Verde</option>
                  <option value="41">Cayman Islands</option>
                  <option value="42">Central African Republic</option>
                  <option value="43">Chad</option>
                  <option value="44">Chile</option>
                  <option value="45">China</option>
                  <option value="46">Christmas Island</option>
                  <option value="47">Cocos (Keeling) Islands</option>
                  <option value="48">Colombia</option>
                  <option value="49">Comoros</option>
                  <option value="50">Congo</option>
                  <option value="51">Congo The Democratic Republic Of The</option>
                  <option value="52">Cook Islands</option>
                  <option value="53">Costa Rica</option>
                  <option value="54">Cote D'Ivoire (Ivory Coast)</option>
                  <option value="55">Croatia (Hrvatska)</option>
                  <option value="56">Cuba</option>
                  <option value="57">Cyprus</option>
                  <option value="58">Czech Republic</option>
                  <option value="59">Denmark</option>
                  <option value="60">Djibouti</option>
                  <option value="61">Dominica</option>
                  <option value="62">Dominican Republic</option>
                  <option value="63">East Timor</option>
                  <option value="64">Ecuador</option>
                  <option value="65">Egypt</option>
                  <option value="66">El Salvador</option>
                  <option value="67">Equatorial Guinea</option>
                  <option value="68">Eritrea</option>
                  <option value="69">Estonia</option>
                  <option value="70">Ethiopia</option>
                  <option value="71">Falkland Islands</option>
                  <option value="72">Faroe Islands</option>
                  <option value="73">Fiji Islands</option>
                  <option value="74">Finland</option>
                  <option value="75">France</option>
                  <option value="76">French Guiana</option>
                  <option value="77">French Polynesia</option>
                  <option value="78">French Southern Territories</option>
                  <option value="79">Gabon</option>
                  <option value="80">Gambia The</option>
                  <option value="81">Georgia</option>
                  <option value="82">Germany</option>
                  <option value="83">Ghana</option>
                  <option value="84">Gibraltar</option>
                  <option value="85">Greece</option>
                  <option value="86">Greenland</option>
                  <option value="87">Grenada</option>
                  <option value="88">Guadeloupe</option>
                  <option value="89">Guam</option>
                  <option value="90">Guatemala</option>
                  <option value="91">Guernsey and Alderney</option>
                  <option value="92">Guinea</option>
                  <option value="93">Guinea-Bissau</option>
                  <option value="94">Guyana</option>
                  <option value="95">Haiti</option>
                  <option value="96">Heard and McDonald Islands</option>
                  <option value="97">Honduras</option>
                  <option value="98">Hong Kong S.A.R.</option>
                  <option value="99">Hungary</option>
                  <option value="100">Iceland</option>
                  <option value="101">India</option>
                  <option value="102">Indonesia</option>
                  <option value="103">Iran</option>
                  <option value="104">Iraq</option>
                  <option value="105">Ireland</option>
                  <option value="106">Israel</option>
                  <option value="107">Italy</option>
                  <option value="108">Jamaica</option>
                  <option value="109">Japan</option>
                  <option value="110">Jersey</option>
                  <option value="111">Jordan</option>
                  <option value="112">Kazakhstan</option>
                  <option value="113">Kenya</option>
                  <option value="114">Kiribati</option>
                  <option value="115">Korea North</option>
                  <option value="116">Korea South</option>
                  <option value="117">Kuwait</option>
                  <option value="118">Kyrgyzstan</option>
                  <option value="119">Laos</option>
                  <option value="120">Latvia</option>
                  <option value="121">Lebanon</option>
                  <option value="122">Lesotho</option>
                  <option value="123">Liberia</option>
                  <option value="124">Libya</option>
                  <option value="125">Liechtenstein</option>
                  <option value="126">Lithuania</option>
                  <option value="127">Luxembourg</option>
                  <option value="128">Macau S.A.R.</option>
                  <option value="129">Macedonia</option>
                  <option value="130">Madagascar</option>
                  <option value="131">Malawi</option>
                  <option value="132">Malaysia</option>
                  <option value="133">Maldives</option>
                  <option value="134">Mali</option>
                  <option value="135">Malta</option>
                  <option value="136">Man (Isle of)</option>
                  <option value="137">Marshall Islands</option>
                  <option value="138">Martinique</option>
                  <option value="139">Mauritania</option>
                  <option value="140">Mauritius</option>
                  <option value="141">Mayotte</option>
                  <option value="142">Mexico</option>
                  <option value="143">Micronesia</option>
                  <option value="144">Moldova</option>
                  <option value="145">Monaco</option>
                  <option value="146">Mongolia</option>
                  <option value="147">Montenegro</option>
                  <option value="148">Montserrat</option>
                  <option value="149">Morocco</option>
                  <option value="150">Mozambique</option>
                  <option value="151">Myanmar</option>
                  <option value="152">Namibia</option>
                  <option value="153">Nauru</option>
                  <option value="154">Nepal</option>
                  <option value="155">Netherlands Antilles</option>
                  <option value="156">Netherlands The</option>
                  <option value="157">New Caledonia</option>
                  <option value="158">New Zealand</option>
                  <option value="159">Nicaragua</option>
                  <option value="160">Niger</option>
                  <option value="161">Nigeria</option>
                  <option value="162">Niue</option>
                  <option value="163">Norfolk Island</option>
                  <option value="164">Northern Mariana Islands</option>
                  <option value="165">Norway</option>
                  <option value="166">Oman</option>
                  <option value="167">Pakistan</option>
                  <option value="168">Palau</option>
                  <option value="169">Palestinian Territory Occupied</option>
                  <option value="170">Panama</option>
                  <option value="171">Papua new Guinea</option>
                  <option value="172">Paraguay</option>
                  <option value="173">Peru</option>
                  <option value="174">Philippines</option>
                  <option value="175">Pitcairn Island</option>
                  <option value="176">Poland</option>
                  <option value="177">Portugal</option>
                  <option value="178">Puerto Rico</option>
                  <option value="179">Qatar</option>
                  <option value="180">Reunion</option>
                  <option value="181">Romania</option>
                  <option value="182">Russia</option>
                  <option value="183">Rwanda</option>
                  <option value="184">Saint Helena</option>
                  <option value="185">Saint Kitts And Nevis</option>
                  <option value="186">Saint Lucia</option>
                  <option value="187">Saint Pierre and Miquelon</option>
                  <option value="188">Saint Vincent And The Grenadines</option>
                  <option value="189">Saint-Barthelemy</option>
                  <option value="190">Saint-Martin (French part)</option>
                  <option value="191">Samoa</option>
                  <option value="192">San Marino</option>
                  <option value="193">Sao Tome and Principe</option>
                  <option value="194">Saudi Arabia</option>
                  <option value="195">Senegal</option>
                  <option value="196">Serbia</option>
                  <option value="197">Seychelles</option>
                  <option value="198">Sierra Leone</option>
                  <option value="199">Singapore</option>
                  <option value="200">Slovakia</option>
                  <option value="201">Slovenia</option>
                  <option value="202">Solomon Islands</option>
                  <option value="203">Somalia</option>
                  <option value="204">South Africa</option>
                  <option value="205">South Georgia</option>
                  <option value="206">South Sudan</option>
                  <option value="207">Spain</option>
                  <option value="208">Sri Lanka</option>
                  <option value="209">Sudan</option>
                  <option value="210">Suriname</option>
                  <option value="211">Svalbard And Jan Mayen Islands</option>
                  <option value="212">Swaziland</option>
                  <option value="213">Sweden</option>
                  <option value="214">Switzerland</option>
                  <option value="215">Syria</option>
                  <option value="216">Taiwan</option>
                  <option value="217">Tajikistan</option>
                  <option value="218">Tanzania</option>
                  <option value="219">Thailand</option>
                  <option value="220">Togo</option>
                  <option value="221">Tokelau</option>
                  <option value="222">Tonga</option>
                  <option value="223">Trinidad And Tobago</option>
                  <option value="224">Tunisia</option>
                  <option value="225">Turkey</option>
                  <option value="226">Turkmenistan</option>
                  <option value="227">Turks And Caicos Islands</option>
                  <option value="228">Tuvalu</option>
                  <option value="229">Uganda</option>
                  <option value="230">Ukraine</option>
                  <option value="231">United Arab Emirates</option>
                  <option value="232">United Kingdom</option>
                  <option value="233">United States</option>
                  <option value="234">United States Minor Outlying Islands</option>
                  <option value="235">Uruguay</option>
                  <option value="236">Uzbekistan</option>
                  <option value="237">Vanuatu</option>
                  <option value="238">Vatican City State (Holy See)</option>
                  <option value="239">Venezuela</option>
                  <option value="240">Vietnam</option>
                  <option value="241">Virgin Islands (British)</option>
                  <option value="242">Virgin Islands (US)</option>
                  <option value="243">Wallis And Futuna Islands</option>
                  <option value="244">Western Sahara</option>
                  <option value="245">Yemen</option>
                  <option value="246">Zambia</option>
                  <option value="247">Zimbabwe</option>
                </select>
              </div>

              <div className="col-4">
                <select name="ecosystem" id="ecosystem" className="form-select ">
                  <option value="">Select Ecosystem</option>
                  <option value="entrepreneurial">Entrepreneurial Finance System (e.g. investor, bank, grant giver)</option>
                  <option value="accelerators">Accelerators and incubators (e.g. start-up system)</option>
                  <option value="industry">Industry system (e.g. company)</option>
                  <option value="talent">Talent system (e.g. university, education, science parks and technology centres)</option>
                  <option value="society">Think-tanks, Societies and interest groups</option>
                  <option value="management">Management system (e.g. government departments, police, health care)</option>
                  <option value="polictics">Politicians and legislators</option>
                  <option value="culture">Livelihood system (e.g. sport, culture, amusement)</option>
                  <option value="demonstration">Demonstration system (exhibition centres and events companies)</option>
                  <option value="global">Global system (e.g. embassies, OECD, UN, Arab League)</option>
                  <option value="urban">Urban and regional system (e.g. smart cities, mobility and transport)</option>
                  <option value="press">Media and the Press</option>
                </select>
              </div>
              <div className="col-4">
                <select name="describe_id" id="describe_id" className="form-select">
                  <option value="">All Industries</option>
                  <option value="1">Government</option>
                  <option value="2">Technology</option>
                  <option value="3">Financial Services</option>
                  <option value="4">Real Estate</option>
                  <option value="5">Agriculture</option>
                  <option value="6">Chemical industries</option>
                  <option value="7">Commerce </option>
                  <option value="8">Construction</option>
                  <option value="9">Education</option>
                  <option value="10">Professional services</option>
                  <option value="11">R&amp;D</option>
                  <option value="12">Media</option>
                  <option value="13">Retailers</option>
                  <option value="14">Energy</option>
                  <option value="15">Telecommunications services</option>
                  <option value="16">Public service</option>
                  <option value="17">Manufacturing</option>
                  <option value="18">Automotive</option>
                  <option value="19">Retail, Wholesale and Distribution</option>
                  <option value="20">Travel, Hospitality and Services</option>
                  <option value="21">Security and Justice</option>
                  <option value="22">Life Sciences &amp; Health Care</option>
                </select>
              </div>
              <div className="col-4">
                <select name="intrests_area" id="area-of-interest" className="form-select">
                  <option value="">All Areas of Interest</option>
                  <option value="Artificial Intelligence (AI)">Artificial Intelligence (AI)</option>
                  <option value="Intelligent Automation">Intelligent Automation</option>
                  <option value="Cyber Security">Cyber Security</option>
                  <option value="Blockchain">Blockchain</option>
                  <option value="Robotics">Robotics</option>
                  <option value="The Internet-of-Things (IoT)">The Internet-of-Things (IoT)</option>
                  <option value="5G">5G</option>
                  <option value="Virtual reality (VR)/Augmented reality (AR)">Virtual reality (VR)/Augmented reality (AR)</option>
                  <option value="Drones">Drones</option>
                  <option value="All Emerging Technologies">All Emerging Technologies</option>
                  <option value="Metaverse">Metaverse</option>
                  <option value="Web3">Web3</option>
                  <option value="NFT (Non-fungible token)">NFT (Non-fungible token)</option>
                </select>
              </div>
              <div className="col-auto text-center">
                <button className="btn btn-primary d-flex-mi " type="submit">
                  <SearchIcon />
                </button>
              </div>
            </div>
          </form>
        </div>
      </Collapse>

      <ListGroup className="shadow-sm">
        <ListGroup.Item className="fw-bold bg-linear-gradient-primary">
          Co-Chairs
        </ListGroup.Item>
        <ListGroup.Item className="d-flex align-items-center justify-content-between flex-column flex-md-row">
          <a href="/shareprofiles/stephen-metcalfe-88e929b053">
            <div className="d-flex justify-content-start align-items-center">
              <BadgeAvatar
                alt="Remy Sharp" 
                src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"}
                size="120"
              />

              <div className="mx-3 d-flex flex-column">
                <h4 className="mb-1 text-primary">Stephen Metcalfe</h4>
                <p className="text-muted mb-0 d-flex-mi">
                  <WorkIcon/>&nbsp;House of Commons - UK Parliament
                </p>
                <p className="text-muted mb-0 d-flex-mi">
                  <InfoIcon/>&nbsp;Stephen Metcalfe MP is Co-Chairman of the All-Party Parliamentary Group on Artificial Intelligence

                </p>
              </div>
            </div>
          </a>
          <Button>Unfollow</Button>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex align-items-center justify-content-between flex-column flex-md-row">
          <a href="/shareprofiles/stephen-metcalfe-88e929b053">
            <div className="d-flex justify-content-start align-items-center">
              <BadgeAvatar
                alt="Tim Clement" 
                src={localStorage.getItem('bucketName')+"/images/profile_picture/49561604843088.png"}
                size="120"
              />

              <div className="mx-3 d-flex flex-column">
                <h4 className="mb-1 text-primary">Tim Clement-Jones</h4>
                <p className="text-muted mb-0">
                  <WorkIcon/>&nbsp;House of Lords - UK Parliament
                </p>
                <p className="text-muted mb-0 d-flex-mi">
                  <InfoIcon/>&nbsp;Political:
Liberal Democrat House of Lords spokesperson for Science Innovation and Technology. Former Chair of
                </p>
              </div>
            </div>
          </a>
          <Button>Unfollow</Button>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup className="my-2 shadow-sm">
        <ListGroup.Item className="fw-bold bg-linear-gradient-primary">
          Vice Chairs
        </ListGroup.Item>
        <ListGroup.Item className="d-flex align-items-center justify-content-between flex-column flex-md-row">
          <a href="/shareprofiles/stephen-metcalfe-88e929b053">
            <div className="d-flex justify-content-start align-items-center">
              <BadgeAvatar
                alt="Remy Sharp" 
                src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"}
                size="120"
              />

              <div className="mx-3 d-flex flex-column">
                <h4 className="mb-1 text-primary">Stephen Metcalfe</h4>
                <p className="text-muted mb-0 d-flex-mi">
                  <WorkIcon/>&nbsp;House of Commons - UK Parliament
                </p>
                <p className="text-muted mb-0 d-flex-mi">
                  <InfoIcon/>&nbsp;Stephen Metcalfe MP is Co-Chairman of the All-Party Parliamentary Group on Artificial Intelligence

                </p>
              </div>
            </div>
          </a>
          <Button>Unfollow</Button>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex align-items-center justify-content-between flex-column flex-md-row">


          <a href="/shareprofiles/stephen-metcalfe-88e929b053">
            <div className="d-flex justify-content-start align-items-center">
              <BadgeAvatar
                alt="Remy Sharp" 
                src={localStorage.getItem('bucketName')+"/images/profile_picture/49561604843088.png"}
                size="120" isLoggedIn={false}
              />

              <div className="ms-3 d-flex flex-column">
                <h4 className="mb-1 text-primary">Tim Clement-Jones</h4>
                <p className="text-muted mb-0">
                  <WorkIcon/>&nbsp;House of Lords - UK Parliament
                </p>
                <p className="text-muted mb-0 d-flex-mi">
                  <InfoIcon/>&nbsp;Political:
Liberal Democrat House of Lords spokesperson for Science Innovation and Technology. Former Chair of
                </p>
              </div>
            </div>
          </a>
          <Button>Unfollow</Button>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-center">
        <Pagination>
      <Pagination.First />
      <Pagination.Prev />
      <Pagination.Item>{1}</Pagination.Item>
      <Pagination.Ellipsis />

      <Pagination.Item>{10}</Pagination.Item>
      <Pagination.Item>{11}</Pagination.Item>
      <Pagination.Item active>{12}</Pagination.Item>
      <Pagination.Item>{13}</Pagination.Item>
      <Pagination.Item disabled>{14}</Pagination.Item>

      <Pagination.Ellipsis />
      <Pagination.Item>{20}</Pagination.Item>
      <Pagination.Next />
      <Pagination.Last />
    </Pagination>
        </ListGroup.Item>
      </ListGroup>
      
    </Container>
  )
}

export default MembersTab
