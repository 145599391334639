import React, { useState, useEffect } from 'react'
import PageLogo from '../assets/img/bicpavilion.png';
import Logo from './Logo';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Chat, Search, SearchOutlined } from "@mui/icons-material";
import BellIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { IconButton } from '@mui/material';
import BadgeAvatar from './BadgeAvatar';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import ChatIcon from '@mui/icons-material/InsertCommentOutlined';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from '@mui/material/Badge';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from "react-router-dom"
import { Form, Row, Col, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import http from '../http'
import Tooltip from '@mui/material/Tooltip';

function Header102() {

  const token = localStorage.getItem('token');
  
  
  const navigate = useNavigate()
  const handleLogin = () => navigate(`/`);

  const [showChatList, setShowChatList] = useState(false);
  const [show, setShow] = useState(false);

  const handleCloseChatList = () => setShowChatList(false);
  const handleShowChatList = () => setShowChatList(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isIconClicked, setIsIconClicked] = useState(true);

  const handleDarkMode = (event) => {
    setIsIconClicked(!isIconClicked);
    const thm = document.documentElement.getAttribute("data-bs-theme");
    const appId = document.getElementById('app-theme');
    const thm2 = appId.getAttribute('data-bs-theme');
    const thmToggler = event.currentTarget;
    const theme = localStorage.getItem("data-bs-theme");
    // thmToggler.innerHTML='<small>{theme}</small>';

    if (thm2 === 'light') {
      appId.setAttribute('data-bs-theme', 'dark');
      document.documentElement.setAttribute("data-bs-theme", "dark");
      localStorage.setItem("data-bs-theme", "dark");
      // thmToggler.innerHTML='<small>LIGHT MODE<small>';
    } else {
      appId.setAttribute('data-bs-theme', 'light');
      document.documentElement.setAttribute("data-bs-theme", "light");
      localStorage.setItem("data-bs-theme", "light");
      //  thmToggler.innerHTML='<small>DARK MODE</small>';
    }
  }

  const [memberName, setMemberName] = useState(["Thirath Virdee", "Alex Welr", "Alan Cook", "James Krik", "Mohsin Khan", "Ijaz Ali", "Raju Dayyala"]);
  // const [memberDesig, setMemberDesig] = useState(["Co-Founder and Chief Technology Officer", "Co-Founder/Chairman", "Principal Consultant - Blockchain", "Founder", "Manager", "Developer", "Programmer"]);
  const [memberFrom, setMemberFrom] = useState(["1 month ago", "2 months ago", "22 minutes ago", "10 seconds ago", "1 year ago", "5 months ago", "1 minute ago"]);

const [avatar,setAvatar]=useState();
const [userName,setUserName]=useState();

  

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="bg-light py-1 ">
        <Container>
          <Navbar.Brand href="/" className='py-0'>
            {/* <Logo width="100vw" src={PageLogo} /> */}
            <Image fluid width="100vh" src={PageLogo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
            <Tooltip arrow
      title={isIconClicked ? 'Dark Mode' : 'Light Mode'} slotProps={{popper: { modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
              <Nav.Link to="javascript:void(0);" onClick={handleDarkMode} id="theme-toggler" >
                  <IconButton className="p-1 btn btn-outline-mode btn-sm">{isIconClicked ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}</IconButton>
              </Nav.Link></Tooltip>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
    </>
  )
}
export default Header102
