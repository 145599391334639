import React, { useState } from 'react'
import Image from 'react-bootstrap/Image';
import Box from '@mui/material/Box';

function Logo(props) {
    const [src, setSrc]=useState(props.src);
    const [width, setWidth]=useState(props.width);
  return (
    <div className="mb-0 text-center">
        {/* <Box
        component="img"
        sx={{ width: 200, height: 71 }}
        alt="Logo"
        src={src}
      /> */}
      {/* <img className="img-fluid" width={width} src={src} height={width} />  */}
      <Image src={src} sx={{width:width, height:width}} fluid/> 
    </div>
  )
}

export default Logo
