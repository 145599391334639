import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import { AccountBalanceOutlined } from '@mui/icons-material'
import googlePlay from '../../../../assets/icons/googleplay.svg';
import appStore from '../../../../assets/icons/appstore.svg';
import appgAI1 from '../../../../assets/img/appgai1.png';
import appgAI2 from '../../../../assets/img/appgai2.png';
import appgAILogo from '../../../../assets/img/appg-ai-logo.jpeg';
import whoswhoLogo from '../../../../assets/img/whoswho-logo.jpeg';
import Carousel from 'react-bootstrap/Carousel';
import { Button, Row, Col, Container } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import BadgeAvatar from '../../../BadgeAvatar';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import HelpIcon from '@mui/icons-material/HelpCenter';
import VideoIcon from '@mui/icons-material/VideoCameraFront';
import Avatar from '@mui/material';
import { Image } from 'react-bootstrap';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CropperUpload from '../../../Cropper/Upload';
import CropperPopup from '../../../Cropper/Popup';
import { Modal, Form } from 'react-bootstrap';
import http from '../../../../http';
import { AWSIMAGE_PATH } from '../../../PavilionConstants';

const CropperInput = styled("input")({
  display: "none"
});


function MiddlePublic(props, { handleClick }) {


  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleParentClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleParentClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [image, setImage] = useState(appgAILogo);
  const [imageLogo, setImageLogo] = useState();
  const [imageLogo2, setImageLogo2] = useState();

  const [imageBannerOne2, setImageBannerOne2] = useState();

  const [imageBannerTwo2, setImageBannerTwo2] = useState();

  const [imageBannerThree2, setImageBannerThree2] = useState();

  const [imagePath, setImagePath] = useState(localStorage.getItem('bucketName') + '/images/pavilion/');
  const [imagePath2, setImagePath2] = useState(localStorage.getItem('bucketName') + '/images/pavilion/');

  const [pvlnId, setPvlnId] = useState(0);
  const [pvlnTitle, setPvlnTitle] = useState('');
  const [pvlnLogo, setPvlnLogo] = useState('');
  const [banners, setBanners] = useState([]);

  const [bannerOne, setBannerOne] = useState('event-photo.png');
  const [bannerTwo, setBannerTwo] = useState('event-photo.png');
  const [bannerThree, setBannerThree] = useState('event-photo.png');

  const [membersCount, setMembersCount] = useState();

  const [publicPost, setPublicPost] = useState();

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  const param = ['poll', 'walls', 'event', 'publication', 'newsinformation'];

  useEffect(() => {

    if (splitURL[1] === 'poll') {
      if (splitURL[2]) {
        http().get(`/publicwebpolls/${splitURL[2]}`).then((data) => {
          setPvlnId(data.data.data.survey['0'].pavilion_id)
        });
      }
    }
    if (splitURL[1] === 'newsinformation') {
      if (splitURL[2]) {
        http().get(`/newsinformation/${splitURL[2]}`).then((data) => {
          setPvlnId(data.data.data.pavilion_id)
        });
      }
    }

  

    if (splitURL[1] === 'walls') {
      if (splitURL[2]) {
        http().get(`/walls/${splitURL[2]}`).then((data) => {
           setPvlnId(data.data.data.pavilion_id)
        });

      }
    }

    if (splitURL[1] === 'event') {
      if (splitURL[2]) {
        http().get(`/event/${splitURL[2]}`).then((data) => {
          setPvlnId(data.data.data.pavilion_id)
        });
      }
    }

    if (splitURL[1] === 'publication') {
      if (splitURL[2]) {
        http().get(`/publicpublication/${splitURL[2]}`).then((data) => {
          setPvlnId(data.data.data.pavilion_id)
        });
      }
    }
    if (splitURL[1] === 'about_pavilion') {
      if (splitURL[2]) {
        http().get('/publicabout/'+splitURL[2]).then((res) => {
          setPvlnId(res.data.data.aboutPavilion.id)
       });
      }
    }
    if (splitURL[1] === 'preview') {
      if (splitURL[2]) {
        http().get('/publicabout/'+splitURL[2]).then((res) => {
          setPvlnId(res.data.data.aboutPavilion.id)
       });
      }
    }
  }, []);

  useEffect(() => {
    if(pvlnId>0){
    http().get(`/pavilionbannerlist/${pvlnId}`).then((data) => {
      setBanners([...banners, ...data.data.data.fullimage]);
      setPvlnTitle(data.data.data.pavilionName);
      if(data.data.data.pavilionLogo){
        setPvlnLogo(imagePath + data.data.data.pavilionLogo);
      } else {
        setPvlnLogo(imagePath + data.data.data.pavilionDefaultLogo);
      }
      
      setMembersCount(data.data.data.pavilionMemberCount);
    });
  }
  },[pvlnId]);
  //   useEffect(() => {
  //     http().get('/webpublicpavilions').then((data) => {
  //       setPvlnTitle(data.data.data.pavilions.data.name);
  //       setImageLogo(imagePath+data.data.data.pavilions.logo);
  //     });
  // },[]);

  //  useEffect(() => {
  //   if(token){
  //       http().get(`/about/${props.pvlnIndex}`).then((data) => {
  //         if (data && data.data && data.data.data && data.data.data.aboutPavilion && data.data.data.aboutPavilion.mobile_banner) {
  //           setBanners(data.data.data.aboutPavilion.mobile_banner.split(','));
  //         }
  //        });
  //       }
  //   },[props.pvlnIndex]);

  useEffect(() => {
    if (banners[0]) {
      // setImageBannerOne(imagePath + banners[0])
      setBannerOne(banners[0])
    } else {
      // setImageBannerOne('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
      setBannerOne('event-photo.png')
    }
    if (banners[1]) {
      // setImageBannerTwo(imagePath + banners[1])
      setBannerTwo(banners[1])
    } else {
      // setImageBannerTwo('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
      setBannerTwo('event-photo.png')
    }
    if (banners[2]) {
      // setImageBannerThree(imagePath + banners[2])
      setBannerThree(banners[2])
    } else {
      // setImageBannerThree('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
      setBannerThree('event-photo.png')
    }

  });

  useEffect(() => {
    // setImageBannerOne(imagePath + imageBannerOne2)
  }, [imageBannerOne2]);

  return (
    <>
      <CardGroup className="flex-column justify-content-between  h-100" style={{ width: "320px" }}>
        {/* <Card className="zoom mb-3 d-flex flex-column align-items-center justify-content-center py-2 bg-light bg-opacity-75" style={{ borderRadius: "15px" }} >
          <Row className="w-100" style={{ height: '181px' }}>
            <Col xs={2} md={2} className='d-flex flex-column align-items-center justify-content-center  gap-0'>
              <IconButton data-aos="fade-right" onClick={() => window.open('https://www.youtube.com/channel/UCpvImeYUgu-UAHyjRKDaPTA', '_blank')} className="btn btn-outline-mode" size="small"><VideoIcon /></IconButton>
            </Col>
            <Col xs={7} md={7} className='d-flex flex-column align-items-center justify-content-center  gap-0 px-0'>
              <Image className="rounded" src={pvlnLogo} style={{ width: "160px", height: "100px" }} />
              <Card.Title className="fs-6 text-secondary text-center fw-bold pb-0 mb-0">{pvlnTitle}</Card.Title>
            </Col>
            <Col xs={3} md={3} className="d-flex flex-column align-items-center justify-content-center">
              <Button variant="light" data-aos="fade-left" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                <span className="badge badge-pill badge-primary bg-primary" style={{ fontSize: "12px" }}>{membersCount}</span>
                <Typography variant="small">Members</Typography>
              </Button>
            </Col>
          </Row>
        </Card> */}
        <Card className="zoom mb-3  d-flex flex-column align-items-center justify-content-center py-2 bg-light bg-opacity-75 w-100" style={{ borderRadius: "15px", maxHeight:'200px' }} >
          {/* <Card.Img style={{ borderRadius: "15px", height:"175px" }} variant="top" src={appgAI} /> */}
          <Row className="w-100 " >
            <Col xs={3} md={3} className='d-flex flex-column align-items-center justify-content-between mx-0'>
            <Button variant="light" data-aos="fade-left" size="sm" className="d-flex flex-column small align-items-center justify-content-center" style={{width:'60px', height:'50px'}} >
                <span className="badge badge-pill badge-primary bg-primary" style={{ fontSize: "12px" }}>{membersCount}</span>
                <Typography variant="small">Members</Typography>
              </Button>
              <IconButton data-aos="fade-right" onClick={handleShow} className="btn btn-outline-mode" size="small"><HelpIcon /></IconButton>
              
            </Col>
            <Col xs={6} md={6} className='d-flex flex-column align-items-center justify-content-start  gap-0 px-0'>
              <Image className="rounded" src={pvlnLogo} style={{ width: "160px", height: "auto" }} />
              {/* <Card.Title className="fs-6 text-secondary text-center fw-bold pb-0 mb-0">{pvlnTitle}</Card.Title> */}
              {/* <Card.Text className="small mb-0 text-center">
                321 Members
              </Card.Text> */}

            </Col>
            <Col xs={3} md={3} className="d-flex flex-column align-items-center justify-content-between mx-0">
              {/* <div>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                  <Box my={2}         >
                    <label htmlFor="fileLogo">
                      <CropperInput accept="image/*" data-value="LOGO" id="fileLogo" type="file" onChange={onFileChange} />
                      <Button as="span" variant="light" data-aos="fade-left" size="sm" className="d-flex flex-column small align-items-center justify-content-end mb-2" >
                        <AddPhotoAlternateIcon />
                        <Typography variant="small">Update Logo</Typography>
                      </Button>
                    </label>
                  </Box>
                  {/* <Upload
              getUploadedFile={(image) => {
            setOpen(true);
            setImage(image);
          }}
        /> 
                  <CropperPopup open={open} image={imageLogo2} handleClose={handleClose}
                    getCroppedFile={(imageLogo) => {
                      setImageLogo(imageLogo)
                      handleClose();
                    }}
                    aspectRatio={16 / 10} />
                </Box></div> */}
            <Button onClick={event => props.handleClick('PVLN-SHOPP', props.pvlnIndex)} variant="light" size="sm" data-aos="fade-left" className="d-flex flex-column small align-items-center justify-content-end" style={{width:'60px', height:'50px'}} >
                <PersonAddIcon />
                <Typography variant="small" >Become Member</Typography>
              </Button>    
              
              <IconButton data-aos="fade-right" onClick={() => window.open('https://www.youtube.com/channel/UCpvImeYUgu-UAHyjRKDaPTA', '_blank')} className="btn btn-outline-mode" size="small"><VideoIcon /></IconButton>
            </Col>
          </Row>
          <Row>
            <Col><Card.Title className="fs-6 text-secondary text-center fw-bold pb-0 mb-0">{pvlnTitle}</Card.Title></Col>
          </Row>
        </Card>
        <Card className="zoom mb-3" style={{ borderRadius: "15px", maxHeight:'200px' }} >
          {/* <Card.Img style={{ borderRadius: "15px", height:"200px" }} variant="top" src={appgAI2} /> */}

          {/* <Button variant="light" onClick={handleShow2} size="sm" className="position-absolute top-0 end-0 m-2 small" style={{ zIndex: "1" }}>
            <AddPhotoAlternateIcon />
            <Typography variant="small">Update Banners1 </Typography>
          </Button> */}

          <Carousel slide={true} className='h-100' >
            {Array.from({ length: banners.length }).map((_, idx) => (
              <Carousel.Item key={idx} interval={2000}>
                <Card.Img style={{ borderRadius: "15px", width: "320px", height: "200px" }} variant="top" src={`${imagePath+((banners[idx]).replace(/^.*[\\/]/, ''))}`} />
              </Carousel.Item>
            ))}
            {/* <Carousel.Item>
              <Card.Img style={{ borderRadius: "15px", height: "200px" }} variant="top" src={appgAI2} /> */}
            {/* <Carousel.Caption>
                <h4>Premium Members</h4>
                <p>Members: 400</p>
              </Carousel.Caption> */}
            {/* </Carousel.Item> */}

          </Carousel>
        </Card>
        <Card className="zoom" style={{ borderRadius: "15px", maxHeight: "80px" }}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-between mb-0 pt-0 pb-2">
            {/* <Card.Title className="fs-6 fw-bold">Welcome to pCloud!</Card.Title> */}
            <Card.Text className="small my-2 text-center">
              Experience Pavilion on the go - now available on your mobile phone through the Play Store and App Store
            </Card.Text>
            <Card.Text >
              <a href="https://play.google.com/store/apps/details?id=com.pavillionapp.pavillion&hl=en&gl=US" target='_blank'>
              <img src={localStorage.getItem('bucketName')+'/images/googleplay.svg'} height="30px" className="me-2" alt="Google Play" /> </a>
              <a href="https://apps.apple.com/us/app/pavilion/id6450182778" target='_blank'>
              <img src={localStorage.getItem('bucketName')+'/images/appstore.svg'} height="30px" className="" alt="App Store" /></a>
            </Card.Text>
          </Card.Body>
        </Card>

      </CardGroup>



    </>
  )
}

export default MiddlePublic
