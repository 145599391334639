import React, { useState, useEffect, useRef } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card, Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Hidden, SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import AddPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddVideoIcon from '@mui/icons-material/VideoCall';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import Select from 'react-select';
import { ECOSYSTEM_OPTIONS } from '../../../../components/PavilionConstants';
import Accordion from 'react-bootstrap/Accordion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormLabel from 'react-bootstrap/FormLabel'
import http from '../../../../http';
import PreLoader from '../../../PreLoader';
import { PLSIZE } from '../../../../components/PavilionConstants';
import Swal from 'sweetalert2'

const AntTabs = styled(Tabs)({
    width: '100%',
    minHeight:'0',
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-flexContainer': {
        border: '1px solid #ececec',
        width: '100%',
        padding: '2px 3px',
        borderRadius: 50,
    },
});

interface StyledTabProps {
    label: string;
}
const AntTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    minHeight: 20,
    width: '50%',
    borderRadius: 50,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 3,
    paddingBottom: 3,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: ['Barlow'].join(','),
    '&:hover': {
        color: '#e30613',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#e30613',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

function PRFLMyWallGallery({ handleClick }) {


    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    
    const [page1, setPage1] = useState(1);
    const [totalPages1, setTotalPages1] = useState(1);
    const [page2, setPage2] = useState(1);
    const [totalPages2, setTotalPages2] = useState(1);
    const [page3, setPage3] = useState(1);
    const [totalPages3, setTotalPages3] = useState(1);
    const [page4, setPage4] = useState(1);
    const [totalPages4, setTotalPages4] = useState(1);

    const [totalPages, setTotalPages] = useState(1);
    
    // const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
    const [loadMsg, setLoadMsg] = useState('loading...');
    const scrollContainerRef = useRef(null);
    // const [items, setItems] = useState([]);


    const [value, setValue] = useState('one');

    const [isUploaded, setIsUploaded] = useState(false);

    const [postGallery, setPostGallery] = useState([]);
    const [userGallery, setUserGallery] = useState([]);


    // useEffect(() => {
        
    //     fetchUserPhotos();
    // }, []);
    
    useEffect(() => {
        if(value==='one'){
            fetchUserPhotos();
            fetchPostsPhotos();
        }
        if(value==='two'){
            fetchUserVideos();
            fetchPostsVideos();
        }
        
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    {/*
    const onFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();

        reader.onload = () => {
            //    getUploadedFile(reader.result);
            //         setImage(reader.result)
            console.log(reader.result)
        };
        reader.readAsDataURL(files[0]);

    }; 
	*/}

    //Infinite Scroll
    // const fetchData = async (__page) => {
    //     console.log(__page);
    //     const response = await http().get(`/mygallery?page=${page}`).then((response) => {
    //          console.log(response.data.data.post_gallery.data)
    //          setMyGallery([...myGallery, ...response.data.data.post_gallery.data]);
    //          const last_page=response.data.data.post_gallery.last_page;
    //         if(page < last_page){
    //           setPage(page + 1);     
    //           setHasMore(true)
    //           setLoadMsg(<PreLoader size={PLSIZE}/>)
    //         } else{ 
    //           setHasMore(false) 
    //           setLoadMsg('No More Records...')
    //         }
    //     })
    //   };


    const onFileChangeVideo = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();
        reader.onload = () => {
            // http().post('/mygallery_upload_video', {
            // 	//pavilion_id:props.idx,
            // 	type:"video",
            // 	attachments:reader.result,
            // 	}).then((data) => {
            // 		Swal.fire({
            //             title: "Successfully Uploaded Video!",
            //             icon: "success",
            //             allowOutsideClick: false,
            //             confirmButtonColor: "#e30613",
            //           });
            // });
            setIsUploaded(false)
            Swal.fire({
                title: "Uploading Video!",
                html: "Uploading Video, Please wait...",
                timerProgressBar: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                iconColor: '#e30613',
                didOpen: () => {
                    Swal.showLoading();
                    http().post('/mygallery_upload_video', {
                        //pavilion_id:props.idx,
                        type: "video",
                        attachments: reader.result,
                    }).then((data) => {

                        Swal.fire({
                            title: "Video uploaded successfully",
                            icon: "success",
                            allowOutsideClick: false,
                            confirmButtonColor: "#e30613",
                        });
                        setIsUploaded(true)
                        // fetchData();
                    });
                },
                willClose: () => {
                    //clearInterval(timerInterval);
                }
            }).then((result) => {

                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log("I was closed by the timer");
                }
            });

        };
        reader.readAsDataURL(files[0]);
    };

    const onFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();

        reader.onload = () => {
            setIsUploaded(false)
            //    getUploadedFile(reader.result);
            //         setImage(reader.result)
            // console.log(reader.result)

            //  http().post('/mygallery_upload_images', {
            // 	//pavilion_id:props.idx,
            // 	type:"photo",
            // 	attachments:reader.result,
            // 	}).then((data) => {
            // 		Swal.fire({
            //             title: "Successfully Uploaded Images!",
            //             icon: "success",
            //             allowOutsideClick: false,
            //             confirmButtonColor: "#e30613",
            //           });
            // });
            Swal.fire({
                title: "Uploading Photo!",
                html: "Uploading Photo, Please wait...",
                timerProgressBar: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                iconColor: '#e30613',
                didOpen: () => {
                    Swal.showLoading();
                    http().post('/mygallery_upload_images', {
                        //pavilion_id: props.idx,
                        type: "photo",
                        attachments: reader.result,
                    }).then((data) => {
                        setTotalPages(0);
                        setPostGallery([]);
                        setUserGallery([]);
                        setHasMore(true);
                        setLoadMsg('');
                        
                        setPage(1);
                        http().get(`/mygallery?page=1`).then((response) => {
                            if (response.data.data.post_gallery.last_page > response.data.data.user_gallery.last_page) {
                                setTotalPages(response.data.data.post_gallery.last_page);
                            } else {
                                setTotalPages(response.data.data.user_gallery.last_page);
                            }            
                            if (response.data.data.post_gallery.data.length > 0) {
                                setPostGallery(response.data.data.post_gallery.data);
                            }
                            if (response.data.data.user_gallery.data.length > 0) {
                                setUserGallery(response.data.data.user_gallery.data);
                            }
                        });
                        setLoadMsg('loading...')
                        setHasMore(true);
					

                        Swal.fire({
                            title: "Photo uploaded successfully",
                            icon: "success",
                            allowOutsideClick: false,
                            confirmButtonColor: "#e30613",
                        });
                        setIsUploaded(true)
                        // fetchData();
                    });
                },
                willClose: () => {
                    //clearInterval(timerInterval);
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log("I was closed by the timer");
                }
            });
        };
        reader.readAsDataURL(files[0]);

    };

        // const fetchData = async () => {
    //   if (page <= totalPages) {
    //         await http().get(`/mygallery?page=${page}`).then((response) => {
    //             if (response.data.data.post_gallery.last_page > response.data.data.user_gallery.last_page) {
    //                 setTotalPages(response.data.data.post_gallery.last_page);
    //             } else {
    //                 setTotalPages(response.data.data.user_gallery.last_page);
    //             }
    //             if (response.data.data.post_gallery.data.length > 0) {
    //                 setPostGallery([...postGallery, ...response.data.data.post_gallery.data]);
    //             }
    //             if (response.data.data.user_gallery.data.length > 0) {
    //                 setUserGallery([...userGallery, ...response.data.data.user_gallery.data]);
    //             }
    //         });
    //         setPage(page + 1);
    //         setLoadMsg({<PreLoader size={PLSIZE} />})
    //         setHasMore(true);
    //     } 
    //     else {
    //         console.log('No more records...');
    //         setLoadMsg('No More Records...');
    //         setHasMore(false);
    //     }
    // };

    const fetchUserPhotos = async () => {
        const res = await http().get(`/webmygallery?page=${page1}`).then((response) => {
            setUserGallery(prevUserGallery=>[...prevUserGallery, ...response.data.data.myUserGallery.data]);
            setTotalPages1(response.data.data.myUserGallery.last_page);
            if (page1 > totalPages1) { setHasMore(false); return; }
        });
        setPage1(page1 + 1);
    };
    
    const fetchPostsPhotos = async () => {
        const res = await http().get(`/webmygallery?page=${page2}`).then((response) => {
            setPostGallery(prevPostGallery=>[...prevPostGallery, ...response.data.data.myPostAttachments.data]);
            setTotalPages2(response.data.data.myPostAttachments.last_page);
            if (page2 > totalPages2) { setHasMore(false); return; }
        });
        setPage2(page2 + 1);
    };

    const fetchUserVideos = async () => {
        const res = await http().get(`/webmyvideo?page=${page3}`).then((response) => {
            setUserGallery(prevUserGallery=>[...prevUserGallery, ...response.data.data.myUserGallery.data]);
            setTotalPages3(response.data.data.myUserGallery.last_page);
            if (page3 > totalPages3) { setHasMore(false); return; }
        });
        setPage3(page3 + 1);
    };

    const fetchPostsVideos = async () => {
        const res = await http().get(`/webmyvideo?page=${page4}`).then((response) => {
            setPostGallery(prevPostGallery=>[...prevPostGallery, ...response.data.data.myPostAttachments.data]);
            setTotalPages4(response.data.data.myPostAttachments.last_page);
            if (page4 > totalPages4) { setHasMore(false); return; }
        });
        setPage4(page4 + 1);
    };

    const handleDeleteClick = (id) => {

    }
	
    const handleDeleteUserClick= (photo)=>{
        Swal.fire({
            title: "Are you sure to delete this Photo?",
            text: "You won't be able to revert this!",
            icon: "warning",
            width: 'auto',
            showCancelButton: true,
            confirmButtonColor: "#e30613",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
              http().post('/webmygalleryuserimagesdelete', {
                name: photo,
                type: "photo",
              }).then((data) => {
                Swal.fire({
                  title: "It is deleted successfully.",
                  icon: "success",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
                setTotalPages(0);
                setPostGallery([]);
                setUserGallery([]);
                setHasMore(true);
                setLoadMsg('');
                
                setPage(1);
                http().get(`/mygallery?page=1`).then((response) => {
                    if (response.data.data.post_gallery.last_page > response.data.data.user_gallery.last_page) {
                        setTotalPages(response.data.data.post_gallery.last_page);
                    } else {
                        setTotalPages(response.data.data.user_gallery.last_page);
                    }            
                    if (response.data.data.post_gallery.data.length > 0) {
                        setPostGallery(response.data.data.post_gallery.data);
                    }
                    if (response.data.data.user_gallery.data.length > 0) {
                        setUserGallery(response.data.data.user_gallery.data);
                    }
                });
                setLoadMsg('loading...')
                setHasMore(true);


      
              });
            }
          });
        
    }

    return (
        <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: '15px' }}>
            <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <div>
                    {/* <IconButton sx={{ padding: 1 }} onClick={event => handleClick('PRFL-MENU1')}><ArrowBackIcon /></IconButton> */}
                    <Typography variant="small">My Wall Gallery</Typography>
                </div>
                <div>
                    <IconButton onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
                    {/* <IconButton ><AddCircleIcon /></IconButton> */}
                </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-50 px-2">
                <div className="container py-0 px-0">

                    <TabContext value={value}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} className="rounded-4 p-0 small d-flex flex-column justify-content-between align-items-center">
                            <AntTabs
                                value={value}
                                onChange={handleChange}
                                scrollButtons={false}
                                aria-label="scrollable auto tabs example"
                            >
                                <AntTab label={<Typography variant="h6" className="text-capitalize py-0" >Photos</Typography>} value="one" />
                                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Videos</Typography>} value="two" />
                            </AntTabs>

                            <TabPanel value="one" className='w-100 p-2 mt-0 mh-60 mb-2 overflow-auto' ref={scrollContainerRef}>
                                <label htmlFor="contained-button-file">
                                    <input
                                        accept="image/*"
                                        id="contained-button-file"
                                        type="file"
                                        className='d-none'
                                        onChange={onFileChange}
                                    />
                                    <Button as="span" size="sm " variant="outlined-secondary" className="d-flex align-items-center p-1 " ><AddPhotoIcon fontSize="small" />&nbsp;Add Photo</Button>
                                </label>
                                    {/* <InfiniteScroll
                                        pageStart={1}
                                        loadMore={fetchData}
                                        hasMore={hasMore}
                                        getScrollParent={() => scrollContainerRef.current}
                                        initialLoad={true}
                                        useWindow={false}
                                        loader={loader}
                                    > */}
                                    <InfiniteScroll
                                    dataLength={page1 <= totalPages1 ? page1 * 5 : page2 * 5}
                                    next={page1 <= totalPages1 ? fetchUserPhotos : fetchPostsPhotos }
                                    scrollableTarget="scrollContainerRef"
                                    hasMore={hasMore}
                                    height={'300'}
                                    className='slim-scrollbar mh-55'
                                    loader={<PreLoader size={PLSIZE} />}
                                    endMessage={<h6>No more records...</h6>}
                                >
                                    <Row xs={1} md={1} className="g-0 "  >
                                        {/* {userGallery.length===0 && (<div>User Photos not found</div>)} */}
                                        {userGallery.map((photo, index) => (
                                            <Col key={index} className="py-2">
                                                <Card className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }}>
                                                    <Card.Img variant="top" className='rounded' src={localStorage.getItem('bucketName') + `/${photo.path}/${photo.name}`} />
                                                    <Card.ImgOverlay className='d-flex justify-content-end align-items-start'>
                                                        <IconButton className='text-primary bg-light' onClick={event => handleDeleteUserClick(photo.name)}><DeleteIcon /></IconButton>
                                                    </Card.ImgOverlay>
                                                 </Card>
                                            </Col>
                                        ))}
                                        {/* {postGallery.length===0 && (<div>Posts Photos not found</div>)} */}
                                        {/* {postGallery.filter(photo => photo.type === "photo").map((photo, index) => ( */}
                                        {postGallery.map((photo, index) => (
                                            <Col key={index} className="py-2">
                                                <Card className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }}>
                                                    <Card.Img variant="top" className='rounded' src={localStorage.getItem('bucketName') + `/${photo.path}/${photo.file_name}`} />
                                                </Card>
                                            </Col>
                                        ))}
                                        </Row>
                                    </InfiniteScroll>
                            </TabPanel>
                            <TabPanel value="two" className='w-100 p-2 mh-60 mt-0 mb-2 overflow-auto' ref={scrollContainerRef}>
                                <label htmlFor="contained-button-file">
                                    <input
                                        accept="video/*"
                                        id="contained-button-file"
                                        type="file"
                                        className='d-none'
                                        onChange={onFileChangeVideo}
                                    />
                                    <Button as="span" size="sm " variant="outlined-secondary" className="d-flex align-items-center p-0 " ><AddVideoIcon fontSize="small" />&nbsp;Add Video</Button>
                                </label>

                                <InfiniteScroll
                                    dataLength={page3 <= totalPages3 ? page3 * 5 : page4 * 5}
                                    next={page3 <= totalPages3 ? fetchUserVideos : fetchPostsVideos }
                                    scrollableTarget="scrollContainerRef"
                                    hasMore={hasMore}
                                    height={'300'}
                                    className='slim-scrollbar mh-55'
                                    loader={<PreLoader size={PLSIZE} />}
                                    endMessage={<h6>No more records...</h6>}
                                >
                                <Row xs={1} md={1} className="g-0 " >
                                    {/* <InfiniteScroll
                                        pageStart={1}
                                        loadMore={fetchData}
                                        hasMore={hasMore}
                                        initialLoad={true}
                                        getScrollParent={() => scrollContainerRef.current}
                                        useWindow={false}
                                        loader={loader}
                                    > */}
                                        {/* {userGallery.length===0 && ('User Videos not found')} */}
                                        {/* {userGallery.filter(photo => photo.type === "video").map((photo, index) => ( */}
                                        {userGallery.map((photo, index) => (
                                            <Col key={index} className="py-2">
                                                <Card className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }}>
                                                    <video controls autostart='true' name="media"><source src={localStorage.getItem('bucketName') + `/${photo.path}/${photo.name}`} type="video/mp4" /></video>
                                                    <Card.ImgOverlay className='d-flex justify-content-end align-items-start'>
                                                        <IconButton className='text-primary bg-light' onClick={event => handleDeleteClick(photo.id)}><DeleteIcon /></IconButton>
                                                    </Card.ImgOverlay>
                                                </Card>
                                            </Col>
                                        ))}
                                        {/* {postGallery.length===0 && ('Posts Videos not found')} */}
                                        {/* {postGallery.filter(photo => photo.type === "video").map((photo, index) => ( */}
                                        {postGallery.map((photo, index) => (
                                            <Col key={index} className="py-2">
                                                <Card className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }}>
                                                    <video controls autostart="true" name="media"><source src={localStorage.getItem('bucketName') + `/${photo.path}/${photo.name}`} type="video/mp4" /></video>
                                                </Card>
                                            </Col>
                                        ))}
                                        </Row>
                                    </InfiniteScroll>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </div>
            </ListGroup.Item>
        </ListGroup>
    )
}

export default PRFLMyWallGallery



