import React, { useEffect, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import axios from "axios";
import http from '../../../../http';
import Collapse from 'react-bootstrap/Collapse';
import Badge from 'react-bootstrap/Badge';
import Comment from '@mui/icons-material/MessageOutlined';
import Share from '@mui/icons-material/ReplyOutlined';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Comments from '../../../Comments';
import Like from '@mui/icons-material/FavoriteBorder';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TelegramIcon from '@mui/icons-material/Telegram';
import Swal from 'sweetalert2'

function PVLNPublicationDetails(props, { handleClick }) {
  //console.log(props);
  const [userdetails, setUserdetails] = useState([]);
  const [post, setPost] = useState([]);
  const [postfile, setPostfile] = useState([]);
  const [open, setOpen] = useState({});
  const [publicationId, setPublicationId] = useState(props.publicationId);
  const [postvalues, setPostsValues] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [categoryPublication, setCategoryPublication] = useState([]);
  const divStyle = {
    borderRadius: '40px',
  };


  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
    if (splitURL[1] === 'publication') {
      if (splitURL[2]) {
        setPublicationId(splitURL[2])
      }
    }

  }, [location]);

  useEffect(() => {

    http().get('/singlepublicationcomments/' + props.publicationId).then((data) => {
      console.log(data)
      console.log('1')
      console.log(data.data.data.posts.data)
      setPostsValues(data.data.data.posts.data)
    });

    http().get(`/publicationdetail/${props.pavilionId}/${publicationId}`).then((data) => {
      console.log(data.data.data.publicationRecord);
      setPost(data.data.data.publicationRecord);
      setPostfile(data.data.data.publicationRecord.file);

    });
  }, []);

  const handleCommentsClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const downloadPublication = (id) => {

    // const url = 'https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/56991719118870.pdf'; // Replace with your PDF file URL
    // const fileName = 'downloaded-file.pdf'; // Replace with your desired file name

    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', fileName);
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    {/*http().get(`/awsfiledownload/${id}/publication`).then((data) => {
      console.log(data);
    });*/}
  };

  const handleCommentsLikeClick = (id) => {
    http().post('/likes/store', {
      type: "publications",
      post_id: id,
    }).then((data) => {
      console.log(data);
      http().get('/singlewallpost/' + props.pavilionId + '/' + id + '/publications').then((data) => {
        console.log(data)
        console.log(data.data.data.post.likes.length);
        document.getElementById('postlikecount' + id).innerHTML = data.data.data.post.likes.length;
      });
    });
  };

  const socialPopUp = (dataurl) => {
    const width = 550;
    const height = 420;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);
    window.open(
      dataurl,
      'twitter-share-dialog',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };
  const handleCopyUrl = (dataurl) => {
    navigator.clipboard.writeText( dataurl).then(() => {
      Swal.fire({
        title: "Text copied to clipboard!",
        text : dataurl,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
      <div className="container slim-scrollbar mh-70  py-0">
        <ListGroup className="overflow-hidden h-100 px-0 " style={{ borderRadius: "10px" }}>
          <ListGroup.Item active className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Row className="w-100 g-2 d-flex justify-content-between align-items-center ">
              <Col md={2}>
                <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + '/images/profile_picture/' + post.users_image} name="Name" size='40' />
              </Col>
              <Col md={10} className='text-start'>
                <h6 className='mb-0'>{post.user_name}</h6>
                {/* <small>03 Tue Jan, 2023 at 03:07 PM</small> */}
              </Col>
            </Row>

            {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Image fluid
              src={localStorage.getItem('bucketName') + '/images/publication/' + post.image}
            />
          </ListGroup.Item>

          <ListGroup.Item className='  border-0 py-1 '>
            <h6 className="fw-bold">{post.name}</h6>
            <p className='lead mb-1'><strong>Category</strong> : {post.category_name} </p>
            <p className='lead mb-1'><strong>Publication Date:</strong>{post.date}</p>
            <p className='lead mb-1'><strong>Description:</strong> {post.description}</p>
            <div className="d-flex justify-content-between align-items-center">
              <p className='lead mb-1 fw-bold'><b>{post.payment_type}</b></p>
              {/* <Button className='bnt btn-primary'>Download</Button> */}
			  {post.file && (<>
              <a class="btn btn-primary btn-sm" id={post.file} name={post.file} target="_blank" href={apiUrl + "/webawsfiledownload/" + post.file + "/publication"}  >Download</a>
			  </>
              )
              }
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className='w-50 lead mb-1'><strong>Document Type:</strong><br /> {post.document_name}</p>
              <p className='w-50 lead mb-1'><strong>Tags:</strong><br /> {post.tags}</p>
            </div>
          </ListGroup.Item>
          {postvalues.map((innerArray, outerIndex) => (
            <>
              {innerArray.comments.map((LibraryCommentsArray, outerIndex) => (
                <>
                </>
              ))}
              <ListGroup.Item className="w-100 d-flex align-items-center justify-content-between h-100 p-0 border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                <ButtonGroup size="sm" className=" w-100 rounded-bottom">
                  <Button size="sm" variant="outline-secondary" className="small" onClick={() => handleCommentsLikeClick(innerArray.id)} ><Like fontSize='small' /> Like <Badge bg="primary" id={'postlikecount' + innerArray.id}>{innerArray.likes.length}</Badge></Button>
                  <Button size="sm" onClick={() => handleCommentsClick(innerArray.id)}
                    aria-controls="collapseComments3"
                    aria-expanded={open}
                    variant="outline-secondary" className='small'><Comment fontSize='small' /> Comment <Badge bg="primary" id={'postCommentcount' + innerArray.id}>{innerArray.all_comments.length > 0 && (innerArray.all_comments.length)}</Badge></Button>
                  
                  
                  <Dropdown as={Button} drop='up' variant="outline-secondary" >
                  {/* <Button size="sm" variant="outline-secondary" className="small"><Share fontSize='small' className="flipx" /> Share</Button> */}
              <Dropdown.Toggle id="dropdown-basic" variant='outline-secondary'   className="w-100 small d-flex justify-content-center align-items-center p-0 border-0">
                <Share className='flipx' fontSize='small' /> Share 
              </Dropdown.Toggle>

              <Dropdown.Menu >
                <Dropdown.Item className='small'  onClick={()=>socialPopUp('https://telegram.me/share/url?url='+window.location.origin+'/publication/'+innerArray.id)} ><TelegramIcon fontSize='small' /> Telegram</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://www.facebook.com/sharer.php?u='+window.location.origin+'/publication/'+innerArray.id)} ><FacebookIcon fontSize='small'/> Facebook</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://twitter.com/share?url='+window.location.origin+'/publication/'+innerArray.id)} ><TwitterIcon fontSize='small'/> Twitter</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://www.linkedin.com/sharing/share-offsite/?url='+window.location.origin+'/publication/'+innerArray.id)} ><LinkedInIcon fontSize='small'/> LinkedIn</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>handleCopyUrl(window.location.origin+'/publication/'+innerArray.id)} ><ContentPasteIcon fontSize='small'/> Copy to Clipboard</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

                </ButtonGroup>
              </ListGroup.Item>
              <Collapse key={outerIndex} in={open[innerArray.id]} className='p-1'>
                <ListGroup.Item>
                  <div id="collapseComments3">
                    <Comments postId={innerArray.id} pavilionId={props.pavilionId} type='publications' />
                  </div>
                </ListGroup.Item>
              </Collapse>
            </>
          ))}

        </ListGroup>
      </div>
    </ListGroup.Item >


  )
}
export default PVLNPublicationDetails
