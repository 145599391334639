import React, { useState, useEffect } from 'react'

import MiddleProfile from './MiddleSection/MiddleProfile'
import MiddlePavilion from './MiddleSection/MiddlePavilion'
import { useLocation } from "react-router-dom";
import MiddlePublic from './MiddleSection/MiddlePublic';
import http from '../../../http'
import MiddlePublicProfile from './MiddleSection/MiddlePublicProfile';

function MiddleBox(props) {

  const [showPVLN, setShowPVLN] = useState(1);
  const token = localStorage.getItem('token')
  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");

  useEffect(() => {

    const param = ['poll', 'walls', 'event', 'publication', 'newsinformation'];

    if (splitURL[1] === 'preview') {
      if (splitURL[2]) {
        http().get('/publicabout/' + splitURL[2]).then((res) => {
          setPIndex(res.data.data.aboutPavilion.id)
          setShowPVLN(3);
        });
      }
    }

    if (splitURL[1] === 'about_pavilion') {
      if (splitURL[2]) {
        http().get('/publicabout/' + splitURL[2]).then((res) => {
          setPIndex(res.data.data.aboutPavilion.id)
          setShowPVLN(3);
        });
      }
    } else if (splitURL[1] === 'shop') {
      if (splitURL[2]) {
        http().get('/publicabout/' + splitURL[2]).then((res) => {
          setPIndex(res.data.data.aboutPavilion.id)
        });
        setShowPVLN(2);
      }
    } else if (splitURL[1] === 'shareprofiles') {
      if (splitURL[2]) {
        setShowPVLN(4);
      }      
    } else if (param.includes(splitURL[1])) {
      if (splitURL[2]) {
        setShowPVLN(3);
      }
    }
  }, [showPVLN]);

  const [pIndex, setPIndex] = useState();

    useEffect(() => {
    // alert(props.pavilion + '- '+ props.pvlnIndex);
    if(props.profile===0){
      setShowPVLN(4)
  }else 
    if (props.pavilion) {
      setShowPVLN(2)
      setPIndex(props.pvlnIndex)
    } 
     else { 
      if (splitURL[1] === 'shareprofiles') {
        if (splitURL[2]) {
          setShowPVLN(4);
        }      
      } else {setShowPVLN(1)}
      }
  }, [props.pavilion,props.pvlnIndex,props.profile]);

  const handleClick = (count, idx) => {
   // alert(`middlebox handleclick pid ${idx}`)
    props.change(count, idx);
    setPIndex(idx)
  }
  //console.log("Pavilion No: ", props.pavilion);
  return (
    <>
      {showPVLN === 1 && <MiddleProfile />}
      {showPVLN === 2 && <MiddlePavilion pvlnIndex={pIndex} handleClick={handleClick} />}
      {showPVLN === 3 && <MiddlePublic pvlnIndex={pIndex} handleClick={handleClick} />}
      {showPVLN === 4 && <MiddlePublicProfile profile={props.profile} />}
    </>
  )
}

export default MiddleBox
