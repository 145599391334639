import React from 'react'
import Layout from '../components/layouts/Layout'
import Container from 'react-bootstrap/Container'
function ErrorPage() {
    
    return (
        <Layout>
            <Container fluid style={{height:'63.5vh'}} className='d-flex justify-content-center align-items-center' data-aos="slide-up">
                    <div>
                    <h1 className='text-primary fw-bold'>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for could not be found.</p>
                    </div>
        </Container>
      </Layout >
     )
}
export default ErrorPage
