import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card,Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import Collapse from 'react-bootstrap/Collapse';
import YoutubeEmbed from '../../../YoutubeEmbed';
import http from '../../../../http';
import ExpandIcon from '@mui/icons-material/ExpandLess';
import CollapseIcon from '@mui/icons-material/ExpandMore';
function PRFLMyPavilions({handleClick}) {



    const [pavilions,setPavilions]=useState([])


    useEffect(() => {
      http().get('/mypavilions').then((res) => {
        setPavilions(res.data.data);
      });
    }, []);

    const handleImageError = (e) => {
      e.target.src = localStorage.getItem('bucketName')+'/images/noimage.webp';
    };
  
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleSetupPavilion = () => 
    {
      setOpen1(!open1)
      setOpen2(false)
      
    };
    const handleExplorePavilion = () => 
    {
      setOpen2(!open2)
      setOpen1(false)
    };

  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <Typography variant="small">My Pavilions</Typography>
                <IconButton className='btn btn-outline-mode' onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75" >
                <div className="container slim-scrollbar mh-70  py-2 ">
                <div className='d-flex justify-content-between mb-2'>
                 <Button variant="outline-secondary" size="sm" onClick={handleSetupPavilion}
        aria-controls="setupPavilion"
        aria-expanded={open1}>Setup your Pavilion {open1 ? <ExpandIcon /> : <CollapseIcon/>}</Button>
        
                 <Button variant="outline-secondary" size="sm" onClick={handleExplorePavilion}
        aria-controls="explorePavilion"
        aria-expanded={open2}>Explore Pavilions {open2 ? <ExpandIcon /> : <CollapseIcon/>}</Button>
                 </div>
                 <Collapse in={open1} className='mb-2'>
        <div id="setupPavilion" ><YoutubeEmbed embedId="67_GA_8A6I0" /></div>
       </Collapse>
       <Collapse in={open2} className='mb-2'>
        <div id="explorePavilion" ><YoutubeEmbed embedId="AvU6tc-d6I0" /></div>
       </Collapse>
                <Row xs={1} md={2} className="g-2 slim-scrollbar" style={{maxHeight:"65vh"}} >
      {pavilions.map((pavilion, index) => (
        <Col key={index}>
          {/* <Card className="mb-0 shadow-sm bg-light" style={{cursor:"pointer"}} onClick={event => handleClick(false, true,`PVLN${pavilion.id}`)}> */}
          <Card className="mb-0 shadow-sm bg-light" style={{cursor:"pointer"}} onClick={event => handleClick(`PVLN-MENU`, pavilion.id)}>
            <Card.Img variant="top" src={localStorage.getItem('bucketName')+ '/' + pavilion.main_image} onError={handleImageError}/>
            <Card.Body className="p-2 d-flex flex-column justify-content-between " style={{minHeight:"75px"}}>

              <Card.Title className="pt-0 my-0" style={{lineHeight:"0.7"}}>
                <Typography key={pavilion.id} variant="small" className="fw-bold text-dark small" >
                    {pavilion.name}               
                </Typography>
              </Card.Title>
              <Card.Text >
                    <Typography key={pavilion.id} variant="small" className="text-muted small">{pavilion.count} members</Typography>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
                </div>
            </ListGroup.Item>
        </ListGroup>
  )
} 

export default PRFLMyPavilions
