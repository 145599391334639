import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Chat } from "@mui/icons-material";
import { IconButton } from '@mui/material';

function ChatButton() {
    
    function handleClick(evt) {
        alert("yes");
    }

  return (
    
      <IconButton data-aos="fade-left"  className="bg-primary text-light chat-button" size="large" id="chat-button" onClick={handleClick}>
                    <Chat />
                    </IconButton>
    
  )
}

export default ChatButton
