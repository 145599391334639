import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import http from '../../../../../http'

function PRFLSupporters(props, { handleClick }) {


    const [pavilionSlug, setPavilionSlug] = useState()

    const [supporters, setSupporters] = useState([])

    const location = useLocation();
    const splitURL = location.pathname.toString().split("/");
    

    useEffect(() => {
        if (props.idx) {
            http().get('/publicpavilionsponsors/' + props.idx).then((res) => {
                setSupporters(res.data.data)
                console.log(res.data.data)
            });
        }
        if (splitURL[1] === 'about_pavilion') {
            if (splitURL[2]) {
                //   setPavilionSlug(splitURL[2])
                http().get('/publicabout/' + splitURL[2]).then((res) => {
                    //   setPavilionId(res.data.data.aboutPavilion.id)
                    http().get('/publicpavilionsponsors/' + res.data.data.aboutPavilion.id).then((res) => {
                        // alert(res.data.data.length)
                        setSupporters(res.data.data)
                        console.log(res.data.data)
                    });
                });

            }
        }

    }, []);


    const [imagePath, setImagePath] = useState('https://bic3-live.s3.eu-west-2.amazonaws.com' + '/images/sponsor/');
    // const [imagePath, setImagePath]=useState(localStorage.getItem('bucketName')+'/images/sponsor/');


    return (
        <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <div>
                    {props.idx ? (
                        <IconButton onClick={event => props.handleClick('PVLN-MENU', props.idx)}><ArrowBackIcon /></IconButton>
                    ) : (
                        <IconButton onClick={event => props.handleClick('PRFL-MENU2')}><ArrowBackIcon /></IconButton>
                    )}

                    <Typography variant="small">Supporters</Typography>
                </div>
                {props.idx ? (
                    <IconButton onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
                ) : (
                    <IconButton onClick={event => props.handleClick('PRFL-MENU2')}><Home /></IconButton>
                )}
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 p-0" >
                <div className="container slim-scrollbar mh-70 text-center py-0 ">
                    {supporters.length>0 && (<div className='my-2 bw-bold'>Thank you for your support</div>)}
                    {supporters.length>12 && (<medium className='my-2'>- scroll for more -</medium>)}
                    <div className="profile-grid py-2 text-center px-2">
                        {supporters.length > 0 && supporters.map((innerArray, outerIndex) => (
                            <div className="border border border-2 p-2 d-flex justify-content-center align-items-center" style={{ minHeight: '100px', minWidth: '100px' }}  >
                                <Image key={outerIndex} src={imagePath + innerArray.logo} />
                            </div>
                        ))}
                    </div>
                    {supporters.length===0 && (<div className='my-2'>Supporters not found</div>)}
                </div>
            </ListGroup.Item>
        </ListGroup>
    )
}

export default PRFLSupporters
