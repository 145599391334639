import React, { useEffect, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import http from '../../../../http';

import Collapse from 'react-bootstrap/Collapse';
import Badge from 'react-bootstrap/Badge';
import Comment from '@mui/icons-material/MessageOutlined';
import Share from '@mui/icons-material/ReplyOutlined';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Comments from '../../../Comments';
import Like from '@mui/icons-material/FavoriteBorder';

function PVLNLibraryDetail(props, { handleClick }) {
  console.log('sssv1')
  console.log(props);
  console.log('sssv2')
  const [userdetails, setUserdetails] = useState([]);
  const [post, setPost] = useState([]);
  const [postfile, setPostfile] = useState([]);
  const [categoryPublication, setCategoryPublication] = useState([]);
  const [open, setOpen] = useState({});
  const [postvalues, setPostsValues] = useState([]);
  // const [postComments, setPostsValuesComments] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [urlData, setUrlData] = useState('');
  const [pdfData, setPdfData] = useState('');
  const [librarytags, setLibraryTags] = useState([]);


  const divStyle = {
    borderRadius: '40px',
  };
  useEffect(() => {
    http().get('/singlelibrarycomments/' + props.librarydataId).then((data) => {
      console.log(data)
      console.log('1')
      console.log(data.data.data.posts.data)
      setPostsValues(data.data.data.posts.data)
      // setPostsValuesComments(data.data.data.posts.comments)

      //comments
      // setPostsValues(data.data.data.posts.data);
      // setDomain(data.data.data.domain);
    });

    http().get('/librarydetail/' + props.pavilionId + '/' + props.librarydataId).then((data) => {
      console.log(data.data.data);
      setPost(data.data.data.libraryRecord);
      setPostfile(data.data.data.libraryRecord.image);
      setLibraryTags(data.data.data.libraryRecord.tags.split('@##@'));
      if (data.data.data.libraryRecord.url.length > 4) {
        if (data.data.data.libraryRecord.url.slice(0, 3) === 'URL') {
          setUrlData(data.data.data.libraryRecord.url.slice(3));
          setPdfData('');
        }
        if (data.data.data.libraryRecord.url.slice(0, 3) === 'PDF') {
          setPdfData(data.data.data.libraryRecord.url.slice(3));
          setUrlData('');
        }
      } else {
        setUrlData('');
        setPdfData('');
      }


    });
  }, []);

  const handleCommentsClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleCommentsLikeClick = (id) => {
    http().post('/likes/store', {
      type: "library",
      post_id: id,
    }).then((data) => {
      console.log(data);
      http().get('/singlewallpost/' + props.pavilionId + '/' + id + '/library').then((data) => {
        console.log(data)
        console.log(data.data.data.post.likes.length);
        document.getElementById('postlikecount' + id).innerHTML = data.data.data.post.likes.length;
      });
    });
  };

  // const handleEventClick = (idx) => {
  // }
  return (
    // <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
    //   <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
    //     <div>
    //       <IconButton onClick={event => handleClick(false, true, 'PVLN2')}><ArrowBackIcon /></IconButton>
    //       <Typography variant="small">Publication Details</Typography>
    //     </div>
    //     <IconButton onClick={event => handleClick(true, false, 'PVLN0')}><Home /></IconButton>

    //   </ListGroup.Item>
    <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
      <div className="container slim-scrollbar mh-70  py-0">
        <ListGroup className="overflow-hidden h-100 px-0 " style={{ borderRadius: "10px" }}>
          <ListGroup.Item active className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Row className="w-100 g-2 d-flex justify-content-between align-items-center ">
              <Col md={2}>
                <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + '/images/profile_picture/' + post.users_image} name="Name" size='40' />

              </Col>
              <Col md={10} className=' text-start'>
                <h6 className='mb-0'>{post.user_name}</h6>
                {/* <small>03 Tue Jan, 2023 at 03:07 PM</small> */}
              </Col>
            </Row>

            {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Image fluid
              src={localStorage.getItem('bucketName') + '/images/library/' + post.image}
            />
          </ListGroup.Item>

          <ListGroup.Item className='  border-0 py-1 '>
            <h6 className="fw-bold">{post.name}</h6>
            <p className='lead mb-1'><strong>Category</strong> :{post.category_name}</p>
            <p className='lead mb-1'><strong>Publication Date:</strong> {post.date}</p>
            <p className='lead mb-1'><strong>Description:</strong> {post.description}</p>
            <div className="d-flex justify-content-between align-items-center">
              <p className='lead mb-1'><strong>FREE</strong></p>
              {pdfData && (<>
                <a class="btn btn-primary" id={post.file} name={post.file} target="_blank" href={apiUrl + "/webawsfiledownload/" + pdfData + "/library"}  >Download</a>
              </>
              )
              }

			{urlData && (
              <>
                <a class="btn btn-primary" target="_blank" href={urlData}  >View</a>
              </>              
            )
            }
            </div>            
            <div className="d-flex justify-content-between align-items-center">
              <p className='w-50 lead mb-1'><strong>Document Type:</strong><br /> {post.document_name}</p>
              <p className='w-50 lead mb-1'><strong>Tags:</strong><br />
                {librarytags.map((librarytag, idx) => (
                  <>{librarytag.replace(/,/g, ', ')}
                  </>
                ))}

              </p>
            </div>
          </ListGroup.Item>
          {postvalues.map((innerArray, outerIndex) => (
            <>
              {innerArray.comments.map((LibraryCommentsArray, outerIndex) => (
                <>
                </>
              ))}
              <ListGroup.Item className="w-100 d-flex align-items-center justify-content-between h-100 p-0 border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                <ButtonGroup size="sm" className=" w-100 rounded-bottom">
                  <Button size="sm" variant="outline-secondary" className="small" onClick={() => handleCommentsLikeClick(innerArray.id)} ><Like fontSize='small' /> Like <Badge bg="primary" id={'postlikecount' + innerArray.id}>{innerArray.likes.length}</Badge></Button>
                  <Button size="sm" onClick={() => handleCommentsClick(innerArray.id)}
                    aria-controls="collapseComments3"
                    aria-expanded={open}
                    variant="outline-secondary" className='small'><Comment fontSize='small' /> Comment <Badge bg="primary" id={'postCommentcount' + innerArray.id}>{innerArray.all_comments.length > 0 && (innerArray.all_comments.length)}</Badge></Button>
                </ButtonGroup>
              </ListGroup.Item>
              <Collapse key={outerIndex} in={open[innerArray.id]} className='p-1'>
                <ListGroup.Item>
                  <div id="collapseComments3">
                    <Comments postId={innerArray.id} pavilionId={props.pavilionId} type='library' />
                  </div>
                </ListGroup.Item>
              </Collapse>
            </>
          ))}
        </ListGroup>
      </div>
    </ListGroup.Item >
  )
}
export default PVLNLibraryDetail
