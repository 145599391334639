import React, {useState, useEffect} from "react";
import { styled } from '@mui/material/styles';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { formatDate } from '../../../components/PavilionConstants';
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row, Col, Image
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from '../../components/AdminBreadCrumbs';

import Badge from '@mui/material/Badge';

import { Pagination } from "@mui/material";


import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from '@mui/icons-material/Add';
import { Typography } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import CRUDDropdown from "../../../components/CRUDDropdown";
import HiveIcon from '@mui/icons-material/Hive';
import HubIcon from '@mui/icons-material/Hub';
import Avatar from '@mui/material/Avatar';
import Swal from 'sweetalert2'
import adminhttp from '../../../adminhttp';
import { getPermissions } from "../../utils/utils";

const MembershipCard = (props) => {
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const data2 = {
    name: data.name,
    rolename: props.data.name,
  }
  const splitURL = location.pathname.toString().split("/");

  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = () => {
    //setShow(true)
  };
  const handleDropdown = (e) => {
    e.stopPropagation();
    //setShow(true)
  };

  const handleStatus = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: `Are you sure to ${props.data.status===1 ? 'Deactive' : 'Activate'} this Membership?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: `Yes, ${props.data.status===1 ? 'Deactive' : 'Activate'} this Membership!`
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().post('/admin/update_membership_status', {
          membership_id:props.data.id,
          pavilion_id: splitURL[3],
          status:props.data.status===1?0:1,
      }).then((data) => {
        Swal.fire({
          title: `Membership has been ${props.data.status===1 ? 'Deactivated' : 'Activated'}.`,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          if (result['isConfirmed']){
             props.handleRequest();
          }
        });
      });
      }
    });
  };

  useEffect(() => {
    getPermissions().then((res)=> (setPermissions(res)))
  },[Permissions]);
  return (
    
    <Accordion className='rounded-4 bg-toggle'>
    <AccordionSummary sx={{flexDirection: 'row-reverse'}}
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
      id="panel1-header"

    >

    <Box sx={{ flexGrow: 1 }} className='ms-3'>
      <Grid container spacing={2} >
        <Grid item xs={1} md={1} >
           <BadgeAvatar src={localStorage.getItem('bucketName')+'/images/pavilion/thumbnails/'+ props.data.image} name={props.data.name} size='50'/> 
          {/* <Avatar > <HubIcon /> </Avatar> */}
        </Grid>
        <Grid item xs={1} md={5} className='d-flex align-items-center'>
        <Typography variant="lead" component="h5" gutterBottom>
          {props.data.name}
          </Typography>
        </Grid>
        <Grid item xs={1} md={2} className='d-flex align-items-center'>
        <Typography variant="lead" component="h5" gutterBottom>
          £{props.data.price}.00
         </Typography>                    
       
        </Grid>
        <Grid item xs={1} md={3} className='d-flex align-items-center'>
        <Typography variant="lead" component="lead" gutterBottom>
          {formatDate(props.data.created_at)}
          </Typography>
        </Grid>
        {/* <Grid item md={1} className='d-flex align-items-center'>
           xs=8 
        </Grid> */}
        <Grid item xs={1} md={1} className='d-flex align-items-center justify-content-end' >
        <CRUDDropdown
            title={<MoreVertIcon fontSize="small" />}
            handleDropdown={(e)=>handleDropdown(e)}
            addTitle="Edit Membership"
            addClick={props.handleEdit(props.data.id)}
            editTitle={
              props.data.status === 1
                ? "Deactivate"
                : "Activate"
            }
            editClick={handleStatus}
            deleteTitle={permissions['view-pavilion-acl'] && "ACL"}
            deleteClick={() => navigate(`${props.data.id}`, { state: data2 })}
          />
        </Grid>
      </Grid>
    </Box>
  
    </AccordionSummary>
    <AccordionDetails>
      <h5>Description</h5>
       <p className='lead' dangerouslySetInnerHTML={{ __html: props.data.description }} /> 
      {/* {props.data.description} */}
    </AccordionDetails>
  </Accordion>
//     <Card className='rounded-3'   sx={{':hover': {boxShadow: 5},}}>
//     <div style={{ position: "relative" }}>
//       <CardMedia style={{ }}   component="img" image={"https://img.freepik.com/free-vector/colorful-watercolor-background_23-2148492944.jpg?size=626&ext=jpg&ga=GA1.1.942094041.1718883224&semt=ais_hybrid"} title="Pancakes" alt="Pancakes"/> 
//       <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',position: "absolute", color: "white",top: 0,left: "50%",transform: "translateX(-50%)",}}> 
//       <div className='h-100 d-flex flex-column align-items-center justify-content-center w-100'>
//         <BadgeAvatar
//                   src={localStorage.getItem('bucketName')+'images/profile_picture/'+ props.data.image}
//                   name={props.data.name}
//                   size="70"
//                 />    
//                   <Typography variant='h5' className='mt-1 text-light fw-bold'>{props.data.name}</Typography>
//                   <Typography variant='h6' className='text-light'>{props.data.email}</Typography>
//         <ProgressBar now={60} variant='warning' style={{height:'5px' , width:'100%'}} className='w-75 mt-3' />
//         </div>
//       </div>
//   </div>
//   <CardContent className='pb-1 pt-4 text-center'>
//   <Grid container spacing={2}>
//         <Grid item xs={4}>
//         <Badge fullWidth className='mb-3' color="success" max={formatDate(props.data.last_login_at)} badgeContent={props.data.last_login_at ? formatDate(props.data.last_login) : '0' }></Badge>
//         <h6>Last Login</h6>
//         </Grid>
//         <Grid item xs={4}>
//         <Badge  className='mb-3' color="warning" max={formatDate(props.data.last_seen)} badgeContent={formatDate(props.data.last_seen)}></Badge>
//         <h6>Last Seen</h6>
//         </Grid>
//         <Grid item xs={4}>
//         <Badge  className='mb-3' color="info" max={props.data.status} badgeContent={props.data.status===1 ? 'Active' : 'Deactive'}></Badge>
//         <h6>Status</h6>
//         </Grid>
//       </Grid>
//       </CardContent>
// </Card>
  )
}

export default MembershipCard
