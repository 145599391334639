export const markers = [
    // { markerOffset: -15, name: "Buenos Aires", coordinates: [-58.3816, -34.6037] , icon: 'https://flagcdn.com/h80/ua.png'},,,
    // { markerOffset: -15, name: "La Paz", coordinates: [-68.1193, -16.4897] },,,
    // { markerOffset: 25, name: "Brasilia", coordinates: [-47.8825, -15.7942] },,,
    // { markerOffset: 25, name: "Santiago", coordinates: [-70.6693, -33.4489] },,,
    // { markerOffset: 25, name: "Bogota", coordinates: [-74.0721, 4.711] },,,
    // { markerOffset: 25, name: "Quito", coordinates: [-78.4678, -0.1807] },,,
    // { markerOffset: -15, name: "Georgetown", coordinates: [-58.1551, 6.8013] },,,
    // { markerOffset: -15, name: "Asuncion", coordinates: [-57.5759, -25.2637] },,,
    // { markerOffset: 25, name: "Paramaribo", coordinates: [-55.2038, 5.852] },,,
    // { markerOffset: 25, name: "Montevideo", coordinates: [-56.1645, -34.9011] },,,
    // { markerOffset: -15, name: "Caracas", coordinates: [-66.9036, 10.4806] },,,
    // { markerOffset: -15, name: "Lima", coordinates: [-77.0428, -12.0464] },,
    { markersOffset: -15, name: "Andorra", coordinates: [1.601554,42.546245], icon: "https://flagpedia.net/data/flags/h80/ad.webp"},
    { markersOffset: -15, name: "United Arab Emirates", coordinates: [53.847818,23.424076], icon: "https://flagpedia.net/data/flags/h80/ae.webp"},
    { markersOffset: -15, name: "Afghanistan", coordinates: [67.709953,33.93911], icon: "https://flagpedia.net/data/flags/h80/af.webp"},
    { markersOffset: -15, name: "Antigua and Barbuda", coordinates: [-61.796428,17.060816], icon: "https://flagpedia.net/data/flags/h80/ag.webp"},
    { markersOffset: -15, name: "Anguilla", coordinates: [-63.068615,18.220554], icon: "https://flagpedia.net/data/flags/h80/ai.webp"},
    { markersOffset: -15, name: "Albania", coordinates: [20.168331,41.153332], icon: "https://flagpedia.net/data/flags/h80/al.webp"},
    { markersOffset: -15, name: "Armenia", coordinates: [45.038189,40.069099], icon: "https://flagpedia.net/data/flags/h80/am.webp"},
    { markersOffset: -15, name: "Netherlands Antilles", coordinates: [-69.060087,12.226079], icon: "https://flagpedia.net/data/flags/h80/an.webp"},
    { markersOffset: -15, name: "Angola", coordinates: [17.873887,-11.202692], icon: "https://flagpedia.net/data/flags/h80/ao.webp"},
    { markersOffset: -15, name: "Antarctica", coordinates: [-0.071389,-75.250973], icon: "https://flagpedia.net/data/flags/h80/aq.webp"},
    { markersOffset: -15, name: "Argentina", coordinates: [-63.616672,-38.416097], icon: "https://flagpedia.net/data/flags/h80/ar.webp"},
    { markersOffset: -15, name: "American Samoa", coordinates: [-170.132217,-14.270972], icon: "https://flagpedia.net/data/flags/h80/as.webp"},
    { markersOffset: -15, name: "Austria", coordinates: [14.550072,47.516231], icon: "https://flagpedia.net/data/flags/h80/at.webp"},
    { markersOffset: -15, name: "Australia", coordinates: [133.775136,-25.274398], icon: "https://flagpedia.net/data/flags/h80/au.webp"},
    { markersOffset: -15, name: "Aruba", coordinates: [-69.968338,12.52111], icon: "https://flagpedia.net/data/flags/h80/aw.webp"},
    { markersOffset: -15, name: "Azerbaijan", coordinates: [47.576927,40.143105], icon: "https://flagpedia.net/data/flags/h80/az.webp"},
    { markersOffset: -15, name: "Bosnia and Herzegovina", coordinates: [17.679076,43.915886], icon: "https://flagpedia.net/data/flags/h80/ba.webp"},
    { markersOffset: -15, name: "Barbados", coordinates: [-59.543198,13.193887], icon: "https://flagpedia.net/data/flags/h80/bb.webp"},
    { markersOffset: -15, name: "Bangladesh", coordinates: [90.356331,23.684994], icon: "https://flagpedia.net/data/flags/h80/bd.webp"},
    { markersOffset: -15, name: "Belgium", coordinates: [4.469936,50.503887], icon: "https://flagpedia.net/data/flags/h80/be.webp"},
    { markersOffset: -15, name: "Burkina Faso", coordinates: [-1.561593,12.238333], icon: "https://flagpedia.net/data/flags/h80/bf.webp"},
    { markersOffset: -15, name: "Bulgaria", coordinates: [25.48583,42.733883], icon: "https://flagpedia.net/data/flags/h80/bg.webp"},
    { markersOffset: -15, name: "Bahrain", coordinates: [50.637772,25.930414], icon: "https://flagpedia.net/data/flags/h80/bh.webp"},
    { markersOffset: -15, name: "Burundi", coordinates: [29.918886,-3.373056], icon: "https://flagpedia.net/data/flags/h80/bi.webp"},
    { markersOffset: -15, name: "Benin", coordinates: [2.315834,9.30769], icon: "https://flagpedia.net/data/flags/h80/bj.webp"},
    { markersOffset: -15, name: "Bermuda", coordinates: [-64.75737,32.321384], icon: "https://flagpedia.net/data/flags/h80/bm.webp"},
    { markersOffset: -15, name: "Brunei", coordinates: [114.727669,4.535277], icon: "https://flagpedia.net/data/flags/h80/bn.webp"},
    { markersOffset: -15, name: "Bolivia", coordinates: [-63.588653,-16.290154], icon: "https://flagpedia.net/data/flags/h80/bo.webp"},
    { markersOffset: -15, name: "Brazil", coordinates: [-51.92528,-14.235004], icon: "https://flagpedia.net/data/flags/h80/br.webp"},
    { markersOffset: -15, name: "Bahamas", coordinates: [-77.39628,25.03428], icon: "https://flagpedia.net/data/flags/h80/bs.webp"},
    { markersOffset: -15, name: "Bhutan", coordinates: [90.433601,27.514162], icon: "https://flagpedia.net/data/flags/h80/bt.webp"},
    { markersOffset: -15, name: "Bouvet Island", coordinates: [3.413194,-54.423199], icon: "https://flagpedia.net/data/flags/h80/bv.webp"},
    { markersOffset: -15, name: "Botswana", coordinates: [24.684866,-22.328474], icon: "https://flagpedia.net/data/flags/h80/bw.webp"},
    { markersOffset: -15, name: "Belarus", coordinates: [27.953389,53.709807], icon: "https://flagpedia.net/data/flags/h80/by.webp"},
    { markersOffset: -15, name: "Belize", coordinates: [-88.49765,17.189877], icon: "https://flagpedia.net/data/flags/h80/bz.webp"},
    { markersOffset: -15, name: "Canada", coordinates: [-106.346771,56.130366], icon: "https://flagpedia.net/data/flags/h80/ca.webp"},
    { markersOffset: -15, name: "Cocos [Keeling] Islands", coordinates: [96.870956,-12.164165], icon: "https://flagpedia.net/data/flags/h80/cc.webp"},
    { markersOffset: -15, name: "Congo [DRC]", coordinates: [21.758664,-4.038333], icon: "https://flagpedia.net/data/flags/h80/cd.webp"},
    { markersOffset: -15, name: "Central African Republic", coordinates: [20.939444,6.611111], icon: "https://flagpedia.net/data/flags/h80/cf.webp"},
    { markersOffset: -15, name: "Congo [Republic]", coordinates: [15.827659,-0.228021], icon: "https://flagpedia.net/data/flags/h80/cg.webp"},
    { markersOffset: -15, name: "Switzerland", coordinates: [8.227512,46.818188], icon: "https://flagpedia.net/data/flags/h80/ch.webp"},
    { markersOffset: -15, name: "Côte d'Ivoire", coordinates: [-5.54708,7.539989], icon: "https://flagpedia.net/data/flags/h80/ci.webp"},
    { markersOffset: -15, name: "Cook Islands", coordinates: [-159.777671,-21.236736], icon: "https://flagpedia.net/data/flags/h80/ck.webp"},
    { markersOffset: -15, name: "Chile", coordinates: [-71.542969,-35.675147], icon: "https://flagpedia.net/data/flags/h80/cl.webp"},
    { markersOffset: -15, name: "Cameroon", coordinates: [12.354722,7.369722], icon: "https://flagpedia.net/data/flags/h80/cm.webp"},
    { markersOffset: -15, name: "China", coordinates: [104.195397,35.86166], icon: "https://flagpedia.net/data/flags/h80/cn.webp"},
    { markersOffset: -15, name: "Colombia", coordinates: [-74.297333,4.570868], icon: "https://flagpedia.net/data/flags/h80/co.webp"},
    { markersOffset: -15, name: "Costa Rica", coordinates: [-83.753428,9.748917], icon: "https://flagpedia.net/data/flags/h80/cr.webp"},
    { markersOffset: -15, name: "Cuba", coordinates: [-77.781167,21.521757], icon: "https://flagpedia.net/data/flags/h80/cu.webp"},
    { markersOffset: -15, name: "Cape Verde", coordinates: [-24.013197,16.002082], icon: "https://flagpedia.net/data/flags/h80/cv.webp"},
    { markersOffset: -15, name: "Christmas Island", coordinates: [105.690449,-10.447525], icon: "https://flagpedia.net/data/flags/h80/cx.webp"},
    { markersOffset: -15, name: "Cyprus", coordinates: [33.429859,35.126413], icon: "https://flagpedia.net/data/flags/h80/cy.webp"},
    { markersOffset: -15, name: "Czech Republic", coordinates: [15.472962,49.817492], icon: "https://flagpedia.net/data/flags/h80/cz.webp"},
    { markersOffset: -15, name: "Germany", coordinates: [10.451526,51.165691], icon: "https://flagpedia.net/data/flags/h80/de.webp"},
    { markersOffset: -15, name: "Djibouti", coordinates: [42.590275,11.825138], icon: "https://flagpedia.net/data/flags/h80/dj.webp"},
    { markersOffset: -15, name: "Denmark", coordinates: [9.501785,56.26392], icon: "https://flagpedia.net/data/flags/h80/dk.webp"},
    { markersOffset: -15, name: "Dominica", coordinates: [-61.370976,15.414999], icon: "https://flagpedia.net/data/flags/h80/dm.webp"},
    { markersOffset: -15, name: "Dominican Republic", coordinates: [-70.162651,18.735693], icon: "https://flagpedia.net/data/flags/h80/do.webp"},
    { markersOffset: -15, name: "Algeria", coordinates: [1.659626,28.033886], icon: "https://flagpedia.net/data/flags/h80/dz.webp"},
    { markersOffset: -15, name: "Ecuador", coordinates: [-78.183406,-1.831239], icon: "https://flagpedia.net/data/flags/h80/ec.webp"},
    { markersOffset: -15, name: "Estonia", coordinates: [25.013607,58.595272], icon: "https://flagpedia.net/data/flags/h80/ee.webp"},
    { markersOffset: -15, name: "Egypt", coordinates: [30.802498,26.820553], icon: "https://flagpedia.net/data/flags/h80/eg.webp"},
    { markersOffset: -15, name: "Western Sahara", coordinates: [-12.885834,24.215527], icon: "https://flagpedia.net/data/flags/h80/eh.webp"},
    { markersOffset: -15, name: "Eritrea", coordinates: [39.782334,15.179384], icon: "https://flagpedia.net/data/flags/h80/er.webp"},
    { markersOffset: -15, name: "Spain", coordinates: [-3.74922,40.463667], icon: "https://flagpedia.net/data/flags/h80/es.webp"},
    { markersOffset: -15, name: "Ethiopia", coordinates: [40.489673,9.145], icon: "https://flagpedia.net/data/flags/h80/et.webp"},
    { markersOffset: -15, name: "Finland", coordinates: [25.748151,61.92411], icon: "https://flagpedia.net/data/flags/h80/fi.webp"},
    { markersOffset: -15, name: "Fiji", coordinates: [179.414413,-16.578193], icon: "https://flagpedia.net/data/flags/h80/fj.webp"},
    { markersOffset: -15, name: "Falkland Islands [Islas Malvinas]", coordinates: [-59.523613,-51.796253], icon: "https://flagpedia.net/data/flags/h80/fk.webp"},
    { markersOffset: -15, name: "Micronesia", coordinates: [150.550812,7.425554], icon: "https://flagpedia.net/data/flags/h80/fm.webp"},
    { markersOffset: -15, name: "Faroe Islands", coordinates: [-6.911806,61.892635], icon: "https://flagpedia.net/data/flags/h80/fo.webp"},
    { markersOffset: -15, name: "France", coordinates: [2.213749,46.227638], icon: "https://flagpedia.net/data/flags/h80/fr.webp"},
    { markersOffset: -15, name: "Gabon", coordinates: [11.609444,-0.803689], icon: "https://flagpedia.net/data/flags/h80/ga.webp"},
    { markersOffset: -15, name: "United Kingdom", coordinates: [-3.435973,55.378051], icon: "https://flagpedia.net/data/flags/h80/gb.webp"},
    { markersOffset: -15, name: "Grenada", coordinates: [-61.604171,12.262776], icon: "https://flagpedia.net/data/flags/h80/gd.webp"},
    { markersOffset: -15, name: "Georgia", coordinates: [43.356892,42.315407], icon: "https://flagpedia.net/data/flags/h80/ge.webp"},
    { markersOffset: -15, name: "French Guiana", coordinates: [-53.125782,3.933889], icon: "https://flagpedia.net/data/flags/h80/gf.webp"},
    { markersOffset: -15, name: "Guernsey", coordinates: [-2.585278,49.465691], icon: "https://flagpedia.net/data/flags/h80/gg.webp"},
    { markersOffset: -15, name: "Ghana", coordinates: [-1.023194,7.946527], icon: "https://flagpedia.net/data/flags/h80/gh.webp"},
    { markersOffset: -15, name: "Gibraltar", coordinates: [-5.345374,36.137741], icon: "https://flagpedia.net/data/flags/h80/gi.webp"},
    { markersOffset: -15, name: "Greenland", coordinates: [-42.604303,71.706936], icon: "https://flagpedia.net/data/flags/h80/gl.webp"},
    { markersOffset: -15, name: "Gambia", coordinates: [-15.310139,13.443182], icon: "https://flagpedia.net/data/flags/h80/gm.webp"},
    { markersOffset: -15, name: "Guinea", coordinates: [-9.696645,9.945587], icon: "https://flagpedia.net/data/flags/h80/gn.webp"},
    { markersOffset: -15, name: "Guadeloupe", coordinates: [-62.067641,16.995971], icon: "https://flagpedia.net/data/flags/h80/gp.webp"},
    { markersOffset: -15, name: "Equatorial Guinea", coordinates: [10.267895,1.650801], icon: "https://flagpedia.net/data/flags/h80/gq.webp"},
    { markersOffset: -15, name: "Greece", coordinates: [21.824312,39.074208], icon: "https://flagpedia.net/data/flags/h80/gr.webp"},
    { markersOffset: -15, name: "South Georgia and the South Sandwich Islands", coordinates: [-36.587909,-54.429579], icon: "https://flagpedia.net/data/flags/h80/gs.webp"},
    { markersOffset: -15, name: "Guatemala", coordinates: [-90.230759,15.783471], icon: "https://flagpedia.net/data/flags/h80/gt.webp"},
    { markersOffset: -15, name: "Guam", coordinates: [144.793731,13.444304], icon: "https://flagpedia.net/data/flags/h80/gu.webp"},
    { markersOffset: -15, name: "Guinea-Bissau", coordinates: [-15.180413,11.803749], icon: "https://flagpedia.net/data/flags/h80/gw.webp"},
    { markersOffset: -15, name: "Guyana", coordinates: [-58.93018,4.860416], icon: "https://flagpedia.net/data/flags/h80/gy.webp"},
    // { markersOffset: -15, name: "Gaza Strip", coordinates: [34.308825,31.354676], icon: "https://flagpedia.net/data/flags/h80/gz.webp"},
    { markersOffset: -15, name: "Hong Kong", coordinates: [114.109497,22.396428], icon: "https://flagpedia.net/data/flags/h80/hk.webp"},
    { markersOffset: -15, name: "Heard Island and McDonald Islands", coordinates: [73.504158,-53.08181], icon: "https://flagpedia.net/data/flags/h80/hm.webp"},
    { markersOffset: -15, name: "Honduras", coordinates: [-86.241905,15.199999], icon: "https://flagpedia.net/data/flags/h80/hn.webp"},
    { markersOffset: -15, name: "Croatia", coordinates: [15.2,45.1], icon: "https://flagpedia.net/data/flags/h80/hr.webp"},
    { markersOffset: -15, name: "Haiti", coordinates: [-72.285215,18.971187], icon: "https://flagpedia.net/data/flags/h80/ht.webp"},
    { markersOffset: -15, name: "Hungary", coordinates: [19.503304,47.162494], icon: "https://flagpedia.net/data/flags/h80/hu.webp"},
    { markersOffset: -15, name: "Indonesia", coordinates: [113.921327,-0.789275], icon: "https://flagpedia.net/data/flags/h80/id.webp"},
    { markersOffset: -15, name: "Ireland", coordinates: [-8.24389,53.41291], icon: "https://flagpedia.net/data/flags/h80/ie.webp"},
    { markersOffset: -15, name: "Israel", coordinates: [34.851612,31.046051], icon: "https://flagpedia.net/data/flags/h80/il.webp"},
    { markersOffset: -15, name: "Isle of Man", coordinates: [-4.548056,54.236107], icon: "https://flagpedia.net/data/flags/h80/im.webp"},
    { markersOffset: -15, name: "India", coordinates: [78,23], icon: "https://flagpedia.net/data/flags/h80/in.webp"},
    { markersOffset: -15, name: "British Indian Ocean Territory", coordinates: [71.876519,-6.343194], icon: "https://flagpedia.net/data/flags/h80/io.webp"},
    { markersOffset: -15, name: "Iraq", coordinates: [43.679291,33.223191], icon: "https://flagpedia.net/data/flags/h80/iq.webp"},
    { markersOffset: -15, name: "Iran", coordinates: [53.688046,32.427908], icon: "https://flagpedia.net/data/flags/h80/ir.webp"},
    { markersOffset: -15, name: "Iceland", coordinates: [-19.020835,64.963051], icon: "https://flagpedia.net/data/flags/h80/is.webp"},
    { markersOffset: -15, name: "Italy", coordinates: [12.56738,41.87194], icon: "https://flagpedia.net/data/flags/h80/it.webp"},
    { markersOffset: -15, name: "Jersey", coordinates: [-2.13125,49.214439], icon: "https://flagpedia.net/data/flags/h80/je.webp"},
    { markersOffset: -15, name: "Jamaica", coordinates: [-77.297508,18.109581], icon: "https://flagpedia.net/data/flags/h80/jm.webp"},
    { markersOffset: -15, name: "Jordan", coordinates: [36.238414,30.585164], icon: "https://flagpedia.net/data/flags/h80/jo.webp"},
    { markersOffset: -15, name: "Japan", coordinates: [138.252924,36.204824], icon: "https://flagpedia.net/data/flags/h80/jp.webp"},
    { markersOffset: -15, name: "Kenya", coordinates: [37.906193,-0.023559], icon: "https://flagpedia.net/data/flags/h80/ke.webp"},
    { markersOffset: -15, name: "Kyrgyzstan", coordinates: [74.766098,41.20438], icon: "https://flagpedia.net/data/flags/h80/kg.webp"},
    { markersOffset: -15, name: "Cambodia", coordinates: [104.990963,12.565679], icon: "https://flagpedia.net/data/flags/h80/kh.webp"},
    { markersOffset: -15, name: "Kiribati", coordinates: [-168.734039,-3.370417], icon: "https://flagpedia.net/data/flags/h80/ki.webp"},
    { markersOffset: -15, name: "Comoros", coordinates: [43.872219,-11.875001], icon: "https://flagpedia.net/data/flags/h80/km.webp"},
    { markersOffset: -15, name: "Saint Kitts and Nevis", coordinates: [-62.782998,17.357822], icon: "https://flagpedia.net/data/flags/h80/kn.webp"},
    { markersOffset: -15, name: "North Korea", coordinates: [127.510093,40.339852], icon: "https://flagpedia.net/data/flags/h80/kp.webp"},
    { markersOffset: -15, name: "South Korea", coordinates: [127.766922,35.907757], icon: "https://flagpedia.net/data/flags/h80/kr.webp"},
    { markersOffset: -15, name: "Kuwait", coordinates: [47.481766,29.31166], icon: "https://flagpedia.net/data/flags/h80/kw.webp"},
    { markersOffset: -15, name: "Cayman Islands", coordinates: [-80.566956,19.513469], icon: "https://flagpedia.net/data/flags/h80/ky.webp"},
    { markersOffset: -15, name: "Kazakhstan", coordinates: [66.923684,48.019573], icon: "https://flagpedia.net/data/flags/h80/kz.webp"},
    { markersOffset: -15, name: "Laos", coordinates: [102.495496,19.85627], icon: "https://flagpedia.net/data/flags/h80/la.webp"},
    { markersOffset: -15, name: "Lebanon", coordinates: [35.862285,33.854721], icon: "https://flagpedia.net/data/flags/h80/lb.webp"},
    { markersOffset: -15, name: "Saint Lucia", coordinates: [-60.978893,13.909444], icon: "https://flagpedia.net/data/flags/h80/lc.webp"},
    { markersOffset: -15, name: "Liechtenstein", coordinates: [9.555373,47.166], icon: "https://flagpedia.net/data/flags/h80/li.webp"},
    { markersOffset: -15, name: "Sri Lanka", coordinates: [80.771797,7.873054], icon: "https://flagpedia.net/data/flags/h80/lk.webp"},
    { markersOffset: -15, name: "Liberia", coordinates: [-9.429499,6.428055], icon: "https://flagpedia.net/data/flags/h80/lr.webp"},
    { markersOffset: -15, name: "Lesotho", coordinates: [28.233608,-29.609988], icon: "https://flagpedia.net/data/flags/h80/ls.webp"},
    { markersOffset: -15, name: "Lithuania", coordinates: [23.881275,55.169438], icon: "https://flagpedia.net/data/flags/h80/lt.webp"},
    { markersOffset: -15, name: "Luxembourg", coordinates: [6.129583,49.815273], icon: "https://flagpedia.net/data/flags/h80/lu.webp"},
    { markersOffset: -15, name: "Latvia", coordinates: [24.603189,56.879635], icon: "https://flagpedia.net/data/flags/h80/lv.webp"},
    { markersOffset: -15, name: "Libya", coordinates: [17.228331,26.3351], icon: "https://flagpedia.net/data/flags/h80/ly.webp"},
    { markersOffset: -15, name: "Morocco", coordinates: [-7.09262,31.791702], icon: "https://flagpedia.net/data/flags/h80/ma.webp"},
    { markersOffset: -15, name: "Monaco", coordinates: [7.412841,43.750298], icon: "https://flagpedia.net/data/flags/h80/mc.webp"},
    { markersOffset: -15, name: "Moldova", coordinates: [28.369885,47.411631], icon: "https://flagpedia.net/data/flags/h80/md.webp"},
    { markersOffset: -15, name: "Montenegro", coordinates: [19.37439,42.708678], icon: "https://flagpedia.net/data/flags/h80/me.webp"},
    { markersOffset: -15, name: "Madagascar", coordinates: [46.869107,-18.766947], icon: "https://flagpedia.net/data/flags/h80/mg.webp"},
    { markersOffset: -15, name: "Marshall Islands", coordinates: [171.184478,7.131474], icon: "https://flagpedia.net/data/flags/h80/mh.webp"},
    { markersOffset: -15, name: "Macedonia [FYROM]", coordinates: [21.745275,41.608635], icon: "https://flagpedia.net/data/flags/h80/mk.webp"},
    { markersOffset: -15, name: "Mali", coordinates: [-3.996166,17.570692], icon: "https://flagpedia.net/data/flags/h80/ml.webp"},
    { markersOffset: -15, name: "Myanmar [Burma]", coordinates: [95.956223,21.913965], icon: "https://flagpedia.net/data/flags/h80/mm.webp"},
    { markersOffset: -15, name: "Mongolia", coordinates: [103.846656,46.862496], icon: "https://flagpedia.net/data/flags/h80/mn.webp"},
    { markersOffset: -15, name: "Macau", coordinates: [113.543873,22.198745], icon: "https://flagpedia.net/data/flags/h80/mo.webp"},
    { markersOffset: -15, name: "Northern Mariana Islands", coordinates: [145.38469,17.33083], icon: "https://flagpedia.net/data/flags/h80/mp.webp"},
    { markersOffset: -15, name: "Martinique", coordinates: [-61.024174,14.641528], icon: "https://flagpedia.net/data/flags/h80/mq.webp"},
    { markersOffset: -15, name: "Mauritania", coordinates: [-10.940835,21.00789], icon: "https://flagpedia.net/data/flags/h80/mr.webp"},
    { markersOffset: -15, name: "Montserrat", coordinates: [-62.187366,16.742498], icon: "https://flagpedia.net/data/flags/h80/ms.webp"},
    { markersOffset: -15, name: "Malta", coordinates: [14.375416,35.937496], icon: "https://flagpedia.net/data/flags/h80/mt.webp"},
    { markersOffset: -15, name: "Mauritius", coordinates: [57.552152,-20.348404], icon: "https://flagpedia.net/data/flags/h80/mu.webp"},
    { markersOffset: -15, name: "Maldives", coordinates: [73.22068,3.202778], icon: "https://flagpedia.net/data/flags/h80/mv.webp"},
    { markersOffset: -15, name: "Malawi", coordinates: [34.301525,-13.254308], icon: "https://flagpedia.net/data/flags/h80/mw.webp"},
    { markersOffset: -15, name: "Mexico", coordinates: [-102.552784,23.634501], icon: "https://flagpedia.net/data/flags/h80/mx.webp"},
    { markersOffset: -15, name: "Malaysia", coordinates: [101.975766,4.210484], icon: "https://flagpedia.net/data/flags/h80/my.webp"},
    { markersOffset: -15, name: "Mozambique", coordinates: [35.529562,-18.665695], icon: "https://flagpedia.net/data/flags/h80/mz.webp"},
    { markersOffset: -15, name: "Namibia", coordinates: [18.49041,-22.95764], icon: "https://flagpedia.net/data/flags/h80/na.webp"},
    { markersOffset: -15, name: "New Caledonia", coordinates: [165.618042,-20.904305], icon: "https://flagpedia.net/data/flags/h80/nc.webp"},
    { markersOffset: -15, name: "Niger", coordinates: [8.081666,17.607789], icon: "https://flagpedia.net/data/flags/h80/ne.webp"},
    { markersOffset: -15, name: "Norfolk Island", coordinates: [167.954712,-29.040835], icon: "https://flagpedia.net/data/flags/h80/nf.webp"},
    { markersOffset: -15, name: "Nigeria", coordinates: [8.675277,9.081999], icon: "https://flagpedia.net/data/flags/h80/ng.webp"},
    { markersOffset: -15, name: "Nicaragua", coordinates: [-85.207229,12.865416], icon: "https://flagpedia.net/data/flags/h80/ni.webp"},
    { markersOffset: -15, name: "Netherlands", coordinates: [5.291266,52.132633], icon: "https://flagpedia.net/data/flags/h80/nl.webp"},
    { markersOffset: -15, name: "Norway", coordinates: [8.468946,60.472024], icon: "https://flagpedia.net/data/flags/h80/no.webp"},
    { markersOffset: -15, name: "Nepal", coordinates: [84.124008,28.394857], icon: "https://flagpedia.net/data/flags/h80/np.webp"},
    { markersOffset: -15, name: "Nauru", coordinates: [166.931503,-0.522778], icon: "https://flagpedia.net/data/flags/h80/nr.webp"},
    { markersOffset: -15, name: "Niue", coordinates: [-169.867233,-19.054445], icon: "https://flagpedia.net/data/flags/h80/nu.webp"},
    { markersOffset: -15, name: "New Zealand", coordinates: [174.885971,-40.900557], icon: "https://flagpedia.net/data/flags/h80/nz.webp"},
    { markersOffset: -15, name: "Oman", coordinates: [55.923255,21.512583], icon: "https://flagpedia.net/data/flags/h80/om.webp"},
    { markersOffset: -15, name: "Panama", coordinates: [-80.782127,8.537981], icon: "https://flagpedia.net/data/flags/h80/pa.webp"},
    { markersOffset: -15, name: "Peru", coordinates: [-75.015152,-9.189967], icon: "https://flagpedia.net/data/flags/h80/pe.webp"},
    { markersOffset: -15, name: "French Polynesia", coordinates: [-149.406843,-17.679742], icon: "https://flagpedia.net/data/flags/h80/pf.webp"},
    { markersOffset: -15, name: "Papua New Guinea", coordinates: [143.95555,-6.314993], icon: "https://flagpedia.net/data/flags/h80/pg.webp"},
    { markersOffset: -15, name: "Philippines", coordinates: [121.774017,12.879721], icon: "https://flagpedia.net/data/flags/h80/ph.webp"},
    { markersOffset: -15, name: "Pakistan", coordinates: [69.345116,30.375321], icon: "https://flagpedia.net/data/flags/h80/pk.webp"},
    { markersOffset: -15, name: "Poland", coordinates: [19.145136,51.919438], icon: "https://flagpedia.net/data/flags/h80/pl.webp"},
    { markersOffset: -15, name: "Saint Pierre and Miquelon", coordinates: [-56.27111,46.941936], icon: "https://flagpedia.net/data/flags/h80/pm.webp"},
    { markersOffset: -15, name: "Pitcairn Islands", coordinates: [-127.439308,-24.703615], icon: "https://flagpedia.net/data/flags/h80/pn.webp"},
    { markersOffset: -15, name: "Puerto Rico", coordinates: [-66.590149,18.220833], icon: "https://flagpedia.net/data/flags/h80/pr.webp"},
    { markersOffset: -15, name: "Palestinian Territories", coordinates: [35.233154,31.952162], icon: "https://flagpedia.net/data/flags/h80/ps.webp"},
    { markersOffset: -15, name: "Portugal", coordinates: [-8.224454,39.399872], icon: "https://flagpedia.net/data/flags/h80/pt.webp"},
    { markersOffset: -15, name: "Palau", coordinates: [134.58252,7.51498], icon: "https://flagpedia.net/data/flags/h80/pw.webp"},
    { markersOffset: -15, name: "Paraguay", coordinates: [-58.443832,-23.442503], icon: "https://flagpedia.net/data/flags/h80/py.webp"},
    { markersOffset: -15, name: "Qatar", coordinates: [51.183884,25.354826], icon: "https://flagpedia.net/data/flags/h80/qa.webp"},
    { markersOffset: -15, name: "Réunion", coordinates: [55.536384,-21.115141], icon: "https://flagpedia.net/data/flags/h80/re.webp"},
    { markersOffset: -15, name: "Romania", coordinates: [24.96676,45.943161], icon: "https://flagpedia.net/data/flags/h80/ro.webp"},
    { markersOffset: -15, name: "Serbia", coordinates: [21.005859,44.016521], icon: "https://flagpedia.net/data/flags/h80/rs.webp"},
    { markersOffset: -15, name: "Russia", coordinates: [105.318756,61.52401], icon: "https://flagpedia.net/data/flags/h80/ru.webp"},
    { markersOffset: -15, name: "Rwanda", coordinates: [29.873888,-1.940278], icon: "https://flagpedia.net/data/flags/h80/rw.webp"},
    { markersOffset: -15, name: "Saudi Arabia", coordinates: [45.079162,23.885942], icon: "https://flagpedia.net/data/flags/h80/sa.webp"},
    { markersOffset: -15, name: "Solomon Islands", coordinates: [160.156194,-9.64571], icon: "https://flagpedia.net/data/flags/h80/sb.webp"},
    { markersOffset: -15, name: "Seychelles", coordinates: [55.491977,-4.679574], icon: "https://flagpedia.net/data/flags/h80/sc.webp"},
    { markersOffset: -15, name: "Sudan", coordinates: [30.217636,12.862807], icon: "https://flagpedia.net/data/flags/h80/sd.webp"},
    { markersOffset: -15, name: "Sweden", coordinates: [18.643501,60.128161], icon: "https://flagpedia.net/data/flags/h80/se.webp"},
    { markersOffset: -15, name: "Singapore", coordinates: [103.819836,1.352083], icon: "https://flagpedia.net/data/flags/h80/sg.webp"},
    { markersOffset: -15, name: "Saint Helena", coordinates: [-10.030696,-24.143474], icon: "https://flagpedia.net/data/flags/h80/sh.webp"},
    { markersOffset: -15, name: "Slovenia", coordinates: [14.995463,46.151241], icon: "https://flagpedia.net/data/flags/h80/si.webp"},
    { markersOffset: -15, name: "Svalbard and Jan Mayen", coordinates: [23.670272,77.553604], icon: "https://flagpedia.net/data/flags/h80/sj.webp"},
    { markersOffset: -15, name: "Slovakia", coordinates: [19.699024,48.669026], icon: "https://flagpedia.net/data/flags/h80/sk.webp"},
    { markersOffset: -15, name: "Sierra Leone", coordinates: [-11.779889,8.460555], icon: "https://flagpedia.net/data/flags/h80/sl.webp"},
    { markersOffset: -15, name: "San Marino", coordinates: [12.457777,43.94236], icon: "https://flagpedia.net/data/flags/h80/sm.webp"},
    { markersOffset: -15, name: "Senegal", coordinates: [-14.452362,14.497401], icon: "https://flagpedia.net/data/flags/h80/sn.webp"},
    { markersOffset: -15, name: "Somalia", coordinates: [46.199616,5.152149], icon: "https://flagpedia.net/data/flags/h80/so.webp"},
    { markersOffset: -15, name: "Suriname", coordinates: [-56.027783,3.919305], icon: "https://flagpedia.net/data/flags/h80/sr.webp"},
    { markersOffset: -15, name: "São Tomé and Príncipe", coordinates: [6.613081,0.18636], icon: "https://flagpedia.net/data/flags/h80/st.webp"},
    { markersOffset: -15, name: "El Salvador", coordinates: [-88.89653,13.794185], icon: "https://flagpedia.net/data/flags/h80/sv.webp"},
    { markersOffset: -15, name: "Syria", coordinates: [38.996815,34.802075], icon: "https://flagpedia.net/data/flags/h80/sy.webp"},
    { markersOffset: -15, name: "Swaziland", coordinates: [31.465866,-26.522503], icon: "https://flagpedia.net/data/flags/h80/sz.webp"},
    { markersOffset: -15, name: "Turks and Caicos Islands", coordinates: [-71.797928,21.694025], icon: "https://flagpedia.net/data/flags/h80/tc.webp"},
    { markersOffset: -15, name: "Chad", coordinates: [18.732207,15.454166], icon: "https://flagpedia.net/data/flags/h80/td.webp"},
    { markersOffset: -15, name: "French Southern Territories", coordinates: [69.348557,-49.280366], icon: "https://flagpedia.net/data/flags/h80/tf.webp"},
    { markersOffset: -15, name: "Togo", coordinates: [0.824782,8.619543], icon: "https://flagpedia.net/data/flags/h80/tg.webp"},
    { markersOffset: -15, name: "Thailand", coordinates: [100.992541,15.870032], icon: "https://flagpedia.net/data/flags/h80/th.webp"},
    { markersOffset: -15, name: "Tajikistan", coordinates: [71.276093,38.861034], icon: "https://flagpedia.net/data/flags/h80/tj.webp"},
    { markersOffset: -15, name: "Tokelau", coordinates: [-171.855881,-8.967363], icon: "https://flagpedia.net/data/flags/h80/tk.webp"},
    { markersOffset: -15, name: "Timor-Leste", coordinates: [125.727539,-8.874217], icon: "https://flagpedia.net/data/flags/h80/tl.webp"},
    { markersOffset: -15, name: "Turkmenistan", coordinates: [59.556278,38.969719], icon: "https://flagpedia.net/data/flags/h80/tm.webp"},
    { markersOffset: -15, name: "Tunisia", coordinates: [9.537499,33.886917], icon: "https://flagpedia.net/data/flags/h80/tn.webp"},
    { markersOffset: -15, name: "Tonga", coordinates: [-175.198242,-21.178986], icon: "https://flagpedia.net/data/flags/h80/to.webp"},
    { markersOffset: -15, name: "Turkey", coordinates: [35.243322,38.963745], icon: "https://flagpedia.net/data/flags/h80/tr.webp"},
    { markersOffset: -15, name: "Trinidad and Tobago", coordinates: [-61.222503,10.691803], icon: "https://flagpedia.net/data/flags/h80/tt.webp"},
    { markersOffset: -15, name: "Tuvalu", coordinates: [177.64933,-7.109535], icon: "https://flagpedia.net/data/flags/h80/tv.webp"},
    { markersOffset: -15, name: "Taiwan", coordinates: [120.960515,23.69781], icon: "https://flagpedia.net/data/flags/h80/tw.webp"},
    { markersOffset: -15, name: "Tanzania", coordinates: [34.888822,-6.369028], icon: "https://flagpedia.net/data/flags/h80/tz.webp"},
    { markersOffset: -15, name: "Ukraine", coordinates: [31.16558,48.379433], icon: "https://flagpedia.net/data/flags/h80/ua.webp"},
    { markersOffset: -15, name: "Uganda", coordinates: [32.290275,1.373333], icon: "https://flagpedia.net/data/flags/h80/ug.webp"},
    { markersOffset: -15, name: "U.S. Minor Outlying Islands", coordinates: [,], icon: "https://flagpedia.net/data/flags/h80/um.webp"},
    { markersOffset: -15, name: "United States", coordinates: [-95.712891,37.09024], icon: "https://flagpedia.net/data/flags/h80/us.webp"},
    { markersOffset: -15, name: "Uruguay", coordinates: [-55.765835,-32.522779], icon: "https://flagpedia.net/data/flags/h80/uy.webp"},
    { markersOffset: -15, name: "Uzbekistan", coordinates: [64.585262,41.377491], icon: "https://flagpedia.net/data/flags/h80/uz.webp"},
    { markersOffset: -15, name: "Vatican City", coordinates: [12.453389,41.902916], icon: "https://flagpedia.net/data/flags/h80/va.webp"},
    { markersOffset: -15, name: "Saint Vincent and the Grenadines", coordinates: [-61.287228,12.984305], icon: "https://flagpedia.net/data/flags/h80/vc.webp"},
    { markersOffset: -15, name: "Venezuela", coordinates: [-66.58973,6.42375], icon: "https://flagpedia.net/data/flags/h80/ve.webp"},
    { markersOffset: -15, name: "British Virgin Islands", coordinates: [-64.639968,18.420695], icon: "https://flagpedia.net/data/flags/h80/vg.webp"},
    { markersOffset: -15, name: "U.S. Virgin Islands", coordinates: [-64.896335,18.335765], icon: "https://flagpedia.net/data/flags/h80/vi.webp"},
    { markersOffset: -15, name: "Vietnam", coordinates: [108.277199,14.058324], icon: "https://flagpedia.net/data/flags/h80/vn.webp"},
    { markersOffset: -15, name: "Vanuatu", coordinates: [166.959158,-15.376706], icon: "https://flagpedia.net/data/flags/h80/vu.webp"},
    { markersOffset: -15, name: "Wallis and Futuna", coordinates: [-177.156097,-13.768752], icon: "https://flagpedia.net/data/flags/h80/wf.webp"},
    { markersOffset: -15, name: "Samoa", coordinates: [-172.104629,-13.759029], icon: "https://flagpedia.net/data/flags/h80/ws.webp"},
    { markersOffset: -15, name: "Kosovo", coordinates: [20.902977,42.602636], icon: "https://flagpedia.net/data/flags/h80/xk.webp"},
    { markersOffset: -15, name: "Yemen", coordinates: [48.516388,15.552727], icon: "https://flagpedia.net/data/flags/h80/ye.webp"},
    { markersOffset: -15, name: "Mayotte", coordinates: [45.166244,-12.8275], icon: "https://flagpedia.net/data/flags/h80/yt.webp"},
    { markersOffset: -15, name: "South Africa", coordinates: [22.937506,-30.559482], icon: "https://flagpedia.net/data/flags/h80/za.webp"},
    { markersOffset: -15, name: "Zambia", coordinates: [27.849332,-13.133897], icon: "https://flagpedia.net/data/flags/h80/zm.webp"},
    { markersOffset: -15, name: "Zimbabwe", coordinates: [29.154857,-19.015438], icon: "https://flagpedia.net/data/flags/h80/zw.webp"},
    

  ];