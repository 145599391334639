import React, { useState } from 'react'
import BadgeAvatar from './BadgeAvatar'
import { Row, Col, Form, Button, Container } from 'react-bootstrap'
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { IconButton } from '@mui/material';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Dropdown from 'react-bootstrap/Dropdown';
import CRUDDropdown from './CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAlt';
import ReplyIcon from '@mui/icons-material/Reply';
import Badge from 'react-bootstrap/Badge';

const CommentsInput = () => {
    const [cmt, setCmt] = useState('')
  const [replyCount, setReplyCount] = useState(0)
  const [thumbCount, setThumbCount] = useState(0)

  function handleEmoji(e) {
    setCmt(cmt + e.target.value)

  }
  function handleDeleteComment(e) {

  }
  function handleReplyClick(e) {
    setReplyCount(replyCount + 1)
  }
  function handleThumbClick(e) {
    setThumbCount(thumbCount + 1)
  }

  const onFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();

    reader.onload = () => {
      //    getUploadedFile(reader.result);
      //         setImage(reader.result)
      console.log(reader.result)
    };
    reader.readAsDataURL(files[0]);

  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      //event.target.value=cmt;
      
    }
  }

  return (
    <div className="write-comment  my-3 d-flex justify-content-between align-items-center">
      <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName')+"/images/profile_picture/27291604841249.png"} name="Stephen Metcalfe" size='25' />
      <div className="input-holder w-100 ps-2 pe-0 d-flex justify-content-between align-items-center">
        <Form.Control onChange={(e) => setCmt(e.target.value)} id="commentsField" onKeyPress={handleKeyPress} value={cmt} name="commendsField" size="small" className='py-0' type="text" placeholder="Write a comment..." />
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            className='d-none'
            onChange={onFileChange}
          />
          {/* <Button as="span" size="sm " variant="outlined-secondary" className="d-flex align-items-center p-1 " ><AddPhotoIcon fontSize="small" />&nbsp;Add Photo</Button> */}
          <IconButton as="span" size="small" style={{ cursor: 'pointer' }} variant="secondary" ><AddPhotoIcon /></IconButton>
        </label>
        <Dropdown >
          <Dropdown.Toggle as={IconButton} size="small" id="dropdown-split-basic" ><AddReactionIcon /></Dropdown.Toggle>
          <Dropdown.Menu >
            <div className='d-flex align-items-center flex-wrap justify-content-center'>
              <IconButton value="😁" onClick={handleEmoji} size="small">😀</IconButton>
              <IconButton value="😁" onClick={handleEmoji} size="small">😁</IconButton>
              <IconButton value="😂" onClick={handleEmoji} size="small">😂</IconButton>
              <IconButton value="😃" onClick={handleEmoji} size="small">😃</IconButton>
              <IconButton value="😄" onClick={handleEmoji} size="small">😄</IconButton>
              <IconButton value="😅" onClick={handleEmoji} size="small">😅</IconButton>
              <IconButton value="😆" onClick={handleEmoji} size="small">😆</IconButton>
              <IconButton value="😇" onClick={handleEmoji} size="small">😇</IconButton>
              <IconButton value="😈" onClick={handleEmoji} size="small">😈</IconButton>
              <IconButton value="😉" onClick={handleEmoji} size="small">😉</IconButton>
              <IconButton value="😋" onClick={handleEmoji} size="small">😋</IconButton>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default CommentsInput
