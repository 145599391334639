import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom'


function ProfileNavBar() {
  return (

<Navbar expand="lg" className="bg-light rounded-bottom">
      <Container className="d-flex flex-column justify-content-center">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="myprofile">My Profile</Nav.Link>
            <Nav.Link as={NavLink} to="mywall">My Wall</Nav.Link>
            <Nav.Link as={NavLink} to="mycommunity">My Community</Nav.Link>
            <Nav.Link as={NavLink} to="myevents">My Events</Nav.Link>
            <Nav.Link as={NavLink} to="mywallgallery">My Wall Gallery</Nav.Link>
            <Nav.Link as={NavLink} to="myresources">My Resources</Nav.Link>
            <Nav.Link as={NavLink} to="mypurchasedhistory">My Purchased History</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default ProfileNavBar
