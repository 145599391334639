import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import http from '../../../http';

function ShopTab(props, { handleClick }) {
    const [memberships, setMemberships] = useState([]);
    useEffect(() => {
        http().get('/shop/'+props.idx).then((res) => {
          console.log(res.data.data);
          setMemberships(res.data.data.memberships);
        });
    }, []);


    return (
        <Container className="mt-2 px-0">

            <div className="col-12 col-sm-12 px-0">
                <div className="border-0 d-flex justify-content-between bg-toggle rounded shadow-sm px-3 py-2 mb-2">
                    <h5 className="d-flex-mi mb-0"><ShoppingCartIcon/>&nbsp;Shop</h5>
                </div>
            </div>
            <div className="col-12 col-sm-12 ps-0 pe-0 ">
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                </div>
                <div className="container border-0  bg-toggle rounded shadow-sm py-5">
                    <section className="d-flex justify-content-center">
                        <Row xs={1} md={2} className="g-4">
                            <Col>
                                <Card>
                                    <Card.Img variant="top" src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/85291662484318.png"} />
                                    <Card.Body className="text-center">
                                        <Card.Title>Freemium</Card.Title>
                                        <Card.Text className="h1">
                                            £0
                                        </Card.Text>
                                        <Button variant="primary">Included</Button>
                                    </Card.Body>
                                    <Card.Footer className="text-center">
                                        <input type="checkbox" className="custom-control-input" id="defaultChecked1"></input>
                                        <label htmlFor="defaultChecked1">&nbsp;Mark as Favourite</label>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img variant="top" src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/85291662484318.png"} />
                                    <Card.Body className="text-center">
                                        <Card.Title>Member One Year</Card.Title>
                                        <Card.Text className="h1">
                                            £250
                                        </Card.Text>
                                        <Button variant="primary">KNOW MORE</Button>
                                    </Card.Body>
                                    
                                    <Card.Footer className="text-center">
                                        <input type="checkbox" className="custom-control-input" id="defaultChecked"></input>
                                        <label htmlFor="defaultChecked">&nbsp;Mark as Favourite</label>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>


        </Container>
    )
}
export default ShopTab
