import { AccountBalanceOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import ListGroup from 'react-bootstrap/ListGroup';
import { Badge } from '@mui/material';
function AllPavilionsBox(props) {

    const [show, setShow] = useState(props.showMe);

    return (
        <>
            {show && (<Container fluid className="px-0">
                <ListGroup className="overflow-hidden px-0 ">
                    <ListGroup.Item active className='bg-linear-gradient-primary text-center pt-2 pb-1 rounded-top'>
                        <h5 className="mb-0 d-flex-mi">
                            <AccountBalanceOutlined /> &nbsp;All Pavilions
                        </h5>
                    </ListGroup.Item>
                    <ListGroup.Item action href="/members/who%27s-who" className="d-flex align-items-center">
                        <img src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/95061639721979.png"} alt="Who's who" style={{ width: '40px', height: '40px' }} className="rounded" />
                        <div id="paviname" className="ms-3 d-sm-inline">
                            <h6 className="text-muted  mb-0">Who's who</h6>
                            <small className="text-dark ">3475 Members</small>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item action href="/members/who%27s-who" className="d-flex align-items-center">
                        <img src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/62581603467852.png"} alt="APPG Artificial Intelligence" style={{ width: '40px', height: '40px' }} className="rounded" />
                        <div id="paviname" className="ms-3 d-sm-inline">
                            <h6 className="text-muted  mb-0">APPG Artificial Intelligence</h6>
                            <small className="text-dark ">387 Members </small>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item action href="/members/who%27s-who" className="d-flex align-items-center">
                        <img src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/79901603468540.png"} alt="APPG Blockchain" style={{ width: '40px', height: '40px' }} className="rounded" />
                        <div id="paviname" className="ms-3 d-sm-inline">
                            <h6 className="text-muted  mb-0">APPG Blockchain</h6>
                            <small className="text-dark ">212 Members </small>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item action href="/members/who%27s-who" className="d-flex align-items-center">
                        <img src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/64151685778329.png"} alt="Digital Finance Frontier Club" style={{ width: '40px', height: '40px' }} className="rounded" />
                        <div id="paviname" className="ms-3 d-sm-inline">
                            <h6 className="text-muted  mb-0">Digital Finance Frontier Club</h6>
                            <small className="text-dark ">8 Members </small>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item action href="/members/who%27s-who" className="d-flex align-items-center">
                    <img src={localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/91491669017706.png"} alt="THE INNOVATORS BOARD" style={{ width: '40px', height: '40px' }} className="rounded" />
                        <div id="paviname" className="ms-3 d-sm-inline">
                            <h6 className="text-muted  mb-0">THE INNOVATORS BOARD</h6>
                            <small className="text-dark ">79 Members </small>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="text-center">
                        <button type="button" className="btn btn-sm btn-outline-primary"><a href="https://bicpavilion.com/viewallpavilions">Go To All Pavilions</a></button>
                    </ListGroup.Item>
                    
                </ListGroup>
            </Container>
            )}
        </>
    )
}

export default AllPavilionsBox
