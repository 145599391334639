import { Outlet, Navigate } from 'react-router-dom'

const PublicRoutes = () => {
    const token=localStorage.getItem('token')
    let auth = {'token':token}
    return(
        auth.token ? <Navigate to="/homepavilion"/> : <Outlet/>
    )
}

export default PublicRoutes