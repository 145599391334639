import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import libraryThumbnail from '../../assets/img/default-library-thumbnail.jpg';
import Popup from './Popup';

const Input = styled("input")({
  display: "none"
});

const MyCropper = (props) => {
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(libraryThumbnail);
  const handleClose = () => {
    setOpen(false);
  };
//input file dialogue
const onFileChange = (e) => {
  e.preventDefault();
  let files;
  if (e.dataTransfer) {
    files = e.dataTransfer.files;
  } else if (e.target) {
    files = e.target.files;
  }
  if (files.length === 0) {
    return alert("Please select a file.");
  }
  const reader = new FileReader();

  reader.onload = () => {
    //    getUploadedFile(reader.result);
    setImage(reader.result)
    props.sendCropData(image);
    setOpen(true)
  };
  reader.readAsDataURL(files[0]);

};

//input file dialog


  return (
    <div>
       <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Box my={2} >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={onFileChange}
                      />
                      <img className='rounded-2' style={{ cursor: "pointer" }} src={image} alt="cropped" height="100%" width="100%" />
                    </label>
                  </Box>

                  {/* <Upload image={image}
                    getUploadedFile={(image) => {
                      setOpen(true);
                      setImage(image);
                    }}
                  /> */}
                  
                  <Popup
                    open={open}
                    handleClose={handleClose}
                    image={image}
                    getCroppedFile={(image) => {
                      setImage(image);
                      handleClose();
                    }}
                    aspectRatio={9 / 13}
                  />
                </Box>
    </div>
  )
}

export default MyCropper
