import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import BannerCard from "../components/AdminCards/BannerCard";
import Alert from "@mui/material/Alert";

import { NavLink } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
// import Table from 'react-bootstrap/Table';
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from 'sweetalert2'
import Spinner from 'react-bootstrap/Spinner';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));
const width = 100;
const style = {
  maxWidth: width,
  // borderStyle: "border-box"
};

const AdminUserRoleACL = (props) => {
  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");
  
  const [progress, setProgress] = useState(false);

  const [banners, setBanners] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  //   const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);

  const [modulesData, setModulesData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/roles/`}
      state={data}
    >
      Admin User Roles
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
       {data.rolename} - Access Control
    </Typography>,
  ];

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    adminhttp()
      .get(`admin/assign/permissions/roles/${splitURL[3]}`)
      .then((data) => {
        setModulesData(data.data.data.modules);
        setPermissionsData(data.data.data.permissions);
        setSelectedPermissions(data.data.data.selectedRolePermissions);
        console.log(selectedPermissions);
      });
  }, []);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked; // Check whether "Select All" is checked or not
  
    // Extract all permission IDs
    const allPermissionIds = permissionsData.map((perm) => perm.id);
  
    // Update the state with all or no permissions
    setSelectedPermissions(isChecked ? allPermissionIds : []);
  
    // Update the state for the "Select All" checkbox
    setSelectAll(isChecked);
  };

  const handleCheckboxChange = (id) => (e) => {
    const updatedPermissions = e.target.checked
      ? [...selectedPermissions, id]
      : selectedPermissions.filter((permId) => permId !== id);

    setSelectedPermissions(updatedPermissions);
  };

  const handleSelectModule = (moduleId) => {
    const modulePermissions = permissionsData
      .filter((perm) => perm.module_id === moduleId)
      .map((perm) => perm.id);

    const isModuleSelected = modulePermissions.every((id) =>
      selectedPermissions.includes(id)
    );

    const updatedPermissions = isModuleSelected
      ? selectedPermissions.filter((id) => !modulePermissions.includes(id))
      : [...new Set([...selectedPermissions, ...modulePermissions])];

    setSelectedPermissions(updatedPermissions);
  };

  const handleSaveChanges = async () => {
    try {
      setProgress(true)
      const payload = {
        role: splitURL[3],
        permissions: selectedPermissions,
      };

      await adminhttp().post("admin/assign/permissions/roles", payload);

      Swal.fire({
        title: "Permissions saved successfully.",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
      setProgress(false)
    } catch (error) {
      console.error("Error saving permissions:", error);
      Swal.fire({
        title: "Failed to save permissions. Please try again.",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
    }
  };

  return (
    <AdminLayout>
      <Container className="bg-light pb-5" sx={{ height: "100vh" }}>
        <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>

        <Box
          component="section"
          className="d-flex align-items-center justify-content-between"
          sx={{ width: "100%", px: 2, pt: 0, pb: 1 }}
          data-aos="fade-up"
        >
          <h4>
            {data.rolename} - Access Control</h4> 
          <div className="d-flex justify-content-end align-items-center">
          <FormControlLabel
  label="Select All"
  control={
    <Checkbox
      size="small"
      className="itemcheckbox"
      sx={{
        color: red[500],
        "&.Mui-checked": {
          color: red[600],
        },
      }}
      checked={selectAll} // Bind to the selectAll state
      onChange={handleSelectAll} // Handle Select All
    />
  }
/>
            <Form className="me-2">
              <Form.Control
                type="search"
                size="small"
                placeholder="Search Members"
              />
            </Form>
            <Button
              variant="primary"
              type="button"
              onClick={handleSaveChanges}
            >
              {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Changes
            </Button>
            {/* <Box sx={{ alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
           </Box> */}
          </div>
        </Box>

        <ListGroup variant="flush">
          <ListGroup.Item className="bg-light">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} className="mb-3">
                <Grid item xs={6} md={3}>
                  <Item className="text-start bg-secondary text-light fw-bold">
                    Modules
                  </Item>
                </Grid>
                <Grid item xs={6} md={9}>
                  <Item className="text-start  bg-secondary text-light fw-bold">
                    Actions
                  </Item>
                </Grid>
              </Grid>

              <Grid container spacing={2} >
        {modulesData.map((module) => (
          <React.Fragment key={module.id} >
            <Grid item xs={6} md={3} >
              <Item className="text-start py-auto">
                <label className="ms-2 fs-6 lh-1" style={{ cursor: "pointer" }}>
                  <input
                    id={module.id}
                    type="checkbox"
                    name={module.name}
                    className={`form-check-input me-2 my-0 item-checkbox${module.id}`}
                    checked={
                      permissionsData
                        .filter((perm) => perm.module_id === module.id)
                        .every((perm) => selectedPermissions.includes(perm.id))
                    }
                    onChange={() => handleSelectModule(module.id)}
                  />
                  {module.name}
                </label>
              </Item>
            </Grid>
            <Grid item xs={6} md={9}>
              <Item className="text-start ps-3 py-auto">
                {permissionsData
                  .filter((item) => item.module_id === module.id)
                  .map((item) => (
                    <label
                      key={item.id}
                      className="ms-2  fs-6 "
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        id={item.id}
                        type="checkbox"
                        checked={selectedPermissions.includes(item.id)}
                        name={item.name}
                        className={`me-2 form-check-input item-checkbox${module.id}`}
                        onChange={handleCheckboxChange(item.id)}
                      />
                      {item.name}
                    </label>
                  ))}
              </Item>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
            </Box>
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </AdminLayout>
  );
};
export default AdminUserRoleACL;
