import React, { useState, useEffect } from 'react';
import { Container, Form, Button, ProgressBar, Row, Col, Card } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import '../assets/css/setup.css';
import { Filter1, Filter2, Filter3, Filter4, Filter5, Filter6, Filter7 } from '@mui/icons-material';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardGroup from 'react-bootstrap/CardGroup';
import Select from 'react-select';
import { useLocation } from 'react-router-dom'
import { COUNTRIES, CITIES, CATEGORIES } from './PavilionConstants';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import http from '../http';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import { useParams, useSearchParams } from 'react-router-dom';
const PavilionSetupForm = (props) => {

    const location = useLocation();
    const splitURL = location.pathname.toString().split("/");

    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');

    const [country, setCountry] = useState();
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        if (splitURL[1] === 'plans') {
            if (splitURL[2]) {
                setCountry(splitURL[2])
            }
        }
        if(sessionId){
            setStep(7)
        }
    }, []);


    const [isChecked, setIsChecked] = useState(false);
    const [planName, setPlanName] = useState(props.planName);
    const [planId, setPlanId] = useState(props.planId);
    const [planType, setPlanType] = useState(props.planType);
    const [planAmt, setPlanAmt] = useState(props.planAmt);
    const [validated, setValidated] = useState(false);
    const [step, setStep] = useState(1);
    // const [formData, setFormData] = useState({});

    // const [value, setValue] = useState('1');
    // const [value2, setValue2] = useState('1');
    // const [value3, setValue3] = useState('1');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [phone, setPhone] = useState('');
    const [countrylist, setCountrylist] = useState([]);
    const [citylist, setCitylist] = useState([]);
    const [address, setAddress] = useState('');
    const [pavilionName, setPavilionName] = useState('');
    const [pavilionDescription, setPavilionDesc] = useState('');
    const [pavilionTotalMembers, setPavilionTotalMembers] = useState('');
    const [pavilionCountry, setPavilionCountry] = useState('');
    const [categoryOptions, setPavilioncategory] = useState([]);
    const [bankName, setBankName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [sortCode, setSortCode] = useState('');
    const [swiftCode, setSwiftCode] = useState('');
    const [iban, setIban] = useState('');
    /*const [planName, setPlanName] = useState('');
    const [planType, setPlanType] = useState('');
    const [planAmount, setPlanAmount] = useState('');*/
    // const [couponCode, setCouponCode] = useState('');
    const [countryName, setCountryName] = useState('');
    const [cityName, setCityName] = useState('');
    const [userId, setUserId] = useState('');
    const [pavilonId, setpavilonId] = useState('');
    const [pavilonSlug, setPavilonSlug] = useState('');
    const [categoryvalue, setcategoryvalue] = useState('');
    const [bankAction, setBankAction] = useState('add');
    const [pavilionUsersAction, setPavilionUsersAction] = useState('add');
    const [addPavilionAction, setAddPavilionAction] = useState('add');
    const [addPavilionDataAction, setAddPavilionDataAction] = useState('dataadd');
    const divStyleNone = {
        display: 'none',
    };

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        countryName: '',
        address: '',
        pavilionName: '',
        pavilionDescription: '',
        pavilionCountry: '',
        pavilionTotalMembers: '',
        categoryvalue: '',
        bankName: '',
        accountName: '',
        accountNumber: '',
        sortCode: '',
        swiftCode: '',
        iban: ''
    });

    const nameChange = (event) => {
        setName(event.target.value);
    }
    const emailChange = (event) => {
        setEmail(event.target.value);
    }
    const passwordChange = (event) => {
        setPasswordValue(event.target.value);
    }
    const phoneChange = (event) => {
        setPhone(event.target.value);
    }
    const addressChange = (event) => {
        setAddress(event.target.value);
    }
    const pavilionNameChange = (event) => {
        setPavilionName(event.target.value);
        http().post('/pavilionExist', {
            pavilion_name: event.target.value,
            pavilion_action: 'add',
        }).then((data) => {
            if (data.data.message === 'yes') {
                setPavilionName('');
                Swal.fire({
                    title: "Pavilion is already available, Please give new pavilion!",
                    icon: "success",
                    allowOutsideClick: false,
                    confirmButtonColor: '#e30613'
                });
            } else {
                setPavilionName(event.target.value);
            }
        });
        setPavilonSlug(event.target.value.replace(/\s+/g, '-'));
    }
    const pavilionDescriptionChange = (event) => {
        setPavilionDesc(event.target.value);
    }
    const pavilionTotalMembersChange = (event) => {
        setPavilionTotalMembers(event.target.value);
    }
    const pavilionCountryChange = (event) => {
        setPavilionCountry(event.target.value);
    }
    const bankNameChange = (event) => {
        setBankName(event.target.value);
    }
    const accountNameChange = (event) => {
        setAccountName(event.target.value);
    }
    const accountNumberChange = (event) => {
        setAccountNumber(event.target.value);
    }
    const sortCodeChange = (event) => {
        setSortCode(event.target.value);
    }
    const swiftCodeChange = (event) => {
        setSwiftCode(event.target.value);
    }
    const ibanChange = (event) => {
        setIban(event.target.value);
    }
    /*
    const planNameChange = (event) => {
        setPlanName(event.target.value);
    }
    const planTypeChange = (event) => {
        setPlanType(event.target.value);
    }
    const planAmountChange = (event) => {
        setPlanAmount(event.target.value);
    }*/
    // const couponCodeChange = (event) => {
    //     setCouponCode(event.target.value);
    // }
    const pavilionCategoryChange = (event) => {
        setcategoryvalue(event);
    }

    const handleNext = () => {
        // alert('yes');
        // alert(step);

        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
        // alert(step)
        // if (step == 2) {
        //     setPavilionUsersAction('edit');
        // }
        // if (step == 3) {
        //     setAddPavilionAction('edit');
        //     setAddPavilionDataAction('dataedit');
        // }
        // if (step == 5) {
        //     setBankAction('edit');
        // }
    };

    const handlePreview = () => {
        // alert('Pavilion Preview');
    };

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    {/*const handleChange = (event) => {
        const name = event.currentTarget;
        const email = event.currentTarget;
        const phone = event.currentTarget;
        const value = event.target.value;


        // setState({...state,[event.target.name]: value });

        if (name.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (email.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (phone.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

    */}

    const handleSubmit = (event) => {
        setProgress(true);
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setValidated(true);
            setProgress(false);
            if (sessionId) {
                setStep(7);
            }
        }
    };
    const handleStripeSubmit = async () => {
        setProgress(true);
        try {
            http().post('/purchasePackage', { user_email: email, user_name: name, package_id: planId, user_id:userId, pavilion_id:pavilonId, pavilion_name:pavilionName, country:countryName, user_phone:phone, coupon_id:''})
            .then((res) => {
                console.log(res.data.data.url);
                window.open(res.data.data.url,'_self');
            })
            .catch((error) => {
              console.error('Error:', error);
            //   setErrorMessage('An error occurred Please try again.'); // Set error message
            });
          // Handle the response data
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        } finally {
          setProgress(false);
        }
      };
    
    // const handleCardClick = () => {

    //     if (isChecked) { setIsChecked(false) }
    //     else { setIsChecked(true) }

    // }
    // const onCardChange = (e) => {
    //     alert(e.target.checked);
    //     if (e.target.checked) {
    //         setIsChecked(true);
    //     } else {
    //         setIsChecked(false);
    //     }
    // };
    const [logo, setLogo] = useState('');
    const [icon, setIcon] = useState('');
    const [card, setCard] = useState('');
    const [banner, setBanner] = useState('');

    const onCardChange = (event, idx) => {

        if (event.target.name === 'logoGroup') {
            setLogo(idx)
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                name: event.target.value,
                pavilion_action: 'edit',
                action_name: 'logo'
            }).then((data) => {
                // console.log(data.data);
            });
        }
        if (event.target.name === 'iconGroup') {
            setIcon(idx)
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                name: event.target.value,
                pavilion_action: 'edit',
                action_name: 'icon'
            }).then((data) => {
                // console.log(data.data);
            });
        }
        if (event.target.name === 'cardGroup') {
            setCard(idx)
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                name: event.target.value,
                pavilion_action: 'edit',
                action_name: 'card'
            }).then((data) => {
                // console.log(data.data);
            });
        }
        if (event.target.name === 'bannerGroup') {
            setBanner(idx)
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                name: event.target.value,
                pavilion_action: 'edit',
                action_name: 'banner'
            }).then((data) => {
                // console.log(data.data);
            });
        }

    };

    useEffect(() => {
        //countrylist
        http().get('/getcountries').then((data) => {
            setCountrylist(data.data.data.countries);
        });
        http().get('/pavilioncategory').then((data) => {
            setPavilioncategory(data.data.data);
        });

    }, []);

    const handleSelectChange = (event) => {
        setCountryName(event.target.value);
        if (event.target.value != '') {
            http().get('/cities/' + document.getElementById('country_id').value).then((data) => {
                setCitylist(data.data.data.CountryCities);
                //setCitylist(data);
            });
        }
    };
    const handleCityChange = (event) => {
        setCityName(event.target.value);
    }

    const pavilionUsers = (event) => {
        const newErrors = {};
        if (!name) {
            newErrors.name = 'Name is required.';
            setErrors(newErrors);
        } else if (!email) {
            newErrors.email = 'Email is required.';
            setErrors(newErrors);
        } else if (!phone) {
            newErrors.phone = 'Phone is required.';
            setErrors(newErrors);
        } else if (!countryName) {
            newErrors.countryName = 'Country is required.';
            setErrors(newErrors);
        } else if (!cityName) {
            newErrors.cityName = 'City is required.';
            setErrors(newErrors);
        } else if (!address) {
            newErrors.address = 'Address is required.';
            setErrors(newErrors);
        } else {
            // setValue('2');
            setProgress(true);
            http().post('/pavilionUsers', {
                user_name: name,
                user_email: email,
                user_phone: phone,
                country: countryName,
                user_city: cityName,
                user_address: address,
                user_action: pavilionUsersAction,
                user_id: userId,
                password: passwordValue,
                user: 'notexisted',
            }).then((data) => {
                if (pavilionUsersAction === 'add') {
                    setUserId(data.data);
                    setPavilionUsersAction('edit');
                }
                setProgress(false);
                setStep(step + 1);
                //alert(data.data);
            });

        }


    }

    const logoEvent = (event) => {
        // console.log(event)
        // console.log(event.target.value)
        http().post('/addPavilion', {
            pavilion_id: pavilonId,
            name: event.target.value,
            pavilion_action: 'edit',
            action_name: 'logo'
        }).then((data) => {
            // console.log(data.data);
        });
    }



    const logoFileEvent = (event) => {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();
        reader.onload = () => {
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                logofile: reader.result,
                pavilion_action: 'edit',
                action_name: 'logoupload'
            }).then((data) => {
                console.log(data.data);
            });
        };
        reader.readAsDataURL(files[0]);
    }




    const iconEvent = (event) => {
        console.log(event)
        console.log(event.target.value)
        http().post('/addPavilion', {
            pavilion_id: pavilonId,
            name: event.target.value,
            pavilion_action: 'edit',
            action_name: 'icon'
        }).then((data) => {
            // console.log(data.data);
        });
    }


    const iconFileEvent = (event) => {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();
        reader.onload = () => {
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                iconfile: reader.result,
                pavilion_action: 'edit',
                action_name: 'iconupload'
            }).then((data) => {
                console.log(data.data);
            });
        };
        reader.readAsDataURL(files[0]);
    }

    const cardEvent = (event) => {
        // console.log(event)
        // console.log(event.target.value)
        http().post('/addPavilion', {
            pavilion_id: pavilonId,
            name: event.target.value,
            pavilion_action: 'edit',
            action_name: 'card'
        }).then((data) => {
            // console.log(data.data);
        });
    }

    const cardFileEvent = (event) => {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();
        reader.onload = () => {
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                cardfile: reader.result,
                pavilion_action: 'edit',
                action_name: 'cardupload'
            }).then((data) => {
                console.log(data.data);
            });
        };
        reader.readAsDataURL(files[0]);
    }

    const bannerEvent = (event) => {
        // console.log(event)
        // console.log(event.target.value)
        http().post('/addPavilion', {
            pavilion_id: pavilonId,
            name: event.target.value,
            pavilion_action: 'edit',
            action_name: 'banner'
        }).then((data) => {
            // console.log(data.data);
        });
    }

    const bannerFileEvent = (event) => {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        const reader = new FileReader();
        reader.onload = () => {
            http().post('/addPavilion', {
                pavilion_id: pavilonId,
                bannerfile: reader.result,
                pavilion_action: 'edit',
                action_name: 'bannerupload'
            }).then((data) => {
                console.log(data.data);
            });
        };
        reader.readAsDataURL(files[0]);
    }
    const addPavilion = (event) => {
        // alert('addpavilion');
        // setValue('3');
        const newErrors = {};
        if (!pavilionName) {
            newErrors.pavilionName = 'Pavilion Name is required.';
            setErrors(newErrors);
        } else if (!pavilionDescription) {
            newErrors.pavilionDescription = 'Pavilion Description is required.';
            setErrors(newErrors);
        } else if (!pavilionCountry) {
            newErrors.pavilionCountry = 'Pavilion Country is required.';
            setErrors(newErrors);
        } else if (!pavilionTotalMembers) {
            newErrors.pavilionTotalMembers = 'Pavilion Total Members is required.';
            setErrors(newErrors);
        } else {
            setProgress(true);
            http().post('/addPavilion', {
                user_id: userId,
                pavilion_name: pavilionName,
                pavilion_description: pavilionDescription,
                country_id_pavilion: pavilionCountry,
                pavilion_total_members: pavilionTotalMembers,
                pavilion_action: addPavilionAction,
                pavilion_id: pavilonId,
                action_name: addPavilionDataAction,
            }).then((data) => {
                //console.log(data.data);
                if (addPavilionAction === 'add') {
                    setpavilonId(data.data);
                    setAddPavilionAction('edit');
                    setAddPavilionDataAction('dataedit');

                }
                setProgress(false)
                setStep(step + 1);
            });
        }

    }

    const dataCategory = (event) => {
        // alert('dataCategory');
        // setValue('3');
        const newErrors = {};
        if (!categoryvalue) {
            newErrors.categoryvalue = 'Category is required.';
            setErrors(newErrors);
        } else {
            // setValue('4');
            setProgress(true);
            http().post('/addPavilion', {
                "pavilion_category": categoryvalue,
                "pavilion_action": 'edit',
                "pavilion_id": pavilonId,
                "action_name": 'dataeditcategory',
            }).then((data) => {
                //console.log(data.data);
                setProgress(false)
                setStep(step + 1);
                // setpavilonId(data.data);
            });
        }
    }

    const bankdetails = (event) => {
        // setValue('6');
        // alert('bankdetails');
        // setValue('3');
        const newErrors = {};
        if (!bankName) {
            newErrors.bankName = 'Bank Name is required.';
            setErrors(newErrors);
        } else if (!accountName) {
            newErrors.accountName = 'Account Name is required.';
            setErrors(newErrors);
        } else if (!accountNumber) {
            newErrors.accountNumber = 'Account Number is required.';
            setErrors(newErrors);
        } else if (!sortCode) {
            newErrors.sortCode = 'Sort Code is required.';
            setErrors(newErrors);
        } else if (!swiftCode) {
            newErrors.swiftCode = 'Swift Code is required.';
            setErrors(newErrors);
        } else if (!iban) {
            newErrors.iban = 'Iban is required.';
            setErrors(newErrors);
        } else {
            setProgress(true);
            http().post('/bankdetails', {
                "pavilion_category[]": categoryvalue,
                pavilion_id: pavilonId,
                bank_name: bankName,
                account_name: accountName,
                account_number: accountNumber,
                sort_code: sortCode,
                swift_code: swiftCode,
                iban: iban,
                user_id: userId,
                bank_id: "",
                bank_action: bankAction,
            }).then((data) => {
                setBankAction('edit');
                //console.log(data.data);
                setProgress(false);
                setStep(step + 1);
            });
        }
    }


    return (
        <Row as={Container} >
            <Col md={4} className=" text-light px-0" >
                <div id="title-container" className="d-flex flex-column align-items-center justify-content-center">
                    <img src={localStorage.getItem('bucketName') + "/images/bic-white-logo.png"} />
                    <h2 className="mt-3">PAVILION 3.0</h2>
                    <h3 className="my-4">New Pavilion Setup</h3>
                    <p>Pavilion Platform is part of Big Innovation Centre in all global regions. Pavilion Platform is registered in England and Wales No. 11556856</p>
                </div>

            </Col>
            <Col md={8} className="bg-light ps-0">

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <input type="text" name="pavilonId" id="pavilonId" value={pavilonId} style={divStyleNone} />
                    <input type="text" name="pavilonSlug" id="pavilonSlug" value={pavilonSlug} style={divStyleNone} />
                    <input type="text" name="userId" value={userId} style={divStyleNone} />
                    <ProgressBar striped variant="danger" className="text-primary" now={(step / 4) * 100} />
                    <Container className="ps-5 text-start">
                        {step === 1 && (
                            <Form.Group controlId="formStep1" id="step1" className="form-group-sm ">
                                <h4 className="mt-3 border-bottom border-dark text-primary">
                                    <Filter1 className="mb-1" /> Basic Information
                                </h4>
                                <p>Please fill your authentic Contact Details</p>
                                <div className="mb-3 floating-label">
                                    <Form.Control required id="name" onChange={nameChange} value={name} placeholder="" size="sm" type="text" />
                                    <Form.Label className="bg-light">Name</Form.Label>
                                    {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    <Form.Control required id="email" name="email" onChange={emailChange} value={email} placeholder="" is-valid type="email" />
                                    <Form.Label className="bg-light">Email</Form.Label>
                                    {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    <Form.Control required id="password" name="password" onChange={passwordChange} value={passwordValue} placeholder="" is-valid type="password" />
                                    <Form.Label className="bg-light">Password</Form.Label>
                                    {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    <Form.Control required id="phone" name="phone" onChange={phoneChange} value={phone} is-valid size="sm" placeholder="" type="tel" />
                                    <Form.Label className="bg-light">Phone</Form.Label>
                                    {errors.phone && <div style={{ color: 'red' }}>{errors.phone}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Label className="bg-light">Country</Form.Label> */}
                                    {/* <Select autosize={true} className="w-100 basic-multi-select w-90" placeholder="" classNamePrefix="select" options={COUNTRIES} /> */}
                                    <Form.Select required size="sm" id="country_id" name="country_id" aria-label="Default select example" className="w-100 basic-multi-select" value={countryName} onChange={handleSelectChange}>
                                        <option value="">Country</option>
                                        {countrylist.map((countrylistArray, countrylistIndex) => (
                                            <option value={countrylistArray.id}>{countrylistArray.name}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.countryName && <div style={{ color: 'red' }}>{errors.countryName}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Label className="bg-light">City</Form.Label> */}
                                    {/* <Select autosize={true} className="basic-multi-select w-90" placeholder="Select City" classNamePrefix="select" options={CITIES} /> */}
                                    <Form.Select required id="city" name="city" value={cityName} size="sm" aria-label="Default select example" className="basic-multi-select w-100" onChange={handleCityChange}>
                                        <option value=''>City</option>
                                        {citylist.map((citylistArray, citylistIndex) => (
                                            <option value={citylistArray.id}>{citylistArray.name}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.cityName && <div style={{ color: 'red' }}>{errors.cityName}</div>}

                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control id="textarea" as="textarea" placeholder='' rows={3} /> */}
                                    <Form.Control required as="textarea" value={address} id="address" name="address" placeholder="Address" rows={3} onChange={addressChange} />
                                    <Form.Label className="bg-light">Address</Form.Label>
                                    {errors.address && <div style={{ color: 'red' }}>{errors.address}</div>}
                                </div>
                            </Form.Group>
                        )}
                        {step === 2 && (
                            <Form.Group controlId="formStep2" id="step2">
                                <h4 className="mt-3 border-bottom border-dark text-primary">
                                    <Filter2 className="mb-1" /> Pavilion Information
                                </h4>
                                <p>Please fill your authentic Contact Details</p>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control required id="pavilion-name" size="sm" placeholder='' type="text" /> */}
                                    <Form.Control size="sm" type="text" name="pavilionName" value={pavilionName} onChange={pavilionNameChange} placeholder="Pavilion Name" />
                                    <Form.Label className="bg-light">Pavilion Name</Form.Label>
                                    {errors.pavilionName && <div style={{ color: 'red' }}>{errors.pavilionName}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control id="pavilion-desc" as="textarea" rows={3} placeholder='' /> */}
                                    <Form.Control as="textarea" name="pavilionDescription" value={pavilionDescription} onChange={pavilionDescriptionChange} placeholder="Pavilion Description" rows={3} />
                                    <Form.Label className="bg-light">Pavilion Description</Form.Label>
                                    {errors.pavilionDescription && <div style={{ color: 'red' }}>{errors.pavilionDescription}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Label className="bg-light">Country</Form.Label> */}
                                    {/* <Select autosize={true} className="basic-multi-select w-90" placeholder="Select Country" classNamePrefix="select" options={COUNTRIES} /> */}
                                    <Form.Select size="sm" id="pavilionCountryId" name="pavilionCountryId" aria-label="Default select example" className="basic-multi-select w-90" value={pavilionCountry} onChange={pavilionCountryChange}>
                                        <option value="">Country</option>
                                        {countrylist.map((countrylistArray, countrylistIndex) => (
                                            <option value={countrylistArray.id}>{countrylistArray.name}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.pavilionCountry && <div style={{ color: 'red' }}>{errors.pavilionCountry}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Select required id="pavilion_total_members" size="sm" name="pavilion_total_members">
                                        <option value=""></option>
                                        <option value="1-500">1 - 500 members</option>
                                        <option value="501-1000">501 - 1000 members</option>
                                        <option value="1001-5000">1001 - 5000 members</option>
                                        <option value="above 5000">above 5000</option>
                                    </Form.Select> */}
                                    <Form.Select name="pavilion_total_members" id="pavilion_total_members" placeholder="Total Members Expected" size="sm" aria-label="Default select example" onChange={pavilionTotalMembersChange} value={pavilionTotalMembers}>
                                        <option selected disabled value="">Total members expected</option>
                                        <option value="1-500">1 - 500 members</option>
                                        <option value="501-1000">501 - 1000 members</option>
                                        <option value="1001-5000">1001 - 5000 members</option>
                                        <option value="above 5000">above 5000</option>
                                    </Form.Select>
                                    <Form.Label className="bg-light">Total No of Members Expected</Form.Label>
                                    {errors.pavilionTotalMembers && <div style={{ color: 'red' }}>{errors.pavilionTotalMembers}</div>}

                                </div>
                            </Form.Group>
                        )}
                        {step === 3 && (
                            <Form.Group controlId="formStep3" id="step3">
                                <h4 className="mt-3 border-bottom border-dark text-primary">
                                    <Filter3 className="mb-1" /> Pavilion Category
                                </h4>
                                <p>Please select your relevant pavilion categories</p>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Label className="bg-light">Categories</Form.Label> */}
                                    {/* <Select isMulti autosize={true} className="basic-multi-select w-90" placeholder="Select Country" classNamePrefix="select" options={CATEGORIES} /> */}
                                    {/* <Select isMulti autosize={true} className="basic-multi-select w-100" placeholder="Select Country" classNamePrefix="select" value={categoryvalue} onChange={pavilionCategoryChange} options={categoryOptions} /> */}
                                    <Select isMulti key="1234" className="basic-multi-select w-100" placeholder="Select Category" classNamePrefix="select" value={categoryvalue} onChange={pavilionCategoryChange} options={categoryOptions} >
                                        {/* {categoryOptions.map((categoryOptionsArray, categoryOptionsIndex) => (
                                            <option value={categoryOptionsArray.id}>{categoryOptionsArray.name}</option>
                                        ))} */}
                                    </Select>
                                    {errors.categoryvalue && <div style={{ color: 'red' }}>{errors.categoryvalue}</div>}
                                </div>
                            </Form.Group>
                        )}
                        {step === 4 && (
                            <Form.Group controlId="formStep4" id="step4">
                                <h4 className="mt-3 border-bottom border-dark text-primary">
                                    <Filter4 className="mb-1" /> Select Pavilion Graphics
                                </h4>
                                <p>Please choose/upload your pavilion graphics</p>
                                <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className="mb-3" fill
                                >
                                    <Tab eventKey="home" title="Predefined Graphics">
                                        <Tabs
                                            defaultActiveKey="logo"
                                            id="uncontrolled-tab-example"
                                            fill
                                        >
                                            <Tab eventKey="logo" title="LOGO" >
                                                <Row xs={1} md={4} className="g-5 mt-0 mb-4">
                                                    {Array.from({ length: 4 }).map((_, idx) => (
                                                        <Col key={idx}>
                                                            <label>
                                                                <Card className="mb-0" style={{ cursor: 'pointer' }}>
                                                                    <Card.Img variant="top" src={localStorage.getItem('bucketName') + `/assets/frontend/images/newpavilion/logo${4 + idx}.png`} />
                                                                    <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
                                                                        <Form.Check
                                                                            className="p-0"
                                                                            name="logoGroup"
                                                                            type="radio"
                                                                            size="lg"
                                                                            value={`logo${4 + idx}.png`}
                                                                            id={`logo${idx}`}
                                                                            style={{ transform: 'scale(2)' }}
                                                                            onChange={(e) => onCardChange(e, idx)}
                                                                            defaultChecked={idx === 0 && true}
                                                                        />
                                                                    </Card.ImgOverlay>
                                                                </Card>
                                                            </label>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="icon" title="ICON" >
                                                <Row xs={1} md={4} className="g-5 mt-0 mb-4">
                                                    {Array.from({ length: 4 }).map((_, idx) => (
                                                        <Col key={idx}>
                                                            <label>
                                                                <Card className="mb-0" style={{ cursor: 'pointer' }}>
                                                                    <Card.Img variant="top" src={localStorage.getItem('bucketName') + `/assets/frontend/images/newpavilion/00${4 + idx}.png`} />
                                                                    <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
                                                                        <Form.Check
                                                                            className="p-0"
                                                                            name="iconGroup"
                                                                            type="radio"
                                                                            size="lg"
                                                                            id={`icon${idx}`}
                                                                            value={`00${4 + idx}.png`}
                                                                            style={{ transform: 'scale(2)' }}
                                                                            onChange={(e) => onCardChange(e, idx)}
                                                                            defaultChecked={idx === 0 && true}
                                                                        />
                                                                    </Card.ImgOverlay>
                                                                </Card>
                                                            </label>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="card" title="CARD" >
                                                <Row xs={1} md={2} className="g-3 mt-0 mb-4">
                                                    {Array.from({ length: 4 }).map((_, idx) => (
                                                        <Col key={idx}>
                                                            <label>
                                                                <Card style={{ cursor: 'pointer' }} >
                                                                    <Card.Img variant="top" src={localStorage.getItem('bucketName') + `/assets/frontend/images/newpavilion/card${4 + idx}.png`} />
                                                                    <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
                                                                        <Form.Check
                                                                            className="p-0"
                                                                            name="cardGroup"
                                                                            type="radio"
                                                                            value={`card${4 + idx}.png`}
                                                                            id={`card${idx}`}
                                                                            style={{ transform: 'scale(2)' }}
                                                                            onChange={(e) => onCardChange(e, idx)}
                                                                            defaultChecked={idx === 0 && true}
                                                                        />
                                                                    </Card.ImgOverlay>
                                                                </Card>
                                                            </label>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="banner" title="BANNER" >
                                                <Row xs={6} md={12} className="g-3 mt-0 mb-4">
                                                    {Array.from({ length: 4 }).map((_, idx) => (
                                                        <Col key={idx} className='col-6'>
                                                            <label>
                                                                <Card className="mb-0" id="cardt" style={{ cursor: 'pointer' }}>
                                                                    <Card.Img variant="top" src={`${localStorage.getItem('bucketName')}/assets/frontend/images/newpavilion/bannerreact${1 + idx}.jpeg`} />
                                                                    <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
                                                                        <Form.Check
                                                                            label=""
                                                                            data-id={`${idx}`}
                                                                            className="p-0 m-0"
                                                                            name="bannerGroup"
                                                                            type="radio"
                                                                            size="lg"
                                                                            value={`bannerreact${1 + idx}.jpeg`}
                                                                            id={`banner${idx}`}
                                                                            style={{ transform: 'scale(2)' }}
                                                                            onChange={(e) => onCardChange(e, idx)}
                                                                            defaultChecked={idx === 0 && true}
                                                                        />
                                                                    </Card.ImgOverlay>
                                                                </Card>
                                                            </label>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                        <Form.Group className="my-3  d-flex ">

                                            <Form.Check
                                                required
                                                feedback="You must agree before submitting."
                                            />
                                            <Form.Check.Label className='ms-2'>I hereby declare that information provided in the online registration form is true to the best of my knowledge.</Form.Check.Label>
                                        </Form.Group>
                                    </Tab>
                                    <Tab eventKey="profile" title="User Defined Graphics">
                                        <div className="mb-4 mt-4 floating-label">
                                            <Form.Control type="file" id="logofile" name="logofile" onChange={logoFileEvent} />
                                            <Form.Label className="bg-light text-primary">Logo ( 200 x 70 px )</Form.Label>
                                        </div>
                                        <div className="mb-4 floating-label">
                                            <Form.Control type="file" id="iconfile" name="iconfile" onChange={iconFileEvent} />
                                            <Form.Label className="bg-light text-primary">Icon ( 100 x 100 px )</Form.Label>
                                        </div>
                                        <div className="mb-4 floating-label">
                                            <Form.Control type="file" id="cardfile" name="cardfile" onChange={cardFileEvent} />
                                            <Form.Label className="bg-light text-primary">Card ( 280 x 181 px )</Form.Label>
                                        </div>
                                        <div className="mb-4 floating-label">
                                            <Form.Control type="file" id="bannerfile" name="bannerfile" onChange={bannerFileEvent} />
                                            <Form.Label className="bg-light text-primary">Banner ( 320 x 200 px )</Form.Label>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Form.Group>
                        )}
                        {step === 5 && (
                            <Form.Group controlId="formStep5" id="step1" className="form-group-sm ">
                                <h4 className="mt-3 border-bottom border-dark text-primary">
                                    <Filter5 className="mb-1" /> Bank Details
                                </h4>
                                <p>Please fill your authentic Bank Details</p>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control required id="bank-name" size="sm" placeholder='' type="text" /> */}
                                    <Form.Control size="sm" type="text" value={bankName} onChange={bankNameChange} placeholder="Bank Name" />
                                    <Form.Label className="bg-light">Bank Name</Form.Label>
                                    {errors.bankName && <div style={{ color: 'red' }}>{errors.bankName}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control required id="account-name" size="sm" placeholder='' type="text" /> */}
                                    <Form.Control size="sm" type="text" value={accountName} onChange={accountNameChange} placeholder="Account Name" />
                                    <Form.Label className="bg-light">Account Name</Form.Label>
                                    {errors.accountName && <div style={{ color: 'red' }}>{errors.accountName}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control required id="account-no" size="sm" placeholder='' type="text" /> */}
                                    <Form.Control size="sm" type="text" value={accountNumber} onChange={accountNumberChange} placeholder="Account Number" />
                                    <Form.Label className="bg-light">Account Number</Form.Label>
                                    {errors.accountNumber && <div style={{ color: 'red' }}>{errors.accountNumber}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control required id="sort-code" size="sm" placeholder='' type="text" /> */}
                                    <Form.Control size="sm" type="text" value={sortCode} onChange={sortCodeChange} placeholder="Sort Code" />
                                    <Form.Label className="bg-light">Sort Code</Form.Label>
                                    {errors.sortCode && <div style={{ color: 'red' }}>{errors.sortCode}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control required id="swift-code" size="sm" placeholder='' type="text" /> */}
                                    <Form.Control size="sm" type="text" value={swiftCode} onChange={swiftCodeChange} placeholder="Swift Code" />
                                    <Form.Label className="bg-light">Swift Code</Form.Label>
                                    {errors.swiftCode && <div style={{ color: 'red' }}>{errors.swiftCode}</div>}
                                </div>
                                <div className="mb-3 floating-label">
                                    {/* <Form.Control required id="iban-no" placeholder='' size="sm" type="text" /> */}
                                    <Form.Control size="sm" type="text" value={iban} onChange={ibanChange} placeholder="IBAN" />
                                    <Form.Label className="bg-light">IBAN</Form.Label>
                                    {errors.iban && <div style={{ color: 'red' }}>{errors.iban}</div>}
                                </div>
                            </Form.Group>
                        )}
                        {step === 6 && (
                            <Form.Group controlId="formStep6" id="step1" className="form-group-sm ">
                                <h4 className="mt-3 border-bottom border-dark text-primary">
                                    <Filter6 className="mb-1" /> Make Payment
                                </h4>
                                <p>Please fill your authentic Payment Details</p>
                                {/* {planId} */}
                                <div className="mb-3 floating-label">
                                    <Form.Control readOnly id="plan-name" default="" placeholder="" size="sm" type="text" value={planName} />
                                    <Form.Label className="bg-light">Plan Name</Form.Label>
                                </div>
                                <div className="mb-3 floating-label">
                                    <Form.Control id="plan-type" placeholder='' readOnly size="sm" type="text" value={planType.duration === 'month' ? 'Monthly' : 'Yearly'} />
                                    <Form.Label className="bg-light">Plan Type</Form.Label>
                                </div>
                                <div className="mb-3 floating-label">
                                    <Form.Control id="amount" readOnly placeholder='' size="sm" type="text" value={planAmt} />
                                    <Form.Label className="bg-light">Amount</Form.Label>
                                </div>
                                <div className="mb-3 floating-label">
                                    <Form.Control id="coupon-code" placeholder='' size="sm" type="text" />
                                    <Form.Label className="bg-light">Coupon Code</Form.Label>
                                    <div className="my-2"><strong>Note:</strong> If you have coupon code then you can add otherwise leave blank.</div>
                                </div>
                            </Form.Group>
                        )}
                        {step === 7 && (
                            <Form.Group controlId="formStep6" id="step1" className="form-group-sm ">
                                <h4 className="mt-3 mb-5 border-bottom border-dark text-primary">
                                    <Filter7 className="mb-1" /> Congratulations!
                                </h4>
                                <div className="manage text-center">
                                    <h3>Congratulations <span className="shown_name"></span></h3>
                                    <CheckCircleIcon className="text-success display-1" fontSize="10rem" />
                                    <p>Your New Pavilion request successfully submitted.</p>
                                    <p>Our Team will get back to you soon</p>


                                </div>
                            </Form.Group>
                        )}

                        <div className="d-flex justify-content-between align-items-end">
                            {(step > 1 && step < 7) && (
                                <Button variant="secondary" onClick={handlePrevious}>
                                    Previous
                                </Button>
                            )}
                            {step === 4 && (
                                <a href={'/preview/' + pavilonSlug} target='_blank'><Button variant="secondary" >
                                    Preview
                                </Button></a>
                            )}
                            {step === 6 && (
                                <a href={'/preview/' + pavilonSlug} target='_blank'><Button variant="secondary" >
                                    Preview
                                </Button></a>
                            )}
                            {step < 6 && (<>
                                {step === 1 && (
                                    <Button variant="secondary" disabled={progress} onClick={pavilionUsers}>
                                        {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Next
                                    </Button>
                                )}
                                {step === 2 && (
                                    <Button variant="secondary" disabled={progress} onClick={addPavilion}>
                                        {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Next
                                    </Button>
                                )}
                                {step === 3 && (
                                    <Button variant="secondary" disabled={progress} onClick={dataCategory}>
                                        {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Next
                                    </Button>
                                )}
                                {step === 4 && (
                                    <Button variant="secondary" onClick={handleNext}>
                                        Next
                                    </Button>
                                )}
                                {step === 5 && (
                                    <Button variant="secondary" disabled={progress} onClick={bankdetails}>
                                        {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Next
                                    </Button>
                                )}
                            </>
                            )}
                            {step === 6 && (
                                <Button variant="primary" disabled={progress} onClick={handleStripeSubmit}>
                                    {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Submit
                                </Button>
                            )}

                        </div>
                    </Container>
                </Form>
            </Col>
        </Row >
    );
};

export default PavilionSetupForm;