import React,{useEffect, useState} from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search, Settings } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import Card from 'react-bootstrap/Card';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import http from '../../../../../http';
import Spinner from 'react-bootstrap/Spinner';
import PreLoader from '../../../../PreLoader';
import { PLSIZE } from '../../../../PavilionConstants';
import {useLocation, useNavigate} from 'react-router-dom';


function PRFLNewPublicPavilion({handleClick}) {

  const navigate=useNavigate();
  
    const [loading, setLoading]=useState(true);
    const [pavilions,setPavilions]=useState(['Whos Who', 'APPG Artificial Intelligence', 'BIC Premium Members', 'The Innovators Board','New Pavilion','Best Pavilion'])
    const [members,setMembers]=useState(['50 Members', '80 Members', '35 Members', '48 Members','85 Members','20 Members'])

	const [pavilionlist, setPavilionlist] = useState([]);
	const [countrylist, setCountrylist] = useState([]);
	const token=localStorage.getItem('token');
	const [selectedOption, setSelectedOption] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [countryData, setcountryData] = useState('');
	
  const location= useLocation();

  useEffect(() => {
    const splitURL = location.pathname.toString().split("/");
    const param = ['poll','walls','event','publication','newsinformation'];
  
    if(param.includes(splitURL[1])){
      if (splitURL[2] ) { 
        // handleClick('PVLN-MENU') 
      } 
    }
    //   if (splitURL[1] === 'poll') {
  //       if (splitURL[2] ) { handleClick('PVLN-MENU') } 
  //   }
  
    // console.log(`The current route is Menu1 ${splitURL[1]}`);
}, [location]);

	useEffect(() => {
		  http().get('/webpublicnewpavilion').then((data) => {
			setLoading(false);
		  //console.log(data);
		  //console.log(data.data.data.pavilions);
		  setPavilionlist(data.data.data.pavilions.data);
		});
  },[]);
		
		
  
    
   
  return (
    <>
    
    <ListGroup className="zoom overflow-hidden h-100  px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant="light" className='d-flex justify-content-between   border-0 align-items-center bg-linear-gradient-primary text-center py-0 '>
                <Typography variant="small">All Pavilions</Typography>
                <IconButton ><Settings /></IconButton>
            </ListGroup.Item>
            
			<ListGroup.Item className="d-flex flex-column align-items-center h-100  border-0 justify-content-start bg-light bg-opacity-75" >
			{loading ? (<PreLoader size={PLSIZE} />) : (<>
            
            {/* <div className="input-group input-group-sm mb-2" >
                    <input type="search" className="form-control rounded input-sm w-50" id="floatingSearch" placeholder="Search Pavilions" onChange={handleInputChange} />
                    <select className="form-select form-select-md ms-2 rounded input-sm" id="inputGroupSelect04" onChange={handleSelectChange}>
                        <option defaultValue="" >Global</option>
						{countrylist.map((countrylistArray, countrylistIndex) => (
                         <option value={countrylistArray.id}>{countrylistArray.name}</option>
						))}
                    </select>
                </div> */}
				
            <Row xs={1} md={2} className="g-2 slim-scrollbar" style={{maxHeight:"65vh"}} >
			
	  {pavilionlist.map((innerArray, outerIndex) => (
        <Col key={innerArray.id}>
          <Card className="mb-0 shadow-sm bg-light" style={{cursor:"pointer"}} 
              // onClick={token ? (event => handleClick(`PVLN-MENU`,innerArray.id)) : (()=> navigate('/'))}
               onClick={token ? (()=> window.location.href='/homepavilion') : (()=> navigate('/'))}
          >
			 <Card.Img variant="top" src={(innerArray.main_image == 'images/pavilion/') ? (localStorage.getItem('bucketName')+'/images/noimage.png') : (localStorage.getItem('bucketName')+'/'+innerArray.main_image)} />
            <Card.Body className="p-2 d-flex flex-column justify-content-between " style={{minHeight:"75px"}}>

              <Card.Title className="pt-0 my-0" style={{lineHeight:"0.7"}}>
                <Typography key={innerArray.id} variant="small" className="lead small" >
                    {innerArray.name}               
                </Typography>
              </Card.Title>
              <Card.Text >
                    <Typography key={innerArray.id} variant="small" className="text-muted small">{innerArray.count}</Typography>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
	  
    </Row>
	</>
	)}
            </ListGroup.Item>
				
        </ListGroup>
        

        </>
  )
}

export default PRFLNewPublicPavilion
