import React, { useState } from 'react'
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import Group from '@mui/icons-material/Group';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Container, Stack } from 'react-bootstrap'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';
import SaveIcon from '@mui/icons-material/Save';
import { ListGroup } from 'react-bootstrap';
import BadgeAvatar from '../../../components/BadgeAvatar';
import { ECOSYSTEM_OPTIONS } from '../../../components/PavilionConstants';

function MyProfileTab() {
    const options = [
        { value: 'entrepreneurial', label: 'Entrepreneurial Finance System (e.g. investor, bank, grant giver)'},
        { value: 'accelerators', label: 'Accelerators and incubators (e.g. start-up system)'},
        { value: 'industry', label: 'Industry system (e.g. company)'},
        { value: 'talent', label: 'Talent system (e.g. university, education, science parks and technology centres)'},
        { value: 'society', label: 'Think-tanks, Societies and interest groups'},
        { value: 'management', label: 'Management system (e.g. government departments, police, health care)'},
        { value: 'polictics', label: 'Politicians and legislators'},
        { value: 'culture', label: 'Livelihood system (e.g. sport, culture, amusement)'},
        { value: 'demonstration', label: 'Demonstration system (exhibition centres and events companies)'},
        { value: 'global', label: 'Global system (e.g. embassies, OECD, UN, Arab League)'},
        { value: 'urban', label: 'Urban and regional system (e.g. smart cities, mobility and transport)'},
        { value: 'press', label: 'Media and the Press'},
      ]
   
  return (
    <Container fluid className="mt-2">
                        <div className="row d-flex flex-column flex-sm-row">
                            <div className="col-12 col-sm-12 ps-0 pe-0 ">
                                <div className="list-group p-0 m-0 shadow-sm bg-toggle ">
                                    <div className="row mx-1 my-0 d-flex justify-content-between align-items-center">
                                        <div className="col-12 col-sm-6 m-auto">
                                            <div className="d-flex align-items-center justify-content-start">
                                                <div className="profile-img card shadow-none bg-transparent border-0 text-white avatar-120  my-3">

                                                    {/* <img id="avatar" className="card-img rounded-circle" src={localStorage.getItem('bucketName')+"/images/profile_picture/23231686680942.png"} alt="Card image" /> */}
                                                    <BadgeAvatar src={localStorage.getItem('bucketName')+"/images/profile_picture/23231686680942.png"} alt="Charles Smith" size="120"/>
                                                    <div className="card-img-overlay bg-transparent m-0 ">
                                                        <button className="delbtn btn btn-dark position-absolute top-0 end-0  opacity-0 rounded-circle d-flex-mi p-1">
                                                            <i className="material-symbols-outlined " >close</i></button>
                                                        <button className="btn btn-light position-absolute cropper rounded bottom-0 d-flex-mi p-1" data-bs-toggle="modal" data-bs-target="#uploadProfileModal">
                                                            {/* <i className="material-symbols-outlined " >photo_camera</i> */}
                                                            <PhotoCameraIcon />
                                                            Update
                                                            {/* <input type="file" className="sr-only" id="input2" accept="image/*"/> */}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="ms-3 ">
                                                    <h4 className="mb-1 text-primary">Charles Smith</h4>
                                                    <p className="text-muted mb-0 d-flex-mi small"><i className="material-symbols-outlined me-1" >work</i>
                                                        Application Engineer</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="btn-group d-flex justify-content-center pb-3" role="group" aria-label="Basic outlined example">
                                                <button type="button" className="btn btn-outline-secondary px-1  w-25">
                                                    <span className="badge rounded-pill bg-primary">4</span><br />
                                                    Followers
                                                </button>
                                                <button type="button" className="btn btn-outline-secondary px-1 w-25">
                                                    <span className="badge rounded-pill bg-primary ">2</span><br />
                                                    Following</button>
                                                <button title="Share Link" type="button" className="btn btn-outline-secondary d-flex flex-column justify-content-center align-items-center px-1" id="copy-btn">
                                                    <input type="hidden" id="my_link" value="https://bicpavilion.com/shareprofiles/charles-smith-7af9959813" className="form-control" />
                                                    <ShareIcon className="text-primary" />Share</button>
                                                <button type="button" className="btn btn-outline-secondary px-1 lh-1 w-25 pb-2" onclick="window.location.href = 'https://bicpavilion.com/shareprofiles/charles-smith-7af9959813'">
                                                    <VisibilityIcon className="text-primary"/><br />Public
                                                    Profile</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <ListGroup className="p-0 my-2 shadow-sm">
                                    <Form if="formEditProfile" method="post" enctype="multipart/form-data">
                                        {/* <form id="formEditProfile" action="/user/profile" method="post" enctype="multipart/form-data"> */}
                                        <input type="hidden" name="profilePicture" id="profilePicture" value="" />
                                        <input type="hidden" name="_token" value="DtNbFTFX301k3xELc4pjKbelSE1Bs7WVYxdKw8KP" />
                                        
                                        <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                            <h5 className="mb-0">About</h5>
                                            <Button variant="outline-primary" size="sm" ><SaveIcon fontSize="small"/>&nbsp;Save</Button>
                                        </ListGroup.Item>
                                        <ListGroup.Item >
                                            <div className="row d-flex align-items-center justify-content-between border-bottom mb-2 pb-2">
                                                <div className="col-12 col-sm-3">
                                                    <h5>Basic Info</h5>
                                                </div>
                                                <div className="col-12 col-sm-9">
                                                    <div className="input-group  mb-1">
                                                        <Form.Select aria-label="Default select example">
                                                            <option selected>Title...</option>
                                                            <option value="Mr" >Mr.</option>
                                                            <option value="Ms">Ms.</option>
                                                            <option value="Dr">Dr.</option>
                                                            <option value="Prof">Prof.</option>
                                                            <option value="Eng">Eng.</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="input-group mb-1">
                                                        <Form.Control type="text" placeholder="Charles" />
                                                    </div>
                                                    <div className="input-group mb-1">
                                                        <Form.Control type="text" placeholder="Smith" />
                                                    </div>

                                                    <div className="input-group mb-1 ">
                                                        {/* <input type="date" className="form-control w-50" placeholder="Date of Birth" name="dob" id="dob" value=""/> */}
                                                        <Form.Control type="date" id="dob" aria-describedby="dateOfBirth" />
                                                    </div>

                                                    <div className="input-group mb-1 ">
                                                    <Form.Select name="gender" id="gender" aria-label="Default select example">
                                                        {/* <select className="form-select w-25" name="gender" id="gender"> */}
                                                            <option selected>Gender...</option>
                                                            <option value="His" >His</option>
                                                            <option value="Her">Her</option>
                                                            <option value="Them">Them</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className="input-group mb-1 ">
                                                    <Form.Select name="language" id="language" aria-label="Default select example">
                                                        {/* <select className="form-select w-25" name="language" id="language"> */}
                                                            <option selected>Language...</option>
                                                            <option value="1" >
                                                                English</option>
                                                            <option value="2">
                                                                Arabic</option>
                                                            <option value="3">
                                                                Urdu</option>
                                                            <option value="4">
                                                                Hindi</option>
                                                            <option value="5">
                                                                French</option>
                                                            <option value="6">
                                                                German</option>
                                                            <option value="7">
                                                                Italian</option>
                                                            <option value="8">
                                                                Spanish</option>
                                                            <option value="9">
                                                                Dutch</option>
                                                            <option value="10">
                                                                Greek</option>
                                                            <option value="11">
                                                                Hungarian</option>
                                                            <option value="12">
                                                                Czech</option>
                                                            <option value="13">
                                                                Finnish</option>
                                                            <option value="14">
                                                                Croatian</option>
                                                            <option value="15">
                                                                Danish</option>
                                                            <option value="16">
                                                                Portuguese</option>
                                                            <option value="17">
                                                                Russian</option>
                                                            <option value="18">
                                                                Turkish</option>
                                                            <option value="19">
                                                                Polish</option>
                                                            <option value="20">
                                                                Germanic</option>
                                                            <option value="21">
                                                                Irish</option>
                                                            <option value="22">
                                                                Lithuanian</option>
                                                            <option value="23">
                                                                Swedish</option>
                                                            <option value="24">
                                                                Romanian</option>
                                                            <option value="25">
                                                                Albanian</option>
                                                            <option value="26">
                                                                Bulgarian</option>
                                                            <option value="27">
                                                                Basque</option>
                                                        </Form.Select>
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row d-flex align-items-center justify-content-between border-bottom mb-2 pb-2">
                                                <div className="col-12 col-sm-3">
                                                    <h5>Contact Info</h5>
                                                </div>
                                                <div className="col-12 col-sm-9 ">
                                                    <div className="input-group mb-1">
                                                    <Form.Control type="email" id="email" name="email" placeholder="name@example.com" />
                                                        {/* <input type="email" readonly="" className="form-control w-50" aria-label="Email" placeholder="Email" name="email" id="email" value="charlespavilion123@gmail.com" /> */}
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}
                                                    </div>
                                                    <div className="input-group mb-1 ">
                                                    <Form.Control type="tel" id="telephone" name="telephone" placeholder="Enter Phone " />
                                                        {/* <input type="telephone" autocomplete="off" onkeypress="return event.charcode >= 48 &amp;&amp; event.charcode <= 57 " className="form-control w-50" aria-label="Phone" placeholder="Phone" name="phone" id="phone" value="" /> */}
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}

                                                    </div>
                                                    <InputGroup className="mb-1 ">
                                                    <Form.Select name="country" id="country" aria-label="Default select example">
                                                        {/* <select className="form-select w-25" name="country" id="country_id"> */}
                                                            <option selected >Country...</option>
                                                            <option value="1">
                                                                Afghanistan</option>
                                                            <option value="2">
                                                                Aland Islands</option>
                                                            <option value="3">
                                                                Albania</option>
                                                            <option value="4">
                                                                Algeria</option>
                                                            <option value="5">
                                                                American Samoa</option>
                                                            <option value="6">
                                                                Andorra</option>
                                                            <option value="7">
                                                                Angola</option>
                                                            <option value="8">
                                                                Anguilla</option>
                                                            <option value="9">
                                                                Antarctica</option>
                                                            <option value="10">
                                                                Antigua And Barbuda</option>
                                                            <option value="11">
                                                                Argentina</option>
                                                            <option value="12">
                                                                Armenia</option>
                                                            <option value="13">
                                                                Aruba</option>
                                                            <option value="14">
                                                                Australia</option>
                                                            <option value="15">
                                                                Austria</option>
                                                            <option value="16">
                                                                Azerbaijan</option>
                                                            <option value="17">
                                                                Bahamas The</option>
                                                            <option value="18">
                                                                Bahrain</option>
                                                            <option value="19">
                                                                Bangladesh</option>
                                                            <option value="20">
                                                                Barbados</option>
                                                            <option value="21">
                                                                Belarus</option>
                                                            <option value="22">
                                                                Belgium</option>
                                                            <option value="23">
                                                                Belize</option>
                                                            <option value="24">
                                                                Benin</option>
                                                            <option value="25">
                                                                Bermuda</option>
                                                            <option value="26">
                                                                Bhutan</option>
                                                            <option value="27">
                                                                Bolivia</option>
                                                            <option value="28">
                                                                Bosnia and Herzegovina</option>
                                                            <option value="29">
                                                                Botswana</option>
                                                            <option value="30">
                                                                Bouvet Island</option>
                                                            <option value="31">
                                                                Brazil</option>
                                                            <option value="32">
                                                                British Indian Ocean Territory</option>
                                                            <option value="33">
                                                                Brunei</option>
                                                            <option value="34">
                                                                Bulgaria</option>
                                                            <option value="35">
                                                                Burkina Faso</option>
                                                            <option value="36">
                                                                Burundi</option>
                                                            <option value="37">
                                                                Cambodia</option>
                                                            <option value="38">
                                                                Cameroon</option>
                                                            <option value="39">
                                                                Canada</option>
                                                            <option value="40">
                                                                Cape Verde</option>
                                                            <option value="41">
                                                                Cayman Islands</option>
                                                            <option value="42">
                                                                Central African Republic</option>
                                                            <option value="43">
                                                                Chad</option>
                                                            <option value="44">
                                                                Chile</option>
                                                            <option value="45">
                                                                China</option>
                                                            <option value="46">
                                                                Christmas Island</option>
                                                            <option value="47">
                                                                Cocos (Keeling) Islands</option>
                                                            <option value="48">
                                                                Colombia</option>
                                                            <option value="49">
                                                                Comoros</option>
                                                            <option value="50">
                                                                Congo</option>
                                                            <option value="51">
                                                                Congo The Democratic Republic Of The</option>
                                                            <option value="52">
                                                                Cook Islands</option>
                                                            <option value="53">
                                                                Costa Rica</option>
                                                            <option value="54">
                                                                Cote D'Ivoire (Ivory Coast)</option>
                                                            <option value="55">
                                                                Croatia (Hrvatska)</option>
                                                            <option value="56">
                                                                Cuba</option>
                                                            <option value="57">
                                                                Cyprus</option>
                                                            <option value="58">
                                                                Czech Republic</option>
                                                            <option value="59">
                                                                Denmark</option>
                                                            <option value="60">
                                                                Djibouti</option>
                                                            <option value="61">
                                                                Dominica</option>
                                                            <option value="62">
                                                                Dominican Republic</option>
                                                            <option value="63">
                                                                East Timor</option>
                                                            <option value="64">
                                                                Ecuador</option>
                                                            <option value="65">
                                                                Egypt</option>
                                                            <option value="66">
                                                                El Salvador</option>
                                                            <option value="67">
                                                                Equatorial Guinea</option>
                                                            <option value="68">
                                                                Eritrea</option>
                                                            <option value="69">
                                                                Estonia</option>
                                                            <option value="70">
                                                                Ethiopia</option>
                                                            <option value="71">
                                                                Falkland Islands</option>
                                                            <option value="72">
                                                                Faroe Islands</option>
                                                            <option value="73">
                                                                Fiji Islands</option>
                                                            <option value="74">
                                                                Finland</option>
                                                            <option value="75">
                                                                France</option>
                                                            <option value="76">
                                                                French Guiana</option>
                                                            <option value="77">
                                                                French Polynesia</option>
                                                            <option value="78">
                                                                French Southern Territories</option>
                                                            <option value="79">
                                                                Gabon</option>
                                                            <option value="80">
                                                                Gambia The</option>
                                                            <option value="81">
                                                                Georgia</option>
                                                            <option value="82">
                                                                Germany</option>
                                                            <option value="83">
                                                                Ghana</option>
                                                            <option value="84">
                                                                Gibraltar</option>
                                                            <option value="85">
                                                                Greece</option>
                                                            <option value="86">
                                                                Greenland</option>
                                                            <option value="87">
                                                                Grenada</option>
                                                            <option value="88">
                                                                Guadeloupe</option>
                                                            <option value="89">
                                                                Guam</option>
                                                            <option value="90">
                                                                Guatemala</option>
                                                            <option value="91">
                                                                Guernsey and Alderney</option>
                                                            <option value="92">
                                                                Guinea</option>
                                                            <option value="93">
                                                                Guinea-Bissau</option>
                                                            <option value="94">
                                                                Guyana</option>
                                                            <option value="95">
                                                                Haiti</option>
                                                            <option value="96">
                                                                Heard and McDonald Islands</option>
                                                            <option value="97">
                                                                Honduras</option>
                                                            <option value="98">
                                                                Hong Kong S.A.R.</option>
                                                            <option value="99">
                                                                Hungary</option>
                                                            <option value="100">
                                                                Iceland</option>
                                                            <option value="101">
                                                                India</option>
                                                            <option value="102">
                                                                Indonesia</option>
                                                            <option value="103">
                                                                Iran</option>
                                                            <option value="104">
                                                                Iraq</option>
                                                            <option value="105">
                                                                Ireland</option>
                                                            <option value="106">
                                                                Israel</option>
                                                            <option value="107">
                                                                Italy</option>
                                                            <option value="108">
                                                                Jamaica</option>
                                                            <option value="109">
                                                                Japan</option>
                                                            <option value="110">
                                                                Jersey</option>
                                                            <option value="111">
                                                                Jordan</option>
                                                            <option value="112">
                                                                Kazakhstan</option>
                                                            <option value="113">
                                                                Kenya</option>
                                                            <option value="114">
                                                                Kiribati</option>
                                                            <option value="115">
                                                                Korea North</option>
                                                            <option value="116">
                                                                Korea South</option>
                                                            <option value="117">
                                                                Kuwait</option>
                                                            <option value="118">
                                                                Kyrgyzstan</option>
                                                            <option value="119">
                                                                Laos</option>
                                                            <option value="120">
                                                                Latvia</option>
                                                            <option value="121">
                                                                Lebanon</option>
                                                            <option value="122">
                                                                Lesotho</option>
                                                            <option value="123">
                                                                Liberia</option>
                                                            <option value="124">
                                                                Libya</option>
                                                            <option value="125">
                                                                Liechtenstein</option>
                                                            <option value="126">
                                                                Lithuania</option>
                                                            <option value="127">
                                                                Luxembourg</option>
                                                            <option value="128">
                                                                Macau S.A.R.</option>
                                                            <option value="129">
                                                                Macedonia</option>
                                                            <option value="130">
                                                                Madagascar</option>
                                                            <option value="131">
                                                                Malawi</option>
                                                            <option value="132">
                                                                Malaysia</option>
                                                            <option value="133">
                                                                Maldives</option>
                                                            <option value="134">
                                                                Mali</option>
                                                            <option value="135">
                                                                Malta</option>
                                                            <option value="136">
                                                                Man (Isle of)</option>
                                                            <option value="137">
                                                                Marshall Islands</option>
                                                            <option value="138">
                                                                Martinique</option>
                                                            <option value="139">
                                                                Mauritania</option>
                                                            <option value="140">
                                                                Mauritius</option>
                                                            <option value="141">
                                                                Mayotte</option>
                                                            <option value="142">
                                                                Mexico</option>
                                                            <option value="143">
                                                                Micronesia</option>
                                                            <option value="144">
                                                                Moldova</option>
                                                            <option value="145">
                                                                Monaco</option>
                                                            <option value="146">
                                                                Mongolia</option>
                                                            <option value="147">
                                                                Montenegro</option>
                                                            <option value="148">
                                                                Montserrat</option>
                                                            <option value="149">
                                                                Morocco</option>
                                                            <option value="150">
                                                                Mozambique</option>
                                                            <option value="151">
                                                                Myanmar</option>
                                                            <option value="152">
                                                                Namibia</option>
                                                            <option value="153">
                                                                Nauru</option>
                                                            <option value="154">
                                                                Nepal</option>
                                                            <option value="155">
                                                                Netherlands Antilles</option>
                                                            <option value="156">
                                                                Netherlands The</option>
                                                            <option value="157">
                                                                New Caledonia</option>
                                                            <option value="158">
                                                                New Zealand</option>
                                                            <option value="159">
                                                                Nicaragua</option>
                                                            <option value="160">
                                                                Niger</option>
                                                            <option value="161">
                                                                Nigeria</option>
                                                            <option value="162">
                                                                Niue</option>
                                                            <option value="163">
                                                                Norfolk Island</option>
                                                            <option value="164">
                                                                Northern Mariana Islands</option>
                                                            <option value="165">
                                                                Norway</option>
                                                            <option value="166">
                                                                Oman</option>
                                                            <option value="167">
                                                                Pakistan</option>
                                                            <option value="168">
                                                                Palau</option>
                                                            <option value="169">
                                                                Palestinian Territory Occupied</option>
                                                            <option value="170">
                                                                Panama</option>
                                                            <option value="171">
                                                                Papua new Guinea</option>
                                                            <option value="172">
                                                                Paraguay</option>
                                                            <option value="173">
                                                                Peru</option>
                                                            <option value="174">
                                                                Philippines</option>
                                                            <option value="175">
                                                                Pitcairn Island</option>
                                                            <option value="176">
                                                                Poland</option>
                                                            <option value="177">
                                                                Portugal</option>
                                                            <option value="178">
                                                                Puerto Rico</option>
                                                            <option value="179">
                                                                Qatar</option>
                                                            <option value="180">
                                                                Reunion</option>
                                                            <option value="181">
                                                                Romania</option>
                                                            <option value="182">
                                                                Russia</option>
                                                            <option value="183">
                                                                Rwanda</option>
                                                            <option value="184">
                                                                Saint Helena</option>
                                                            <option value="185">
                                                                Saint Kitts And Nevis</option>
                                                            <option value="186">
                                                                Saint Lucia</option>
                                                            <option value="187">
                                                                Saint Pierre and Miquelon</option>
                                                            <option value="188">
                                                                Saint Vincent And The Grenadines</option>
                                                            <option value="189">
                                                                Saint-Barthelemy</option>
                                                            <option value="190">
                                                                Saint-Martin (French part)</option>
                                                            <option value="191">
                                                                Samoa</option>
                                                            <option value="192">
                                                                San Marino</option>
                                                            <option value="193">
                                                                Sao Tome and Principe</option>
                                                            <option value="194">
                                                                Saudi Arabia</option>
                                                            <option value="195">
                                                                Senegal</option>
                                                            <option value="196">
                                                                Serbia</option>
                                                            <option value="197">
                                                                Seychelles</option>
                                                            <option value="198">
                                                                Sierra Leone</option>
                                                            <option value="199">
                                                                Singapore</option>
                                                            <option value="200">
                                                                Slovakia</option>
                                                            <option value="201">
                                                                Slovenia</option>
                                                            <option value="202">
                                                                Solomon Islands</option>
                                                            <option value="203">
                                                                Somalia</option>
                                                            <option value="204">
                                                                South Africa</option>
                                                            <option value="205">
                                                                South Georgia</option>
                                                            <option value="206">
                                                                South Sudan</option>
                                                            <option value="207">
                                                                Spain</option>
                                                            <option value="208">
                                                                Sri Lanka</option>
                                                            <option value="209">
                                                                Sudan</option>
                                                            <option value="210">
                                                                Suriname</option>
                                                            <option value="211">
                                                                Svalbard And Jan Mayen Islands</option>
                                                            <option value="212">
                                                                Swaziland</option>
                                                            <option value="213">
                                                                Sweden</option>
                                                            <option value="214" >
                                                                Switzerland</option>
                                                            <option value="215">
                                                                Syria</option>
                                                            <option value="216">
                                                                Taiwan</option>
                                                            <option value="217">
                                                                Tajikistan</option>
                                                            <option value="218">
                                                                Tanzania</option>
                                                            <option value="219">
                                                                Thailand</option>
                                                            <option value="220">
                                                                Togo</option>
                                                            <option value="221">
                                                                Tokelau</option>
                                                            <option value="222">
                                                                Tonga</option>
                                                            <option value="223">
                                                                Trinidad And Tobago</option>
                                                            <option value="224">
                                                                Tunisia</option>
                                                            <option value="225">
                                                                Turkey</option>
                                                            <option value="226">
                                                                Turkmenistan</option>
                                                            <option value="227">
                                                                Turks And Caicos Islands</option>
                                                            <option value="228">
                                                                Tuvalu</option>
                                                            <option value="229">
                                                                Uganda</option>
                                                            <option value="230">
                                                                Ukraine</option>
                                                            <option value="231">
                                                                United Arab Emirates</option>
                                                            <option value="232">
                                                                United Kingdom</option>
                                                            <option value="233">
                                                                United States</option>
                                                            <option value="234">
                                                                United States Minor Outlying Islands</option>
                                                            <option value="235">
                                                                Uruguay</option>
                                                            <option value="236">
                                                                Uzbekistan</option>
                                                            <option value="237">
                                                                Vanuatu</option>
                                                            <option value="238">
                                                                Vatican City State (Holy See)</option>
                                                            <option value="239">
                                                                Venezuela</option>
                                                            <option value="240">
                                                                Vietnam</option>
                                                            <option value="241">
                                                                Virgin Islands (British)</option>
                                                            <option value="242">
                                                                Virgin Islands (US)</option>
                                                            <option value="243">
                                                                Wallis And Futuna Islands</option>
                                                            <option value="244">
                                                                Western Sahara</option>
                                                            <option value="245">
                                                                Yemen</option>
                                                            <option value="246">
                                                                Zambia</option>
                                                            <option value="247">
                                                                Zimbabwe</option>
                                                        </Form.Select>
                                                        {/* <input type="hidden" name="privacy_country" className="privacy" value="0" /> */}
                                                        <Button variant="outline-secondary" className="py-0"><VisibilityIcon/></Button>{' '}
                                                    </InputGroup>

                                                    <div className="input-group mb-1 ">
                                                    <Form.Select name="city" id="city" aria-label="Default select example">
                                                        {/* <select className="form-select w-25" name="city" id="city"> */}
                                                            <option selected value="17974">Kerzers</option>
                                                            <option value="18183">Muri</option>
                                                            <option value="17356">Aadorf</option>
                                                            <option value="17357">Aarau</option>
                                                            <option value="17358">Aarberg</option>
                                                            <option value="17359">Aarburg</option>
                                                            <option value="17360">Aarwangen</option>
                                                            <option value="17361">Acquarossa</option>
                                                            <option value="17362">Adelboden</option>
                                                            <option value="17363">Adligenswil</option>
                                                            <option value="17364">Adliswil</option>
                                                            <option value="17365">Adliswil / Adliswil (Stadtkern)</option>
                                                            <option value="17366">Adliswil / Hündli-Zopf</option>
                                                            <option value="17367">Adliswil / Oberleimbach</option>
                                                            <option value="17368">Adliswil / Sonnenberg</option>
                                                            <option value="17369">Adliswil / Sood</option>
                                                            <option value="17370">Adliswil / Tal</option>
                                                            <option value="17371">Aesch</option>
                                                            <option value="17372">Aesch</option>
                                                            <option value="17373">Aeschi b. Spiez</option>
                                                            <option value="17374">Affeltrangen</option>
                                                            <option value="17375">Affoltern / Hasenbüel</option>
                                                            <option value="17376">Affoltern / Oberdorf</option>
                                                            <option value="17377">Affoltern / Sonnenberg</option>
                                                            <option value="17378">Affoltern / Unterdorf</option>
                                                            <option value="17379">Affoltern am Albis</option>
                                                            <option value="17380">Agno</option>
                                                            <option value="17381">Aigle</option>
                                                            <option value="17382">Aigle District</option>
                                                            <option value="17383">Aire-la-Ville</option>
                                                            <option value="17384">Airolo</option>
                                                            <option value="17385">Alle</option>
                                                            <option value="17386">Allschwil</option>
                                                            <option value="17387">Alpnach</option>
                                                            <option value="17388">Altdorf</option>
                                                            <option value="17389">Altendorf</option>
                                                            <option value="17390">Alterswil</option>
                                                            <option value="17391">Altishofen</option>
                                                            <option value="17392">Altnau</option>
                                                            <option value="17393">Altstätten</option>
                                                            <option value="17394">Amden</option>
                                                            <option value="17395">Amriswil</option>
                                                            <option value="17396">Andelfingen</option>
                                                            <option value="17397">Andermatt</option>
                                                            <option value="17398">Andwil</option>
                                                            <option value="17399">Anières</option>
                                                            <option value="17400">Appenzell</option>
                                                            <option value="17401">Apples</option>
                                                            <option value="17402">Arbon</option>
                                                            <option value="17403">Arbon District</option>
                                                            <option value="17404">Arch</option>
                                                            <option value="17405">Ardon</option>
                                                            <option value="17406">Arisdorf</option>
                                                            <option value="17407">Aristau</option>
                                                            <option value="17408">Arlesheim</option>
                                                            <option value="17409">Arosa</option>
                                                            <option value="17410">Arth</option>
                                                            <option value="17411">Arvigo</option>
                                                            <option value="17412">Arzier</option>
                                                            <option value="17413">Arzo</option>
                                                            <option value="17414">Ascona</option>
                                                            <option value="17415">Attalens</option>
                                                            <option value="17416">Attinghausen</option>
                                                            <option value="17417">Attiswil</option>
                                                            <option value="17418">Au</option>
                                                            <option value="17419">Au</option>
                                                            <option value="17420">Au / Mittel-Dorf</option>
                                                            <option value="17421">Au / Unter-Dorf</option>
                                                            <option value="17422">Aubonne</option>
                                                            <option value="17423">Auvernier</option>
                                                            <option value="17424">Auw</option>
                                                            <option value="17425">Avenches</option>
                                                            <option value="17426">Avry-sur-Matran</option>
                                                            <option value="17427">Ayent</option>
                                                            <option value="17428">Baar</option>
                                                            <option value="17429">Bachenbülach</option>
                                                            <option value="17430">Bad Ragaz</option>
                                                            <option value="17431">Bad Zurzach</option>
                                                            <option value="17432">Baden</option>
                                                            <option value="17433">Bagnes</option>
                                                            <option value="17434">Balerna</option>
                                                            <option value="17435">Balgach</option>
                                                            <option value="17436">Ballens</option>
                                                            <option value="17437">Ballwil</option>
                                                            <option value="17438">Balsthal</option>
                                                            <option value="17439">Baltschieder</option>
                                                            <option value="17440">Bas-Vully</option>
                                                            <option value="17441">Basse-Nendaz</option>
                                                            <option value="17442">Bassecourt</option>
                                                            <option value="17443">Bassersdorf</option>
                                                            <option value="17444">Bauen</option>
                                                            <option value="17445">Bauma</option>
                                                            <option value="17446">Bavois</option>
                                                            <option value="17447">Beatenberg</option>
                                                            <option value="17448">Beckenried</option>
                                                            <option value="17449">Begnins</option>
                                                            <option value="17450">Belfaux</option>
                                                            <option value="17451">Bellevue</option>
                                                            <option value="17452">Bellinzona</option>
                                                            <option value="17453">Bellinzona District</option>
                                                            <option value="17454">Bellmund</option>
                                                            <option value="17455">Belmont-sur-Lausanne</option>
                                                            <option value="17456">Belp</option>
                                                            <option value="17457">Benglen</option>
                                                            <option value="17458">Benken</option>
                                                            <option value="17459">Berg</option>
                                                            <option value="17460">Berikon</option>
                                                            <option value="17461">Beringen</option>
                                                            <option value="17462">Bern</option>
                                                            <option value="17463">Bern-Mittelland District</option>
                                                            <option value="17464">Bernex</option>
                                                            <option value="17465">Berolle</option>
                                                            <option value="17466">Beromünster</option>
                                                            <option value="17467">Besenbüren</option>
                                                            <option value="17468">Bettlach</option>
                                                            <option value="17469">Bevaix</option>
                                                            <option value="17470">Bex</option>
                                                            <option value="17471">Bezirk Aarau</option>
                                                            <option value="17472">Bezirk Affoltern</option>
                                                            <option value="17473">Bezirk Andelfingen</option>
                                                            <option value="17474">Bezirk Arlesheim</option>
                                                            <option value="17475">Bezirk Baden</option>
                                                            <option value="17476">Bezirk Bremgarten</option>
                                                            <option value="17477">Bezirk Brugg</option>
                                                            <option value="17478">Bezirk Bucheggberg</option>
                                                            <option value="17479">Bezirk Bülach</option>
                                                            <option value="17480">Bezirk Dielsdorf</option>
                                                            <option value="17481">Bezirk Dietikon</option>
                                                            <option value="17482">Bezirk Dorneck</option>
                                                            <option value="17483">Bezirk Einsiedeln</option>
                                                            <option value="17484">Bezirk Gersau</option>
                                                            <option value="17485">Bezirk Gäu</option>
                                                            <option value="17486">Bezirk Gösgen</option>
                                                            <option value="17487">Bezirk Hinterland</option>
                                                            <option value="17488">Bezirk Hinwil</option>
                                                            <option value="17489">Bezirk Horgen</option>
                                                            <option value="17490">Bezirk Höfe</option>
                                                            <option value="17491">Bezirk Kulm</option>
                                                            <option value="17492">Bezirk Küssnacht</option>
                                                            <option value="17493">Bezirk Laufen</option>
                                                            <option value="17494">Bezirk Laufenburg</option>
                                                            <option value="17495">Bezirk Lebern</option>
                                                            <option value="17496">Bezirk Lenzburg</option>
                                                            <option value="17497">Bezirk Liestal</option>
                                                            <option value="17498">Bezirk March</option>
                                                            <option value="17499">Bezirk Meilen</option>
                                                            <option value="17500">Bezirk Mittelland</option>
                                                            <option value="17501">Bezirk Muri</option>
                                                            <option value="17502">Bezirk Oberklettgau</option>
                                                            <option value="17503">Bezirk Olten</option>
                                                            <option value="17504">Bezirk Pfäffikon</option>
                                                            <option value="17505">Bezirk Reiat</option>
                                                            <option value="17506">Bezirk Rheinfelden</option>
                                                            <option value="17507">Bezirk Schaffhausen</option>
                                                            <option value="17508">Bezirk Schleitheim</option>
                                                            <option value="17509">Bezirk Schwyz</option>
                                                            <option value="17510">Bezirk Sissach</option>
                                                            <option value="17511">Bezirk Solothurn</option>
                                                            <option value="17512">Bezirk Stein</option>
                                                            <option value="17513">Bezirk Thal</option>
                                                            <option value="17514">Bezirk Thierstein</option>
                                                            <option value="17515">Bezirk Unterklettgau</option>
                                                            <option value="17516">Bezirk Uster</option>
                                                            <option value="17517">Bezirk Vorderland</option>
                                                            <option value="17518">Bezirk Waldenburg</option>
                                                            <option value="17519">Bezirk Wasseramt</option>
                                                            <option value="17520">Bezirk Winterthur</option>
                                                            <option value="17521">Bezirk Zofingen</option>
                                                            <option value="17522">Bezirk Zurzach</option>
                                                            <option value="17523">Bezirk Zürich</option>
                                                            <option value="17524">Biasca</option>
                                                            <option value="17525">Biberist</option>
                                                            <option value="17526">Biel/Bienne</option>
                                                            <option value="17527">Biel/Bienne District</option>
                                                            <option value="17528">Biglen</option>
                                                            <option value="17529">Bilten</option>
                                                            <option value="17530">Binningen</option>
                                                            <option value="17531">Binz</option>
                                                            <option value="17532">Binzikon</option>
                                                            <option value="17533">Bioggio</option>
                                                            <option value="17534">Birchwil</option>
                                                            <option value="17535">Birmensdorf</option>
                                                            <option value="17536">Birmenstorf</option>
                                                            <option value="17537">Birr</option>
                                                            <option value="17538">Birsfelden</option>
                                                            <option value="17539">Bière</option>
                                                            <option value="17540">Blenio District</option>
                                                            <option value="17541">Blonay</option>
                                                            <option value="17542">Blumenstein</option>
                                                            <option value="17543">Blécherette</option>
                                                            <option value="17544">Bodio</option>
                                                            <option value="17545">Bolligen</option>
                                                            <option value="17546">Boltigen</option>
                                                            <option value="17547">Bonaduz</option>
                                                            <option value="17548">Boncourt</option>
                                                            <option value="17549">Boniswil</option>
                                                            <option value="17550">Bonstetten</option>
                                                            <option value="18286">Orbe</option>
                                                            <option value="17551">Bottens</option>
                                                            <option value="17552">Bottighofen</option>
                                                            <option value="17553">Bottmingen</option>
                                                            <option value="17554">Boudry</option>
                                                            <option value="17555">Boudry District</option>
                                                            <option value="17556">Bowil</option>
                                                            <option value="17557">Breitenbach</option>
                                                            <option value="17558">Bremgarten</option>
                                                            <option value="17559">Breíl</option>
                                                            <option value="17560">Brienz</option>
                                                            <option value="17561">Brig</option>
                                                            <option value="17562">Brig District</option>
                                                            <option value="17563">Brislach</option>
                                                            <option value="17564">Brissago</option>
                                                            <option value="17565">Brittnau</option>
                                                            <option value="17566">Broc</option>
                                                            <option value="17567">Bronschhofen</option>
                                                            <option value="17568">Broye District</option>
                                                            <option value="17569">Broye-Vully District</option>
                                                            <option value="17570">Brugg</option>
                                                            <option value="17571">Brusio</option>
                                                            <option value="17572">Brügg</option>
                                                            <option value="17573">Brütten</option>
                                                            <option value="17574">Brüttisellen</option>
                                                            <option value="17575">Bubendorf</option>
                                                            <option value="17576">Bubikon</option>
                                                            <option value="17577">Buchholterberg</option>
                                                            <option value="17578">Buchrain</option>
                                                            <option value="17579">Buchs</option>
                                                            <option value="17580">Buchs</option>
                                                            <option value="17581">Bulle</option>
                                                            <option value="17582">Buochs</option>
                                                            <option value="17583">Burgdorf</option>
                                                            <option value="17584">Burgistein</option>
                                                            <option value="17585">Buseno</option>
                                                            <option value="17586">Bussigny</option>
                                                            <option value="17587">Buttisholz</option>
                                                            <option value="17588">Buttwil</option>
                                                            <option value="17589">Buus</option>
                                                            <option value="17590">Bäch</option>
                                                            <option value="17591">Bäretswil</option>
                                                            <option value="17592">Bäriswil</option>
                                                            <option value="17593">Bätterkinden</option>
                                                            <option value="17594">Bévilard</option>
                                                            <option value="17595">Bönigen</option>
                                                            <option value="17596">Bösingen</option>
                                                            <option value="17597">Bühler</option>
                                                            <option value="17598">Bülach</option>
                                                            <option value="17599">Bülach / Gstückt</option>
                                                            <option value="17600">Bülach / Seematt</option>
                                                            <option value="17601">Bülach / Soligänter</option>
                                                            <option value="17602">Bürglen</option>
                                                            <option value="17603">Bürglen</option>
                                                            <option value="17604">Büron</option>
                                                            <option value="17605">Büsserach</option>
                                                            <option value="17606">Bütschwil</option>
                                                            <option value="17607">Cadempino</option>
                                                            <option value="17608">Cadenazzo</option>
                                                            <option value="17609">Cadro</option>
                                                            <option value="17610">Camorino</option>
                                                            <option value="17611">Canobbio</option>
                                                            <option value="17612">Carouge</option>
                                                            <option value="17613">Caslano</option>
                                                            <option value="17614">Castel San Pietro</option>
                                                            <option value="17615">Cauco</option>
                                                            <option value="17616">Caux</option>
                                                            <option value="17617">Cazis</option>
                                                            <option value="17618">Celerina</option>
                                                            <option value="17619">Cernier</option>
                                                            <option value="17620">Cevio</option>
                                                            <option value="17621">Chalais</option>
                                                            <option value="17622">Cham</option>
                                                            <option value="17623">Chamoson</option>
                                                            <option value="17624">Champéry</option>
                                                            <option value="17625">Chancy</option>
                                                            <option value="17626">Chardonne</option>
                                                            <option value="17627">Charmey</option>
                                                            <option value="17628">Charrat</option>
                                                            <option value="17629">Chavannes</option>
                                                            <option value="17630">Chavannes-le-Veyron</option>
                                                            <option value="17631">Chavornay</option>
                                                            <option value="17632">Chermignon-d’en Haut</option>
                                                            <option value="17633">Cheseaux</option>
                                                            <option value="17634">Chevilly</option>
                                                            <option value="17635">Chexbres</option>
                                                            <option value="17636">Chiasso</option>
                                                            <option value="17637">Chippis</option>
                                                            <option value="17638">Chur</option>
                                                            <option value="17639">Churwalden</option>
                                                            <option value="17640">Château-d'Oex</option>
                                                            <option value="17641">Châtel-Saint-Denis</option>
                                                            <option value="17642">Chézard-Saint-Martin</option>
                                                            <option value="17643">Chêne-Bougeries</option>
                                                            <option value="17644">Chêne-Bourg</option>
                                                            <option value="17645">Claro</option>
                                                            <option value="17646">Collombey</option>
                                                            <option value="17647">Colombier</option>
                                                            <option value="17648">Comano</option>
                                                            <option value="17649">Confignon</option>
                                                            <option value="17650">Conthey</option>
                                                            <option value="17651">Conthey District</option>
                                                            <option value="17652">Coppet</option>
                                                            <option value="17653">Corgémont</option>
                                                            <option value="17654">Corminboeuf</option>
                                                            <option value="17655">Cornaux</option>
                                                            <option value="17656">Corsier</option>
                                                            <option value="17657">Cortaillod</option>
                                                            <option value="17658">Cossonay</option>
                                                            <option value="17659">Courfaivre</option>
                                                            <option value="17660">Courgenay</option>
                                                            <option value="17661">Courgevaux</option>
                                                            <option value="17662">Courrendlin</option>
                                                            <option value="17663">Courroux</option>
                                                            <option value="17664">Court</option>
                                                            <option value="17665">Courtelary</option>
                                                            <option value="17666">Courtételle</option>
                                                            <option value="17667">Couvet</option>
                                                            <option value="17668">Crissier</option>
                                                            <option value="17669">Cuarnens</option>
                                                            <option value="17670">Cugnasco</option>
                                                            <option value="17671">Cugy</option>
                                                            <option value="17672">Cully</option>
                                                            <option value="17673">Dachsen</option>
                                                            <option value="17674">Dagmersellen</option>
                                                            <option value="17675">Dallenwil</option>
                                                            <option value="17676">Dardagny</option>
                                                            <option value="17677">Davos</option>
                                                            <option value="17678">Degersheim</option>
                                                            <option value="17679">Deitingen</option>
                                                            <option value="17680">Delémont</option>
                                                            <option value="17681">Delémont District</option>
                                                            <option value="17682">Densbüren</option>
                                                            <option value="17683">Derendingen</option>
                                                            <option value="17684">Diegten</option>
                                                            <option value="17685">Dielsdorf</option>
                                                            <option value="17686">Diemtigen</option>
                                                            <option value="17687">Diepoldsau</option>
                                                            <option value="17688">Diessenhofen</option>
                                                            <option value="17689">Dietikon</option>
                                                            <option value="17690">Dietikon / Almend</option>
                                                            <option value="17691">Dietikon / Guggenbühl</option>
                                                            <option value="17692">Dietikon / Hofacker</option>
                                                            <option value="17693">Dietikon / Kreuzacker</option>
                                                            <option value="17694">Dietikon / Oberdorf</option>
                                                            <option value="17695">Dietikon / Schönenwerd</option>
                                                            <option value="17696">Dietikon / Vorstadt</option>
                                                            <option value="17697">Dietlikon / Dietlikon (Dorf)</option>
                                                            <option value="17698">Dietlikon / Eichwiesen</option>
                                                            <option value="17699">Disentis</option>
                                                            <option value="17700">Domat</option>
                                                            <option value="17701">Dombresson</option>
                                                            <option value="17702">Domdidier</option>
                                                            <option value="17703">Dorf</option>
                                                            <option value="17704">Dornach</option>
                                                            <option value="17705">Dottikon</option>
                                                            <option value="17706">Dällikon / Dällikon (Dorf)</option>
                                                            <option value="17707">Dänikon</option>
                                                            <option value="17708">Därligen</option>
                                                            <option value="17709">Dübendorf</option>
                                                            <option value="17710">Dübendorf / Kunklerstrasse</option>
                                                            <option value="17711">Dübendorf / Sonnenberg</option>
                                                            <option value="17712">Dübendorf / Vogelquartier</option>
                                                            <option value="17713">Dübendorf / Wasserfurren</option>
                                                            <option value="17714">Düdingen</option>
                                                            <option value="17715">Dürnten</option>
                                                            <option value="17716">Dürrenroth</option>
                                                            <option value="17717">Dürrenäsch</option>
                                                            <option value="17718">Ebikon</option>
                                                            <option value="17719">Ebmatingen</option>
                                                            <option value="17720">Ebnat-Kappel</option>
                                                            <option value="17721">Echallens</option>
                                                            <option value="17722">Ecublens</option>
                                                            <option value="17723">Ecublens</option>
                                                            <option value="17724">Effretikon</option>
                                                            <option value="17725">Effretikon / Rappenhalde-Bannhalde</option>
                                                            <option value="17726">Effretikon / Rikon</option>
                                                            <option value="17727">Effretikon / Watt</option>
                                                            <option value="17728">Egerkingen</option>
                                                            <option value="17729">Egg</option>
                                                            <option value="17730">Eggersriet</option>
                                                            <option value="17731">Eggiwil</option>
                                                            <option value="17732">Egliswil</option>
                                                            <option value="17733">Egnach</option>
                                                            <option value="17734">Ehrendingen</option>
                                                            <option value="17735">Eich</option>
                                                            <option value="17736">Eichberg</option>
                                                            <option value="17737">Eiken</option>
                                                            <option value="17738">Einsiedeln</option>
                                                            <option value="17739">Elgg</option>
                                                            <option value="17740">Elgg / Städtchen und Umgebung</option>
                                                            <option value="17741">Elsau-Räterschen</option>
                                                            <option value="17742">Elsau-Räterschen / Räterschen</option>
                                                            <option value="17743">Embrach</option>
                                                            <option value="17744">Embrach / Embrach (Dorfkern)</option>
                                                            <option value="17745">Embrach / Kellersacker</option>
                                                            <option value="17746">Emmen</option>
                                                            <option value="17747">Emmental District</option>
                                                            <option value="17748">Emmetten</option>
                                                            <option value="17749">Endingen</option>
                                                            <option value="17750">Engelberg</option>
                                                            <option value="17751">Ennenda</option>
                                                            <option value="17752">Ennetbürgen</option>
                                                            <option value="17753">Entlebuch</option>
                                                            <option value="17754">Entlebuch District</option>
                                                            <option value="17755">Entremont District</option>
                                                            <option value="17756">Epalinges</option>
                                                            <option value="17757">Ependes</option>
                                                            <option value="17758">Eriswil</option>
                                                            <option value="17759">Erlach</option>
                                                            <option value="17760">Erlen</option>
                                                            <option value="17761">Erlenbach</option>
                                                            <option value="17762">Erlenbach / links des Dorfbachs oberhalb Bahnlinie</option>
                                                            <option value="17763">Erlenbach / rechts des Dorfbachs oberhalb Bahnlinie</option>
                                                            <option value="17764">Erlenbach im Simmental</option>
                                                            <option value="17765">Erlinsbach</option>
                                                            <option value="17766">Ermatingen</option>
                                                            <option value="17767">Ersigen</option>
                                                            <option value="17768">Erstfeld</option>
                                                            <option value="17769">Eschenbach</option>
                                                            <option value="17770">Eschenz</option>
                                                            <option value="17771">Eschlikon</option>
                                                            <option value="17772">Escholzmatt</option>
                                                            <option value="17773">Esslingen</option>
                                                            <option value="17774">Estavayer-le-Lac</option>
                                                            <option value="17775">Ettingen</option>
                                                            <option value="17776">Ettiswil</option>
                                                            <option value="17777">Evilard</option>
                                                            <option value="17778">Evionnaz</option>
                                                            <option value="17779">Evolène</option>
                                                            <option value="17780">Faido</option>
                                                            <option value="17781">Fehraltorf</option>
                                                            <option value="17782">Feldmeilen</option>
                                                            <option value="17783">Felsberg</option>
                                                            <option value="17784">Ferenbalm</option>
                                                            <option value="17785">Ferreyres</option>
                                                            <option value="17786">Feuerthalen</option>
                                                            <option value="17787">Feusisberg</option>
                                                            <option value="17788">Fiesch</option>
                                                            <option value="17789">Fischingen</option>
                                                            <option value="17790">Flamatt</option>
                                                            <option value="17791">Flawil</option>
                                                            <option value="17792">Fleurier</option>
                                                            <option value="17793">Flims</option>
                                                            <option value="17794">Flums</option>
                                                            <option value="17795">Flurlingen</option>
                                                            <option value="17796">Flüelen</option>
                                                            <option value="17797">Flühli</option>
                                                            <option value="17798">Fontainemelon</option>
                                                            <option value="17799">Fontenais</option>
                                                            <option value="17800">Founex</option>
                                                            <option value="17801">Franches-Montagnes District</option>
                                                            <option value="17802">Fraubrunnen</option>
                                                            <option value="17803">Frauenfeld</option>
                                                            <option value="17804">Frauenfeld District</option>
                                                            <option value="17805">Frauenkappelen</option>
                                                            <option value="17806">Freienbach</option>
                                                            <option value="17807">Freienstein</option>
                                                            <option value="17808">Frenkendorf</option>
                                                            <option value="17809">Fribourg</option>
                                                            <option value="17810">Frick</option>
                                                            <option value="17811">Froideville</option>
                                                            <option value="17812">Frutigen</option>
                                                            <option value="17813">Frutigen-Niedersimmental District</option>
                                                            <option value="17814">Fulenbach</option>
                                                            <option value="17815">Fully</option>
                                                            <option value="17816">Fällanden</option>
                                                            <option value="17817">Füllinsdorf</option>
                                                            <option value="17818">Gachnang</option>
                                                            <option value="17819">Gais</option>
                                                            <option value="17820">Gampel</option>
                                                            <option value="17821">Gams</option>
                                                            <option value="17822">Ganterschwil</option>
                                                            <option value="17823">Gattikon</option>
                                                            <option value="17824">Gebenstorf</option>
                                                            <option value="17825">Gelterkinden</option>
                                                            <option value="17826">Genolier</option>
                                                            <option value="17827">Genève</option>
                                                            <option value="17828">Gerlafingen</option>
                                                            <option value="17829">Geroldswil</option>
                                                            <option value="17830">Gersau</option>
                                                            <option value="17831">Geuensee</option>
                                                            <option value="17832">Giffers</option>
                                                            <option value="17833">Gimel</option>
                                                            <option value="17834">Gipf-Oberfrick</option>
                                                            <option value="17835">Giswil</option>
                                                            <option value="17836">Giubiasco</option>
                                                            <option value="17837">Givisiez</option>
                                                            <option value="17838">Gland</option>
                                                            <option value="17839">Glarus</option>
                                                            <option value="17840">Glattbrugg</option>
                                                            <option value="17841">Glattbrugg / Rohr/Platten-Balsberg</option>
                                                            <option value="17842">Glattbrugg / Wydacker/Bettacker/Lättenwiesen</option>
                                                            <option value="17843">Glattfelden</option>
                                                            <option value="17844">Glovelier</option>
                                                            <option value="17845">Glâne District</option>
                                                            <option value="17846">Gockhausen</option>
                                                            <option value="17847">Goldach</option>
                                                            <option value="17848">Goldau</option>
                                                            <option value="17849">Goldingen</option>
                                                            <option value="17850">Gommiswald</option>
                                                            <option value="17851">Goms District</option>
                                                            <option value="17852">Gonten</option>
                                                            <option value="17853">Gontenschwil</option>
                                                            <option value="17854">Gordola</option>
                                                            <option value="17855">Gorgier</option>
                                                            <option value="17856">Gossau</option>
                                                            <option value="17857">Gossau</option>
                                                            <option value="17858">Gottlieben</option>
                                                            <option value="17859">Grabs</option>
                                                            <option value="17860">Grancy</option>
                                                            <option value="17861">Grand-Savagnier</option>
                                                            <option value="17862">Grandson</option>
                                                            <option value="17863">Gravesano</option>
                                                            <option value="17864">Greifensee</option>
                                                            <option value="17865">Greifensee / Müllerwis / Seilerwis</option>
                                                            <option value="17866">Greifensee / Pfisterhölzli</option>
                                                            <option value="17867">Grellingen</option>
                                                            <option value="17868">Grenchen</option>
                                                            <option value="17869">Grimisuat</option>
                                                            <option value="17870">Grindelwald</option>
                                                            <option value="17871">Grolley</option>
                                                            <option value="17872">Gros-de-Vaud District</option>
                                                            <option value="17873">Grossacker/Opfikon</option>
                                                            <option value="17874">Grossaffoltern</option>
                                                            <option value="17875">Grosswangen</option>
                                                            <option value="17876">Gruyère District</option>
                                                            <option value="17877">Gruyères</option>
                                                            <option value="17878">Gryon</option>
                                                            <option value="17879">Grächen</option>
                                                            <option value="17880">Gränichen</option>
                                                            <option value="17881">Grône</option>
                                                            <option value="17882">Grüsch</option>
                                                            <option value="17883">Grüt</option>
                                                            <option value="17884">Gstaad</option>
                                                            <option value="17885">Guggisberg</option>
                                                            <option value="17886">Gunzgen</option>
                                                            <option value="17887">Gunzwil</option>
                                                            <option value="17888">Gutenswil</option>
                                                            <option value="17889">Güttingen</option>
                                                            <option value="17890">Haag (Rheintal)</option>
                                                            <option value="17891">Hadlikon</option>
                                                            <option value="17892">Hallau</option>
                                                            <option value="17893">Hasle</option>
                                                            <option value="17894">Haslen</option>
                                                            <option value="17895">Hausen</option>
                                                            <option value="17896">Hausen am Albis / Hausen (Dorf)</option>
                                                            <option value="17897">Hedingen</option>
                                                            <option value="17898">Hegnau</option>
                                                            <option value="17899">Hegnau / Dammboden-Grindel</option>
                                                            <option value="17900">Hegnau / Sunnebüel-Eich</option>
                                                            <option value="17901">Heiden</option>
                                                            <option value="17902">Heimberg</option>
                                                            <option value="17903">Heimiswil</option>
                                                            <option value="17904">Heitenried</option>
                                                            <option value="17905">Henggart</option>
                                                            <option value="17906">Hergiswil</option>
                                                            <option value="17907">Herisau</option>
                                                            <option value="17908">Hermiswil</option>
                                                            <option value="17909">Herrliberg</option>
                                                            <option value="17910">Herzogenbuchsee</option>
                                                            <option value="17911">Hettlingen</option>
                                                            <option value="17912">Hildisrieden</option>
                                                            <option value="17913">Hilterfingen</option>
                                                            <option value="17914">Himmelried</option>
                                                            <option value="17915">Hindelbank</option>
                                                            <option value="17916">Hinteregg</option>
                                                            <option value="17917">Hinterrhein</option>
                                                            <option value="17918">Hinwil</option>
                                                            <option value="17919">Hittnau / Hittnau (Dorf)</option>
                                                            <option value="17920">Hitzkirch</option>
                                                            <option value="17921">Hochdorf</option>
                                                            <option value="17922">Hochdorf District</option>
                                                            <option value="17923">Hochfelden</option>
                                                            <option value="17924">Hochwald</option>
                                                            <option value="17925">Hofstetten</option>
                                                            <option value="17926">Hohenrain</option>
                                                            <option value="17927">Hombrechtikon</option>
                                                            <option value="17928">Homburg</option>
                                                            <option value="17929">Horgen</option>
                                                            <option value="17930">Horgen / Allmend</option>
                                                            <option value="17931">Horgen / Horgen (Dorfkern)</option>
                                                            <option value="17932">Horgen / Oberdorf</option>
                                                            <option value="17933">Horgen / Scheller-Stockerstrasse</option>
                                                            <option value="17934">Horn</option>
                                                            <option value="17935">Horw</option>
                                                            <option value="17936">Huttwil</option>
                                                            <option value="17937">Hägendorf</option>
                                                            <option value="17938">Häggenschwil</option>
                                                            <option value="17939">Hägglingen</option>
                                                            <option value="17940">Hérens District</option>
                                                            <option value="17941">Hérémence</option>
                                                            <option value="17942">Hölstein</option>
                                                            <option value="17943">Höri</option>
                                                            <option value="17944">Hünenberg</option>
                                                            <option value="17945">Hüttwilen</option>
                                                            <option value="17946">Ibach</option>
                                                            <option value="17947">Igis</option>
                                                            <option value="17948">Ilanz</option>
                                                            <option value="17949">Illnau</option>
                                                            <option value="17950">Illnau / Unter-Illnau</option>
                                                            <option value="17951">Ingenbohl</option>
                                                            <option value="17952">Ins</option>
                                                            <option value="17953">Interlaken</option>
                                                            <option value="17954">Interlaken-Oberhasli District</option>
                                                            <option value="17955">Inwil</option>
                                                            <option value="17956">Itingen</option>
                                                            <option value="17957">Jegenstorf</option>
                                                            <option value="17958">Jenaz</option>
                                                            <option value="17959">Jona</option>
                                                            <option value="17960">Jonen</option>
                                                            <option value="17961">Jongny</option>
                                                            <option value="17962">Jonschwil</option>
                                                            <option value="17963">Jura bernois</option>
                                                            <option value="17964">Jura-Nord vaudois District</option>
                                                            <option value="17965">Jussy</option>
                                                            <option value="17966">Kaiseraugst</option>
                                                            <option value="17967">Kaisten</option>
                                                            <option value="17968">Kallnach</option>
                                                            <option value="17969">Kaltbrunn</option>
                                                            <option value="17970">Kandersteg</option>
                                                            <option value="17971">Kappelen</option>
                                                            <option value="17972">Kehrsatz</option>
                                                            <option value="17973">Kerns</option>
                                                            <option value="17975">Kilchberg</option>
                                                            <option value="17976">Kilchberg / Bächler-Stocken</option>
                                                            <option value="17977">Kilchberg / Kilchberg (Dorfkern)</option>
                                                            <option value="17978">Killwangen</option>
                                                            <option value="17979">Kindhausen / Kindhausen (Dorf)</option>
                                                            <option value="17980">Kirchberg</option>
                                                            <option value="17981">Kirchberg</option>
                                                            <option value="17982">Kirchlindach</option>
                                                            <option value="17983">Kleinandelfingen</option>
                                                            <option value="17984">Kleinlützel</option>
                                                            <option value="17985">Klingnau</option>
                                                            <option value="17986">Klosters Serneus</option>
                                                            <option value="17987">Kloten</option>
                                                            <option value="17988">Kloten / Balsberg</option>
                                                            <option value="17989">Kloten / Freienberg (Chanzler-Chlini Chaseren)</option>
                                                            <option value="17990">Kloten / Geissberg</option>
                                                            <option value="17991">Kloten / Holberg</option>
                                                            <option value="17992">Kloten / Horainli</option>
                                                            <option value="17993">Kloten / Hostrass</option>
                                                            <option value="17994">Kloten / Kloten (Zentrum)</option>
                                                            <option value="17995">Kloten / Rütlen</option>
                                                            <option value="17996">Kloten / Spitz</option>
                                                            <option value="17997">Knonau</option>
                                                            <option value="17998">Knutwil</option>
                                                            <option value="17999">Koblenz</option>
                                                            <option value="18000">Kollbrunn</option>
                                                            <option value="18001">Kollbrunn / Kollbrunn (Dorfkern)</option>
                                                            <option value="18002">Konolfingen</option>
                                                            <option value="18003">Koppigen</option>
                                                            <option value="18004">Krauchthal</option>
                                                            <option value="18005">Kreuzlingen</option>
                                                            <option value="18006">Kreuzlingen District</option>
                                                            <option value="18007">Kriegstetten</option>
                                                            <option value="18008">Kriens</option>
                                                            <option value="18009">Krummenau</option>
                                                            <option value="18010">Kölliken</option>
                                                            <option value="18011">Köniz</option>
                                                            <option value="18012">Künten</option>
                                                            <option value="18013">Küsnacht</option>
                                                            <option value="18014">Küsnacht / Dorf</option>
                                                            <option value="18015">Küsnacht / Goldbach</option>
                                                            <option value="18016">Küsnacht / Heslibach</option>
                                                            <option value="18017">Küsnacht / Itschnach</option>
                                                            <option value="18018">Küsnacht / Schiedhalden</option>
                                                            <option value="18019">Küssnacht</option>
                                                            <option value="18020">Küttigen</option>
                                                            <option value="18021">La Chaux-de-Fonds</option>
                                                            <option value="18022">La Chaux-de-Fonds District</option>
                                                            <option value="18023">La Neuveville</option>
                                                            <option value="18024">La Punt Chamues-ch</option>
                                                            <option value="18025">La Roche</option>
                                                            <option value="18026">La Sarraz</option>
                                                            <option value="18027">La Tour-de-Peilz</option>
                                                            <option value="18028">La Tour-de-Trême</option>
                                                            <option value="18029">Laax</option>
                                                            <option value="18030">Lachen</option>
                                                            <option value="18031">Lake District</option>
                                                            <option value="18032">Lancy</option>
                                                            <option value="18033">Landquart</option>
                                                            <option value="18034">Langenbruck</option>
                                                            <option value="18035">Langendorf</option>
                                                            <option value="18036">Langenthal</option>
                                                            <option value="18037">Langnau</option>
                                                            <option value="18038">Langnau / Langnau (Dorf)</option>
                                                            <option value="18039">Langnau / Vitaquartier</option>
                                                            <option value="18040">Langnau am Albis</option>
                                                            <option value="18041">Langrickenbach</option>
                                                            <option value="18042">Laufen</option>
                                                            <option value="18043">Laufenburg</option>
                                                            <option value="18044">Lauffohr (Brugg)</option>
                                                            <option value="18045">Laupen</option>
                                                            <option value="18046">Laupersdorf</option>
                                                            <option value="18047">Lauperswil</option>
                                                            <option value="18048">Lausanne</option>
                                                            <option value="18049">Lausanne District</option>
                                                            <option value="18050">Lausen</option>
                                                            <option value="18051">Lauterbrunnen</option>
                                                            <option value="18052">Lavaux-Oron District</option>
                                                            <option value="18053">Lavertezzo</option>
                                                            <option value="18054">Le Chenit</option>
                                                            <option value="18055">Le Grand-Saconnex</option>
                                                            <option value="18056">Le Landeron</option>
                                                            <option value="18057">Le Locle</option>
                                                            <option value="18058">Le Locle District</option>
                                                            <option value="18059">Le Mont-sur-Lausanne</option>
                                                            <option value="18060">Le Noirmont</option>
                                                            <option value="18061">Le Vaud</option>
                                                            <option value="18062">Leibstadt</option>
                                                            <option value="18063">Lengnau</option>
                                                            <option value="18064">Lenk</option>
                                                            <option value="18065">Lens</option>
                                                            <option value="18066">Lenzburg</option>
                                                            <option value="18067">Lenzerheide</option>
                                                            <option value="18068">Les Avanchets</option>
                                                            <option value="18069">Les Bois</option>
                                                            <option value="18070">Les Brenets</option>
                                                            <option value="18071">Les Breuleux</option>
                                                            <option value="18072">Les Geneveys-sur-Coffrane</option>
                                                            <option value="18073">Les Ponts-de-Martel</option>
                                                            <option value="18074">Leuk</option>
                                                            <option value="18075">Leuk District</option>
                                                            <option value="18076">Leukerbad</option>
                                                            <option value="18077">Leuzigen</option>
                                                            <option value="18078">Leventina District</option>
                                                            <option value="18079">Leysin</option>
                                                            <option value="18080">Leytron</option>
                                                            <option value="18081">Lichtensteig</option>
                                                            <option value="18082">Liesberg</option>
                                                            <option value="18083">Liestal</option>
                                                            <option value="18084">Ligornetto</option>
                                                            <option value="18085">Linden</option>
                                                            <option value="18086">Linthal</option>
                                                            <option value="18087">Littau</option>
                                                            <option value="18088">Locarno</option>
                                                            <option value="18089">Locarno District</option>
                                                            <option value="18090">Lodrino</option>
                                                            <option value="18091">Losone</option>
                                                            <option value="18092">Lostorf</option>
                                                            <option value="18093">Lotzwil</option>
                                                            <option value="18094">Lucens</option>
                                                            <option value="18095">Lucerne-Land District</option>
                                                            <option value="18096">Lucerne-Stadt District</option>
                                                            <option value="18097">Luchsingen</option>
                                                            <option value="18098">Lugano</option>
                                                            <option value="18099">Lugano District</option>
                                                            <option value="18100">Lumino</option>
                                                            <option value="18101">Lungern</option>
                                                            <option value="18102">Luterbach</option>
                                                            <option value="18103">Luthern</option>
                                                            <option value="18104">Lutry</option>
                                                            <option value="18105">Luzein</option>
                                                            <option value="18106">Luzern</option>
                                                            <option value="18107">Lyss</option>
                                                            <option value="18108">Lyssach</option>
                                                            <option value="18109">Läufelfingen</option>
                                                            <option value="18110">Löhningen</option>
                                                            <option value="18111">Lütisburg</option>
                                                            <option value="18112">Lützelflüh</option>
                                                            <option value="18113">Madiswil</option>
                                                            <option value="18114">Magadino</option>
                                                            <option value="18115">Magden</option>
                                                            <option value="18116">Maienfeld</option>
                                                            <option value="18117">Malans</option>
                                                            <option value="18118">Malleray</option>
                                                            <option value="18119">Malters</option>
                                                            <option value="18120">Malvaglia</option>
                                                            <option value="18121">Marin-Epagnier</option>
                                                            <option value="18122">Marly</option>
                                                            <option value="18123">Marsens</option>
                                                            <option value="18124">Marthalen</option>
                                                            <option value="18125">Martigny District</option>
                                                            <option value="18126">Martigny-Combe</option>
                                                            <option value="18127">Martigny-Ville</option>
                                                            <option value="18128">Massagno</option>
                                                            <option value="18129">Matten</option>
                                                            <option value="18130">Mattenbach (Kreis 7)</option>
                                                            <option value="18131">Mattenbach (Kreis 7) / Deutweg</option>
                                                            <option value="18132">Mattenbach (Kreis 7) / Endliker</option>
                                                            <option value="18133">Mattenbach (Kreis 7) / Gutschick</option>
                                                            <option value="18134">Matzendorf</option>
                                                            <option value="18135">Matzingen</option>
                                                            <option value="18136">Mauensee</option>
                                                            <option value="18137">Mauraz</option>
                                                            <option value="18138">Meggen</option>
                                                            <option value="18139">Meierskappel</option>
                                                            <option value="18140">Meilen</option>
                                                            <option value="18141">Meinier</option>
                                                            <option value="18142">Meinisberg</option>
                                                            <option value="18143">Meiringen</option>
                                                            <option value="18144">Meisterschwanden</option>
                                                            <option value="18145">Melano</option>
                                                            <option value="18146">Melchnau</option>
                                                            <option value="18147">Melide</option>
                                                            <option value="18148">Mellingen</option>
                                                            <option value="18149">Mendrisio</option>
                                                            <option value="18150">Mendrisio District</option>
                                                            <option value="18151">Menziken</option>
                                                            <option value="18152">Menzingen</option>
                                                            <option value="18153">Menznau</option>
                                                            <option value="18154">Merenschwand</option>
                                                            <option value="18155">Mesocco</option>
                                                            <option value="18156">Messen</option>
                                                            <option value="18157">Mettmenstetten</option>
                                                            <option value="18158">Meyrin</option>
                                                            <option value="18159">Minusio</option>
                                                            <option value="18160">Mogelsberg</option>
                                                            <option value="18161">Mollens</option>
                                                            <option value="18162">Mollis</option>
                                                            <option value="18163">Montagnola</option>
                                                            <option value="18164">Montagny</option>
                                                            <option value="18165">Montana</option>
                                                            <option value="18166">Monte Carasso</option>
                                                            <option value="18167">Monthey</option>
                                                            <option value="18168">Monthey District</option>
                                                            <option value="18169">Montreux</option>
                                                            <option value="18170">Morbio Inferiore</option>
                                                            <option value="18171">Morges</option>
                                                            <option value="18172">Morges District</option>
                                                            <option value="18173">Mosnang</option>
                                                            <option value="18174">Moudon</option>
                                                            <option value="18175">Moutier</option>
                                                            <option value="18176">Muhen</option>
                                                            <option value="18177">Mumpf</option>
                                                            <option value="18178">Muolen</option>
                                                            <option value="18179">Muotathal</option>
                                                            <option value="18180">Muralto</option>
                                                            <option value="18181">Murgenthal</option>
                                                            <option value="18182">Muri</option>
                                                            <option value="18184">Murten/Morat</option>
                                                            <option value="18185">Muttenz</option>
                                                            <option value="18186">Männedorf</option>
                                                            <option value="18187">Männedorf / Ausserfeld</option>
                                                            <option value="18188">Männedorf / Dorfkern</option>
                                                            <option value="18189">Märstetten-Dorf</option>
                                                            <option value="18190">Mézières</option>
                                                            <option value="18191">Möhlin</option>
                                                            <option value="18192">Mönchaltorf</option>
                                                            <option value="18193">Mönchaltorf / Dorf</option>
                                                            <option value="18194">Mörschwil</option>
                                                            <option value="18195">Mühleberg</option>
                                                            <option value="18196">Mühlehalde</option>
                                                            <option value="18197">Mühlethurnen</option>
                                                            <option value="18198">Müllheim</option>
                                                            <option value="18199">Münchenbuchsee</option>
                                                            <option value="18200">Münchenstein</option>
                                                            <option value="18201">Münchenwiler</option>
                                                            <option value="18202">Münchwilen</option>
                                                            <option value="18203">Münchwilen District</option>
                                                            <option value="18204">Münsingen</option>
                                                            <option value="18205">Münsterlingen</option>
                                                            <option value="18206">Müntschemier</option>
                                                            <option value="18207">Naters</option>
                                                            <option value="18208">Nebikon</option>
                                                            <option value="18209">Neerach</option>
                                                            <option value="18210">Neftenbach / Dorf Neftenbach</option>
                                                            <option value="18211">Netstal</option>
                                                            <option value="18212">Neuchâtel</option>
                                                            <option value="18213">Neuchâtel District</option>
                                                            <option value="18214">Neudorf</option>
                                                            <option value="18215">Neuenhof</option>
                                                            <option value="18216">Neuenkirch</option>
                                                            <option value="18217">Neuhausen</option>
                                                            <option value="18218">Neunkirch</option>
                                                            <option value="18219">Nidau</option>
                                                            <option value="18220">Niederbipp</option>
                                                            <option value="18221">Niederbüren</option>
                                                            <option value="18222">Niederglatt</option>
                                                            <option value="18223">Niederglatt / Niederglatt (Dorfkern)</option>
                                                            <option value="18224">Niedergösgen</option>
                                                            <option value="18225">Niederhasli</option>
                                                            <option value="18226">Niederhelfenschwil</option>
                                                            <option value="18227">Niederlenz</option>
                                                            <option value="18228">Niederrohrdorf</option>
                                                            <option value="18229">Niederurnen</option>
                                                            <option value="18230">Nottwil</option>
                                                            <option value="18231">Novazzano</option>
                                                            <option value="18232">Nunningen</option>
                                                            <option value="18233">Nyon</option>
                                                            <option value="18234">Nyon District</option>
                                                            <option value="18235">Näfels</option>
                                                            <option value="18236">Nänikon</option>
                                                            <option value="18237">Nänikon / Nänikon (Dorfkern)</option>
                                                            <option value="18238">Nürensdorf</option>
                                                            <option value="18239">Oberaargau</option>
                                                            <option value="18240">Oberbipp</option>
                                                            <option value="18241">Oberbuchsiten</option>
                                                            <option value="18242">Oberburg</option>
                                                            <option value="18243">Oberdiessbach</option>
                                                            <option value="18244">Oberdorf</option>
                                                            <option value="18245">Oberegg</option>
                                                            <option value="18246">Oberengstringen</option>
                                                            <option value="18247">Oberengstringen / Rauchacher</option>
                                                            <option value="18248">Oberengstringen / Sonnenberg</option>
                                                            <option value="18249">Oberengstringen / Zentrum</option>
                                                            <option value="18250">Oberentfelden</option>
                                                            <option value="18251">Oberglatt</option>
                                                            <option value="18252">Oberglatt / Bahnhofquartier</option>
                                                            <option value="18253">Oberglatt / Oberglatt (Dorfkern)</option>
                                                            <option value="18254">Obergösgen</option>
                                                            <option value="18255">Oberhelfenschwil</option>
                                                            <option value="18256">Oberkirch</option>
                                                            <option value="18257">Oberlunkhofen</option>
                                                            <option value="18258">Obermeilen</option>
                                                            <option value="18259">Oberrieden</option>
                                                            <option value="18260">Oberrieden / Berg</option>
                                                            <option value="18261">Oberrieden / Mitte</option>
                                                            <option value="18262">Oberriet</option>
                                                            <option value="18263">Oberrohrdorf</option>
                                                            <option value="18264">Oberrüti</option>
                                                            <option value="18265">Oberschrot</option>
                                                            <option value="18266">Obersiggenthal</option>
                                                            <option value="18267">Obersimmental-Saanen District</option>
                                                            <option value="18268">Oberurnen</option>
                                                            <option value="18269">Oberuzwil</option>
                                                            <option value="18270">Oberwil</option>
                                                            <option value="18271">Oberwinterthur (Kreis 2)</option>
                                                            <option value="18272">Oberwinterthur (Kreis 2) / Guggenbühl</option>
                                                            <option value="18273">Oberwinterthur (Kreis 2) / Hegi</option>
                                                            <option value="18274">Oberwinterthur (Kreis 2) / Talacker</option>
                                                            <option value="18275">Oberwinterthur (Kreis 2) / Zinzikon</option>
                                                            <option value="18276">Obfelden</option>
                                                            <option value="18277">Obfelden / Oberlunnern</option>
                                                            <option value="18278">Obfelden / Toussen</option>
                                                            <option value="18279">Oensingen</option>
                                                            <option value="18280">Oetwil</option>
                                                            <option value="18281">Oftringen</option>
                                                            <option value="18282">Ollon</option>
                                                            <option value="18283">Olten</option>
                                                            <option value="18284">Onex</option>
                                                            <option value="18285">Opfikon</option>
                                                            <option value="18287">Ormalingen</option>
                                                            <option value="18288">Oron-la-Ville</option>
                                                            <option value="18289">Orpund</option>
                                                            <option value="18290">Orsières</option>
                                                            <option value="18291">Orvin</option>
                                                            <option value="18292">Otelfingen</option>
                                                            <option value="18293">Othmarsingen</option>
                                                            <option value="18294">Ottenbach</option>
                                                            <option value="18295">Ouest Lausannois District</option>
                                                            <option value="18296">Pailly</option>
                                                            <option value="18297">Palézieux</option>
                                                            <option value="18298">Pampigny</option>
                                                            <option value="18299">Paudex</option>
                                                            <option value="18300">Payerne</option>
                                                            <option value="18301">Penthalaz</option>
                                                            <option value="18302">Penthéréaz</option>
                                                            <option value="18303">Perroy</option>
                                                            <option value="18304">Peseux</option>
                                                            <option value="18305">Pfaffhausen</option>
                                                            <option value="18306">Pfaffnau</option>
                                                            <option value="18307">Pfeffingen</option>
                                                            <option value="18308">Pfungen</option>
                                                            <option value="18309">Pfyn</option>
                                                            <option value="18310">Pfäffikon</option>
                                                            <option value="18311">Pfäffikon</option>
                                                            <option value="18312">Pfäffikon / Irgenhausen</option>
                                                            <option value="18313">Pfäffikon / Pfäffikon (Dorfkern)</option>
                                                            <option value="18314">Pieterlen</option>
                                                            <option value="18315">Plaffeien</option>
                                                            <option value="18316">Plan-les-Ouates</option>
                                                            <option value="18317">Pompaples</option>
                                                            <option value="18318">Pontresina</option>
                                                            <option value="18319">Porrentruy</option>
                                                            <option value="18320">Porrentruy District</option>
                                                            <option value="18321">Poschiavo</option>
                                                            <option value="18322">Prangins</option>
                                                            <option value="18323">Praroman</option>
                                                            <option value="18324">Pratteln</option>
                                                            <option value="18325">Pregassona</option>
                                                            <option value="18326">Prilly</option>
                                                            <option value="18327">Préverenges</option>
                                                            <option value="18328">Puidoux</option>
                                                            <option value="18329">Pully</option>
                                                            <option value="18330">Puplinge</option>
                                                            <option value="18331">Pura</option>
                                                            <option value="18332">Péry</option>
                                                            <option value="18333">Quarten</option>
                                                            <option value="18334">Radelfingen</option>
                                                            <option value="18335">Rafz</option>
                                                            <option value="18336">Ramsen</option>
                                                            <option value="18337">Randogne</option>
                                                            <option value="18338">Rapperswil</option>
                                                            <option value="18339">Raron</option>
                                                            <option value="18340">Raron District</option>
                                                            <option value="18341">Rebstein</option>
                                                            <option value="18342">Rechthalten</option>
                                                            <option value="18343">Reconvilier</option>
                                                            <option value="18344">Regensdorf</option>
                                                            <option value="18345">Regensdorf / Feldblumen-Riedthofstrasse</option>
                                                            <option value="18346">Regensdorf / Hofacher-Geeren</option>
                                                            <option value="18347">Regensdorf / Obstgarten</option>
                                                            <option value="18348">Region Albula</option>
                                                            <option value="18349">Region Bernina</option>
                                                            <option value="18350">Region Engiadina Bassa / Val Müstair</option>
                                                            <option value="18351">Region Imboden</option>
                                                            <option value="18352">Region Landquart</option>
                                                            <option value="18353">Region Maloja</option>
                                                            <option value="18354">Region Moesa</option>
                                                            <option value="18355">Region Plessur</option>
                                                            <option value="18356">Region Prättigau / Davos</option>
                                                            <option value="18357">Region Surselva</option>
                                                            <option value="18358">Region Viamala</option>
                                                            <option value="18359">Rehetobel</option>
                                                            <option value="18360">Reichenburg</option>
                                                            <option value="18361">Reiden</option>
                                                            <option value="18362">Reigoldswil</option>
                                                            <option value="18363">Reinach</option>
                                                            <option value="18364">Renens</option>
                                                            <option value="18365">Rheineck</option>
                                                            <option value="18366">Rheinfelden</option>
                                                            <option value="18367">Rhäzüns</option>
                                                            <option value="18368">Riaz</option>
                                                            <option value="18369">Richterswil</option>
                                                            <option value="18370">Richterswil / Burghalde</option>
                                                            <option value="18371">Richterswil / Dorfkern</option>
                                                            <option value="18372">Richterswil / Richterswil (Dorfkern)</option>
                                                            <option value="18373">Rickenbach bei Wil</option>
                                                            <option value="18374">Riddes</option>
                                                            <option value="18375">Riedholz</option>
                                                            <option value="18376">Riggisberg</option>
                                                            <option value="18377">Ringgenberg</option>
                                                            <option value="18378">Riva San Vitale</option>
                                                            <option value="18379">Riviera District</option>
                                                            <option value="18380">Riviera-Pays-d'Enhaut District</option>
                                                            <option value="18381">Rodersdorf</option>
                                                            <option value="18382">Roggwil</option>
                                                            <option value="18383">Roggwil</option>
                                                            <option value="18384">Rohr</option>
                                                            <option value="18385">Rohrbach</option>
                                                            <option value="18386">Rolle</option>
                                                            <option value="18387">Romanel-sur-Lausanne</option>
                                                            <option value="18388">Romanshorn</option>
                                                            <option value="18389">Romont</option>
                                                            <option value="18390">Root</option>
                                                            <option value="18391">Rorbas</option>
                                                            <option value="18392">Rorschach</option>
                                                            <option value="18393">Rothenthurm</option>
                                                            <option value="18394">Rothrist</option>
                                                            <option value="18395">Rotkreuz</option>
                                                            <option value="18396">Rubigen</option>
                                                            <option value="18397">Rudolfstetten</option>
                                                            <option value="18398">Rue</option>
                                                            <option value="18399">Rupperswil</option>
                                                            <option value="18400">Russikon</option>
                                                            <option value="18401">Ruswil</option>
                                                            <option value="18402">Römerswil</option>
                                                            <option value="18403">Röschenz</option>
                                                            <option value="18404">Rüderswil</option>
                                                            <option value="18405">Rüeggisberg</option>
                                                            <option value="18406">Rümlang</option>
                                                            <option value="18407">Rümlang / Rümlang (Dorfkern)</option>
                                                            <option value="18408">Rüschegg</option>
                                                            <option value="18409">Rüschlikon</option>
                                                            <option value="18410">Rüthi</option>
                                                            <option value="18411">Rüti</option>
                                                            <option value="18412">Rüti / Dorfzentrum, Südl. Teil</option>
                                                            <option value="18413">Rüti / Oberdorf</option>
                                                            <option value="18414">Rüti / Westlicher Dorfteil</option>
                                                            <option value="18415">Saanen</option>
                                                            <option value="18416">Saas-Fee</option>
                                                            <option value="18417">Saas-Grund</option>
                                                            <option value="18418">Sachseln</option>
                                                            <option value="18419">Safenwil</option>
                                                            <option value="18420">Saignelégier</option>
                                                            <option value="18421">Saillon</option>
                                                            <option value="18422">Saint-Aubin-Sauges</option>
                                                            <option value="18423">Saint-Blaise</option>
                                                            <option value="18424">Saint-Cergue</option>
                                                            <option value="18425">Saint-Imier</option>
                                                            <option value="18426">Saint-Livres</option>
                                                            <option value="18427">Saint-Léonard</option>
                                                            <option value="18428">Saint-Maurice</option>
                                                            <option value="18429">Saint-Maurice District</option>
                                                            <option value="18430">Saint-Prex</option>
                                                            <option value="18431">Sainte-Croix</option>
                                                            <option value="18432">Salgesch</option>
                                                            <option value="18433">Salmsach</option>
                                                            <option value="18434">Salvan</option>
                                                            <option value="18435">Samedan</option>
                                                            <option value="18436">Samstagern</option>
                                                            <option value="18437">Sankt Antoni</option>
                                                            <option value="18438">Sankt Gallen</option>
                                                            <option value="18439">Sankt Gallenkappel</option>
                                                            <option value="18440">Sankt Margrethen</option>
                                                            <option value="18441">Sankt Niklaus</option>
                                                            <option value="18442">Sankt Peterzell</option>
                                                            <option value="18443">Sankt Stephan</option>
                                                            <option value="18444">Sargans</option>
                                                            <option value="18445">Sarine District</option>
                                                            <option value="18446">Sarmenstorf</option>
                                                            <option value="18447">Sarnen</option>
                                                            <option value="18448">Satigny</option>
                                                            <option value="18449">Sattel</option>
                                                            <option value="18450">Saubraz</option>
                                                            <option value="18451">Savigny</option>
                                                            <option value="18452">Savièse</option>
                                                            <option value="18453">Saxon</option>
                                                            <option value="18454">Schachen</option>
                                                            <option value="18455">Schaffhausen</option>
                                                            <option value="18456">Schafisheim</option>
                                                            <option value="18457">Scharans</option>
                                                            <option value="18458">Schattdorf</option>
                                                            <option value="18459">Schenkon</option>
                                                            <option value="18460">Schiers</option>
                                                            <option value="18461">Schindellegi</option>
                                                            <option value="18462">Schinznach Bad</option>
                                                            <option value="18463">Schinznach Dorf</option>
                                                            <option value="18464">Schleitheim</option>
                                                            <option value="18465">Schlieren</option>
                                                            <option value="18466">Schlieren / Boden</option>
                                                            <option value="18467">Schlieren / Engstingerquartier</option>
                                                            <option value="18468">Schlieren / Freiestrasse</option>
                                                            <option value="18469">Schlieren / Kamp</option>
                                                            <option value="18470">Schlieren / Spital</option>
                                                            <option value="18471">Schlieren / Zentrum</option>
                                                            <option value="18472">Schmerikon</option>
                                                            <option value="18473">Schmitten</option>
                                                            <option value="18474">Schwaderloch</option>
                                                            <option value="18475">Schwanden</option>
                                                            <option value="18476">Schwarzenberg</option>
                                                            <option value="18477">Schwellbrunn</option>
                                                            <option value="18478">Schwerzenbach</option>
                                                            <option value="18479">Schwerzenbach / Blatten</option>
                                                            <option value="18480">Schwerzenbach / Chimli</option>
                                                            <option value="18481">Schwyz</option>
                                                            <option value="18482">Schänis</option>
                                                            <option value="18483">Schöftland</option>
                                                            <option value="18484">Schönenbuch</option>
                                                            <option value="18485">Schönenwerd</option>
                                                            <option value="18486">Schötz</option>
                                                            <option value="18487">Schübelbach</option>
                                                            <option value="18488">Schüpfen</option>
                                                            <option value="18489">Schüpfheim</option>
                                                            <option value="18490">Scuol</option>
                                                            <option value="18491">Seeberg</option>
                                                            <option value="18492">Seedorf</option>
                                                            <option value="18493">Seedorf</option>
                                                            <option value="18494">Seeland District</option>
                                                            <option value="18495">Seen (Kreis 3)</option>
                                                            <option value="18496">Seen (Kreis 3) / Büelwiesen</option>
                                                            <option value="18497">Seen (Kreis 3) / Ganzenbühl</option>
                                                            <option value="18498">Seen (Kreis 3) / Oberseen</option>
                                                            <option value="18499">Seen (Kreis 3) / Waldegg</option>
                                                            <option value="18500">Seen (Kreis 3) / Waser</option>
                                                            <option value="18501">Seengen</option>
                                                            <option value="18502">Seftigen</option>
                                                            <option value="18503">Seglingen</option>
                                                            <option value="18504">Sellenbüren</option>
                                                            <option value="18505">Seltisberg</option>
                                                            <option value="18506">Selzach</option>
                                                            <option value="18507">Sementina</option>
                                                            <option value="18508">Sempach</option>
                                                            <option value="18509">Sennwald</option>
                                                            <option value="18510">Sense District</option>
                                                            <option value="18511">Seon</option>
                                                            <option value="18512">Seuzach / Seuzach (Dorf)</option>
                                                            <option value="18513">Seuzach Dorf</option>
                                                            <option value="18514">Seuzach Dorf / Breite-Weid</option>
                                                            <option value="18515">Sevelen</option>
                                                            <option value="18516">Siebnen</option>
                                                            <option value="18517">Sierre</option>
                                                            <option value="18518">Sierre District</option>
                                                            <option value="18519">Signau</option>
                                                            <option value="18520">Sigriswil</option>
                                                            <option value="18521">Silenen</option>
                                                            <option value="18522">Silvaplana</option>
                                                            <option value="18523">Sins</option>
                                                            <option value="18524">Sion District</option>
                                                            <option value="18525">Sirnach</option>
                                                            <option value="18526">Sissach</option>
                                                            <option value="18527">Sitten</option>
                                                            <option value="18528">Solothurn</option>
                                                            <option value="18529">Sonnhalde</option>
                                                            <option value="18530">Sorengo</option>
                                                            <option value="18531">Sottens</option>
                                                            <option value="18532">Speicher</option>
                                                            <option value="18533">Spiez</option>
                                                            <option value="18534">Spreitenbach</option>
                                                            <option value="18535">St. Moritz</option>
                                                            <option value="18536">Stabio</option>
                                                            <option value="18537">Stadt Winterthur (Kreis 1)</option>
                                                            <option value="18538">Stadt Winterthur (Kreis 1) / Altstadt</option>
                                                            <option value="18539">Stadt Winterthur (Kreis 1) / Brühlberg</option>
                                                            <option value="18540">Stadt Winterthur (Kreis 1) / Heiligberg</option>
                                                            <option value="18541">Stadt Winterthur (Kreis 1) / Lind</option>
                                                            <option value="18542">Stadt Winterthur (Kreis 1) / Neuwiesen</option>
                                                            <option value="18543">Stadt Winterthur (Kreis 1) / Tössfeld</option>
                                                            <option value="18544">Staffelbach</option>
                                                            <option value="18545">Stalden</option>
                                                            <option value="18546">Stans</option>
                                                            <option value="18547">Stansstad</option>
                                                            <option value="18548">Staufen</option>
                                                            <option value="18549">Steckborn</option>
                                                            <option value="18550">Steffisburg</option>
                                                            <option value="18551">Stein</option>
                                                            <option value="18552">Stein am Rhein</option>
                                                            <option value="18553">Steinen</option>
                                                            <option value="18554">Steinhausen</option>
                                                            <option value="18555">Steinmaur</option>
                                                            <option value="18556">Stetten</option>
                                                            <option value="18557">Stettfurt</option>
                                                            <option value="18558">Stettlen</option>
                                                            <option value="18559">Strengelbach</option>
                                                            <option value="18560">Stäfa</option>
                                                            <option value="18561">Subingen</option>
                                                            <option value="18562">Suhr</option>
                                                            <option value="18563">Sulgen</option>
                                                            <option value="18564">Sulz</option>
                                                            <option value="18565">Sulz</option>
                                                            <option value="18566">Sumiswald</option>
                                                            <option value="18567">Sursee</option>
                                                            <option value="18568">Sursee District</option>
                                                            <option value="18569">Sâles</option>
                                                            <option value="18570">Tafers</option>
                                                            <option value="18571">Tagelswangen</option>
                                                            <option value="18572">Tamins</option>
                                                            <option value="18573">Tanay</option>
                                                            <option value="18574">Tann</option>
                                                            <option value="18575">Tann / Tann (Dorfkern)</option>
                                                            <option value="18576">Tavannes</option>
                                                            <option value="18577">Tegerfelden</option>
                                                            <option value="18578">Tesserete</option>
                                                            <option value="18579">Teufen</option>
                                                            <option value="18580">Teufenthal</option>
                                                            <option value="18581">Thal</option>
                                                            <option value="18582">Thalwil</option>
                                                            <option value="18583">Thalwil / Berg</option>
                                                            <option value="18584">Thalwil / Dorfkern</option>
                                                            <option value="18585">Thalwil / Nord</option>
                                                            <option value="18586">Thalwil / See</option>
                                                            <option value="18587">Thalwil / Süd</option>
                                                            <option value="18588">Thayngen</option>
                                                            <option value="18589">Therwil</option>
                                                            <option value="18590">Thierachern</option>
                                                            <option value="18591">Thun</option>
                                                            <option value="18592">Thun District</option>
                                                            <option value="18593">Thundorf</option>
                                                            <option value="18594">Thusis</option>
                                                            <option value="18595">Thônex</option>
                                                            <option value="18596">Tiefencastel</option>
                                                            <option value="18597">Toffen</option>
                                                            <option value="18598">Trachselwald</option>
                                                            <option value="18599">Tramelan</option>
                                                            <option value="18600">Travers</option>
                                                            <option value="18601">Treyvaux</option>
                                                            <option value="18602">Triengen</option>
                                                            <option value="18603">Trimbach</option>
                                                            <option value="18604">Trimmis</option>
                                                            <option value="18605">Trogen</option>
                                                            <option value="18606">Troinex</option>
                                                            <option value="18607">Troistorrents</option>
                                                            <option value="18608">Trub</option>
                                                            <option value="18609">Trubschachen</option>
                                                            <option value="18610">Trun</option>
                                                            <option value="18611">Trélex</option>
                                                            <option value="18612">Tuggen</option>
                                                            <option value="18613">Turbenthal</option>
                                                            <option value="18614">Turgi</option>
                                                            <option value="18615">Turtmann</option>
                                                            <option value="18616">Tägerwilen</option>
                                                            <option value="18617">Täuffelen</option>
                                                            <option value="18618">Töss (Kreis 4)</option>
                                                            <option value="18619">Töss (Kreis 4) / Eichliacker</option>
                                                            <option value="18620">Töss (Kreis 4) / Schlosstal</option>
                                                            <option value="18621">Töss (Kreis 4) / Vorder-Dättnau</option>
                                                            <option value="18622">Udligenswil</option>
                                                            <option value="18623">Ueberstorf</option>
                                                            <option value="18624">Uerikon</option>
                                                            <option value="18625">Uerkheim</option>
                                                            <option value="18626">Uetendorf</option>
                                                            <option value="18627">Uetikon</option>
                                                            <option value="18628">Uetikon / Grossdorf</option>
                                                            <option value="18629">Uhwiesen</option>
                                                            <option value="18630">Uitikon</option>
                                                            <option value="18631">Unterengstringen</option>
                                                            <option value="18632">Unteriberg</option>
                                                            <option value="18633">Unterkulm</option>
                                                            <option value="18634">Unterlunkhofen</option>
                                                            <option value="18635">Unterseen</option>
                                                            <option value="18636">Untersiggenthal</option>
                                                            <option value="18637">Untervaz</option>
                                                            <option value="18638">Unterägeri</option>
                                                            <option value="18639">Urdorf</option>
                                                            <option value="18640">Urdorf / Bodenfeld</option>
                                                            <option value="18641">Urdorf / Moos</option>
                                                            <option value="18642">Urdorf / Oberurdorf</option>
                                                            <option value="18643">Urnäsch</option>
                                                            <option value="18644">Urtenen</option>
                                                            <option value="18645">Uster</option>
                                                            <option value="18646">Uster / Gschwader</option>
                                                            <option value="18647">Uster / Kirch-Uster</option>
                                                            <option value="18648">Uster / Nieder-Uster</option>
                                                            <option value="18649">Uster / Nossikon</option>
                                                            <option value="18650">Uster / Ober-Uster</option>
                                                            <option value="18651">Uttigen</option>
                                                            <option value="18652">Uttwil</option>
                                                            <option value="18653">Utzenstorf</option>
                                                            <option value="18654">Uznach</option>
                                                            <option value="18655">Uzwil</option>
                                                            <option value="18656">Val-de-Ruz District</option>
                                                            <option value="18657">Val-de-Travers District</option>
                                                            <option value="18658">Vallemaggia District</option>
                                                            <option value="18659">Vallorbe</option>
                                                            <option value="18660">Vals Platz</option>
                                                            <option value="18661">Vandœuvres</option>
                                                            <option value="18662">Varen</option>
                                                            <option value="18663">Vechigen</option>
                                                            <option value="18664">Veltheim</option>
                                                            <option value="18665">Veltheim (Kreis 5)</option>
                                                            <option value="18666">Veltheim (Kreis 5) / Blumenau</option>
                                                            <option value="18667">Veltheim (Kreis 5) / Rosenberg</option>
                                                            <option value="18668">Verbier</option>
                                                            <option value="18669">Vernayaz</option>
                                                            <option value="18670">Vernier</option>
                                                            <option value="18671">Verscio</option>
                                                            <option value="18672">Versoix</option>
                                                            <option value="18673">Vevey</option>
                                                            <option value="18674">Veveyse District</option>
                                                            <option value="18675">Vex</option>
                                                            <option value="18676">Veyrier</option>
                                                            <option value="18677">Vicques</option>
                                                            <option value="18678">Viganello</option>
                                                            <option value="18679">Villars-sur-Glâne</option>
                                                            <option value="18680">Villars-sur-Ollon</option>
                                                            <option value="18681">Villaz-Saint-Pierre</option>
                                                            <option value="18682">Villeneuve</option>
                                                            <option value="18683">Villigen</option>
                                                            <option value="18684">Villmergen</option>
                                                            <option value="18685">Villnachern</option>
                                                            <option value="18686">Vionnaz</option>
                                                            <option value="18687">Visp</option>
                                                            <option value="18688">Visp District</option>
                                                            <option value="18689">Visperterminen</option>
                                                            <option value="18690">Vitznau</option>
                                                            <option value="18691">Volketswil / Volketswil (Dorf)</option>
                                                            <option value="18692">Vordemwald</option>
                                                            <option value="18693">Vorderthal</option>
                                                            <option value="18694">Vouvry</option>
                                                            <option value="18695">Vuadens</option>
                                                            <option value="18696">Vuarrens</option>
                                                            <option value="18697">Vétroz</option>
                                                            <option value="18698">Wagenhausen</option>
                                                            <option value="18699">Wahlen</option>
                                                            <option value="18700">Wahlkreis Rheintal</option>
                                                            <option value="18701">Wahlkreis Rorschach</option>
                                                            <option value="18702">Wahlkreis Sarganserland</option>
                                                            <option value="18703">Wahlkreis See-Gaster</option>
                                                            <option value="18704">Wahlkreis St. Gallen</option>
                                                            <option value="18705">Wahlkreis Toggenburg</option>
                                                            <option value="18706">Wahlkreis Werdenberg</option>
                                                            <option value="18707">Wahlkreis Wil</option>
                                                            <option value="18708">Walchwil</option>
                                                            <option value="18709">Wald</option>
                                                            <option value="18710">Waldenburg</option>
                                                            <option value="18711">Waldkirch</option>
                                                            <option value="18712">Waldstatt</option>
                                                            <option value="18713">Walenstadt</option>
                                                            <option value="18714">Walkringen</option>
                                                            <option value="18715">Wallisellen</option>
                                                            <option value="18716">Wallisellen / Rieden</option>
                                                            <option value="18717">Wallisellen / Wallisellen-Ost</option>
                                                            <option value="18718">Wallisellen / Wallisellen-West</option>
                                                            <option value="18719">Waltenschwil</option>
                                                            <option value="18720">Walzenhausen</option>
                                                            <option value="18721">Wangen</option>
                                                            <option value="18722">Wangen</option>
                                                            <option value="18723">Wangen an der Aare</option>
                                                            <option value="18724">Wattenwil</option>
                                                            <option value="18725">Wattwil</option>
                                                            <option value="18726">Wauwil</option>
                                                            <option value="18727">Weesen</option>
                                                            <option value="18728">Wegenstetten</option>
                                                            <option value="18729">Weggis</option>
                                                            <option value="18730">Weinfelden</option>
                                                            <option value="18731">Weinfelden District</option>
                                                            <option value="18732">Weiningen</option>
                                                            <option value="18733">Weisslingen</option>
                                                            <option value="18734">Welschenrohr</option>
                                                            <option value="18735">Werthenstein</option>
                                                            <option value="18736">Wettingen</option>
                                                            <option value="18737">Wettswil / Ausser-Dorf</option>
                                                            <option value="18738">Wettswil / Wettswil (Dorf)</option>
                                                            <option value="18739">Wetzikon</option>
                                                            <option value="18740">Wetzikon / Kempten</option>
                                                            <option value="18741">Wetzikon / Ober-Wetzikon</option>
                                                            <option value="18742">Wetzikon / Robenhausen</option>
                                                            <option value="18743">Wetzikon / Unter-Wetzikon</option>
                                                            <option value="18744">Wichtrach</option>
                                                            <option value="18745">Wiedlisbach</option>
                                                            <option value="18746">Wiesendangen / Wiesendangen (Dorf)</option>
                                                            <option value="18747">Wigoltingen</option>
                                                            <option value="18748">Wikon</option>
                                                            <option value="18749">Wil</option>
                                                            <option value="18750">Wila</option>
                                                            <option value="18751">Wilchingen</option>
                                                            <option value="18752">Wilderswil</option>
                                                            <option value="18753">Wildhaus</option>
                                                            <option value="18754">Willisau</option>
                                                            <option value="18755">Willisau District</option>
                                                            <option value="18756">Wimmis</option>
                                                            <option value="18757">Windisch</option>
                                                            <option value="18758">Winterthur</option>
                                                            <option value="18759">Wittenbach</option>
                                                            <option value="18760">Wittnau</option>
                                                            <option value="18761">Wohlen</option>
                                                            <option value="18762">Wohlen</option>
                                                            <option value="18763">Wolfenschiessen</option>
                                                            <option value="18764">Wolfhausen</option>
                                                            <option value="18765">Wolfwil</option>
                                                            <option value="18766">Wolhusen</option>
                                                            <option value="18767">Wollerau</option>
                                                            <option value="18768">Worb</option>
                                                            <option value="18769">Worben</option>
                                                            <option value="18770">Wynau</option>
                                                            <option value="18771">Wynigen</option>
                                                            <option value="18772">Wyssachen</option>
                                                            <option value="18773">Wädenswil</option>
                                                            <option value="18774">Wädenswil / Boller-Giessen</option>
                                                            <option value="18775">Wädenswil / Büelen</option>
                                                            <option value="18776">Wädenswil / Dorf (Wädenswil)</option>
                                                            <option value="18777">Wädenswil / Eichweid</option>
                                                            <option value="18778">Wädenswil / Hangenmoos</option>
                                                            <option value="18779">Wädenswil / Leihof-Mühlebach</option>
                                                            <option value="18780">Wädenswil / Untermosen-Fuhr</option>
                                                            <option value="18781">Wängi</option>
                                                            <option value="18782">Wölflinswil</option>
                                                            <option value="18783">Wülflingen (Kreis 6)</option>
                                                            <option value="18784">Wülflingen (Kreis 6) / Härti</option>
                                                            <option value="18785">Wülflingen (Kreis 6) / Lindenplatz</option>
                                                            <option value="18786">Wülflingen (Kreis 6) / Niederfeld</option>
                                                            <option value="18787">Wülflingen (Kreis 6) / Oberfeld</option>
                                                            <option value="18788">Würenlingen</option>
                                                            <option value="18789">Würenlos</option>
                                                            <option value="18790">Yverdon-les-Bains</option>
                                                            <option value="18791">Yvonand</option>
                                                            <option value="18792">Zell</option>
                                                            <option value="18793">Zermatt</option>
                                                            <option value="18794">Zernez</option>
                                                            <option value="18795">Zizers</option>
                                                            <option value="18796">Zofingen</option>
                                                            <option value="18797">Zollikerberg</option>
                                                            <option value="18798">Zollikofen</option>
                                                            <option value="18799">Zollikon</option>
                                                            <option value="18800">Zuchwil</option>
                                                            <option value="18801">Zug</option>
                                                            <option value="18802">Zumikon</option>
                                                            <option value="18803">Zunzgen</option>
                                                            <option value="18804">Zuoz</option>
                                                            <option value="18805">Zuzwil</option>
                                                            <option value="18806">Zweisimmen</option>
                                                            <option value="18807">Zwingen</option>
                                                            <option value="18808">Zäziwil</option>
                                                            <option value="18809">Zürich</option>
                                                            <option value="18810">Zürich (Kreis 1)</option>
                                                            <option value="18811">Zürich (Kreis 1) / City</option>
                                                            <option value="18812">Zürich (Kreis 1) / Lindenhof</option>
                                                            <option value="18813">Zürich (Kreis 1) / Rathaus</option>
                                                            <option value="18814">Zürich (Kreis 10)</option>
                                                            <option value="18815">Zürich (Kreis 10) / Höngg</option>
                                                            <option value="18816">Zürich (Kreis 10) / Rütihof</option>
                                                            <option value="18817">Zürich (Kreis 10) / Wipkingen</option>
                                                            <option value="18818">Zürich (Kreis 11)</option>
                                                            <option value="18819">Zürich (Kreis 11) / Affoltern</option>
                                                            <option value="18820">Zürich (Kreis 11) / Oerlikon</option>
                                                            <option value="18821">Zürich (Kreis 11) / Schwandenholz</option>
                                                            <option value="18822">Zürich (Kreis 11) / Seebach</option>
                                                            <option value="18823">Zürich (Kreis 12)</option>
                                                            <option value="18824">Zürich (Kreis 12) / Auzelg</option>
                                                            <option value="18825">Zürich (Kreis 12) / Hirzenbach</option>
                                                            <option value="18826">Zürich (Kreis 12) / Saatlen</option>
                                                            <option value="18827">Zürich (Kreis 12) / Schwamendingen-Mitte</option>
                                                            <option value="18828">Zürich (Kreis 2)</option>
                                                            <option value="18829">Zürich (Kreis 2) / Enge</option>
                                                            <option value="18830">Zürich (Kreis 2) / Mittel-Leimbach</option>
                                                            <option value="18831">Zürich (Kreis 2) / Unter-Leimbach</option>
                                                            <option value="18832">Zürich (Kreis 2) / Wollishofen</option>
                                                            <option value="18833">Zürich (Kreis 3)</option>
                                                            <option value="18834">Zürich (Kreis 3) / Alt-Wiedikon</option>
                                                            <option value="18835">Zürich (Kreis 3) / Friesenberg</option>
                                                            <option value="18836">Zürich (Kreis 3) / Sihlfeld</option>
                                                            <option value="18837">Zürich (Kreis 4) / Aussersihl</option>
                                                            <option value="18838">Zürich (Kreis 4) / Hard</option>
                                                            <option value="18839">Zürich (Kreis 4) / Langstrasse</option>
                                                            <option value="18840">Zürich (Kreis 4) / Werd</option>
                                                            <option value="18841">Zürich (Kreis 5)</option>
                                                            <option value="18842">Zürich (Kreis 5) / Escher-Wyss</option>
                                                            <option value="18843">Zürich (Kreis 5) / Gewerbeschule</option>
                                                            <option value="18844">Zürich (Kreis 6)</option>
                                                            <option value="18845">Zürich (Kreis 6) / Oberstrass</option>
                                                            <option value="18846">Zürich (Kreis 6) / Unterstrass</option>
                                                            <option value="18847">Zürich (Kreis 7)</option>
                                                            <option value="18848">Zürich (Kreis 7) / Fluntern</option>
                                                            <option value="18849">Zürich (Kreis 7) / Hirslanden</option>
                                                            <option value="18850">Zürich (Kreis 7) / Hottingen</option>
                                                            <option value="18851">Zürich (Kreis 7) / Witikon</option>
                                                            <option value="18852">Zürich (Kreis 8)</option>
                                                            <option value="18853">Zürich (Kreis 8) / Mühlebach</option>
                                                            <option value="18854">Zürich (Kreis 8) / Seefeld</option>
                                                            <option value="18855">Zürich (Kreis 8) / Weinegg</option>
                                                            <option value="18856">Zürich (Kreis 9)</option>
                                                            <option value="18857">Zürich (Kreis 9) / Albisrieden</option>
                                                            <option value="18858">Zürich (Kreis 9) / Altstetten</option>

                                                        </Form.Select>
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="row d-flex align-items-center justify-content-between  border-bottom mb-2 pb-2">
                                                <div className="col-12 col-sm-3">
                                                    <h5>Work Details</h5>
                                                </div>
                                                <div className="col-12 col-sm-9">

                                                    <InputGroup className="mb-1 ">
                                                        <Form.Control type="text" id="organizaiton" name="organization" value="Org"/>
                                                        {/* <input type="text" className="form-control" aria-label="Organization" placeholder="Organization" name="organization" id="organization" value="Org" /> */}
                                                        {/* <input type="hidden" className="form-control" name="privacy_organization" value="0" /> */}
                                                        <Button variant="outline-secondary" className="py-0"><VisibilityIcon/></Button>{' '}

                                                    </InputGroup>
                                                    <InputGroup className="mb-1 ">
                                                    <Form.Control type="text" id="designation" name="designation" value="Designation"/>
                                                        {/* <input type="text" className="form-control" placeholder="Occupation" name="designation" id="designation" value="Application Engineer" /> */}
                                                        {/* <input type="hidden" name="privacy_designation" className="privacy" value="0" /> */}
                                                        <Button variant="outline-secondary" className="py-0"><VisibilityIcon/></Button>{' '}
                                                    </InputGroup>
                                                    <InputGroup className="mb-1 ">
                                                    <Form.Select name="interests_area"  id="interests_area" aria-label="Default select example">

                                                        {/* <select className="form-select w-25 " name="intrests_area" id="area-of-interest"> */}
                                                            <option selected>Area of Interest...</option>
                                                            <option value="Artificial Intelligence (AI)" >
                                                                Artificial Intelligence (AI)</option>
                                                            <option value="Intelligent Automation">
                                                                Intelligent Automation</option>
                                                            <option value="Cyber Security">
                                                                Cyber Security</option>
                                                            <option value="Blockchain">
                                                                Blockchain</option>
                                                            <option value="Robotics">
                                                                Robotics</option>
                                                            <option value="The Internet-of-Things (IoT)">
                                                                The Internet-of-Things (IoT)</option>
                                                            <option value="5G">
                                                                5G</option>
                                                            <option value="Virtual reality (VR)/Augmented reality (AR)">
                                                                Virtual reality (VR)/Augmented reality (AR)</option>
                                                            <option value="Drones">
                                                                Drones</option>
                                                            <option value="All Emerging Technologies">
                                                                All Emerging Technologies</option>
                                                            <option value="Metaverse">
                                                                Metaverse</option>
                                                            <option value="Web3">
                                                                Web3</option>
                                                            <option value="NFT (Non-fungible token)">
                                                                NFT (Non-fungible token)</option>
                                                        </Form.Select>
                                                        {/* <input type="hidden" name="privacy_intrests_area" className="privacy" value="0" /> */}
                                                        <Button variant="outline-secondary" className="py-0"><VisibilityIcon/></Button>{' '}

                                                    </InputGroup>
                                                    <InputGroup className="mb-1">
                                                    <Form.Select name="describes"  id="describes" aria-label="Default select example">
                                                        {/* <select className="form-select w-25" name="describes" id="describes"> */}
                                                            <option selected>Industries &amp; Sectors...</option>
                                                            <option value="1" >
                                                                Government</option>
                                                            <option value="2">
                                                                Technology</option>
                                                            <option value="3">
                                                                Financial Services</option>
                                                            <option value="4">
                                                                Real Estate</option>
                                                            <option value="5">
                                                                Agriculture</option>
                                                            <option value="6">
                                                                Chemical industries</option>
                                                            <option value="7">
                                                                Commerce </option>
                                                            <option value="8">
                                                                Construction</option>
                                                            <option value="9">
                                                                Education</option>
                                                            <option value="10">
                                                                Professional services</option>
                                                            <option value="11">
                                                                R&amp;D</option>
                                                            <option value="12">
                                                                Media</option>
                                                            <option value="13">
                                                                Retailers</option>
                                                            <option value="14">
                                                                Energy</option>
                                                            <option value="15">
                                                                Telecommunications services</option>
                                                            <option value="16">
                                                                Public service</option>
                                                            <option value="17">
                                                                Manufacturing</option>
                                                            <option value="18">
                                                                Automotive</option>
                                                            <option value="19">
                                                                Retail, Wholesale and Distribution</option>
                                                            <option value="20">
                                                                Travel, Hospitality and Services</option>
                                                            <option value="21">
                                                                Security and Justice</option>
                                                            <option value="22">
                                                                Life Sciences &amp; Health Care</option>
                                                        </Form.Select>
                                                        {/* <input type="hidden" name="privacy_describes" className="privacy" value="0" /> */}
                                                        <Button variant="outline-secondary" className="py-0"><VisibilityIcon/></Button>{' '}

                                                    </InputGroup>
                                                    
                                                    <InputGroup className="mb-1">
                                                    <Select  isMulti autosize={true} className="basic-multi-select w-90" placeholder="Ecosystem" classNamePrefix="select" options={ECOSYSTEM_OPTIONS} />
                                                        {/* <select className="form-select select2-hidden-accessible" multiple="" aria-label="multiple select example" id="ecosystem_select2" data-placeholder="Ecosystem" name="ecosystem[]" tabindex="-1" aria-hidden="true" data-select2-id="select2-data-ecosystem_select2"> */}
                                                        {/* <input type="hidden" name="privacy_ecosystem_eye" className="privacy form-control" value="0" /> */}
                                                        
                                                        <Button variant="outline-secondary" className="py-0"><VisibilityIcon/></Button>{' '}
                                                        </InputGroup>
                                                    
                                                    <div className="input-group mb-1">
                                                        <textarea className="form-control" placeholder="About" id="description" name="description">Software Engineer</textarea>
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row d-flex align-items-center border-bottom mb-2 pb-2">
                                                <div className="col-12 col-sm-3">
                                                    <h5>Social Media</h5>
                                                </div>
                                                <div className="col-12 col-sm-9">
                                                    <div className="input-group mb-1">
                                                        <input type="text" className="form-control" name="twitter" id="twitter" placeholder="Twitter" value="https://twitter.com/?lang=en" />
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}
                                                    </div>
                                                    <div className="input-group mb-1">
                                                        <input type="text" className="form-control" name="facebook" id="facebook" placeholder="Facebook" value="https://www.facebook.com" />
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}
                                                    </div>
                                                    <div className="input-group mb-1">
                                                        <input type="text" className="form-control" name="linkedin" id="linkedin" placeholder="Linked in" value="https://www.linkedin.com" />
                                                        {/* <!-- <button className="btn btn-outline-light d-flex-mi" type="button"><i
                                                    className="material-symbols-outlined fill">visibility</i></button> --> */}
                                                    </div>
                                                    {/* <!-- <div className="mb-3 floating-label input-group">
                                        <input id="text" className="form-control" placeholder=" " />
                                        <label for="text">Twitter</label>
                                        <button className="btn btn-outline-light d-flex-mi" type="button"
                                                                                ><i
                                                                                    className="material-symbols-outlined fill">visibility</i></button>
                                    </div> --> */}
                                                </div>
                                                <div className="text-end py-2">
                                                    <Button variant="outline-primary" size="sm" ><SaveIcon fontSize="small"/>&nbsp;Save</Button>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </Form>
                                </ListGroup>
                                <div className="list-group p-0 my-2 shadow-sm">
                                    <div className="list-group-item py-1 border-0 mb-0 d-flex justify-content-between align-items-center text-bg-heading">
                                        Membership Plan
                                        {/* <!-- <button className="btn btn-light d-flex-mi" type="button"><i
                                        className="material-symbols-outlined">save</i> Save</button> --> */}
                                    </div>
                                    <div className="list-group-item">
                                        <div className="row d-flex align-items-center justify-content-between ">

                                            <div className="container text-center">
                                                <div className="row ">
                                                    <div className="col-12 col-sm-4 border text-start py-2 my-0">
                                                        <h5 className="mb-0">APPG Blockchain</h5>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Membership Name</div>
                                                        <div className="border p-2">Purchased Date</div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Freemium                                                                                                                                                                                                                                                       </div>
                                                        <div className="border p-2">21-08-23
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-4 border text-start py-2 my-0">
                                                        <h5 className="mb-0">THE INNOVATORS BOARD</h5>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Membership Name</div>
                                                        <div className="border p-2">Purchased Date</div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Freemium                                                                                                                                                                                                                                                       </div>
                                                        <div className="border p-2">09-06-22
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-4 border text-start py-2 my-0">
                                                        <h5 className="mb-0">Digital Finance Frontier Club</h5>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Membership Name</div>
                                                        <div className="border p-2">Purchased Date</div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Freemium                                                                                                                                                                                                                                                       </div>
                                                        <div className="border p-2">23-02-23
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>


                                <div className="list-group p-0 my-2 shadow-sm">
                                    <div className="list-group-item active border-0 mb-0 d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Role</h5>
                                        {/* <!-- <button className="btn btn-light d-flex-mi" type="button"><i
                                        className="material-symbols-outlined">save</i> Save</button> --> */}
                                    </div>
                                    <div className="list-group-item">
                                        <div className="row d-flex align-items-center justify-content-between ">

                                            <div className="container text-center">
                                                <div className="row ">
                                                    <div className="col-12 col-sm-4 border text-start py-2 my-0">
                                                        <h5 className="mb-0">Who's who</h5>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Role</div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Listed</div>
                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-12 col-sm-4 border text-start py-2 my-0">
                                                        <h5 className="mb-0">APPG Artificial Intelligence</h5>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Role</div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 text-start p-0 my-0">
                                                        <div className="border p-2">Secretariat</div>
                                                    </div>
                                                </div>





                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </Container>
  )
}

export default MyProfileTab
