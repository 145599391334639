import React, { useEffect, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
import Share from '@mui/icons-material/ReplyOutlined';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import axios from "axios";
import http from '../../../../../http';
import { Link } from 'react-router-dom';
import PieChart from '../../../../Charts/PieChart';
import Modal from 'react-bootstrap/Modal';
import { BarChart } from '../../../../Charts/BarChart';
import { useLocation } from 'react-router-dom';
import AddchartIcon from '@mui/icons-material/Addchart';
import InputGroup from 'react-bootstrap/InputGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chart } from "react-google-charts";





function PUBLPollDetails(props, { handleClick }) {
  //console.log(props);
  const [pavilionName, setPavilionName] = useState();
  const [pavilionLogo, setPavilionLogo] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [poll, setpoll] = useState([]);
  const [postfile, setPostfile] = useState([]);
  const [surveryListData, setSurveryListData] = useState([]);
  const [pollId, setPollId] = useState(props.setPollId);
  const [pollResult, setPollResult] = useState([]);
  const [pollTotalParticipants, setPollTotalParticipants] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [categorypoll, setCategorypoll] = useState([]);
  const divStyle = {
    borderRadius: '40px',
  };


  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
    if (splitURL[1] === 'poll') {
      if (splitURL[2]) {
        setPollId(splitURL[2])
        // if (pollId) {
        http().get(`/publicwebpolls/${splitURL[2]}`).then((data) => {
          console.log('yes');
          console.log(data);
          setSurveryListData(data.data.data.survey);
        });
        // }
      }
    }

  }, []);

  const handlePollResultClick = (i) => {
    //alert(i);
    http().get('/webpollresult/' + props.idx + '/' + i).then((data) => {
      setShow(true);
      console.log('vvvv');
      console.log(data);
      console.log(data.data);
      console.log(data.data.data);
      console.log(data.data.data.result);
      setPollResult(data.data.data.result);
      setPollTotalParticipants(data.data.data.total_participants);
    });
  }

  return (
    <>
      <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
          <div>
            <IconButton ><Share className='flipx' /></IconButton>
            <Typography variant="small">Survey Shared</Typography>
          </div>
        </ListGroup.Item>
        <ListGroup.Item key={456} className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
          <div className="container slim-scrollbar mh-70  py-0">
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75 px-2">
              <div className="container slim-scrollbar mh-70  py-0">
                {surveryListData.map((innerArray, outerIndex) => (
                  <form name={"survey_" + innerArray.survey_id} id={"survey_" + innerArray.survey_id} >
                    <ListGroup className="overflow-hidden px-0  mb-2 " style={{ borderRadius: "5px" }}>
                      <input type="text" style={{ display: 'none' }} name={innerArray.survey_id} value={innerArray.survey_id} />
                      <ListGroup.Item variant='light' className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
                        <Row className="w-100  d-flex justify-content-between align-items-center ">
                          <Col md={2}>
                            <BadgeAvatar src={localStorage.getItem('bucketName') + '/images/profile_picture/' + innerArray.user_image} name={innerArray.user_name} size='40' isLoggedIn={false} />
                          </Col>
                          <Col md={10} className=' text-start'>
                            <h6 className='mb-0'>{innerArray.user_name}</h6>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex flex-column align-items-center justify-content-center h-100 border-0 p-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                        <ListGroup className="rounded-0 w-100">
                          <ListGroup.Item className='d-flex align-items-center justify-content-between'>
                            <h6>{innerArray.survey_name}</h6>
                          </ListGroup.Item>
                          {innerArray.question.map((innerArrayQuestion, outerIndexQuestion) => (<ListGroup.Item className="">
                            <h6>  Q{outerIndexQuestion + 1}. {innerArrayQuestion.question_name}  </h6>
                            {innerArrayQuestion.type == "multiple" && (
                              <div>
                                <input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} />
                                {innerArrayQuestion.option.map((innerArrayQuestionOption, outerIndexQuestionOption) => (
                                  <Form.Check className="px-0 mb-2">
                                    <Form.Check.Label for="archived" >
                                      <Form.Check.Input id="archived" className="me-2" name={"row_multiple_" + innerArrayQuestion.question_id + "[]"} type="radio" value={innerArrayQuestionOption.option_id} />
                                      {innerArrayQuestionOption.multiple_value}
                                      {innerArrayQuestion.question_id}</Form.Check.Label>
                                  </Form.Check>
                                ))}
                              </div>
                            )}
                            {innerArrayQuestion.type == "scaling" && (
                              <div>
                                <input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} />
                                <div className="d-flex justify-content-between small">
                                  {innerArrayQuestion.option.map((innerArrayQuestionOption, outerIndexQuestionOption) => (
                                    <span>{innerArrayQuestionOption.scaling_value}</span>
                                  ))}
                                </div>
                                <Form.Range min='0' name={"scaling_" + innerArrayQuestion.question_id} max={innerArrayQuestion.option.length - 1} variant="primary" />
                              </div>
                            )}
                            {innerArrayQuestion.type == "ranking" && (
                              <div>
                                <input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} />
                                {innerArrayQuestion.option.map((innerArrayQuestionOption, outerIndexQuestionOption) => (
                                  <InputGroup className="mb-2 d-flex align-items-center" size="sm">{innerArrayQuestionOption.option_id}
                                    <Form.Select className='p-1' size="sm" required name={"row_ranking_" + innerArrayQuestionOption.option_id} id={innerArrayQuestionOption.option_id} >
                                      <option selected value='0'>0</option>
                                      {innerArrayQuestion.option.map((innerArrayQuestionOptionDropdown, outerIndexQuestionOptionDropdown) => (
                                        <option value={outerIndexQuestionOptionDropdown + 1}  >{outerIndexQuestionOptionDropdown + 1}</option>
                                      ))}
                                    </Form.Select>
                                    <InputGroup.Text className='w-75'>{innerArrayQuestionOption.ranking_value}</InputGroup.Text>
                                  </InputGroup>
                                ))}
                              </div>
                            )}
                            {innerArrayQuestion.type == "matrix" && (
                              <Container>
                                <Row>
                                  <input type="text" style={{ display: 'none' }} name={innerArrayQuestion.question_id} value={innerArrayQuestion.question_name} />
                                  <Col></Col>
                                  {innerArrayQuestion.option.map((innerArrayQuestionRow, outerIndexQuestionRow) => (
                                    <Col>
                                      {innerArrayQuestionRow.columns.map((innerArrayQuestioncolumns, outerIndexQuestioncolumns) => (
                                        <span>
                                          {innerArrayQuestionRow.matrix_serial_no == innerArrayQuestioncolumns.matrix_columns_level ? (innerArrayQuestioncolumns.matrix_columns_title) : ('')}
                                        </span>
                                      ))}
                                    </Col>
                                  ))}
                                </Row>
                                {innerArrayQuestion.option.map((innerArrayQuestionColumnData, outerIndexQuestionColumn) => (
                                  <Row><Col>{innerArrayQuestionColumnData.matrix_rows_title}</Col>
                                    {innerArrayQuestionColumnData.columns.map((innerArrayQuestionColumn, outerIndexQuestionColumn) => (
                                      <Col><Form.Check type="radio" name="row-group1" name={"row_matrix_" + innerArrayQuestion.question_id + '@' + innerArrayQuestionColumnData.option_id} value={innerArrayQuestionColumn.option_column_id} /></Col>
                                    ))}
                                  </Row>
                                ))}
                              </Container>
                            )}
                          </ListGroup.Item>
                          ))}
                        </ListGroup>
                        <ListGroup className="w-100 rounded-0 ">
                          <ListGroup.Item className="d-flex justify-content-between align-items-center px-1">
                            <Button variant="primary" className='px-1 py-0' type='button' size="sm" onClick={() => handlePollResultClick(innerArray.survey_id)}>Survey Results</Button>
                          </ListGroup.Item>
                        </ListGroup>
                      </ListGroup.Item>
                    </ListGroup>
                  </form>
                ))}
              </div>
            </ListGroup.Item>
          </div>
        </ListGroup.Item >

      </ListGroup>
      <Modal centered show={show} backdrop="static" keyboard={false} onHide={handleClose}>
        <Modal.Header className='my-1 py-1' closeButton>
          <Modal.Title className='lead'>Survey Results</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0 m-0 d-flex align-items-start' style={{ overflowY: "scroll" }}>
          <Container >
            {/*<PieChart className='mb-5' />*/}
            {pollTotalParticipants == 0 && (<>No Records Found </>)}
            {pollTotalParticipants >= 1 && (<>

              {pollResult.map((innerArray, outerIndex) => (
                <div>{innerArray.question.questionname}
                  {innerArray.quetiontype == 'scaling' && (<>
                    <Chart
                      chartType="BarChart"
                      data={innerArray.question.typescalarData}
                      options={{ "title": `${innerArray.question.questionname}` }}
                      width="100%"
                      height="350px"
                    />
                  </>)}
                  {innerArray.quetiontype == 'ranking' && (<>
                    {innerArray.question.typemultiple.map((innerArrayValue, outerIndexValue) => (
                      <Chart
                        chartType="PieChart"
                        data={innerArrayValue.rank}
                        options={{ "title": `Rank ${outerIndexValue + 1}` }}
                        width="100%"
                        height="350px"
                      />
                    ))}
                  </>)}
                  {innerArray.quetiontype == 'multiple' && (<>
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      height="350px"
                      data={innerArray.question.typemultipleData}
                      options={{
                        "title": `${innerArray.question.questionname}`,
                        chartArea: { width: "75%", height: "350px" },
                        legend: { position: "none" },
                        annotations: { alwaysOutside: true }
                      }}
                    />
                  </>)}
                  {innerArray.quetiontype == 'matrix' && (<>
                    {innerArray.question.typemultiplematrix.map((innerArrayValue, outerIndexValue) => (
                      <Chart
                        chartType="PieChart"
                        data={innerArrayValue.matrix}
                        options={{ "title": `${innerArrayValue.name}` }}
                        width="100%"
                        height="350px"
                      />
                    ))}
                  </>)}
                </div>
              ))}
            </>
            )}
            {/*<BarChart />*/}
          </Container>
        </Modal.Body>
        <Modal.Footer className='my-1 py-1 d-flex justify-content-between align-items-center '>
          {pollTotalParticipants != 0 && (
            <medium>User participants count so for {pollTotalParticipants}</medium>
          )}


          <Button size="sm" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default PUBLPollDetails
