import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Hidden, SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import InputGroup from 'react-bootstrap/InputGroup';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import Select from 'react-select';
//import { CATEGORIES, ECOSYSTEM_OPTIONS } from '../../../PavilionConstants';
import Accordion from 'react-bootstrap/Accordion';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import LooksFiveIcon from '@mui/icons-material/Looks5';
import LooksSixIcon from '@mui/icons-material/Looks6';
import Modal from 'react-bootstrap/Modal';
import { useRef } from "react"
import banner1 from '../../../../assets/img/banner1.png'
import banner2 from '../../../../assets/img/banner2.png'
import banner3 from '../../../../assets/img/banner3.png'
import banner4 from '../../../../assets/img/banner4.png'
import http from '../../../../http';


const AntTabs2 = styled(Tabs)({
  height: '20px',
  minHeight: '20px',
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#000000',
  },
});

const AntTabs = styled(Tabs)({
  height: '36px',
  minHeight: '36px',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    border: '1px solid #ececec',
    width: 'fit-content',
    padding: '2px 3px',
    borderRadius: 50,
  },
});

interface StyledTabProps {
  label: string;
}
const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minHeight: 20,
  borderRadius: 50,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 3,
  paddingBottom: 3,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: ['Barlow'].join(','),
  '&:hover': {
    color: '#e30613',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#e30613',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

function PRFLPavilionSetup({planId, planName, planType, planAmount, change, handleClick }) {
  const [showModal, setShowModal] = useState(false);  
 
    const handleLogoClick = (index) => {
      const logoCheck = document.getElementById(`logo${index}`);
      logoCheck.checked=true;
    }
    const handleIconClick = (index) => {
      const iconCheck = document.getElementById(`icon${index}`);
      iconCheck.checked=true;
    }
    const handleCardClick = (index) => {
      const cardCheck = document.getElementById(`card${index}`);
      cardCheck.checked=true;
    }
    const [bannerImage,SetBannerImage]=useState([banner1,banner2,banner3,banner4]);
    const handleBannerClick = (index) => {
      const bannerCheck = document.getElementById(`banner${index}`);
      bannerCheck.checked=true;
    }
 

    const [value, setValue] = useState('1');
  const [value2, setValue2] = useState('1');
  const [value3, setValue3] = useState('1');

  const handleChange = (event, newValue) => {
    //setValue(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };
  const handleChange3 = (event, newValue) => {
    setValue3(newValue);
  };
  
 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [passwordValue, setPasswordValue] = useState('');
 const [phone, setPhone] = useState('');
 const [countrylist, setCountrylist] = useState([]);
 const [citylist, setCitylist] = useState([]);
 const [address, setAddress] = useState([]);
 const [pavilionName, setPavilionName] = useState([]);
 const [pavilionDescription, setPavilionDesc] = useState([]);
 const [pavilionTotalMembers, setPavilionTotalMembers] = useState([]);
 const [pavilionCountry, setPavilionCountry] = useState([]);
 const [categoryOptions, setPavilioncategory] = useState([]);
 const [bankName, setBankName] = useState('');
 const [accountName, setAccountName] = useState('');
 const [accountNumber, setAccountNumber] = useState('');
 const [sortCode, setSortCode] = useState('');
 const [swiftCode, setSwiftCode] = useState('');
 const [iban, setIban] = useState('');
 /*const [planName, setPlanName] = useState('');
 const [planType, setPlanType] = useState('');
 const [planAmount, setPlanAmount] = useState('');*/
 const [couponCode, setCouponCode] = useState('');
 const [countryName, setCountryName] = useState('');
 const [cityName, setCityName] = useState('');
 const [userId,setUserId]=useState('');
 const [pavilonId,setpavilonId]=useState('');
 const [categoryvalue,setcategoryvalue]=useState(1);


const pavilionUsers = (event) => {
			setValue('2');  
			http().post('/pavilionUsers', {
			user_name:name,
			user_email:email,
			user_phone:phone,
			country:countryName,
			user_city:cityName,
			user_address:address,
			user_action:'add',
			user_id:userId,
			password:passwordValue,
			user:'notexisted',
	}).then((data) => {
			 setUserId(data.data);
			 //alert(data.data);
	});
		}


const addPavilion = (event) => {
			setValue('3');  
			http().post('/addPavilion', {
			user_id:userId,
			pavilion_name:pavilionName,
			pavilion_description:pavilionDescription,
			country_id_pavilion:pavilionCountry,
			pavilion_total_members:pavilionTotalMembers,
			pavilion_action:'add',
			pavilion_id:'',
			action_name:'dataadd',
	}).then((data) => {
			 console.log(data.data);
			 setpavilonId(data.data);
	});
		}	
	
const dataCategory = (event) => {
			setValue('4');  
			http().post('/addPavilion', {
			"pavilion_category[]":categoryvalue,
			"pavilion_action":'edit',
			"pavilion_id":pavilonId,
			"action_name":'dataeditcategory',
	}).then((data) => {
			 console.log(data.data);
			// setpavilonId(data.data);
	});
		}		
	
const bankdetails = (event) => {
			setValue('6');  
			/*http().post('/bankdetails', {
			"pavilion_category[]": categoryvalue,
			pavilion_id: pavilonId,
			bank_name: bankName,
			account_name: accountName,
			account_no: accountNumber,
			sort_code: sortCode,
			swift_code: swiftCode,
			iban: iban,
			user_id: userId,
			bank_id: "",
			bank_action: 'add',
	}).then((data) => {
			 console.log(data.data);
	});*/
		}	
		
 const nameChange = (event) => {
	setName(event.target.value);
  }
 const emailChange = (event) => {
	setEmail(event.target.value);
  }  
  const passwordChange = (event) => {
	setPasswordValue(event.target.value);
  }   
  const phoneChange = (event) => {
	setPhone(event.target.value);
  }    
 const addressChange = (event) => {
	setAddress(event.target.value);
  }    
 const pavilionNameChange= (event) => {
	setPavilionName(event.target.value);
  }   
 const pavilionDescriptionChange = (event) => {
	setPavilionDesc(event.target.value);
  }  
const pavilionTotalMembersChange = (event) => {
	setPavilionTotalMembers(event.target.value);
  }  
const pavilionCountryChange = (event) => {
	setPavilionCountry(event.target.value);
}
const bankNameChange = (event) => {
	setBankName(event.target.value);
}
const accountNameChange = (event) => {
	setAccountName(event.target.value);
}
const accountNumberChange = (event) => {
	setAccountNumber(event.target.value);
}
const sortCodeChange = (event) => {
	setSortCode(event.target.value);
}
const swiftCodeChange = (event) => {
	setSwiftCode(event.target.value);
}
const ibanChange = (event) => {
	setIban(event.target.value);
}
/*
const planNameChange = (event) => {
	setPlanName(event.target.value);
}
const planTypeChange = (event) => {
	setPlanType(event.target.value);
}
const planAmountChange = (event) => {
	setPlanAmount(event.target.value);
}*/
const couponCodeChange = (event) => {
	setCouponCode(event.target.value);
}
const pavilionCategoryChange = (event) => {
	setcategoryvalue(event.target.value);
}

		useEffect(() => {
		//countrylist
		http().get('/getcountries').then((data) => {
		  setCountrylist(data.data.data.countries);
		});
		http().get('/pavilioncategory').then((data) => {
		  setPavilioncategory(data.data.data);
		});
		
	  }, []);

	const handleSelectChange = (event) => {
	setCountryName(event.target.value);
		if(event.target.value!=''){
			http().get('/cities/'+document.getElementById('country_id').value).then((data) => {
			setCitylist(data.data.data.CountryCities);
			//setCitylist(data);
		});
		}			
	  };
	  	const handleCityChange = (event) => {
	setCityName(event.target.value);
		}
		
  return (
    <>
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant='light'  className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
          <IconButton onClick={() => change()}><ArrowBackIcon /></IconButton>
          <Typography variant="small">Pavilion Setup</Typography>
        </div>
        <IconButton onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
      </ListGroup.Item>
      <ListGroup.Item className="d-flex align-items-start justify-content-center h-100 px-0  border-0 bg-light bg-opacity-75">
        <div className="container px-0  py-0 slim-scrollbar mh-70">

          <TabContext value={value} >
            <Box sx={{ width: '100%', m: 0, borderBottom: 1, overflowX: 'hidden', flexGrow: 1, borderColor: 'divider' }}>

              <Tabs
                value={value}
                style={{ minHeight: '34px' }}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#e30613",
                    padding: "0",
                    height: '2px',
                  }
                }}
                inkBarStyle={{ color: 'black' }}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable auto tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    minWidth: '10%',
                    '&.Mui-disabled': { opacity: 0.3 },
                  }, textTransform: 'capitalize', justifyContent: 'center',
                  "& button": { fontSize: '12px', margin: '0' },
                  p: 0,
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#e30613",
                    height: 0,
                  },
                  "& .MuiTabs-scroller": {
                    height: '35px',
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: '#e30613'
                  },
                  "& .MuiTab-root": {
                    minHeight: '26px'
                  },
                }}
              >
                <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label={<LooksOneIcon />} value="1" style={{ minWidth: "16.67%" }} />
                <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label={<LooksTwoIcon />} value="2" style={{ minWidth: "16.67%" }} />
                <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label={<LooksThreeIcon />} value="3" style={{ minWidth: "16.67%" }} />
                <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label={<LooksFourIcon />} value="4" style={{ minWidth: "16.67%" }} />
                <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label={<LooksFiveIcon />} value="5" style={{ minWidth: "16.67%" }} />
                <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label={<LooksSixIcon />} value="6" style={{ minWidth: "16.67%" }} />
              </Tabs>
            </Box>
            <TabPanel value="1" sx={{ px: 2, py: 1 }} style={{ textAlign: "center" }} >
              <div className='d-flex justify-content-between'>
                <div className='text-start'>
                  <h5 className='my-0'>Basic Information</h5>
                  <p className="mb-2 small" >Please fill your authentic Contact Details</p>
                </div>
              </div>


              <Form >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control required size="sm" onChange={nameChange}  value={name} type="text" placeholder="Name" className="mb-2 py-1" />
                  <Form.Control required size="sm" onChange={emailChange}  value={email}  type="email" placeholder="Email" className="mb-2  py-1" />
                  <Form.Control required size="sm" onChange={passwordChange}  value={passwordValue}  type="password" placeholder="Password" className="mb-2  py-1" />
                  <Form.Control required size="sm" onChange={phoneChange}  value={phone}   type="tel" placeholder="Phone" className="mb-2  py-1" />
                  <Form.Select required size="sm" id="country_id" name="country_id" aria-label="Default select example" className="my-2  py-1" value={countryName} onChange={handleSelectChange}>
                    <option value="">Country</option>
					{countrylist.map((countrylistArray, countrylistIndex) => (
					 <option value={countrylistArray.id}>{countrylistArray.name}</option>
					))}
                  </Form.Select>
                  <Form.Select required id="city" name="city"  value={cityName} size="sm" aria-label="Default select example" className="mb-2  py-1" onChange={handleCityChange}>
				  <option value=''>City</option>
					{citylist.map((citylistArray, citylistIndex) => (
					 <option value={citylistArray.id}>{citylistArray.name}</option>
					))}		
                  </Form.Select>
                  <Form.Control required as="textarea" value={address} placeholder="Address" rows={2} className="mb-2 py-1" onChange={addressChange} />
                </Form.Group>
                
                
                
                <Button onClick={(event) => pavilionUsers()} variant="primary" style={{ height: 'fit-content' }} size="sm" type="button">
                  Save & Next
                </Button>
              </Form>

            </TabPanel>
            <TabPanel value="2" sx={{ px: 2, py: 1 }} style={{ textAlign: "start" }}>
              <div className='d-flex justify-content-between'>
                <div className='text-start'>
                  <h5 className='my-0'>Pavilion Information</h5>
                  <p className="mb-2 small" >Please fill New Pavilion Details</p>
                </div>
              </div>
              <Form >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                 
                  <Form.Control size="sm" type="text" name="pavilionName" value={pavilionName}  onChange={pavilionNameChange} placeholder="Pavilion Name" className="my-2 py-1" />
                  <Form.Control as="textarea"  name="pavilionDescription" value={pavilionDescription}  onChange={pavilionDescriptionChange} placeholder="Pavilion Description" rows={2} className="mb-2 py-1" />
                  <Form.Select size="sm" id="pavilionCountryId" name="pavilionCountryId" aria-label="Default select example" className="my-2  py-1" value={pavilionCountry} onChange={pavilionCountryChange}>
					<option value="">Country</option>
					{countrylist.map((countrylistArray, countrylistIndex) => (
					 <option value={countrylistArray.id}>{countrylistArray.name}</option>
					))}                   
                  </Form.Select>
                  <Form.Select name="pavilion_total_members" id="pavilion_total_members" placeholder="Total Members Expected" size="sm" aria-label="Default select example" className="mb-2  py-1" onChange={pavilionTotalMembersChange} value={pavilionTotalMembers}>
                    <option selected disabled value="">Total members expected</option>
                    <option value="1-500">1 - 500 members</option>
                    <option value="501-1000">501 - 1000 members</option>
                    <option value="1001-5000">1001 - 5000 members</option>
                    <option value="above 5000">above 5000</option>
                  </Form.Select>
                </Form.Group>
              </Form>
              <div className='d-flex justify-content-between'>
              <Button onClick={() => setValue('1')}  variant="primary" style={{ height: 'fit-content' }} size="sm" type="button">
                  Back
                </Button>
              <Button onClick={(event) => addPavilion()} variant="primary" size="sm" type="button">
                Save and Next
              </Button>
              </div>
            </TabPanel>
            <TabPanel value="3" sx={{ px: 2, py: 1 }} >
              <div className='d-flex justify-content-between'>
                <div className='text-start'>
                  <h5 className='my-0'>Pavilion Category</h5>
                  <p className="mb-2 small" >Please Select Pavilion Categories</p>
                </div>
              </div>
              <Form >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Select isMulti autosize={true} className="basic-multi-select w-100" placeholder="Select Country" classNamePrefix="select" value={categoryvalue}  onChange={pavilionCategoryChange} options={categoryOptions} />

                </Form.Group>
                <div className='d-flex justify-content-between'>
                <Button onClick={() => setValue('2')} variant="primary" style={{ height: 'fit-content' }} size="sm" type="button">
                  Back
                </Button>
                <Button onClick={(event) => dataCategory()} variant="primary" size="sm" type="button">
                  Save and Next
                </Button>
                </div>
              </Form>
            </TabPanel>
            <TabPanel value="4" sx={{ px: 2, py: 1 }} style={{ textAlign: "start" }}>
              <div className='d-flex justify-content-between'>
                <div className='text-start'>
                  <h5 className='my-0'>Pavilion Graphics</h5>
                  <p className="mb-2 small" >Please select Pavilion Graphics</p>
                </div>
              </div>
              <TabContext value={value2} className="d-flex flex-column align-items-center justify-content-center">
                <AntTabs
                  className=''
                  value={value2}
                  onChange={handleChange2}
                  scrollButtons={false}
                  aria-label="scrollable auto tabs example"
                >
                  <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Choose Graphics</Typography>} value="1" />
                  <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Upload Graphics</Typography>} value="2" />
                </AntTabs>

                <TabPanel value="1" sx={{ p: 0 }}>
                  <TabContext value={value3} >
                    <Box sx={{ width: '100%', m: 0, borderBottom: 1, overflowX: 'hidden', flexGrow: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={value3}
                        style={{ minHeight: '30px' }}
                        onChange={handleChange3}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#e30613",
                            padding: "0",
                            height: '2px',
                          }
                        }}
                        inkBarStyle={{ color: 'black' }}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable auto tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            minWidth: '10%',
                            '&.Mui-disabled': { opacity: 0.3 },
                          }, textTransform: 'capitalize', justifyContent: 'center',
                          "& button": { fontSize: '12px', margin: '0' },
                          p: 0,
                          "& .MuiTabs-indicator": {
                            backgroundColor: "#e30613",
                            height: 0,
                          },
                          "& .MuiTab-root.Mui-selected": {
                            color: '#e30613'
                          },
                          "& .MuiTabs-scroller": {
                            height: '30px',
                          },
                          "& .MuiTab-root": {
                            minHeight: '30px'
                          },
                        }}
                      >
                        <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label="LOGO" value="1" style={{ minWidth: "25%" }} />
                        <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label="ICON" value="2" style={{ minWidth: "25%" }} />
                        <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label="CARD" value="3" style={{ minWidth: "25%" }} />
                        <Tab sx={{ textTransform: 'capitalize', padding: 0 }} label="BANNER" value="4" style={{ minWidth: "25%" }} />
                      </Tabs>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0, py: 0, mx: 0 }}  >
                      <Row xs={1} md={2} className="g-2 my-0">
                        {Array.from({ length: 4 }).map((_, idx) => (
                          <Col key={idx}>
                            <Card key={idx} role="button" className="mb-0" onClick={()=> handleLogoClick(idx)} >
                              <Card.Img key={idx}  className="rounded-2" variant="top" src={localStorage.getItem('bucketName')+`/assets/frontend/images/newpavilion/logo${4+idx}.png`} />
                              <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
                                <Form.Check 
                                  label=""
                                  className="p-0"
                                  name="group1"
                                  type="radio"
                                  size="lg"
                                  value={`${idx}`}
                                  id={`logo${idx}`}
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </Card.ImgOverlay>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0, py: 0, mx: 0 }}  >
                      <Row xs={1} md={4} className="g-2 my-0">
                        {Array.from({ length: 4 }).map((_, idx) => (
                          <Col key={idx}>
                            <Card className="mb-0" role="button" key={idx} onClick={()=> handleIconClick(idx)}>
                              <Card.Img  className="rounded-2" variant="top" src={localStorage.getItem('bucketName')+`/assets/frontend/images/newpavilion/00${3+idx}.png`} />
                              <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
                                {/* <CheckCircleIcon className="text-danger display-1 " /> */}
                                <Form.Check
                                  label=""
                                  className="p-0"
                                  name="group1"
                                  type="radio"
                                  size="lg"
                                  value={`${idx}`}
                                  id={`icon${idx}`}
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </Card.ImgOverlay>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel value="3" sx={{ px: 5, py: 0, mx: 0 }} >
                      <Row xs={1} md={2} className="g-2 my-0 ">
                        {Array.from({ length: 4 }).map((_, idx) => (
                          <Col key={idx}>
                            <Card hoverable role="button" className="mb-0" onClick={()=> handleCardClick(idx)}>
                              <Card.Img className="rounded-2" variant="top" src={localStorage.getItem('bucketName')+`/assets/frontend/images/newpavilion/card${4+idx}.png`} />
                              <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
                                <Form.Check
                                  label=""
                                  className="p-0"
                                  name="group1"
                                  type="radio"
                                  value={`${idx}`}
                                  id={`card${idx}`}
                                  style={{ transform: 'scale(2)' }}
                                />
                              </Card.ImgOverlay>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel value="4" sx={{ px: 0, py: 0, mx: 0 }}  >
                      <Row xs={1} md={1} className="g-2 my-0">

                        {Array.from({ length: 4 }).map((_, idx) => (
                          <Col key={idx}>
                            <Card className="mb-0" role="button"  onClick={()=> handleBannerClick(idx)}>
                              <Card.Img key={idx} className="rounded-2"  variant="top" src={bannerImage[idx]}
 />
                              <Card.ImgOverlay  key={idx} className="d-flex justify-content-end align-items-center">
                                
                                <Form.Check
                                  label=""
                                  data-id={`${idx}`}
                                  className="p-0 m-0"
                                  name="group1"
                                  type="radio"
                                  size="sm"
                                  value={`${idx}`}
                                  id={`banner${idx}`}
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </Card.ImgOverlay>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                  </TabContext>
                </TabPanel>
                <TabPanel value="2" sx={{ p: 0, m: 0 }} >
                <div className="mb-4 mt-4 floating-label">
                                            <Form.Control type="file" size="sm" />
                                            <Form.Label className="bg-light text-primary">Logo ( 200 x 70 px )</Form.Label>
                                        </div>
                                        <div className="mb-4 floating-label">
                                            <Form.Control type="file" size="sm" />
                                            <Form.Label className="bg-light text-primary">Icon ( 100 x 100 px )</Form.Label>
                                        </div>
                                        <div className="mb-4 floating-label">
                                            <Form.Control type="file" size="sm" />
                                            <Form.Label className="bg-light text-primary">Card ( 280 x 180 px )</Form.Label>
                                        </div>
                                        <div className="mb-4 floating-label">
                                            <Form.Control type="file" size="sm" />
                                            <Form.Label className="bg-light text-primary">Banner ( 1917 x 319 px )</Form.Label>
                                        </div>
                  
                </TabPanel>
              </TabContext>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">


                </Form.Group>
                <div className='d-flex justify-content-between'>
                <Button onClick={() => setValue('3')} variant="primary" style={{ height: 'fit-content' }} size="sm" type="button">
                  Back
                </Button>
                <Button onClick={() => setValue('5')} variant="primary" size="sm" type="button">
                  Save and Next
                </Button>
                </div>
              </Form>
            </TabPanel>
            <TabPanel value="5" sx={{ px: 2, py: 1 }}>
              <div className='d-flex justify-content-between'>
                <div className='text-start'>
                  <h5 className='my-0'>Bank Details</h5>
                  <p className="mb-2 small" >Please fill your authentic Bank Details</p>
                </div>                
              </div>
              <Form className='text-center'>
                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                  <Form.Control size="sm" type="text" value={bankName}  onChange={bankNameChange}  placeholder="Bank Name" className="mb-2 py-1" />
                  <Form.Control size="sm" type="text" value={accountName}  onChange={accountNameChange}  placeholder="Account Name" className="mb-2 py-1" />
                  <Form.Control size="sm" type="text" value={accountNumber}  onChange={accountNumberChange}  placeholder="Account Number" className="mb-2 py-1" />
                  <Form.Control size="sm" type="text" value={sortCode}  onChange={sortCodeChange}  placeholder="Sort Code" className="mb-2 py-1" />
                  <Form.Control size="sm" type="text" value={swiftCode}  onChange={swiftCodeChange}  placeholder="Swift Code" className="mb-2 py-1" />
                  <Form.Control size="sm" type="text" value={iban}  onChange={ibanChange}  placeholder="IBAN" className="mb-2 py-1" />
                </Form.Group>
                <div className="d-flex justify-content-between">
                <Button onClick={() => setValue('4')} variant="primary" style={{ height: 'fit-content' }} size="sm" type="button">
                  Back
                </Button>
                <Button onClick={(event) => bankdetails()} variant="primary" size="sm" type="submit">
                  Save & Next
                </Button>
                </div>
              </Form>
            </TabPanel>
            <TabPanel value="6" sx={{ px: 2, py: 1 }}>
              <div className='d-flex justify-content-between'>
                <div className='text-start'>
                  <h5 className='my-0'>Make Payment</h5>
                  <p className="mb-2 small" >Please fill your authentic Bank Details</p>
                </div>
              </div>
              <Form className='text-center'>
               <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
               <Form.Control size="sm" readOnly type="text" value={planId} placeholder="Plan Id" className="mb-2 py-1" />
               <Form.Control size="sm" readOnly type="text" value={planName} placeholder="Plan Name" className="mb-2 py-1" />
               <Form.Control size="sm" readOnly type="text" value={planType} placeholder="Plan Type" className="mb-2 py-1" />
                  <Form.Control size="sm" readOnly type="text" value={planAmount} placeholder="Amount" className="mb-2 py-1" />
				<Form.Control size="sm" type="text" onChange={couponCodeChange} value={couponCode}  placeholder="Coupon Code" className="mb-2 py-1" />
                  <small>Note: If you have coupon code then you can add otherwise leave blank.</small>
                </Form.Group>
<div className="d-flex justify-content-between ">
               <Button onClick={() => setValue('5')} variant="primary" style={{ height: 'fit-content' }} size="sm" type="button">
                  Back
                </Button>
                <Button variant="primary" size="sm" type="button" onClick={()=>setShowModal(true)}>
                  Submit
                </Button>
</div>
                
              </Form>
            </TabPanel>
          </TabContext>
        </div>
      </ListGroup.Item>
    </ListGroup>

<Modal
show={showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered
backdrop="static" keyboard={false}
onHide={() => setShowModal(false)}
>
<Modal.Header closeButton className='py-1'>
  <Modal.Title id="contained-modal-title-vcenter">
    Congratulations! 
  </Modal.Title>
</Modal.Header>
<Modal.Body className="text-center">
  
  <CheckCircleIcon className="text-success" style={{fontSize:'5em'}} /> 
  <h4>Your New Pavilion Setup request successfully submitted.</h4>
  <p>
    Our Team will contact you back soon.
  </p>
</Modal.Body>
<Modal.Footer className="text-center py-1">
  <Button onClick={()=>setShowModal(false)} size='sm' variant="primary" type="button">Close</Button>
</Modal.Footer>
</Modal>
</>
  )
}

export default PRFLPavilionSetup


