import React, { useState, useEffect } from 'react'
import PageLogo from '../assets/img/bicpavilion.png';
// import Logo from './Logo';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { Chat, Search, SearchOutlined } from "@mui/icons-material";
// import BellIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { IconButton } from '@mui/material';
import BadgeAvatar from './BadgeAvatar';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
// import ChatIcon from '@mui/icons-material/InsertCommentOutlined';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Badge from '@mui/material/Badge';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from "react-router-dom"
import { Form, Row, Col, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
// import http from '../http'
import Swal from 'sweetalert2';
import LockResetIcon from '@mui/icons-material/LockReset';
import ResetPwdForm from '../components/ResetPwdForm'
import Tooltip from '@mui/material/Tooltip';


function Header101() {

  const token = localStorage.getItem('token');
  // const [userdetails, setUserdetails] = useState([]);
  
  const navigate = useNavigate()
  const handleLogin = () => navigate(`/`);

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#333",
      confirmButtonText: "Yes, Logout"
    }).then((result) => {
      if (result.isConfirmed) {
          localStorage.clear();
          // navigate('/');
          window.location.href = '/';
      }
    });
  };


  const [showChatList, setShowChatList] = useState(false);
  const [show, setShow] = useState(false);

  const handleCloseChatList = () => setShowChatList(false);
  const handleShowChatList = () => setShowChatList(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isIconClicked, setIsIconClicked] = useState(true);

  const handleDarkMode = (event) => {
    setIsIconClicked(!isIconClicked);
    const thm = document.documentElement.getAttribute("data-bs-theme");
    const appId = document.getElementById('app-theme');
    const thm2 = appId.getAttribute('data-bs-theme');
    const thmToggler = event.currentTarget;
    const theme = localStorage.getItem("data-bs-theme");
    // thmToggler.innerHTML='<small>{theme}</small>';

    if (thm2 === 'light') {
      appId.setAttribute('data-bs-theme', 'dark');
      document.documentElement.setAttribute("data-bs-theme", "dark");
      localStorage.setItem("data-bs-theme", "dark");
      // thmToggler.innerHTML='<small>LIGHT MODE<small>';
    } else {
      appId.setAttribute('data-bs-theme', 'light');
      document.documentElement.setAttribute("data-bs-theme", "light");
      localStorage.setItem("data-bs-theme", "light");
      //  thmToggler.innerHTML='<small>DARK MODE</small>';
    }
  }

  const [memberName, setMemberName] = useState(["Thirath Virdee", "Alex Welr", "Alan Cook", "James Krik", "Mohsin Khan", "Ijaz Ali", "Raju Dayyala"]);
  // const [memberDesig, setMemberDesig] = useState(["Co-Founder and Chief Technology Officer", "Co-Founder/Chairman", "Principal Consultant - Blockchain", "Founder", "Manager", "Developer", "Programmer"]);
  const [memberFrom, setMemberFrom] = useState(["1 month ago", "2 months ago", "22 minutes ago", "10 seconds ago", "1 year ago", "5 months ago", "1 minute ago"]);

// const [avatar,setAvatar]=useState();
// const [userName,setUserName]=useState();

//   useEffect(() => {
//     if(token){
//     http().get(`/userdetails`).then((data) => {
//       setAvatar(data.data.data.user.image)
//       setUserName(data.data.data.user.user_name);
//      });
//     }
// },[]);
const handleSelect = (eventKey) => {
  if(eventKey==='resetPwd'){
    setShow(true)
  }
}

const callbackModal = () => {
  setShow(false)
}

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="bg-light py-1 ">
        <Container>
          <Navbar.Brand href="/" className='py-0'>
            {/* <Logo width="100vw" src={PageLogo} /> */}
            <Image fluid width="100vh" src={PageLogo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" onSelect={handleSelect}>
              {/* <Nav.Link  >
                <Dropdown id="dd-notification" >
                  <Dropdown.Toggle  as={IconButton} className="p-1 btn btn-outline-mode btn-sm "  >
                  <Badge badgeContent={444} color="secondary" >
                      <BellIcon />
                      </Badge>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Header >You have 0 notifications
                      <a href="/user/notifications"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                    </Dropdown.Header>
                    <hr className="dropdown-divider" />
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    <hr className="dropdown-divider" />
                    <Dropdown.Header>test</Dropdown.Header>
                  </Dropdown.Menu>
                </Dropdown>
                
              </Nav.Link> */}
              
              {token ? (<>

                
                <Tooltip arrow
      title={localStorage.getItem('Username')} slotProps={{popper: { modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>

                <Nav.Link >
      
                  <BadgeAvatar src={localStorage.getItem('bucketName')+"/images/profile_picture/" + localStorage.getItem('UsernameImage')} name={localStorage.getItem('Username')} size='25' isLoggedIn={token} />
                    
                </Nav.Link>
                </Tooltip>
                <Tooltip arrow
      title="Change Password" slotProps={{popper: { modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
                <Nav.Link  eventKey='resetPwd' >
                
                <IconButton  className="p-0 btn btn-outline-mode btn-sm "><LockResetIcon sx={{fontSize:'1.8rem'}}/></IconButton>
                
                </Nav.Link>
                </Tooltip>
                <Tooltip arrow
      title="Logout" slotProps={{popper: { modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
                <Nav.Link  onClick={handleLogout}>
                
                <IconButton className="p-1 btn btn-outline-mode btn-sm "><LogoutIcon /></IconButton>
                
                </Nav.Link>
                </Tooltip>
                
                </>
              ) : (
                
                            <Tooltip arrow
      title="Login" slotProps={{popper: { modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
        <Nav.Link  onClick={handleLogin}>
                  <IconButton className="p-1 btn btn-outline-mode btn-sm "><LoginIcon /></IconButton>
                  </Nav.Link>
                  </Tooltip>
                
              )}
            
              
              {/* <Nav.Link  onClick={handleShowChatList}>
                   <IconButton className="p-1 btn btn-outline-mode btn-sm"><ChatIcon /></IconButton>
              </Nav.Link> */}
              
              
              <Tooltip arrow
      title={isIconClicked ? 'Dark Mode' : 'Light Mode'} slotProps={{popper: { modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
        <Nav.Link to="javascript:void(0);" onClick={handleDarkMode} id="theme-toggler" >
                  <IconButton className="p-1 btn btn-outline-mode btn-sm">{isIconClicked ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}</IconButton>
                  </Nav.Link>   </Tooltip>

              
              {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Offcanvas show={showChatList} placement="end" onHide={handleCloseChatList} data-aos="slide-right">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Chat</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='pt-0'>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Search User"
              aria-label="search"
              aria-describedby="search-addon1"
              size="sm"
              className="py-1"
            />
            <InputGroup.Text id="search-addon1"><SearchOutlined fontSize='small' /></InputGroup.Text>
          </InputGroup>
          <ListGroup className="d-flex flex-column slim-scrollbar" style={{ maxHeight: "80vh" }}>
            {/* <Row xs={1} md={12} className="g-3 d-flex justify-content-between align-items-center slim-scrollbar mt-2" style={{ maxHeight: "80vh" }} > */}
              {Array.from({ length: 7 }).map((_, idx) => (
                        <ListGroup.Item key='useravatar' className="d-flex align-items-center" action onClick={()=>setShow(true)}>
                        <BadgeAvatar src="" name={memberName[idx]} size='40' isLoggedIn={false} />
                        <div id="paviname" className="ms-3 d-sm-inline">
                            <h6 className="mb-0">{memberName[idx]}</h6>
                            <small className="text-dark ">{memberFrom[idx]}</small>
                        </div>
                    </ListGroup.Item>
                          
                          // <Col key={idx} md={2} className=""> 
                          //   {/* <Image variant="top" className='rounded' style={{width:"40px",height:"40px"}} src={membershipImages[idx]} /> */}
                          //   <BadgeAvatar src="" name={memberName[idx]} size='40' isLoggedIn={false} />

                          // </Col>
                          // <Col md={10}>
                          //   <h6 className='mb-0'>{memberName[idx]}</h6>
                          //   {/* <small>{memberDesig[idx].substring(0, 30)}</small><br /> */}
                          //   <small>{memberFrom[idx]}</small>
                          // </Col>
                          
                        
                      ))}
          {/* </Row> */}
          </ListGroup>
        </Offcanvas.Body>
       
        {/* <Modal show={showChatModal} onHide={handleClose} dialogClassName='custom-dialog'>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
      </Offcanvas>

      <Modal show={show} centered onHide={handleClose} backdrop="static">
        <Modal.Header className='py-1' closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResetPwdForm callbackModal={callbackModal}/>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}
export default Header101
