import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useHash } from '../components/useHash';
import { scrollToSection } from '../utils/utils';
function Footer2() {
    return (
        <footer className="text-center text-bg-secondary aos-init" data-aos="">
            <div className="text-center p-3 bg-dark bg-opacity-50" >
            Should you prefer to pay using bank transfer, please request an invoice from <a className="text-white text-decoration-none" href='mailto: partnerships@biginnovationcentre.com'>partnerships@biginnovationcentre.com</a>
            </div>
            <div className="text-center p-3 bg-dark" >
                © Copyright  - <a className="text-white text-decoration-none" href="https://biginnovationcentre.com/">Big Innovation Centre</a> - All rights reserved. - <a href="/privacypolicy" className="text-white text-decoration-none">Privacy Policy</a>
            </div>
        </footer>
        
    )
}

export default Footer2
