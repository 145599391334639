import React, { useState,useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));



function BadgeAvatar(props) {
  const [src, setSrc] = useState(props.src);
  const [name, setName] = useState(props.name);
  const [size, setSize] = useState(props.size);
  const [isLoggedIn, setIsLoggedIn] = useState(props.isLoggedIn);

if(!src){
  setSrc("broken-image.png");
}
useEffect(() => { setSrc(props.src) }, [props.src]);


  return (
    <Stack direction="row" spacing={2}>

  {isLoggedIn ? (<StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
       <Avatar style={{border: '0.5px solid lightgray'}} className='text-uppercase bg-primary' alt={name} src={src} sx={{ width: Number(size), height: Number(size), fontSize: Number(size)*2/3 }}/>
      </StyledBadge>) : (
           <Avatar style={{border: '0.5px solid lightgray'}} className='text-uppercase bg-primary' alt={name} src={src} sx={{ width: Number(size), height: Number(size), fontSize: Number(size)*2/3 }}/>
      )}
    </Stack>
  );
}
export default BadgeAvatar