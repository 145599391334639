import React, {  useEffect}  from 'react'
import { useLocation } from 'react-router-dom'
import HomeContent from '../components/HomeContent'
import Layout from '../components/layouts/Layout'


function Home(props) {

    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0,0);
    }, [location]);

  return (
<>
    <Layout>
      <HomeContent />
    </Layout>

    {/* <div className='d-flex justify-content-center align-items-center bg-primary vh-100'>
      <div className='bg-white rounded w-25 p-3'>
      <h2 className='text-center fw-bold'>Login</h2>
        <form action="" onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="email" className='fw-bold'>Email</label>
                <input type="email" name='email' onChange={handleInput} placeholder='Enter Email' className='form-control' />
                {errors.email && <span className='text-danger'>{errors.email}</span> }
            </div>
            <div className="mb-3">
                <label htmlFor="password" className='fw-bold'>Password</label>
                <input  maxLength={8} type="password" name='password' onChange={handleInput} placeholder='Enter Password' className='form-control'/>
                {errors.password && <span className='text-danger'>{errors.password}</span> }
            </div>
            <button type='submit' className='btn btn-success w-100 mb-2 fw-bold'>Login</button>
            <p className='text-center'>You are agree to our terms and policies</p>
            <Link to="/register" className='btn btn-default border w-100 fw-bold text-decoration-none'>Register</Link>
                        
        </form>
      </div>
    </div> */}

    </>
  )
}

export default Home
