import React, {useEffect, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
import Share from '@mui/icons-material/ReplyOutlined';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import axios from "axios";
import http from '../../../../../http';
import { formatDate } from '../../../../PavilionConstants';

function PUBLPublicationDetails(props,{ handleClick }) {
//console.log(props);
	const [pavilionName, setPavilionName] = useState();
  const [pavilionLogo, setPavilionLogo] = useState();
  const [createdAt, setCreatedAt] = useState();
	const [publication, setPublication] = useState([]);
	const [postfile, setPostfile] = useState([]);

  const [publicationId, setPublicationId] = useState(props.publicationId);
  
	const [categoryPublication, setCategoryPublication] = useState([]);
	const divStyle = {
			borderRadius: '40px',
		};


  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
      if (splitURL[1] === 'publication') {
          if (splitURL[2] ) {
            setPublicationId(splitURL[2])
          } 
      }
      
  }, [location]);

 useEffect(() => {
  if(publicationId){
	  http().get(`/publicpublication/${publicationId}`).then((data) => {
      
		setPavilionName(data.data.data.PavilionName)
    setPavilionLogo(data.data.data.PavilionLogo)
    setCreatedAt(data.data.data.publicationCreatedDate)

		  setPublication([...publication, data.data.data.publication]);
		  setPostfile(data.data.data.publication.file);
		 });
    }
	}, [publicationId]); 
	

  // const handleEventClick = (idx) => {
  // }
  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item active className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
          <IconButton ><Share className='flipx' /></IconButton>
          <Typography variant="small">Publication Shared</Typography>
        </div>
        {/* <IconButton onClick={event => handleClick(true, false, 'PVLN0')}><Home /></IconButton> */}

      </ListGroup.Item>
     
    <ListGroup.Item key={456} className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
      <div className="container slim-scrollbar mh-70  py-0">
      {publication.map((post, outerIndex) => (
        <ListGroup className="overflow-hidden h-100 px-0 " style={{ borderRadius: "10px" }}>
          <ListGroup.Item key={111} active className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Row className="w-100 g-2 d-flex justify-content-between align-items-center ">
              <Col md={2}>
				 <BadgeAvatar isLoggedIn={false} src={pavilionLogo} name="Name" size='40' />
              </Col>
              <Col md={10} className='text-start'>
                <h6 className='mb-0'>{pavilionName}</h6>
                <small>{createdAt}</small>
              </Col>
            </Row>

            {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
          </ListGroup.Item>
          <ListGroup.Item key={2222} className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Image fluid
                 src={localStorage.getItem('bucketName')+'/images/publication/'+post.image}
			/>
          </ListGroup.Item>

          <ListGroup.Item key={333} className='  border-0 py-1 '>
            <h6 className="fw-bold">{post.name}</h6>
            <p className='lead mb-1'><span classNamnpe='fw-bold'>Category</span> : {post.category_name} </p>
            <p className='lead mb-1'><span className='fw-bold'>Publication Date:</span>{formatDate(post.created_at)}</p>
            <p className='lead mb-1'><span className='fw-bold'>Description:</span></p>
            <p  dangerouslySetInnerHTML={{ __html: post.description }}/>
            <div className="d-flex justify-content-between align-items-center">
              {/* <p className='lead mb-1 fw-bold'><b>{post.payment_type}</b></p> */}
			            <Button className='bnt btn-primary'>View Details</Button> 
            </div>
            {/* <div className="d-flex justify-content-between align-items-center">
              <p className='w-50 lead mb-1'><strong>Document Type:</strong><br /> {post.document_name}</p>
              <p className='w-50 lead mb-1'><strong>Tags:</strong><br /> {post.tags}</p>
            </div> */}
          </ListGroup.Item>
          </ListGroup>
              ))}
      </div>
    </ListGroup.Item >

    </ListGroup>
  )
}
export default PUBLPublicationDetails
