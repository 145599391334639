export const scrollToSection = (id) => {
    
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      window.location.hash = `#${id}`;
    }
  };