import AdminHeader from '../components/AdminHeader'
import React, { useState, useEffect } from 'react';
// import ChatButton from "../ChatButton";
import AdminSidebar from '../components/AdminSidebar';
import '../assets/css/style4.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import AdminNavBar from '../components/AdminNavBar';
import {getPermissions} from '../utils/utils'

const AdminLayout = ({ children, props }) => {


    let bodyElement = document.getElementsByTagName('body')[0];

    // Changing the class of body Before mounting 
    bodyElement.className = "overflow-hidden";

    const [showLS, setShowLS] = useState(false);
    const [permissions, setPermissions] = useState({});
   
    useEffect(() => {
        getPermissions().then((res)=> (setPermissions(res)))
     //   adminhttp()
     //    .get(`admin/admin_user_permissions_by_role/${localStorage.getItem('localstorage_role_id') ? localStorage.getItem('localstorage_role_id') : 'all'}`)
     //    .then((data) => (setPermissions(data.data.data)) )
    },[permissions]);
    
    function handleToggle(newValue) {
        setShowLS(!showLS)
    }
    
    return (
        <div className="admin-wrapper bg-light" >
            <AdminSidebar toggle={showLS} permissions={permissions}/>
            {/* <nav id="sidebar" className={showLS ? 'active' : ''} >
                <div className="sidebar-header">
                    <h3>Bootstrap Sidebar</h3>
                    <strong>BS</strong>
                </div>

                <ul className="list-unstyled components">
                    <li className="active">
                        <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false">
                            <i className="glyphicon glyphicon-home"></i>
                            Home
                        </a>
                        <ul className="collapse list-unstyled" id="homeSubmenu">
                            <li><a href="#">Home 1</a></li>
                            <li><a href="#">Home 2</a></li>
                            <li><a href="#">Home 3</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            <i className="glyphicon glyphicon-briefcase"></i>
                            About
                        </a>
                        <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false">
                            <i className="glyphicon glyphicon-duplicate"></i>
                            Pages
                        </a>
                        <ul className="collapse list-unstyled" id="pageSubmenu">
                            <li><a href="#">Page 1</a></li>
                            <li><a href="#">Page 2</a></li>
                            <li><a href="#">Page 3</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            <i className="glyphicon glyphicon-link"></i>
                            Portfolio
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="glyphicon glyphicon-paperclip"></i>
                            FAQ
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="glyphicon glyphicon-send"></i>
                            Contact
                        </a>
                    </li>
                </ul>

                <ul className="list-unstyled CTAs">
                    <li><a href="https://bootstrapious.com/tutorial/files/sidebar.zip" className="download">Download source</a></li>
                    <li><a href="https://bootstrapious.com/p/bootstrap-sidebar" className="article">Back to article</a></li>
                </ul>
            </nav>
 */}

            <div id="admin-content" className='p-0 w-100'  >
            <AdminNavBar onChange={handleToggle}/>
                {/* <nav className="navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" id="sidebarCollapse" onClick={handleToggle1} className="btn btn-info navbar-btn p-0">
                                <MenuIcon />
                            </button>
                        </div>

                        <div className="" id="bs-example-navbar-collapse-1">
                            <AdminNavBar/>
                        </div>
                    </div>
                </nav> */}
                <div className='slim-scrollbar' style={{ height: '90vh' }}>
                    {children}
                </div>


            </div>
        </div>


    )
}

export default AdminLayout
