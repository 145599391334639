import React, { useState } from 'react'
import PavilionLayout from '../../components/layouts/PavilionLayout'
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import Banner from '../../assets/img/banner-1.png'
import Group from '@mui/icons-material/Group';
import PavilionNavBar from '../../components/pavilion/PavilionNavBar';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Col, Container, Stack } from 'react-bootstrap'
import PavilionHeader from '../../components/pavilion/PavilionHeader';
import PavilionLeftSidebar from '../../components/pavilion/PavilionLeftSidebar';
import PavilionRightSidebar from '../../components/pavilion/PavilionRightSidebar';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import InfoIcon from '@mui/icons-material/Info';
import { Grid } from '@mui/material';
import { ListGroup } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import AboutTab from './PavilionTabs/AboutTab';

import CommunityEngagementBox from '../../components/pavilion/Boxes/CommunityEngagementBox';

function AboutPavilion(props) {
  const [showLS, setShowLS] = useState(true);

  function handleToggle1(evt) {
    if (showLS) { setShowLS(false) }
    else { setShowLS(true) }
  }

  return (
    <>
      <PavilionHeader showLSB={showLS} isLoggedIn={false} handleToggle={handleToggle1} />
      {showLS && (<PavilionLeftSidebar showLSB={showLS} isLoggedIn={false} isPavilion={true} showUD={true} showSB={true} />)}
      <main id="main" style={{ marginLeft: showLS ? '240px' : '0' }} className="bg-header main px-0 pt-0" data-aos="fade-up">
        {/* <div style={{minHeight: '50px'}}>&nbsp;</div> */}
        <Container fluid>
          <div className="bg-header sticky-top" >
            <div className="w-100 bg-header" style={{ minHeight: '65px' }}>&nbsp;</div>
            <Card className="bg-dark text-white mb-3">
              <Card.Img src={Banner} alt="Card image" />
              <Card.ImgOverlay className="d-flex flex-column justify-content-end px-3 py-0">
                <Card.Title className="h4 mb-0 mt-5 mt-sm-0 fs-2vw text-light">The Metaverse Pavilion and Festival</Card.Title>
                <div className="d-flex justify-content-between text-light">
                  <Card.Text>
                    <Group />&nbsp;90 Members
                  </Card.Text>
                  <Card.Text>
                    <Stack gap={1} direction="horizontal" >
                      <Button variant="primary" className="p-1">Become a Member</Button>
                      <Button variant="primary" className="p-1"><HelpCenterIcon /></Button>
                      <Button variant="primary" className="p-1"><VideoCameraFrontIcon /></Button>
                    </Stack>
                  </Card.Text>
                </div>
              </Card.ImgOverlay>
            </Card>
          </div>
          
            <AboutTab/>              

          

        </Container>
      </main>
      <PavilionRightSidebar showPD={true} />
    </>
  )
}

export default AboutPavilion
