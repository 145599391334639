import React , {useState} from 'react'
import PavilionLayout from '../../components/layouts/PavilionLayout'
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import Banner from '../../assets/img/banner-1.png'
import Group from '@mui/icons-material/Group';
import PavilionNavBar from '../../components/pavilion/PavilionNavBar';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Container, Stack } from 'react-bootstrap'
import PavilionLeftSidebar from '../../components/pavilion/PavilionLeftSidebar';
import PavilionHeader from '../../components/pavilion/PavilionHeader';
import PavilionRightSidebar from '../../components/pavilion/PavilionRightSidebar';
import AboutTab from './PavilionTabs/AboutTab';
import WallPostTab from './PavilionTabs/WallPostTab';
import { Outlet } from 'react-router-dom';

function DetailPavilion(props) {
  const [showAbout, setShowAbout]=useState(props.showAbout);
  const [showWallPost, setShowWallPost]=useState(props.showWallPost);
  const [showLS, setShowLS] = useState(true);

  function handleToggle1(evt) {
    if(showLS){setShowLS(false)}
    else{setShowLS(true)}
  }
  return (
    <PavilionLayout>
       <Container fluid>
       <div className="bg-header sticky-top mx-0" >
          <div className="w-100 bg-header" style={{minHeight:'65px'}}>&nbsp;</div>
          <Card style={{ width: '100%' }} className='card-rounded mb-0'>
            <Card.Img variant="top" src={Banner} />
            <Card.ImgOverlay className="d-flex flex-column justify-content-end px-3 py-0">
              <Card.Title className="h4 mb-0 mt-5 mt-sm-0 fs-2vw text-light">The Metaverse Pavilion and Festival</Card.Title>
              <div className="d-flex justify-content-between text-light">
                <Card.Text>
                  <Group />&nbsp;90 Members
                </Card.Text>
                <Card.Text>
                  <Stack gap={1} direction="horizontal" >
                    <Button variant="primary" className="p-1">Subscribe</Button>
                    <Button variant="primary" className="p-1">Become a Member</Button>
                    <Button variant="primary" className="p-1"><HelpCenterIcon /></Button>
                    <Button variant="primary" className="p-1"><VideoCameraFrontIcon /></Button>
                  </Stack>
                </Card.Text>
              </div>
            </Card.ImgOverlay>
          </Card>
          <PavilionNavBar/> 
        </div>
       <Outlet/> 
       </Container>
    </PavilionLayout>
  )
}

export default DetailPavilion
