import React,{useState, useEffect} from 'react'
import Header101 from '../../components/Header101'
import PublicHeader from '../../components/Header102'
import Footer101 from '../../components/Footer101'
// import PageBg from '../../assets/img/bg-light.png';
// import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
// import { AccountBalanceOutlined } from '@mui/icons-material'
// import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
// import Button from 'react-bootstrap/Button';
// import Typography from '@mui/material/Typography';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import { SvgIcon } from '@mui/material';
// import myComm from '../../assets/icons/mycommunity.svg';
// import myPublicProfile from '../../assets/icons/mypublicprofile.svg';
// import updateProfile from '../../assets/icons/updateprofile.svg';
import LeftBox from '../../components/pavilion/Boxes/LeftBox';
import RightBox from '../../components/pavilion/Boxes/RightBox';
import MiddleBox from '../../components/pavilion/Boxes/MiddleBox';
import {useLocation, useNavigate} from 'react-router-dom'
import PreLoader from '../../components/PreLoader';
// import { PLSIZE } from '../../components/PavilionConstants';
import http from '../../http';
import Swal from 'sweetalert2'


function HomePavilion() {

  const [isProfileComplete, setIsProfileComplete]=useState();
  
  let [state, setState] = useState(false);
  const token=localStorage.getItem('token');
  const [loading, setLoading]=useState(true);
  const [isPublicPage,setIsPublicPage]=useState(false);
  
  const [pvlnIndex,setPvlnIndex]=useState();
  const [pvlnCode,setPvlnCode]=useState();

  function handleState(newValue,idx) {
    setPvlnIndex(idx);
    setPvlnCode(newValue);
   // alert(`homepavilion handlestate change ${idx}`)
     if (newValue.substring(0, 4)==='PVLN')  {setState(true)}
     if (newValue==='PVLN-HOME')  {setState(false)}
     if (newValue.substring(0, 4)==='PRFL')  {setState(false)}
   }

   const navigate=useNavigate();

   const location= useLocation();
   const splitURL = location.pathname.toString().split("/");
 

   useEffect(() => {
    setLoading(false);
   },[]);

   useEffect(() => {
    if(token){
      // http().get('/userdetails').then((data) => {
      //   setIsProfileComplete(data.data.data.profile)
      //   // localStorage.setItem("profileComplete", data.data.data.profile);
      //   if(data.data.data.profile===0){
      //     Swal.fire({
      //       title: "Please complete your profile",
      //       text: "",
      //       icon: "warning",
      //       showCancelButton: false,
      //       confirmButtonColor: "#e30613",
      //       cancelButtonColor: "#333",
      //       confirmButtonText: "OK"
      //     }).then((result) => {
      //       if (result.isConfirmed) {
      //           console.log('yes')
      //       }
      //     });
      //   }
        
      // });
    }
   },[isProfileComplete]);

   useEffect(() => {
    const param = ['poll','walls','event','publication','newsinformation', 'about_pavilion','shareprofiles'];
      if(param.includes(splitURL[1])){
        if (splitURL[2] ) {   
          setIsPublicPage(!token)
        } 
      } 
    },[isPublicPage]);

  return (
    
    <div className="wrapper" >
      {loading ? (<PreLoader size={70} position='center' />) : (<>
      {/* {token && <Header101/>} */}
      {isPublicPage ? <PublicHeader/> : <Header101/>}
      <Container className="content-container content d-flex justify-content-center" >
        <Row className='container my-3 d-flex'>
          <Col md={4} className="gap-4 mb-3 mb-md-0"><LeftBox change = {handleState} profile={isProfileComplete}  /></Col >
          <Col md={4} className="gap-4 mb-3 mb-md-0 d-flex flex-column align-items-center" ><MiddleBox change = {handleState} pavilion={state} pvlnIndex={pvlnIndex} profile={isProfileComplete}/></Col>
          <Col md={4} className="gap-4 mb-3 mb-md-0"><RightBox change = {handleState} pvlnIndex={pvlnIndex} pvlnCode={pvlnCode} pavilion={state} profile={isProfileComplete}/></Col>
        </Row>
      </Container>
       <Footer101/>
      </>)}
    </div>
  )
}

export default HomePavilion
