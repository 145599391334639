import React, { useState } from 'react'
import PavilionLayout from '../../components/layouts/PavilionLayout'
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import Banner from '../../assets/img/74861673933396.png'
import Group from '@mui/icons-material/Group';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Container, Stack } from 'react-bootstrap'
import PavilionLeftSidebar from '../../components/pavilion/PavilionLeftSidebar';
import PavilionHeader from '../../components/pavilion/PavilionHeader';
import PavilionRightSidebar from '../../components/pavilion/PavilionRightSidebar';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ProfileNavBar from '../../components/pavilion/ProfileNavBar';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';
import SaveIcon from '@mui/icons-material/Save';
import { Outlet } from 'react-router-dom';
import ChatButton from '../../components/ChatButton';

function ProfilePavilion(props) {

    const [showLS, setShowLS] = useState(true);

    function handleToggle1(evt) {
        if (showLS) { setShowLS(false) }
        else { setShowLS(true) }
    }

      
    return (
        <>
            <PavilionHeader showLSB={showLS} isLoggedIn={false} handleToggle={handleToggle1} />
            {showLS && (<PavilionLeftSidebar showLSB={showLS} isLoggedIn={false} isPavilion={false} showAP={true} />)}
            <main id="main" style={{ marginLeft: showLS ? '240px' : '0' }} className="bg-header main px-0 pt-0" data-aos="fade-up">
                <Container fluid>
                    <div className="bg-header sticky-top" >
                        <div className="w-100 bg-header" style={{ minHeight: '65px' }}>&nbsp;</div>
                        <Card style={{ width: '100%' }} className='card-rounded mb-0'>
                            <Card.Img variant="top" src={Banner} />
                            <Card.ImgOverlay className="d-flex flex-column justify-content-end px-3 py-0">
                                <Card.Title className="h4 mb-0 mt-5 mt-sm-0 fs-2vw text-light">Charles Smith</Card.Title>
                                <div className="d-flex justify-content-between text-light">
                                    <Card.Text>
                                        <Group />&nbsp;4 Members
                                    </Card.Text>
                                    <Card.Text>
                                        <Stack gap={1} direction="horizontal" >
                                            <Button variant="primary" className="p-1">Update Banner</Button>

                                        </Stack>
                                    </Card.Text>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                        <ProfileNavBar />
                    </div>
                    <Outlet/> 
                </Container>
            </main>
            <ChatButton/>
            <PavilionRightSidebar showAP={true} />
        </>
    )
}

export default ProfilePavilion
