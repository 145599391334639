import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search, Settings } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import myComm from '../../../../assets/icons/mycommunity.svg';
import myPublicProfile from '../../../../assets/icons/mypublicprofile.svg';
import updateProfile from '../../../../assets/icons/updateprofile.svg';
import myPavilions from '../../../../assets/icons/mypavilions.svg';
import whosWho from '../../../../assets/icons/whoswho.svg';
import myEvents from '../../../../assets/icons/myevents.svg';
import myResources from '../../../../assets/icons/myresources.svg';
import myWall from '../../../../assets/icons/mywall.svg';
import myWallGallery from '../../../../assets/icons/mywallgallery.svg';
import purchaseHistory from '../../../../assets/icons/purchasehistory.svg';
import helpSupport from '../../../../assets/icons/helpnsupport.svg';
import info from '../../../../assets/icons/info.svg';
import aboutPavilion from '../../../../assets/icons/about.svg';
import howTo from '../../../../assets/icons/videos.svg';
import businessServices from '../../../../assets/icons/businessservices.svg';
import signIn from '../../../../assets/icons/signin.svg';
import featuredOfferings from '../../../../assets/icons/featured-offerings.svg';
import trendingPavilions from '../../../../assets/icons/trending-pavilion.svg';
import publications from '../../../../assets/icons/publications.svg';
import Spinner from 'react-bootstrap/Spinner';
// import ReactDOM from "react-dom";
import PlusIcon from '@mui/icons-material/AddCircleTwoTone';
import MinusIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Collapse from 'react-bootstrap/Collapse';
import { useNavigate, useLocation } from 'react-router-dom';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';

function PRFLMenu2({ handleClick }) {

    const token=localStorage.getItem('token')
    const [loading, setLoading] = useState(true);
    // componentDidMount(){
    const [open, setOpen] = useState(true);
    const navigate = useNavigate()
    const location = useLocation();
    // }
    const splitURL = location.pathname.toString().split("/");
    useEffect(() => {
        //  if (splitURL[1] === 'about_pavilion') {
        //      if (splitURL[2] ) {
        //  	   handleClick('PRFL-MENU2')
        //      } 
        //  }
        setLoading(false)
    }, [])


    const handleLogout = () => {
        localStorage.setItem('token', '');
        navigate(`/`);
      };
    return (
        <>
            {loading ? (<Spinner className="position-relative start-50 top-50 " animation="border" />) : (

                <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
                    <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                        <Typography variant="small">About Pavilion</Typography>
                        <IconButton ><Settings /></IconButton>
                        <IconButton
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            {open ? (<MinusIcon />) : (<PlusIcon />)}
                        </IconButton>
                    </ListGroup.Item>
                    
                    <ListGroup.Item className="d-flex flex-column align-items-center justify-content-start h-100  border-0 bg-light bg-opacity-75" >
                        <Container className="slim-scrollbar mh-70  px-0 py-0">
                        <Collapse in={open} >
                            <div id="example-collapse-text" className="profile-grid py-2 ">
                                <Button onClick={token ? handleLogout : () => navigate(`/`)} size="sm" variant="light" className="d-flex flex-column small align-items-center border-0 justify-content-end pmenu" >
                                    <img src={signIn} className="mb-1" alt="My Public Profile" />
                                    <Typography variant="small">{token ? 'Logout' : 'Login'}</Typography>
                                </Button>
                                {/* <Button onClick={event => handleClick('PRFL02')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                                    <img src={aboutPavilion} className="mb-1" alt="My Public Profile" />
                                    <Typography variant="small">About Pavilion</Typography>
                                </Button> */}
                                <Button onClick={event => handleClick('PRFL-PRDET')} variant="light" size="sm" className="d-flex small flex-column align-items-center justify-content-end border-0" >
                                    {/* <img src={updateProfile} className="mb-1" alt="My Community" /> */}
                                    <InsightsOutlinedIcon fontSize="large" style={{color:"#e30613"}}/>
                                    <Typography variant="small">Insights</Typography>
                                </Button>
                                <Button onClick={event => handleClick('PRFL-SUPTR')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                                    <img src={myComm} className="mb-1" alt="My Events" />
                                    <Typography variant="small">Supporters</Typography>
                                </Button>
                                <Button onClick={event => handleClick('PRFL-OFFER')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                                    <img src={featuredOfferings} className="mb-1" alt="My Pavilion" />
                                    <Typography variant="small">Featured Offerings</Typography>
                                </Button>
                                <Button onClick={event => handleClick('PRFL-EVNTS')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                                    <img src={myEvents} className="mb-1" alt="Upcoming Events" />
                                    <Typography variant="small">Upcoming Events</Typography>
                                </Button>
                                <Button onClick={() => navigate(`/plans`)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                                    <img src={businessServices} className="mb-1" alt="Launch My Pavilion" />
                                    <Typography variant="small">Launch My Pavilion</Typography>
                                </Button>
                            </div>
      </Collapse>
    
                            <h5>Purpose:</h5>
                            <p>The All-Party Parliamentary Group on Artificial Intelligence (APPG AI) was set up in January 2017, with the aim to explore the impact and implications of Artificial Intelligence.</p>
                            <p>All-Party Parliamentary Groups (APPGs) are informal all-party groups in UK Parliament. They are run by and for Members of the Commons and Lords. The APPG AI functions as the permanent authoritative voice within UK Parliament (House of Commons and House of Lords) on all AI-related matters.</p>
                            <Image  rounded src={localStorage.getItem('bucketName')+"/images/paviion_purpose/thumbnails/59881669685236.png"} />
                            <h5 className='mt-2'>Further Details:</h5>
                            <p>We focus on Machine Learning, Facial Recognition, National Security, Cybersecurity, Digital & Physical Infrastructure, Data Policy & Governance, Market Intelligence, Education (skills, jobs & the future of work), Health (diagnostics, COVID, telehealth), AI in the Boardroom, Fin-tech Automation, Innovation & Entrepreneurship, Autonomous Weapons, Sustainability, Policy and Regulation Landscaping + much more.</p>
                            <p>We bring evidence, use cases and future policy scenarios into the UK Parliament while considering the economic, social, ethical and business model implications of developing and deploying AI. Our method of working is research, round tables, webinars, showcasing, taskforces, and events. </p>
                            <Image rounded src={localStorage.getItem('bucketName')+"/images/paviion_further/thumbnails/57411669716654.png"} />
                        </Container>
                    </ListGroup.Item>
                </ListGroup>

            )}
        </>
    )
}

export default PRFLMenu2
