import React, { useState } from 'react'
import http from '../http';
import {useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

function ContactForm() {
  const navigate = useNavigate()
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const fnameHandler = (event) => {
    setFname(event.target.value)
  }
  const lnameHandler = (event) => {
    setLname(event.target.value)
  }
  const emailHandler = (event) => {
    setEmail(event.target.value)
  }
  const phoneHandler = (event) => {
    setPhone(event.target.value)
  }
  const messageHandler = (event) => {
    setMessage(event.target.value)
  }
  function contactSubmit() {
    http().post('/contactuspublic', { fname: fname, lname: lname, email: email, phone: phone, message: message})
        .then((res) => {
          Swal.fire({
            title: 'Contact us request sent.',
            icon: 'success',
        });
        navigate('/contact');
        })
        .catch((error) => {
            console.error('Error:', error);
            Swal.fire({
              title: 'An error occurred. Please try again.',
              icon: 'error',
          });
        });
  }
  const submitHandler = (event) => {
    event.preventDefault()
    contactSubmit()
  }
  return (
    <form className="py-1 p-md-0 rounded-3 bg-light" onSubmit={submitHandler}>
                  <div className="d-flex justify-content-between">
                    <div className="form-floating w-50 me-3 mb-3">
                      <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={fnameHandler}/>
                      <label htmlFor="floatingInput">First Name</label>
                    </div>
                    <div className="form-floating w-50 mb-3">
                      <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={lnameHandler}/>
                      <label htmlFor="floatingInput">Last Name</label>
                    </div>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={emailHandler}/>
                    <label htmlFor="floatingInput">Email Address</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="number" className="form-control" id="floatingPassword" placeholder="Phone" onChange={phoneHandler}/>
                    <label htmlFor="floatingPassword">Phone</label>
                  </div>
                  <div className="form-floating mb-3">
                    <textarea id="message" className="form-control" name="message" required="" placeholder="Message" onChange={messageHandler}></textarea>
                    <label htmlFor="floatingPassword">Message</label>
                  </div>

                  <div className="checkbox mb-3 d-flex justify-content-between align-items-center">
                    <button className="btn btn-lg btn-primary" type="submit">Submit</button>
                  </div>
                </form>
  )
}

export default ContactForm
